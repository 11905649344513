import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation }                  from 'react-i18next';
import { connect }                         from 'react-redux';
import { push }                            from 'connected-react-router';

import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';
import { ECardStatuses }     from '@common/enums';
import {
  IReviewResponseFormValues,
  useReviewResponseModal,
}                      from '@components/Modal/ReviewResponseModal';
import { DeleteModal } from '@components/Modal/DeleteModal';

import { ERequestedReviewStatus } from '@models/enums';
import { IRequestedFeedModel }    from '@src/models';
import { Modal }                  from '@src/App';
import { getRequestStatusLabel }  from '@utils/getRequestStatusLabel';
import { IState }                 from '@store/rootReducer';
import { store }                  from '@store/index';
import {
  deleteRequestedFeeds      as deleteRequestedFeedsActions,
  fetchRequestedFeeds       as fetchRequestedFeedsActions,
  updateRequestedFeedStatus as updateRequestedFeedStatusActions,
  getMeta,
  getRequestedFeeds,
  IRequestedFeedsActionsCreators,
  IRequestedFeedsReducer,
} from '../requestedFeedsReducer';
import { IFeedsLocationProp } from './FeedsContainer';

interface IRequestedFeeds {
  deleteRequestedFeeds      : IRequestedFeedsActionsCreators['deleteRequestedFeeds'];
  fetchRequestedFeeds       : IRequestedFeedsActionsCreators['fetchRequestedFeeds'];
  updateRequestedFeedStatus : IRequestedFeedsActionsCreators['updateRequestedFeedStatus'];
  requestedFeeds            : IRequestedFeedModel[];
  requestedFeedsMeta        : IRequestedFeedsReducer['meta'];
}

const RequestedFeedList = ({
  deleteRequestedFeeds,
  fetchRequestedFeeds,
  updateRequestedFeedStatus,
  requestedFeedsMeta,
  requestedFeeds,
}: IRequestedFeeds) => {
  const { t }   = useTranslation();
  
  const { openReviewResponseModal } = useReviewResponseModal();
  
  const header = useMemo(() => (
    <CardHeader title = {t('requestedFeeds')} />
  ), []);
  
  const onClickRequestChanges = useCallback((requestedFeed: IRequestedFeedModel, status: ERequestedReviewStatus) => {
    const onSubmit = async (values: IReviewResponseFormValues) => {
      updateRequestedFeedStatus({
        EmailText     : values.message,
        feedRequestId : requestedFeed.feedRequestId,
        Status        : status,
      });
    };

    openReviewResponseModal(requestedFeed.publisherName, status, onSubmit);
  }, [openReviewResponseModal]);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchRequestedFeeds({ page: page !== requestedFeedsMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [requestedFeedsMeta.page, requestedFeedsMeta.rowsPerPage]);

  const openFeedForm = useCallback((requestedFeed: IRequestedFeedModel) => {
    const initialValues: IFeedsLocationProp['initialValues'] = {
      requestedFeedId : requestedFeed.feedRequestId,
      rFeedChannel    : [{
        label : requestedFeed.channelName,
        value : requestedFeed.channelId,
      }],
      rFeedTerritory : [{ area: {
        name        : requestedFeed.territoryName,
        territoryId : requestedFeed.territoryId,
        type        : requestedFeed.territoryType,
      } }],
    };

    const suggestedValues: IFeedsLocationProp['suggestedValues'] = [
      { id: 'name',       label: 'Publisher name', value: requestedFeed.publisherName },
      { id: 'websiteUri', label: 'Publisher URL',  value: requestedFeed.publisherUri },
    ];

    store.createdStore.dispatch(push('/admin/feeds/add', {
      initialValues,
      suggestedValues,
      notes: requestedFeed.notes,
    }));
  }, []);

  const deleteFeed = useCallback(async (feedRequestId: (number | string)[]) => {
    deleteRequestedFeeds(feedRequestId as number[]);
  }, []);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'publisherName',        label: t('publisherName') },
    { id: 'channelName',          label: t('channelName') },
    { id: 'status',               label: t('status') },
    { id: 'addButton',            label: t('')},
    { id: 'requestChangesButton', label: t('')},
    { id: 'denyButton',           label: t('')},
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (requestedFeeds.map((requestedFeed) => ({
    publisherName : requestedFeed.publisherName || '',
    channelName   : requestedFeed.channelName || '',
    status        : {
      component : getRequestStatusLabel(requestedFeed.status) || '-',
      tag       : 'component',
    },
    addButton : {
      onClick : () => openFeedForm(requestedFeed),
      tag     : 'link',
      title   : 'add',
    },
    requestChangesButton : {
      onClick : () => onClickRequestChanges(requestedFeed, ERequestedReviewStatus.RequestedChanges),
      tag     : 'link',
      title   : 'request changes',
    },
    denyButton : {
      onClick : () => onClickRequestChanges(requestedFeed, ERequestedReviewStatus.Denied),
      tag     : 'link',
      title   : 'deny',
    },
    id : requestedFeed.feedRequestId || '',
  }))), [requestedFeeds]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 2 },
      { flex: 2 },
      { flex: 2.5 },
      { flex: 1 },
      { flex: 2 },
      { flex: 1 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : requestedFeedsMeta.page + 1,
      total           : requestedFeedsMeta?.totalRequestedFeeds,
      pageSize        : requestedFeedsMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [requestedFeedsMeta.page, requestedFeedsMeta?.totalRequestedFeeds, requestedFeedsMeta.rowsPerPage]);

  useEffect(() => {
    fetchRequestedFeeds();
  }, []);

  return (
    <Card
      header = {header}
      status = {requestedFeedsMeta.isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!requestedFeedsMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            const requestedFeedForDeleting = requestedFeeds.find(obj => (obj.feedRequestId === ids[0]));
            Modal.open(DeleteModal, {
              onDeleteConfirm : () => {
                deleteFeed(ids);
                Modal.close();
              },
              confirmMessage    : (ids.length === 1) ? 'The requested feed will be removed' : 'You cannot restore deleted data',
              entityNameMessage : `For removing enter the full publisher name: "${requestedFeedForDeleting?.publisherName}"`,
              name              : (ids.length === 1) ? `${requestedFeedForDeleting?.publisherName}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  requestedFeeds     : getRequestedFeeds(state),
  requestedFeedsMeta : getMeta(state),
});
  
const mapDispatchToProps = {
  deleteRequestedFeeds      : deleteRequestedFeedsActions,
  fetchRequestedFeeds       : fetchRequestedFeedsActions,
  updateRequestedFeedStatus : updateRequestedFeedStatusActions,
};

export const RequestedFeedsList = connect(mapStateToProps, mapDispatchToProps)(RequestedFeedList);
