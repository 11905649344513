import { TTimeout } from '@src/models';

export const debounce = (() => {
  let timerId: TTimeout | null = null;

  return (fn: () => void, ms = 600) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      timerId = null;
      fn();
    }, ms);
  };
})();
