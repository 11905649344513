import { useEffect }                from 'react';
import { Form, Field, FormikProps } from 'formik';
import styled                       from 'styled-components';

import { Input, Button, Select } from '@common/components';

import { AreaSelection } from '@components/AreaSelection/AreaSelection';
import { MediaUploader } from '@components/MediaUploader/MediaUploader';
import { ColorSelect }   from '@components/ColorSelect/ColorSelect';
import { Title }         from '@components/Title/Title';

import { IOptionType, LOCALES } from '@models/index';

import { TChannelValues }   from '../pages/AddChannelContainer';
import { IChannelsReducer } from '../channelsReducerL';

export enum CHANNELS { GLOBAL, REGIONAL, TERRITORY_SPECIFIC }

const CHANNEL_TYPES = [
  { label: 'Global - Available in all Areas', value: CHANNELS.GLOBAL },
  { label: 'Regional - Available in Select Areas', value: CHANNELS.REGIONAL },
  { label: 'Area-Specific - City Channel', value: CHANNELS.TERRITORY_SPECIFIC },
];

interface IChannelForm extends FormikProps<TChannelValues> {
  categories         : IOptionType[];
  isChannelsFetching : boolean;
  setChannelType     : (type: CHANNELS) => void;
  parent             : IChannelsReducer['categories'][0];
}

export const ChannelForm = ({
  categories,
  isChannelsFetching,
  setChannelType,
  parent,
  values,
}: IChannelForm) => {
  useEffect(() => {
    setChannelType(values.channelType);
  }, [values.channelType]);

  return (
    <>
      <Title name={`${values.channelId ? 'edit the' : 'add a'} channel`} />

      <ChannelForm.Wrapper>
        <ChannelForm.Column>
          <Field
            disabled    = {!values.channelId}
            label       = "Channel category"
            name        = "channelCategoryId"
            placeholder = "Channel category"
            options     = {categories}
            component   = {Select}
          />
          <Field
            label       = "name"
            name        = "name"
            placeholder = "Enter a name"
            component   = {Input}
          />
          <Field
            required
            label           = "icon image"
            name            = "iconUri"
            labelAlignment  = "top"
            container       = "channels"
            component       = {MediaUploader}
            mimeTypeOptions = "image"
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '58px',
              width           : '58px',
              borderRadius    : '50%',
            }}
          />
          <Field
            isWithResizer
            withRemove
            required        = {false}
            aspectRatio     = {378 / 112}
            label           = "header image"
            name            = "headerUri"
            nameSecondary   = "(Optional)"
            labelAlignment  = "top"
            container       = "channels"
            mimeTypeOptions = "image"
            component       = {MediaUploader}
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '100px',
            }}
          />
          <Field
            withRemove
            required        = {false}
            label           = "thumbnail image"
            name            = "thumbnailUri"
            nameSecondary   = "(Optional)"
            labelAlignment  = "top"
            container       = "channels"
            component       = {MediaUploader}
            mimeTypeOptions = "image"
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '100px',
            }}
          />
        </ChannelForm.Column>

        <ChannelForm.Column rightSide>
          <Field
            disabled    = {parent.isCitySpecific}
            label       = "channel reach"
            name        = "channelType"
            placeholder = "Choose where this channel will show up"
            options     = {CHANNEL_TYPES}
            component   = {Select}
          />
          {values.channelType !== CHANNELS.GLOBAL && (
            <Field
              component        = {AreaSelection}
              isSingleCityOnly = {values.channelType === CHANNELS.TERRITORY_SPECIFIC}
              name             = "rChannelTerritory"
            />
          )}
          <Field
            component = {ColorSelect}
            label     = "Color"
            name      = "color"
          />
          <Field
            required    = {false}
            label       = "locale"
            name        = "locale"
            placeholder = "EN-US"
            options     = {LOCALES}
            component   = {Select}
          />
          <ChannelForm.Tip>
            Channels can be Global or you can limit them to certain Territories. For example, for the local sports team or news channel.
          </ChannelForm.Tip>
        </ChannelForm.Column>

        <ChannelForm.ButtonWrapper>
          <Button
            label     = {values.channelId ? 'Save' : 'Add'}
            disabled  = {isChannelsFetching}
          />
        </ChannelForm.ButtonWrapper>
      </ChannelForm.Wrapper>
    </>
  );
};

ChannelForm.ButtonWrapper = styled.div`
  display         : flex;
  justify-content : end;
  margin-top      : 29px;
  width           : 100%;
`;

ChannelForm.Tip = styled.p`
  margin-top   : 30px;
  color        : ${({ theme }) => theme.textColor.gray};
`;

ChannelForm.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : start;
  `}
`;

ChannelForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
