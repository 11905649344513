import { useState, useCallback, useRef } from 'react';

import { TApiCaller, TOption } from '../models';
import { debounce }            from '../utils';

export type TDebounceFetcherHook = <T>(
  endpoint       : string,
  responseMapper : (data: T[]) => TOption[],
) => [TOption[], boolean, (query: string) => void];

type TDebounceFetchInitializer = (apiHandler: TApiCaller) => TDebounceFetcherHook;

// eslint-disable-next-line arrow-body-style
export const initializeDebounceFetchHook: TDebounceFetchInitializer = (apiHandler) => {
  return (endpoint, responseMapper) => {
    const fetchId = useRef(0);

    const [isFetching, setFetchingStatus] = useState(false);
    const [data, setData]                 = useState<TOption[]>([]);

    const fetchData = useCallback(async (value: string) => {
      setData([]);
      setFetchingStatus(true);

      try {
        fetchId.current += 1;

        const fetchIdLatest          = fetchId.current;
        const { data: dataResponse } = await apiHandler(`${endpoint}=${value}`);
        const mappedData             = responseMapper(dataResponse);

        if (fetchIdLatest !== fetchId.current) {
          return;
        }

        setData(mappedData);
        setFetchingStatus(false);
      } catch (e) {
        setFetchingStatus(false);
      }
    }, [endpoint, fetchId]);

    const onSearch = useCallback((inputValue: string) => {
      debounce(() => {
        if (inputValue.length > 1) {
          fetchData(inputValue);
        } else {
          setData([]);
        }
      });
    }, [fetchData]);

    return [data, isFetching, onSearch];
  };
};
