import { useCallback, useEffect, useMemo  }          from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';

import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';
import { ECardStatuses }     from '@common/enums';
import { routesConfig }      from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }       from '@components/Modal/DeleteModal';

import { IForms }  from '@src/models';
import { IState }  from '@store/rootReducer';
import { Modal }   from '@src/App';
import {
  fetchForms  as fetchFormsAction,
  deleteForms as deleteFormsAction,
  getForms, getMeta,
  IFormsActionsCreators,
  IFormsReducer,
} from '../formsReducer';

interface IFormsList {
  forms       : IForms[];
  fetchForms  : IFormsActionsCreators['fetchForms'];
  deleteForms : IFormsActionsCreators['deleteForms'];
  isFetching  : boolean;
  formsMeta   : IFormsReducer['meta'];
  totalForms  : number;
}

export const FormsListContainer = ({
  forms,
  fetchForms,
  deleteForms,
  isFetching,
  formsMeta,
  totalForms,
}: IFormsList) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const onClickForm = useCallback((form: IForms) => {
    history.push(routesConfig.formsEdit.endpoint.replace(':id', form.settingsId.toString()));
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchForms({ page: page !== formsMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [formsMeta.page, formsMeta.rowsPerPage]);

  const deleteForm = useCallback(async (settingsId: (number | string)[]) => {
    deleteForms(settingsId as number[]);
    fetchForms();
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons = {[{ action: () => history.push(routesConfig.formsAdd.endpoint), label: t('createForm') }]}
      title   = {t('forms')}
    />
  ), []);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'categoryName', label: t('categoryName') },
    { id: 'name', label: t('name') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (forms.map((form) => ({
    categoryName : {
      onClick : () => onClickForm(form),
      tag     : 'link',
      title   : form.categoryName || '---',
    },
    name :  form.name || '---',
    id   : form.settingsId,
  }))), [forms]);

  const tableSettings = useMemo(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 3 },
      { flex: 2 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : formsMeta.page + 1,
      total           : totalForms,
      pageSize        : formsMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [formsMeta.page, totalForms, formsMeta.rowsPerPage]);

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!formsMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => Modal.open(DeleteModal, {
            onDeleteConfirm : () => {
              deleteForm(ids);
              Modal.close();
            },
            confirmMessage    : (ids.length === 1) ? 'The Form will be removed' : 'You cannot restore deleted data',
            entityNameMessage : `For removing enter the form ID "${ids}"`,
            name              : (ids.length === 1) ? `${ids[0]}` : '', 
          })}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  forms      : getForms(state),
  formsMeta  : getMeta(state),
  totalForms : getMeta(state).totalForms,
  isFetching : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  fetchForms  : fetchFormsAction,
  deleteForms : deleteFormsAction,
};

export const FormsList = connect(mapStateToProps, mapDispatchToProps)(FormsListContainer);
