import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState }                            from '@store/rootReducer';
import { IBusinessModel, IBusinessLocation } from '@models/index';
import { ITableMeta }                        from '@components/Table/ClickableItem';

export const STATE_KEY = 'businesses';

export interface IBusinessesReducer {
  businesses : Required<IBusinessModel>[];
  branches   : IBusinessLocation[];
  meta       : {
    isFetching       : boolean;
    isBranchFetching : boolean;
    totalBusinesses  : number;
    page             : number;
    rowsPerPage      : number;
    orderBy          : 'Name' | 'Status';
    order            : 'Asc' | 'Desc';
  };
}

export enum BusinessTypes {
  fetchBusinesses              = 'fetchBusinesses',
  fetchBusinessesSuccessfully  = 'fetchBusinessesSuccessfully',
  fetchBusinessesFailure       = 'fetchBusinessesFailure',
  addBusiness                  = 'addBusiness',
  addBusinessSuccessfully      = 'addBusinessSuccessfully',
  addBusinessFailure           = 'addBusinessFailure',
  editBusiness                 = 'editBusiness',
  editBusinessSuccessfully     = 'editBusinessSuccessfully',
  editBusinessFailure          = 'editBusinessFailure',
  deleteBusinesses             = 'deleteBusinesses',
  deleteBusinessesSuccessfully = 'deleteBusinessesSuccessfully',
  deleteBusinessesFailure      = 'deleteBusinessesFailure',

  fetchBranches             = 'fetchBranches',
  fetchBranchesSuccessfully = 'fetchBranchesSuccessfully',
  fetchBranchesFailure      = 'fetchBranchesFailure',
  addBranch                 = 'addBranch',
  addBranchSuccessfully     = 'addBranchSuccessfully',
  addBranchFailure          = 'addBranchFailure',
  editBranch                = 'editBranch',
  editBranchSuccessfully    = 'editBranchSuccessfully',
  editBranchFailure         = 'editBranchFailure',
  deleteBranch              = 'deleteBranch',
  deleteBranchSuccessfully  = 'deleteBranchSuccessfully',
  deleteBranchFailure       = 'deleteBranchFailure',
}

export interface IBusinessesActions {
  [BusinessTypes.fetchBusinesses]              : Action<{ meta: ITableMeta }>;
  [BusinessTypes.fetchBusinessesSuccessfully]  : Action<{ businesses: IBusinessModel[]; meta: { totalBusinesses: number } }>;
  [BusinessTypes.fetchBusinessesFailure]       : Action<{}>;
  [BusinessTypes.addBusiness]                  : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true } }>;
  [BusinessTypes.addBusinessSuccessfully]      : Action<{ meta: { isFetching: false }}>;
  [BusinessTypes.addBusinessFailure]           : Action<{ meta: { isFetching: false }}>;
  [BusinessTypes.editBusiness]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true } }>;
  [BusinessTypes.editBusinessSuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [BusinessTypes.editBusinessFailure]          : Action<{ meta: { isFetching: false }}>;
  [BusinessTypes.deleteBusinesses]             : Action<{ attributes: { businessIds: number[]; meta: { isFetching: true } }}>;
  [BusinessTypes.deleteBusinessesSuccessfully] : Action<{}>;
  [BusinessTypes.deleteBusinessesFailure]      : Action<{}>;

  [BusinessTypes.fetchBranches]             : Action<{ attributes: { branches: []; businessId: number } }>;
  [BusinessTypes.fetchBranchesSuccessfully] : Action<{ branches: IBusinessLocation[] }>;
  [BusinessTypes.fetchBranchesFailure]      : Action<{}>;
  [BusinessTypes.addBranch]                 : Action<{ attributes: { [values: string]: any }; meta: { isBranchFetching: true } }>;
  [BusinessTypes.addBranchSuccessfully]     : Action<{ meta: { isBranchFetching: false } }>;
  [BusinessTypes.addBranchFailure]          : Action<{ meta: { isBranchFetching: false } }>;
  [BusinessTypes.editBranch]                : Action<{ attributes: { [values: string]: any }; meta: { isBranchFetching: true } }>;
  [BusinessTypes.editBranchSuccessfully]    : Action<{ meta: { isBranchFetching: false } }>;
  [BusinessTypes.editBranchFailure]         : Action<{ meta: { isBranchFetching: false } }>;
  [BusinessTypes.deleteBranch]              : Action<{ attributes: { businessLocationId: number; businessId: number } }>;
  [BusinessTypes.deleteBranchSuccessfully]  : Action<{}>;
  [BusinessTypes.deleteBranchFailure]       : Action<{}>;
}

export interface IBusinessesActionsCreators {
  [BusinessTypes.fetchBusinesses]              : (businessMeta?: ITableMeta) => IBusinessesActions[BusinessTypes.fetchBusinesses];
  [BusinessTypes.fetchBusinessesSuccessfully]  : (businesses: IBusinessModel[], totalCount: number) => IBusinessesActions[BusinessTypes.fetchBusinessesSuccessfully];
  [BusinessTypes.fetchBusinessesFailure]       : () => IBusinessesActions[BusinessTypes.fetchBusinessesFailure];
  [BusinessTypes.addBusiness]                  : (values: any) => IBusinessesActions[BusinessTypes.addBusiness];
  [BusinessTypes.addBusinessSuccessfully]      : () => IBusinessesActions[BusinessTypes.addBusinessSuccessfully];
  [BusinessTypes.addBusinessFailure]           : () => IBusinessesActions[BusinessTypes.addBusinessFailure];
  [BusinessTypes.editBusiness]                 : (values: any) => IBusinessesActions[BusinessTypes.editBusiness];
  [BusinessTypes.editBusinessSuccessfully]     : () => IBusinessesActions[BusinessTypes.editBusinessSuccessfully];
  [BusinessTypes.editBusinessFailure]          : () => IBusinessesActions[BusinessTypes.editBusinessFailure];
  [BusinessTypes.deleteBusinesses]             : (businessIds: number[]) => IBusinessesActions[BusinessTypes.deleteBusinesses];
  [BusinessTypes.deleteBusinessesSuccessfully] : () => IBusinessesActions[BusinessTypes.deleteBusinessesSuccessfully];
  [BusinessTypes.deleteBusinessesFailure]      : () => IBusinessesActions[BusinessTypes.deleteBusinessesFailure];

  [BusinessTypes.fetchBranches]             : (businessId: number) => IBusinessesActions[BusinessTypes.fetchBranches];
  [BusinessTypes.fetchBranchesSuccessfully] : (branches: IBusinessLocation[]) => IBusinessesActions[BusinessTypes.fetchBranchesSuccessfully];
  [BusinessTypes.fetchBranchesFailure]      : () => IBusinessesActions[BusinessTypes.fetchBranchesFailure];
  [BusinessTypes.addBranch]                 : (values: any) => IBusinessesActions[BusinessTypes.addBranch];
  [BusinessTypes.addBranchSuccessfully]     : () => IBusinessesActions[BusinessTypes.addBranchSuccessfully];
  [BusinessTypes.addBranchFailure]          : () => IBusinessesActions[BusinessTypes.addBranchFailure];
  [BusinessTypes.editBranch]                : (values: any) => IBusinessesActions[BusinessTypes.editBranch];
  [BusinessTypes.editBranchSuccessfully]    : () => IBusinessesActions[BusinessTypes.editBranchSuccessfully];
  [BusinessTypes.editBranchFailure]         : () => IBusinessesActions[BusinessTypes.editBranchFailure];
  [BusinessTypes.deleteBranch]              : (values: any) => IBusinessesActions[BusinessTypes.deleteBranch];
  [BusinessTypes.deleteBranchSuccessfully]  : () => IBusinessesActions[BusinessTypes.deleteBranchSuccessfully];
  [BusinessTypes.deleteBranchFailure]       : () => IBusinessesActions[BusinessTypes.deleteBranchFailure];
}

type Actions = IBusinessesActions[BusinessTypes.fetchBusinessesSuccessfully]
| IBusinessesActions[BusinessTypes.fetchBusinessesFailure]
| IBusinessesActions[BusinessTypes.addBusiness]
| IBusinessesActions[BusinessTypes.addBusinessSuccessfully]
| IBusinessesActions[BusinessTypes.addBusinessFailure]
| IBusinessesActions[BusinessTypes.editBusinessSuccessfully]
| IBusinessesActions[BusinessTypes.editBusinessFailure]
| IBusinessesActions[BusinessTypes.deleteBusinesses]
| IBusinessesActions[BusinessTypes.deleteBusinessesSuccessfully]
| IBusinessesActions[BusinessTypes.deleteBusinessesFailure] //
| IBusinessesActions[BusinessTypes.fetchBranchesSuccessfully]
| IBusinessesActions[BusinessTypes.fetchBranchesFailure]
| IBusinessesActions[BusinessTypes.addBranch]
| IBusinessesActions[BusinessTypes.addBranchSuccessfully]
| IBusinessesActions[BusinessTypes.addBranchFailure]
| IBusinessesActions[BusinessTypes.editBranchSuccessfully]
| IBusinessesActions[BusinessTypes.editBranchFailure]
| IBusinessesActions[BusinessTypes.deleteBranch]
| IBusinessesActions[BusinessTypes.deleteBranchSuccessfully]
| IBusinessesActions[BusinessTypes.deleteBranchFailure]

const initialState: IBusinessesReducer = {
  businesses : [],
  branches   : [],
  meta       : {
    isFetching       : false,
    isBranchFetching : false,
    totalBusinesses  : 0,
    page             : 0,
    rowsPerPage      : 50,
    orderBy          : 'Name',
    order            : 'Asc',
  },
};

export const {
  fetchBusinesses,
  fetchBusinessesSuccessfully,
  fetchBusinessesFailure,
  addBusiness,
  addBusinessSuccessfully,
  addBusinessFailure,
  editBusiness,
  editBusinessSuccessfully,
  editBusinessFailure,
  deleteBusinesses,
  deleteBusinessesSuccessfully,
  deleteBusinessesFailure,

  fetchBranches,
  fetchBranchesSuccessfully,
  fetchBranchesFailure,
  addBranch,
  addBranchSuccessfully,
  addBranchFailure,
  editBranch,
  editBranchSuccessfully,
  editBranchFailure,
  deleteBranch,
  deleteBranchSuccessfully,
  deleteBranchFailure,
}: IBusinessesActionsCreators = createActions(
  {
    FETCH_BUSINESSES              : (
      businessMeta = {},
    ) => ({ meta: { ...businessMeta, isFetching: true } }),
    FETCH_BUSINESSES_SUCCESSFULLY : (businesses, totalBusinesses) => ({ businesses, meta: { totalBusinesses, isFetching: false } }),
    FETCH_BUSINESSES_FAILURE      : () => ({}),

    ADD_BUSINESS              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    ADD_BUSINESS_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    ADD_BUSINESS_FAILURE      : () => ({ meta: { isFetching: false }}),

    EDIT_BUSINESS              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    EDIT_BUSINESS_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    EDIT_BUSINESS_FAILURE      : () => ({ meta: { isFetching: false }}),

    DELETE_BUSINESSES              : (businessIds) => ({ attributes: { businessIds }, meta: { isFetching: true } }),
    DELETE_BUSINESSES_SUCCESSFULLY : () => ({}),
    DELETE_BUSINESSES_FAILURE      : () => ({}),

    FETCH_BRANCHES              : (businessId) => ({ branches: [], attributes: { businessId }, meta: { isBranchFetching: true } }),
    FETCH_BRANCHES_SUCCESSFULLY : (branches) => ({ branches, meta: { isBranchFetching: false } }),
    FETCH_BRANCHES_FAILURE      : () => ({ meta: { isBranchFetching: false } }),

    ADD_BRANCH              : (values) => ({ attributes: { ...values }, meta: { isBranchFetching: true }}),
    ADD_BRANCH_SUCCESSFULLY : () => ({ meta: { isBranchFetching: false }}),
    ADD_BRANCH_FAILURE      : () => ({ meta: { isBranchFetching: false }}),

    EDIT_BRANCH              : (values) => ({ attributes: { ...values }, meta: { isBranchFetching: true }}),
    EDIT_BRANCH_SUCCESSFULLY : () => ({ meta: { isBranchFetching: false }}),
    EDIT_BRANCH_FAILURE      : () => ({ meta: { isBranchFetching: false }}),

    DELETE_BRANCH              : (values) => ({ attributes: values, meta: { isBranchFetching: true }}),
    DELETE_BRANCH_SUCCESSFULLY : () => ({ meta: { isBranchFetching: false }}),
    DELETE_BRANCH_FAILURE      : () => ({ meta: { isBranchFetching: false }}),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const businessesReducer = handleActions<IBusinessesReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchBusinesses,
      fetchBusinessesSuccessfully,
      addBusiness,
      addBusinessSuccessfully,
      addBusinessFailure,
      editBusiness,
      editBusinessSuccessfully,
      editBusinessFailure,
      deleteBusinesses,
      deleteBusinessesSuccessfully,
      deleteBusinessesFailure,

      fetchBranches,
      fetchBranchesSuccessfully,
      addBranch,
      addBranchSuccessfully,
      addBranchFailure,
      editBranch,
      editBranchSuccessfully,
      editBranchFailure,
      deleteBranch,
      deleteBranchSuccessfully,
      deleteBranchFailure,
    )]: (
      state  : IBusinessesReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchBusinesses.toString()): {
          const actionTyped = action as IBusinessesActions['fetchBusinesses'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, businesses: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchBusinessesSuccessfully.toString()): {
          const actionTyped    = action as IBusinessesActions['fetchBusinessesSuccessfully'];

          return ({ ...state, businesses: actionTyped.payload.businesses, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default businessesReducer;

interface IBusinessesSelectors {
  getBusinesses : (state: IState) => Required<IBusinessModel>[];
  getBranches   : (state: IState) => IBusinessLocation[];
  getMeta       : (state: IState) => IBusinessesReducer['meta'];
}

export const getBusinesses: IBusinessesSelectors['getBusinesses'] = (state: IState) => state[STATE_KEY].businesses;
export const getBranches: IBusinessesSelectors['getBranches']     = (state: IState) => state[STATE_KEY].branches;
export const getMeta: IBusinessesSelectors['getMeta']             = (state: IState) => state[STATE_KEY].meta;
