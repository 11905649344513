import { Formik, FormikProps }  from 'formik';
import { useCallback, useMemo } from 'react';
import { connect }              from 'react-redux';
import * as Yup                 from 'yup';
import styled                   from 'styled-components';

import { CircularLoader }                              from '@components/Loaders/CircularLoader';
import { INITIAL_LOCATION as COMMON_INITIAL_LOCATION } from '@common/components/PlacesAutocomplete/PlacesAutocomplete';

import { ILocation, ITerritoryModel } from '@models/index';
import { IState }                     from '@store/rootReducer';
import { useFetchEntity }             from '@utils/fetchEntity';

import {
  addTerritory  as addTerritoryAction,
  editTerritory as editTerritoryAction,
  getMeta,
  ITerritoriesActionsCreators,
} from '../territoriesReducerL';
import { FormTerritory, TYPES_TO_SHOW_ALL_FORM } from '../components/FormTerritoryL';
import { IZipCode }                              from '../components/ZipCodeSelect';

export interface ITerritoryFieldsSettings {
  parentName : { isDisabled: boolean };
  type       : {
    isDisabled : boolean;
    options    : { label: string; value: TERRITORY_TYPES }[];
  };
}

export enum TERRITORY_TYPES {
  COUNTRY         = 'country',
  STATE           = 'state',
  CITY            = 'city',
  CITY_COLLECTION = 'city_collection',
  DIVIDED_CITY    = 'divided_city',
}

export type ITerritoryFormModel = Omit<ITerritoryModel, 'territoryId' | 'channel' | 'locations'> & {
  locations : (Omit<ILocation, 'postCodes'> & { postCodes?: IZipCode[] })[];
  parent    : {
    label : string;
    value : number;
  } | null,
  channel : {
    label? : string;
    value? : number;
  } | null,
}

type ITerritoryFormPostModel = ITerritoryModel & { parent: { label: string; value: number } | null};

const TYPES_OPTION = [
  { label: 'Country', value: TERRITORY_TYPES.COUNTRY },
  { label: 'State/Province', value: TERRITORY_TYPES.STATE },
  { label: 'City', value: TERRITORY_TYPES.CITY },
  { label: 'City Collection', value: TERRITORY_TYPES.CITY_COLLECTION },
  { label: 'Divided city', value: TERRITORY_TYPES.DIVIDED_CITY },
];

export const INITIAL_LOCATION = {
  ...COMMON_INITIAL_LOCATION,
  locationId : null,
  postCodes  : [],
};

const useLocationValidation = (selectedTerritoryType: TERRITORY_TYPES | null) => Yup.array().of(
  Yup.object().shape({
    address1      : Yup.string()
      .typeError('Find and select city or town')
      .required(),
    city          : Yup.string()
      .typeError('Enter area city')
      .required(),
    country       : Yup.string()
      .typeError('Enter area country')
      .required(),
    googlePlaceId : Yup.string()
      .required(),
    postCode      : Yup.string()
      .nullable(),
    postCodes     : Yup.array()
      .ensure()
      .test(
        'is-code-select',
        'Select at least one postal code',
        (values: IZipCode[] | undefined): boolean => {
          if (selectedTerritoryType !== TERRITORY_TYPES.DIVIDED_CITY) {
            return true;
          }

          return values?.length ? !!values.find((item) => item.isSelected) : true;
        },
      ),
    state : Yup.string()
      .typeError('Enter area state')
      .required(),
  }),
);

const useValidationSchema = () => {
  const validationSchema = useMemo(() => (
    Yup.object().shape({
      availableInMobileApp : Yup.boolean().required(),
      locations            : Yup.array()
        .when('type', (() => {
          let territoryType: TERRITORY_TYPES | null = null;

          return {
            is : (type: TERRITORY_TYPES) => {
              territoryType = type;

              return TYPES_TO_SHOW_ALL_FORM.includes(type);
            },
            then : () => useLocationValidation(territoryType),
          };
        })()),
      locale : Yup.string()
        .required(),
      name : Yup.string()
        .typeError('Enter area name')
        .required(),
      parent : Yup.object()
        .nullable()
        .when('type', {
          is   : (type: TERRITORY_TYPES) => type !== TERRITORY_TYPES.COUNTRY,
          then : Yup.object().typeError('Select country and/or state')
            .shape({ label: Yup.string().typeError('Select country and/or state').required() })
            .required('Select country and/or state'),
        }),
      type : Yup.string()
        .typeError('Select area type')
        .required(),
    })
  ), []);

  return validationSchema;
};

interface ITerritoriesComponent {
  addTerritory  : ITerritoriesActionsCreators['addTerritory'];
  editTerritory : ITerritoriesActionsCreators['editTerritory'];
  isFetching    : boolean;
  location      : { state?: { initialValues: {
    parent     : any;
    type       : TERRITORY_TYPES;
  }; }; };
}

const TerritoriesComponent = ({
  addTerritory,
  editTerritory,
  isFetching,
  location,
}: ITerritoriesComponent) => {
  const validationSchema                 = useValidationSchema();
  const [territory, isTerritoryFetching] = useFetchEntity('feature/Territory') as [ITerritoryModel | null, boolean, () => Promise<void>];

  const initialValues = useMemo<ITerritoryFormModel>(() => {
    const parentId   = territory?.parentId || location.state?.initialValues.parent.parentId || null;
    const parentName = territory?.parentName || location.state?.initialValues.parent.parentName || null;

    const locations: ITerritoryFormModel['locations'] = (territory?.locations || [INITIAL_LOCATION]).map((location) => ({
      ...location,
      postCodes: (location.postCodes || []).map((postCode) => ({ code: postCode, isSelected: true })),
    }));

    return {
      locations,
      parentId,
      parentName,
      availableInMobileApp : territory?.availableInMobileApp || false,
      channelId            : territory?.channelId || null,
      locale               : 'en_us',
      name                 : (territory?.name || null) as string,
      type                 : territory?.type || location.state?.initialValues.type || TERRITORY_TYPES.COUNTRY,
      parent               : { value: parentId, label: parentName },
      channel              : {
        label: territory?.channel?.name,
        value: territory?.channel?.channelId,
      } || null,
    };
  }, [location, territory]);

  const fieldsSettings = useMemo<ITerritoryFieldsSettings>(() => ({
    parentName : { isDisabled: !!territory || !!location.state?.initialValues.parent.parentId },
    type       : {
      isDisabled : !!territory?.type || [TERRITORY_TYPES.COUNTRY, TERRITORY_TYPES.STATE]
        .includes(location.state?.initialValues.type as TERRITORY_TYPES),
      options    : [TERRITORY_TYPES.COUNTRY, TERRITORY_TYPES.STATE].includes(initialValues.type)
        ? TYPES_OPTION : TYPES_OPTION.slice(2, TYPES_OPTION.length),
    },
  }), [location, territory, initialValues]);

  const onSubmit = useCallback((values: ITerritoryFormModel) => {
    const requestData = {
      ...values,
      parentId   : values.parent?.value || values.parentId,
      parentName : values.parent?.label || values.parentName,
      channelId  : values.channel?.value,
      locations  : values.locations
        .filter((location) => !!location.googlePlaceId)
        .map((location) => ({
          ...location,
          postCodes: (location.postCodes || [])
            .filter((code) => code.isSelected)
            .map((code) => code.code),
        })),
    };

    if (territory) {
      const { territoryId } = territory;

      editTerritory({ ...requestData, territoryId });
    } else {
      addTerritory(requestData);
    }
  }, [territory]);

  const updateValuesIfNeeded = useCallback((() => {
    let prevType = initialValues.type;

    return (values: ITerritoryFormPostModel) => {
      if (prevType !== values.type && [TERRITORY_TYPES.COUNTRY, TERRITORY_TYPES.STATE].includes(values.type)) {
        values.channel    = null;
        values.locale     = 'en_us';
        values.locations  = [INITIAL_LOCATION];
        values.parent     = values.type === TERRITORY_TYPES.STATE ? {label: values.parentName || location.state?.initialValues.parent.label, value: values.parentId || location.state?.initialValues.parent.value} : null;
      } else if (prevType !== values.type && values.type !== TERRITORY_TYPES.CITY_COLLECTION) {
        values.locations = [values.locations[0]];
        values.parent    = { label:  location.state?.initialValues.parent.label, value: location.state?.initialValues.parent.value };
      }

      prevType = values.type;

      return values;
    };
  })(), [initialValues]);

  if (isTerritoryFetching) return (
    <CircularLoader />
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<any>) => (
          <FormTerritory
            {...props}
            fieldsSettings = {fieldsSettings}
            values         = {updateValuesIfNeeded(props.values)}
            isFetching     = {isFetching}
          />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 948px; }
`;

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addTerritory  : addTerritoryAction,
  editTerritory : editTerritoryAction,
};

export const Territories = connect(mapStateToProps, mapDispatchToProps)(TerritoriesComponent);
