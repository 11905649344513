import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { FieldProps }                                  from 'formik';
import styled                                          from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

import { FieldError, Label, Modal } from '@common/components';
import { MimeTypeSelector }         from '@common/components/MediaUploader/MimeTypeSelector';

import { uploadFile } from '@utils/uploadFile';

import { MediaBlock, MediaBlockTag } from './MediaBlock';
import { MediaPreview }              from './MediaPreview';
import { ResizersList }              from './ResizersList';

export interface IMediaItem {
  mimeType : 'image' | 'video' | 'youtube'
  uri      : string;
}

interface IMultiMediaUploaderProps extends Partial<FieldProps<IMediaItem[]>> {
  aspect?          : number;
  label?           : string;
  limit            : number;
  mimeTypeOptions? : ('image' | 'video' | 'youtube')[];
  onChange?        : (media: IMediaItem[]) => void;
  required?        : boolean;
  values?          : IMediaItem[];
  wrapperStyle?    : { margin: string; }
}

const INITIAL_WRAPPER_STYLE = { margin: '20px 0 0' };

export const MultiMediaUploader = ({
  aspect,
  field,
  form,
  label,
  limit,
  mimeTypeOptions,
  onChange,
  required,
  values,
  wrapperStyle,
}: IMultiMediaUploaderProps) => {
  const [imagesForResize, setImagesForResize] = useState<File[]>([]);
  const [isUploading, setUploadingStatus]     = useState(false);
  const [mediaPreview, setPreview]            = useState<IMediaItem | null>(null);

  const localValues = useMemo<IMediaItem[]>(() => (field?.value || values || []), [field, values]);

  const updateValues = (newValues: IMediaItem[]) => {
    if (field && form) {
      form.setFieldValue(field.name, newValues);
    } else if (onChange) {
      onChange(newValues);
    }
  };

  const uploadUserFiles = async (files: File[], mimeType: 'image' | 'video') => {
    setUploadingStatus(true);

    const uploadedImages = await Promise.all((files).map((file) => uploadFile(file, { container: 'media' })));

    const mappedValues = uploadedImages.reduce((prev: IMediaItem[], curr) => {
      if (curr) {
        prev.push({ uri: curr.uri, mimeType });
      }

      return prev;
    }, []);

    updateValues([...localValues, ...mappedValues]);
    setUploadingStatus(false);
  };

  const onClickDelete = useCallback((mediaUri: string) => {
    const newValues = localValues.filter((item) => item.uri !== mediaUri);

    updateValues(newValues);
  }, [localValues, updateValues]);

  const onClickPreview = useCallback((mediaUri: string) => {
    const selectedMediaItem = localValues.find((value) => value.uri === mediaUri);

    if (selectedMediaItem) {
      setPreview(selectedMediaItem);
    }
  }, [localValues]);

  const onSubmitResizedImages = useCallback((images: File[]) => {
    uploadUserFiles(images, 'image');
    setImagesForResize([]);
  }, [uploadUserFiles]);

  const onCloseResizer = useCallback(() => {
    setImagesForResize([]);
  }, []);

  const onSelectFile = useCallback((e: ChangeEvent<HTMLInputElement>, mimeType: 'image' | 'video') => {
    if (!e.target.files || e.target.files.length === 0) return;

    const selectedFiles: File[] = Array.from(e.target.files).slice(0, limit - localValues.length);
    const slicedFiles           = selectedFiles.slice(0, limit);

    if (mimeType === 'image') {
      setImagesForResize(slicedFiles);
    } else {
      uploadUserFiles(slicedFiles, mimeType);
    }
  }, [limit, localValues, uploadUserFiles]);

  const onSubmitYouTubeVideo = useCallback((youtubeVideoId: string) => {
    const youtubeEmbedUrl = `https://www.youtube.com/embed/${youtubeVideoId}`;

    updateValues([...localValues, { uri: youtubeEmbedUrl, mimeType: 'youtube' }]);
  }, [localValues, updateValues]);

  return (
    <MultiMediaUploader.Wrapper {...INITIAL_WRAPPER_STYLE} {...wrapperStyle}>
      {!!imagesForResize.length && (
        <ResizersList
          aspect   = {aspect}
          images   = {imagesForResize}
          onSubmit = {onSubmitResizedImages}
          onClose  = {onCloseResizer}
        />
      )}
      <Modal
        onCancel = {() => setPreview(null)}
        title    = "Preview"
        visible  = {!!mediaPreview}
        width    = "auto"
      >
        {mediaPreview && <MediaPreview media={mediaPreview} />}
      </Modal>
      {label && (
        <MultiMediaUploader.LabelWrapper>
          <Label
            label    = {label}
            required = {required}
          />
        </MultiMediaUploader.LabelWrapper>
      )}
      {localValues.length < limit && (
        <MediaBlockTag>
          {isUploading ? (
            <LoadingOutlined />
          ) : (
            <MimeTypeSelector
              multiple
              mimeTypeOptions      = {mimeTypeOptions}
              fieldName            = "multi-media-uploader"
              onSubmitYouTubeVideo = {onSubmitYouTubeVideo}
              onSelectFile         = {onSelectFile}
            />
          )}
        </MediaBlockTag>
      )}
      {localValues.map((mediaItem) => (
        <MediaBlock
          key            = {mediaItem.uri}
          media          = {mediaItem}
          onClickDelete  = {onClickDelete}
          onClickPreview = {onClickPreview}
        />
      ))}
      <FieldError
        field    = {field}
        form     = {form}
      />
    </MultiMediaUploader.Wrapper>
  );
};

MultiMediaUploader.LabelWrapper = styled.div`
  width: 100%;
`;

MultiMediaUploader.Wrapper = styled.div<{ margin: string; }>`
  display   : flex;
  flex-wrap : wrap;
  gap       : 7px;
  margin    : ${({ margin }) => margin};
  width     : 100%;

  .sc-bdfBQB {
    position : sticky;
  }

  .sc-bdfBQB p {
    position : absolute;
    top      : -8px;
  }
`;
