import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';
import { format }                                    from 'date-fns';

import { BaseInput }         from '@common/components';
import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';
import { ECardStatuses }     from '@common/enums';
import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
  setFitFilterField,
} from '@common/components/FiltrationSorting';
import {
  DatePicker,
  getDateISOWithTimeZoneOffset,
} from '@common/components/DatePicker/DatePicker';

import { routesConfig }          from '@components/Breadcrumbs/routesConfig';
import { AutoSuggest }           from '@components/Autosuggest/Autosuggest';
import { DeleteModal }           from '@components/Modal/DeleteModal';

import { useFetchQueryRules }                       from '@hooks/useFetchQueryRules';
import { Modal }                                    from '@src/App';
import {IEventModel, IOptionType, ITerritoryModel } from '@src/models';
import { browserHistory }                           from '@src/store';
import { IState }                                   from '@store/rootReducer';
import { ESortOrder }                               from '@models/enums';
import { getRequestStatusLabel }                    from '@utils/getRequestStatusLabel';
import {
  fetchEvents  as fetchEventsAction,
  deleteEvents as deleteEventsAction,
  IEventsActionsCreators,
  getEvents,
  getMeta,
  IEventsReducer,
} from '../eventsReducer';

interface IEvents {
  events       : IEventModel[];
  eventMeta    : IEventsReducer['meta'];
  fetchEvents  : IEventsActionsCreators['fetchEvents'];
  deleteEvents : IEventsActionsCreators['deleteEvents'];
}

const mapTerritories = (data: ITerritoryModel[]): IOptionType[] => {
  return data.map((item) => ({ label: item.name, value: item.territoryId }));
};

const sortOptions = [
  { label: 'Title', key: 'Title', value: 'Title' },
  { label: 'Starts', key: 'DateStart', value: 'DateStart' },
  { label: 'Business branch name', key: 'BusinessBranchName', value: 'BusinessBranchName' },
  { label: 'Ends', key: 'DateEnd', value: 'DateEnd' },
  { label: 'Status', key: 'Status', value: 'Status' },
];

const filterOptions: IFilterOption[] = [{
  component          : (props) => setFitFilterField(BaseInput, props),
  initialFilterValue : '',
  label              : 'Title',
  value              : 'name',
}, {
  component          : (props) => setFitFilterField(DatePicker, { ...props, withTime: false }),
  initialFilterValue : null,
  label              : 'Starts from',
  value              : 'dateFrom',
}, {
  component          : (props) => setFitFilterField(DatePicker, { ...props, withTime: false }),
  initialFilterValue : null,
  label              : 'Starts to',
  value              : 'dateTo',
}, {
  component          : (props) => setFitFilterField(BaseInput, props),
  initialFilterValue : '',
  label              : 'Business Branch Name',
  value              : 'businessName',
}, {
  component          : (props) => setFitFilterField(AutoSuggest, { ...props, endpoint: 'feature/Territory/search?name', responseMapper: mapTerritories }),
  initialFilterValue : '',
  label              : 'Areas',
  value              : 'territory',
}, {
  component          : (props) => setFitFilterField(BaseInput, props),
  initialFilterValue : '',
  label              : 'Tags',
  value              : 'tags',
  multiple           : true,
}];

const EventList = ({
  events,
  eventMeta,
  fetchEvents,
  deleteEvents,
}: IEvents) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [popoverVisible, setPopoverStatus] = useState(false);

  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'events-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Desc, label: sortOptions[0].label } },
  );

  const onApplyFilters = useCallback((newFilter: any) => {
    const nextFilters = Object.keys(newFilter.filtration).reduce((accum: any, curr) => {
      accum[curr] = newFilter.filtration[curr].value;
      return accum;
    }, {} );
    if (newFilter.sorting) {
      fetchEvents({ ...eventMeta, ...nextFilters, orderBy: newFilter.sorting.value, order: newFilter.sorting.order });
    } else {
      fetchEvents({ ...eventMeta, ...nextFilters });
    }
  }, [eventMeta]);

  const onClickEvent = useCallback((event: IEventModel) => {
    history.push(routesConfig.eventsEdit.endpoint.replace(':id', event.eventId.toString()));
  }, []);

  const onClickClone = useCallback((event: IEventModel) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { eventId, ...nextEvent } = event;

    browserHistory.push('/admin/events/add', { event: nextEvent });
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchEvents({ page: page !== eventMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [eventMeta.page, eventMeta.rowsPerPage]);

  const onDeleteFiltrationRule = useCallback((ruleKey: string) => {
    handlers.deleteFiltrationRule(ruleKey);
    fetchEvents({...eventMeta, [ruleKey]: ''});
  }, [eventMeta, handlers]);

  const deleteEvent = useCallback(async (eventId: (number | string)[], withRecurring?: boolean) => {
    deleteEvents(eventId as number[], withRecurring);
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons           = {[{ action: () => history.push(routesConfig.eventsAdd.endpoint), label: t('createEvent') }]}
      title             = {t('events')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {onDeleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title',              label: t('title') },
    { id: 'starts',             label: t('starts') },
    { id: 'ends',               label: t('ends') },
    { id: 'BusinessBranchName', label: t('businessBranchName') },
    { id: 'area',               label: t('area') },
    { id: 'status',             label: t('status') },
    { id: 'cloneButton',        label: t('') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => ( events.map((event) => ({
    title : {
      onClick : () => onClickEvent(event),
      tag     : 'link',
      title   : event.title || '',
    },
    starts             : format(new Date(getDateISOWithTimeZoneOffset(new Date(event.dateStart), event.timeZone, true)), 'PP | p'),
    ends               : event.dateEnd && format(new Date(getDateISOWithTimeZoneOffset(new Date(event.dateEnd), event.timeZone, true)), 'PP | p'),
    businessBranchName : event.businessLocation?.name || null,
    area               : event.rEventTerritory[0]?.territoryName || null,
    status      : {
      component : getRequestStatusLabel(event.status) || '-',
      tag       : 'component',
    },
    cloneButton : {
      onClick : () => onClickClone(event),
      tag     : 'link',
      title   : 'clone',
    },
    id : event.eventId,
  }))), [events]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 2.5 },
      { flex: 2 },
      { flex: 2 },
      { flex: 2 },
      { flex: 2 },
      { flex: 1 },
      { flex: 0.5 },
    ],
    pagination : {
      current         : eventMeta.page + 1,
      total           : eventMeta.totalEvents,
      pageSize        : eventMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      setPage         : onSetPage,
      showTotal       : true,
    },
  }), [eventMeta.page, eventMeta.totalEvents, eventMeta.rowsPerPage]);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    setPopoverStatus(false);
    onApplyFilters(rules);
  }, [rules]);

  return (
    <Card
      header = {header}
      status = {eventMeta.isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!eventMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            const eventForDeleting = events.find(obj => (obj.eventId === ids[0]));
            Modal.open(DeleteModal, {
              onDeleteConfirm : (withRecurring: boolean) => {
                deleteEvent(ids, withRecurring);
                Modal.close();
              },
              confirmMessage        : (ids.length === 1) ? 'The event will be removed' : 'You cannot restore deleted data',
              entityNameMessage     : `For removing enter the event ID "${eventForDeleting?.eventId}"`,
              entityCheckboxMessage : eventForDeleting?.eventRecurrenceId ? 'Remove all recurring events' : undefined,
              name                  : (ids.length === 1) ? `${eventForDeleting?.eventId}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  events    : getEvents(state),
  eventMeta : getMeta(state),
});

const mapDispatchToProps = {
  deleteEvents : deleteEventsAction,
  fetchEvents  : fetchEventsAction,
};

export const EventsList = connect(mapStateToProps, mapDispatchToProps)(EventList);
