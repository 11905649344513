import { Key }         from 'antd/lib/table/interface';
import { FieldProps }  from 'formik';
import { useCallback } from 'react';
import styled          from 'styled-components';

import { BaseSelectProps, BaseSelect } from './BaseSelect';
import { FieldError }                  from '../FieldError';

interface MultipleSelectProps extends BaseSelectProps, Partial<FieldProps> {
  wrapperStyle?: { margin?: string };
}

const filterOption = (input: string, option?: { [key: string]: unknown }) => (
  (option?.children as string).includes(input)
);

export const MultipleSelect = ({
  field,
  form,
  onChange,
  wrapperStyle,
  value,
  ...componentProps
}: MultipleSelectProps) => {
  const onSelect = useCallback((options: Key | Key[]) => {
    if (field && form) {
      form.setFieldValue(field.name, options);
    } else if (onChange) {
      onChange(options);
    }
  }, [form, field, onChange]);

  return (
    <MultipleSelect.Wrapper {...wrapperStyle}>
      <BaseSelect
        filterOption = {filterOption}
        {...componentProps}
        mode     = "multiple"
        onChange = {onSelect}
        value    = {field?.value || value}
      />
      {field && form && (
        <FieldError
          field = {field}
          form  = {form}
        />
      )}
    </MultipleSelect.Wrapper>
  );
};

MultipleSelect.Wrapper = styled.div<{ margin?: string; }>`
  margin   : ${({ margin, theme }) => margin || theme.field.gap};
  position : relative;

  .ant-select-multiple .ant-select-selection-item {
    background-color   : transparent;
    border             : none;
    height             : auto;
    line-height        : 20px;
    margin-bottom      : 0;
    margin-top         : 0;
    padding-inline-end : 0;

    &:first-child { padding-inline-start: 0; }
  }

  .ant-select-selection-overflow {
    > div {
      > span span:first-child::after { content: ','; }

      &:nth-last-child(2) { > span span:first-child::after { display: none; } }
    }
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    top  : 60%;
    left : 1px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector { border: none; }

  .ant-select-multiple .ant-select-selection-search { margin: 0; }

  .ant-select-multiple .ant-select-selection-item-content { margin-right: 0; }

  .ant-select-multiple .ant-select-selector::after,
  .ant-select-multiple .ant-select-selection-item-remove { display: none; }

  .ant-select-selection-search {
    height: 20px;

    input {
      font-size   : 16px;
      height      : auto;
      line-height : 20px;
    }
  }
`;
