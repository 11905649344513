import { useMemo, useCallback, useEffect, useRef } from 'react';
import { connect }                                 from 'react-redux';
import { Formik }                                  from 'formik';
import * as Yup                                    from 'yup';
import styled                                      from 'styled-components';

import {
  IAreaSelectionItem,
  mapAreasToBannedType,
  validateSelectedAreas,
}                                            from '@components/AreaSelection/AreaSelection';
import { FormNotes }                         from '@components/Form/FormNotes';
import { ISuggestedValues, SuggestedValues } from '@components/Form/SuggestedValues';
import { CircularLoader }                    from '@components/Loaders/CircularLoader';

import { usePreviousPropsOrState } from '@utils/customHooks';
import { useFetchEntity }          from '@utils/fetchEntity';
import { propOr }                  from '@utils/propOr';

import { IFeedModel, ITerritoryModel, IOptionType } from '@models/index';
import { IState }                                   from '@store/rootReducer';

import { FormFeed } from '../components/FormFeed';
import {
  addFeed  as addFeedAction,
  editFeed as editFeedAction,
  getMeta,
  IFeedsActionsCreators,
} from '../feedsReducer';

export interface IFeedsLocationProp {
  notes?           : string | null;
  suggestedValues? : ISuggestedValues['values'];
  initialValues?   : {
    requestedFeedId? : number;
    rFeedChannel?    : IOptionType[];
    rFeedTerritory?  : { area: Pick<ITerritoryModel, 'name' | 'territoryId' | 'type'> }[];
  };
}

interface IFeeds {
  addFeed    : IFeedsActionsCreators['addFeed'];
  editFeed   : IFeedsActionsCreators['editFeed'];
  isFetching : boolean;
  location   : { state?: IFeedsLocationProp };
}

interface IFeedsFormModel {
  name           : string;
  rssUri         : string;
  publisher      : { value?: number; label?: string; } | null;
  rFeedChannel   : { value: number; label: string; }[];
  rFeedTerritory : IAreaSelectionItem[] | any[];
}

type IFeedsFormPostModel = Omit<IFeedsFormModel, 'publisher' | 'rFeedChannel'> & {
  feedId?      : number;
  publisherId? : number;
  rFeedChannel : { channelId: number; feedId?: number; }[];
}

const validationSchema = Yup.object().shape({
  name : Yup.string()
    .typeError('Enter feed title')
    .required(),
  publisher : Yup.object()
    .shape({ label: Yup.string().required('Enter feed publisher') })
    .typeError('Enter feed publisher')
    .required('Enter feed publisher'),
  rssUri : Yup.string()
    .typeError('Enter feed source')
    .required(),
  rFeedChannel : Yup.array()
    .ensure(),
  rFeedTerritory : validateSelectedAreas(0),
});

const FeedsComponent = ({
  addFeed,
  editFeed,
  isFetching,
  location,
}: IFeeds) => {
  const [feed, isFeedFetching, fetchFeed] = useFetchEntity('feature/Feed') as [IFeedModel | null, boolean, () => Promise<void>];

  const formikRef = useRef(null) as any;

  const publisherValues = useMemo(() => {
    if (location.state?.suggestedValues) {
      return {
        name       : location.state.suggestedValues.find((item) => item.id === 'name')?.value || '',
        websiteUri : location.state.suggestedValues.find((item) => item.id === 'websiteUri')?.value || '',
      };
    }
  }, [location.state]);

  const initialValues = useMemo<IFeedsFormModel>(() => ({
    name           : feed?.name || '',
    publisher      : {
      value : feed?.publisher?.publisherId,
      label : feed?.publisher?.name,
    } || null,
    rssUri         : feed?.rssUri || '',
    rFeedChannel   : feed?.rFeedChannel
      ? feed.rFeedChannel.map((fc) => ({ label: fc.channelName, value: fc.channelId }))
      : [],
    rFeedTerritory : feed?.rFeedTerritory || [] as unknown as IAreaSelectionItem[],
    ...(location.state?.initialValues || null),
  }), [feed, location.state?.initialValues]);

  const onSubmit = useCallback((values: IFeedsFormModel) => {
    const requestData: IFeedsFormPostModel = {
      name           : values.name,
      publisherId    : values.publisher?.value,
      rFeedChannel   : values.rFeedChannel.map((fct: any) => ({
        channelId : fct.value,
        feedId    : feed ? feed.feedId : undefined,
      })),
      rFeedTerritory : mapAreasToBannedType(values.rFeedTerritory, { feedId: feed?.feedId }) as IFeedModel['rFeedTerritory'],
      rssUri         : values.rssUri,
    };

    if (feed) {
      requestData.feedId = feed.feedId;
      editFeed(requestData);
    } else {
      addFeed({
        ...requestData,
        requestedFeedId: location.state?.initialValues?.requestedFeedId,
      });
    }
  }, [feed, location.state?.initialValues]);

  const prevPropsOrState = usePreviousPropsOrState({ location });
  useEffect(() => {
    const prevLocationState    = propOr(prevPropsOrState, ['location', 'state'], null);
    const currentLocationState = propOr(location, ['state'], null);

    if (!prevLocationState && location.state && currentLocationState && (currentLocationState !== prevLocationState) && formikRef.current) {
      const { initialValues } = location.state;

      fetchFeed();

      Object.keys(initialValues || {}).forEach((field: string) => {
        if (initialValues?.rFeedChannel && field === 'rFeedChannel') {
          const rFeedChannel = initialValues.rFeedChannel
            ? initialValues.rFeedChannel.map((fct: any) => ({ name: fct.channelName, value: fct.channelId }))
            : [];

          formikRef.current.setFieldValue(field, rFeedChannel, false);
        } else if (initialValues?.rFeedTerritory && field === 'rFeedTerritory') {
          formikRef.current.setFieldValue(field, initialValues.rFeedTerritory, false);
        }

      });
    }
  }, [location, formikRef]);

  if (isFeedFetching) return (
    <Container>
      <CircularLoader />
    </Container>
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        ref              = {formikRef}
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }) => (
          <FormFeed
            {...props}
            isFetching      = {isFetching}
            publisherValues = {publisherValues}
          />
        )}
      </Formik>
      {location.state?.suggestedValues && (
        <SuggestedValues values={location.state.suggestedValues} />
      )}
      {location.state?.notes && (
        <FormNotes notes={location.state.notes} />
      )}
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 947px; }
`;

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addFeed  : addFeedAction,
  editFeed : editFeedAction,
};

export const Feeds = connect(mapStateToProps, mapDispatchToProps)(FeedsComponent);
