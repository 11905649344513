export enum ERequestedReviewStatus {
  New,
  Approved,
  Denied,
  RequestedChanges,
}

export enum ETipType {
  Organization,
  License,
}

export enum ESortOrder {
  Asc  = 'Asc',
  Desc = 'Desc',
}
