import styled from 'styled-components';

export interface LabelProps {
  required?       : boolean;
  label           : string;
  componentStyle? : {
    fontSize?   : number;
    fontWeight? : number;
    transform?  : 'capitalize' | 'none' | 'uppercase';
  }
}

const INITIAL_COMPONENT_STYLE: LabelProps['componentStyle'] = {
  fontSize   : 12,
  fontWeight : 600,
  transform  : 'uppercase',
};

export const Label = ({
  componentStyle,
  label,
  required,
}: LabelProps) => (
  <Label.Component {...INITIAL_COMPONENT_STYLE} {...componentStyle}>
    {label}{required && <>&#42;</>}
  </Label.Component>
);

Label.Component = styled.p<{
  fontSize?   : number;
  fontWeight? : number;
  transform?  : 'capitalize' | 'none' | 'uppercase';
}>`
  font-size      : ${({ fontSize }) => fontSize}px;
  color          : ${({ theme }) => theme.color.black};
  font-weight    : ${({ fontWeight }) => fontWeight} !important;
  line-height    : 18px;
  text-transform : ${({ transform }) => transform};
`;
