import styled from 'styled-components';

import { Modal }          from './Modal';
import { ApprovedCircle } from '../StatusCircles';

export interface IApproveModalProps {
  comment?    : string;
  title       : string;
  closeButton : {
    label   : string;
    onClick : () => void;
  }
}

export const ApproveModal = ({ closeButton, comment, title }: IApproveModalProps) => (
  <Modal
    bodyStyle = {() => 'padding: 50px 123px;'}
    onCancel  =  {closeButton.onClick}
    width     =  "718px"
  >
    <ApproveModal.Content>
      <ApproveModal.Body>
        <ApprovedCircle
          margin = "0 0 22px"
          size   = "64px"
          icon   = {{
            height : '24px',
            width  : '32px',
          }}
        />
        <ApproveModal.Title>{title}</ApproveModal.Title>
        {comment && <ApproveModal.Comment>{comment}</ApproveModal.Comment>}
      </ApproveModal.Body>
      <ApproveModal.Footer>
        <ApproveModal.BackButton onClick={closeButton.onClick}>
          {closeButton.label}
        </ApproveModal.BackButton>
      </ApproveModal.Footer>
    </ApproveModal.Content>
  </Modal>
);

ApproveModal.BackButton = styled.p`
  cursor          : pointer;
  line-height     : 21px;
  text-decoration : underline;
  transition      : all 0.2s;

  &:hover { color: ${({ theme }) => theme.color.blue}; }
`;

ApproveModal.Body = styled.div`
  align-items     : center;
  display         : flex;
  flex            : 1;
  flex-direction  : column;
  justify-content : center;
  width           : 100%;
`;

ApproveModal.Footer = styled.div`
  display         : flex;
  justify-content : center;
  margin-top      : 60px;
  position        : relative;
  width           : 100%;
`;

ApproveModal.Comment = styled.p`
  color      : ${({ theme }) => theme.color.black};
  font-size  : 16px;
  margin-top : 12px;
  text-align : center;
`;

ApproveModal.Content = styled.div`
  align-items    : center;
  display        : flex;
  flex-direction : column;
  font-family    : 'Poppins';
  position       : relative;

  h3,
  p { font-family: 'Poppins'; }
`;

ApproveModal.Title = styled.h3`
  color       : ${({ theme }) => theme.color.black};
  font-size   : 31px;
  font-weight : 600;
  line-height : 44px;
`;
