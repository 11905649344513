import { useCallback, useEffect, useMemo } from 'react';
import { useHistory }                      from 'react-router-dom';
import { useTranslation }                  from 'react-i18next';
import { connect }                         from 'react-redux';

import { Card, CardHeader }  from '@common/components';
import { Table, TableProps } from '@common/components/Table/Table';
import { ECardStatuses }     from '@common/enums';
import { routesConfig }      from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }       from '@components/Modal/DeleteModal';

import { ETipType }  from '@models/enums';
import { Modal }     from '@src/App';
import { ITipModel } from '@src/models';
import { IState }    from '@store/rootReducer';

import {
  ITipsReducer,
  deleteTips as deleteTipsAction,
  fetchTips as fetchTipsAction,
  getMeta,
  getTips,
  ITipsActionsCreators,
} from '../tipsReducer';

interface ITipsListViewProps {
  deleteTips : ITipsActionsCreators['deleteTips'];
  fetchTips  : ITipsActionsCreators['fetchTips'];
  isFetching : boolean;
  tips       : ITipsReducer['tips'];
}

const getReadableTipType = (tipType: ETipType) => {
  const dirtyTypesArray = Object.keys(ETipType);
  const types           = dirtyTypesArray.slice(dirtyTypesArray.length / 2);

  return types[tipType];
};

const TipsListC = ({
  deleteTips,
  fetchTips,
  isFetching,
  tips,
}: ITipsListViewProps) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const onClickTip = useCallback((tip: ITipModel) => {
    history.push(routesConfig.tipsEdit.endpoint.replace(':id', tip.id.toString()));
  }, []);

  const onClickDeleteTips = useCallback(async (tipIds: (number | string)[]) => {
    deleteTips(tipIds as number[]);
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons = {[{ action: () => history.push(routesConfig.tipsAdd.endpoint), label: t('createTip') }]}
      title   = {t('tips')}
    />
  ), []);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'tip',  label: t('tip') },
    { id: 'type', label: t('type') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (tips.map((tip) => ({
    title : {
      onClick : () => onClickTip(tip),
      tag     : 'link',
      title   : tip.tip || '',
    },
    type : getReadableTipType(tip.type),
    id   : tip.id,
  }))), [tips]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex : 1 },
      { flex : 0.2 },
    ],
    pagination : {
      current : 0,
      setPage : (() => {
        fetchTips();
      }),
    },
  }), []);

  useEffect(() => {
    fetchTips();
  }, []);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{width: '1200px'}}
    >
      {!isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            Modal.open(DeleteModal, {
              onDeleteConfirm : () => {
                onClickDeleteTips(ids);
                Modal.close();
              },
              confirmMessage    : (ids.length === 1) ? 'The tip will be removed' : 'You cannot restore deleted data',
              entityNameMessage : `For removing enter the tip ID "${ids}"`,
              name              : (ids.length === 1) ? `${ids}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  isFetching : getMeta(state).isFetching,
  tips       : getTips(state),
});

const mapDispatchToProps = {
  deleteTips : deleteTipsAction,
  fetchTips  : fetchTipsAction,
};

export const TipsList = connect(mapStateToProps, mapDispatchToProps)(TipsListC);
