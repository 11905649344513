import { FiltrationInfo }  from './FiltrationInfo';
import { ITableDataRules } from './FiltrationSorting';
import { SortingInfo }     from './SortingInfo';

interface IFiltrationSortingInfoProps {
  deleteFiltrationRule : (ruleKey: string) => void;
  rules                : ITableDataRules;
}

export const FiltrationSortingInfo = ({ deleteFiltrationRule, rules }: IFiltrationSortingInfoProps) => (
  <div>
    {rules.sorting && (
      <SortingInfo sorting={rules.sorting} />
    )}
    {rules.filtration && (
      <FiltrationInfo
        deleteRule = {deleteFiltrationRule}
        filtration = {rules.filtration}
      />
    )}
  </div>
);
