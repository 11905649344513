import { FunctionComponent, useEffect, useState } from 'react';

import { FilterForm, INewFiltrationRule, TSingleFilterValue } from './FilterForm';
import { FilterSortingSelector }                              from './FiltrationSortingSelector';
import { SortingForm }                                        from './SortingForm';

import { ESortOrder } from '../../enums';
import { TOption }    from '../../models';

export interface ITableDataRules {
  filtration : { [key: string]: Omit<INewFiltrationRule, 'key'> },
  sorting    : (TOption & { order : ESortOrder }) | null,
}

export interface IFilterOption {
  initialFilterValue : TSingleFilterValue;
  label              : string;
  multiple?          : boolean;
  value              : string;
  component          : FunctionComponent<unknown & {
    onChange    : (value: TSingleFilterValue) => void;
    label       : string;
    placeholder : string;
    required    : boolean;
    value       : TSingleFilterValue;
  }>;
}

interface IFiltrationSorting {
  filterOptions     : IFilterOption[],
  setSortingRule    : (newRule: NonNullable<ITableDataRules['sorting']>) => void;
  setFiltrationRule : (newRule: INewFiltrationRule) => void;
  sortOptions       : {
    label : string;
    value : string;
  }[],
}

export const FiltrationSorting = ({
  filterOptions,
  setSortingRule,
  setFiltrationRule,
  sortOptions,
}: IFiltrationSorting) => {
  const [filterForm, setForm] = useState<'sorting' | 'filtration' | null>(null);

  useEffect(() => {
    if (filterOptions.length && !sortOptions.length) {
      setForm('filtration');
    } else if (sortOptions.length && !filterOptions.length) {
      setForm('sorting');
    }
  }, [filterOptions, sortOptions]);

  if (!filterOptions.length && !sortOptions.length) {
    return null;
  }

  if (filterForm === 'sorting') {
    return (
      <SortingForm
        onCancel    = {filterOptions.length ? (() => setForm(null)) : undefined}
        onChange    = {setSortingRule}
        sortOptions = {sortOptions}
      />
    );
  }

  if (filterForm === 'filtration') {
    return (
      <FilterForm
        filterOptions = {filterOptions}
        onCancel      = {sortOptions.length ? (() => setForm(null)) : undefined}
        onChange      = {setFiltrationRule}
      />
    );
  }

  return <FilterSortingSelector onChange={setForm} />;
};
