import { FieldProps }                                from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import dateFnsGenerateConfig                         from 'rc-picker/lib/generate/dateFns';
import generatePicker                                from 'antd/lib/date-picker/generatePicker';
import { getZonedTime, findTimeZone, listTimeZones } from 'timezone-support';
import { format, addHours }                          from 'date-fns';
import styled                                        from 'styled-components';

import { FieldError } from '../FieldError/FieldError';
import { Label }      from '../Label/Label';

import { setPopupContainer } from '../../utils';

const DatePickerAntd = generatePicker<Date>(dateFnsGenerateConfig);
interface IDatePicker extends Partial<FieldProps> {
  datePlaceholder? : string;
  disabled?        : boolean;
  required?        : boolean;
  dateFormat?      : string;
  label            : string;
  onChange?        : (newValue: string | null) => void;
  picker?          : 'year' | 'month' | 'week' | 'quarter' | 'date';
  timePlaceholder? : string;
  timezone?        : string | null;
  withTime?        : boolean;
  value?           : string | null;
  wrapperStyle?    : { margin?: string };
}

const timeZones = listTimeZones();
export const TIME_ZONE_OPTIONS = timeZones
  .filter((tz) => !tz.includes('Etc') && tz.includes('/'))
  .map((z) => ({ label: z, value: z }));

export const getDateISOWithTimeZoneOffset = (date: Date, timeZone: string, isDisplayingValue?: boolean) => {
  const userTimeZoneOffsetInHours = -(date.getTimezoneOffset() / 60);
  const timeZoneObj               = findTimeZone(timeZone);
  const zonedTime                 = getZonedTime(date, timeZoneObj);
  const timeZoneOffsetH           = typeof zonedTime.zone?.offset === 'number' ? -(zonedTime.zone.offset / 60) : userTimeZoneOffsetInHours;

  let diffH;
  if (isDisplayingValue) {
    diffH = timeZoneOffsetH - userTimeZoneOffsetInHours;
  } else {
    diffH = userTimeZoneOffsetInHours - timeZoneOffsetH;
  }

  const timeISOWithTimeZoneOffset = addHours(date, diffH);

  return timeISOWithTimeZoneOffset;
};

export const parseStringToDate = (stringDate: string, timezone: string | null) => {
  if (!timezone) {
    const date               = new Date(stringDate);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;

    return new Date(userTimezoneOffset < 0
      ? date.getTime() + userTimezoneOffset
      : date.getTime() - userTimezoneOffset);
  }

  return getDateISOWithTimeZoneOffset(new Date(stringDate), timezone, true);
};

const resetTime = (date: string, timezone: string | null) => {
  let newTime     = null;
  const fieldDate = new Date(date);

  if (timezone) {
    fieldDate.setHours(0, 0, 1, 0);

    newTime = getDateISOWithTimeZoneOffset(fieldDate, timezone).toISOString();
  } else {
    fieldDate.setUTCHours(0, 0, 1, 0);

    newTime = fieldDate.toISOString();
  }

  return newTime;
};

export const DatePicker = ({
  datePlaceholder,
  field,
  form,
  label,
  onChange,
  picker,
  timePlaceholder,
  wrapperStyle,
  value,
  dateFormat = 'MMM dd, yyyy',
  disabled   = false,
  required   = true,
  timezone   = null,
  withTime   = true,
}: IDatePicker) => {
  const [dropdownsVisibility, setVisibility] = useState<{ date: boolean; time: boolean }>({ date: false, time: false });

  const innerValue = useMemo<string | null>(() => (
    field?.value || value || null
  ), [field?.value, value]);

  const componentDate = useMemo(() => {
    if (!innerValue) return null;

    return parseStringToDate(innerValue, timezone);
  }, [innerValue, timezone]);

  const updatePrevValue = useCallback((newValue: string | null) => {
    if (field && form) {
      form.setFieldValue(field.name, newValue);
    } else if (onChange) {
      onChange(newValue);
    }
  }, [field, form, onChange]);

  const onSelect = useCallback((date: Date | null) => {
    if (!date) {
      updatePrevValue(null);
      return;
    }

    date.setSeconds(0);
    date.setMilliseconds(0);

    let stringDate = date.toString();

    if (timezone) {
      stringDate = getDateISOWithTimeZoneOffset(date, timezone).toISOString();
    } else if (withTime) {
      const timezoneOffset = (date.getTimezoneOffset() / 60) * (date.getTimezoneOffset() > 0 ? -1 : 1);
      date.setHours(date.getHours() - timezoneOffset);
      stringDate = date.toISOString();
    }

    if (!withTime) {
      stringDate = resetTime(stringDate, timezone);
    }

    updatePrevValue(stringDate);
  }, [timezone, withTime, updatePrevValue]);

  useEffect(() => {
    if (!withTime && innerValue) {
      resetTime(innerValue, timezone);
    }
  }, [innerValue, timezone, withTime]);

  return (
    <DatePicker.Container {...wrapperStyle}>
      <Label
        label    = {label}
        required = {required}
      />
      <DatePicker.Wrapper
        datePickerActive = {dropdownsVisibility.date}
        disabled         = {disabled}
        timePickerActive = {withTime ? dropdownsVisibility.time : null}
      >
        <DatePickerAntd
          disabled          = {disabled}
          format            = {dateFormat}
          getPopupContainer = {setPopupContainer}
          value             = {componentDate}
          onChange          = {onSelect}
          open              = {dropdownsVisibility.date}
          onOpenChange      = {(status) => setVisibility({ date: status, time: false })}
          picker            = {picker}
          placeholder       = {datePlaceholder || format(new Date(), dateFormat)}
          suffixIcon        = {null}
        />
        {withTime && (
          <>
            <DatePicker.Separator>@</DatePicker.Separator>
            <DatePickerAntd
              use12Hours
              disabled          = {disabled}
              format            = "h:mm aaa"
              getPopupContainer = {setPopupContainer}
              value             = {componentDate}
              picker            = "time"
              onChange          = {onSelect}
              open              = {dropdownsVisibility.time}
              onOpenChange      = {(status) => setVisibility({ date: false, time: status })}
              suffixIcon        = {null}
              placeholder       = {timePlaceholder || `08:00 am${timezone ? '' : ' (UTC)'}`}
            />
          </>
        )}
        <FieldError field={field} form={form} />
      </DatePicker.Wrapper>
    </DatePicker.Container>
  );
};

DatePicker.Container = styled.div<{ margin?: string }>`
  margin : ${({ margin }) => margin || '20px 0 0'};
`;

DatePicker.Error = styled.p<{ visible: boolean }>`
  bottom       : ${({ visible }) => (visible ? '-17px' : '0')};
  color        : ${({ theme }) => theme.color.red};
  font-size    : 12px;
  opacity      : ${({ visible }) => (visible ? 1 : 0)};
  padding-left : 10px;
  position     : absolute;
  transition   : all 0.3s;
  z-index      : 100;
`;

DatePicker.Label = styled.p`
  font-size      : 12px;
  color          : ${({ theme }) => theme.color.black};
  font-weight    : 600;
  line-height    : 18px;
  text-transform : uppercase;
`;

DatePicker.Wrapper = styled.div<{
  datePickerActive : boolean;
  disabled         : boolean;
  timePickerActive : boolean | null;
}>`
  align-items      : baseline;
  display          : flex;
  gap              : 15px;
  justify-content  : space-between;
  opacity          : ${({ disabled }) => (disabled ? '0.6' : '1')};
  position         : relative;

  .ant-picker {
    background-color : #fff;
    border           : none;
    border-radius    : 0;
    box-shadow       : 0 1px 0 0 ${({ theme }) => theme.color.lightGray};
    flex             : 1;
    margin-top       : 14px;
    padding          : 0 0 3px 0;
    transition       : all 0.2s;
    z-index          : 200;

    input {
      color       : ${({ theme }) => theme.field.placeholderColor};
      line-height : 20px;
      transition  : all 0.3s;

      &:focus { color: ${({ theme }) => theme.color.blue}; }

      ::placeholder { color: ${({ theme }) => theme.color.gray}; }
    }
  }

  .ant-picker:not(:first-child) {
    ${({ disabled, timePickerActive, theme }) => timePickerActive && !disabled && `box-shadow: 0 3px 0 0 ${theme.color.blue};`}

    ${({ disabled, timePickerActive, theme }) => typeof timePickerActive === 'boolean' && !disabled && `
      &:hover { box-shadow: 0 ${timePickerActive ? 3 : 1}px 0 0 ${theme.color.blue}; }
    `}
  }

  .ant-picker:first-child {
    ${({ datePickerActive, disabled, theme }) => datePickerActive && !disabled && `box-shadow: 0 3px 0 0 ${theme.color.blue};`}

    &:hover { box-shadow: ${({ datePickerActive, disabled, theme }) => !disabled && `0 ${datePickerActive ? 3 : 1}px 0 0 ${theme.color.blue}`}; }
  }
`;

DatePicker.Separator = styled.span`
  color       : ${({ theme }) => theme.color.gray};
  font-size   : 20px;
  font-weight : 500;
`;
