import { formatInTimeZone } from 'date-fns-tz';

type TGetDateTimeIntervalsFunc = (
  data: {
    dateEnd   : string | null;
    dateStart : string;
    timeZone  : string;
  },
  dayFormat  : string,
  timeFormat : string,
) => {
  dateInterval : string;
  timeInterval : string;
}

export const getDateTimeIntervals: TGetDateTimeIntervalsFunc = (data, dayFormat, timeFormat) => {
  const { dateEnd, dateStart, timeZone } = data;

  const startDate = formatInTimeZone(new Date(dateStart), timeZone, dayFormat);
  const endDate   = dateEnd && formatInTimeZone(new Date(dateEnd), timeZone, dayFormat);

  const startTime = formatInTimeZone(new Date(dateStart), timeZone, timeFormat);
  const endTime   = dateEnd && formatInTimeZone(new Date(dateEnd), timeZone, timeFormat);

  const dateInterval = (endDate && startDate !== endDate) ? `${startDate} - ${endDate}` : startDate;
  const timeInterval = (endTime && startTime !== endTime) ? `${startTime} - ${endTime}` : startTime;

  return {
    dateInterval,
    timeInterval,
  };
};
