import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState } from '@store/rootReducer';

export const STATE_KEY = 'auth';

export interface IAuthReducer {
  token        : string | null;
  type         : string;
  errorMessage : string | null;
  isAuthorized : boolean;
  meta         : {
    isFetching: boolean;
  };
}

export enum AuthTypes {
  saveLogInData             = 'saveLogInData',
  saveLogInDataSuccessfully = 'saveLogInDataSuccessfully',
  saveLogInDataFailure      = 'saveLogInDataFailure',
  logOut                    = 'logOut',
}

const initialState: IAuthReducer = {
  token        : null,
  type         : 'Bearer',
  errorMessage : null,
  isAuthorized : false,
  meta         : {
    isFetching: false,
  },
};

interface IAuthActions {
  [AuthTypes.saveLogInData]             : Action<typeof initialState>
  [AuthTypes.saveLogInDataSuccessfully] : Action<typeof initialState & { token: string; type: string; isAuthorized: true; meta: { isFetching: false }}>;
  [AuthTypes.saveLogInDataFailure]      : Action<typeof initialState & { errorMessage: string; meta: { isFetching: false }}>;
  [AuthTypes.logOut]                    : Action<typeof initialState>;
}

export interface IAuthActionsCreators {
  [AuthTypes.saveLogInData]             : () => IAuthActions[AuthTypes.saveLogInData];
  [AuthTypes.saveLogInDataSuccessfully] : (token: string, type: string) => IAuthActions[AuthTypes.saveLogInDataSuccessfully];
  [AuthTypes.saveLogInDataFailure]      : (errorMessage: string) => IAuthActions[AuthTypes.saveLogInDataFailure];
  [AuthTypes.logOut]                    : () => IAuthActions[AuthTypes.logOut];
}

type Actions = IAuthActions[AuthTypes.saveLogInData]
| IAuthActions[AuthTypes.saveLogInDataSuccessfully]
| IAuthActions[AuthTypes.saveLogInDataFailure]
| IAuthActions[AuthTypes.logOut];

export const {
  saveLogInData,
  saveLogInDataSuccessfully,
  saveLogInDataFailure,
  logOut,
}: IAuthActionsCreators = createActions(
  {
    SAVE_LOG_IN_DATA              : () => ({ ...initialState }),
    SAVE_LOG_IN_DATA_SUCCESSFULLY : (token, type) => ({ ...initialState, token, type, isAuthorized: true, meta: { isFetching: false }}),
    SAVE_LOG_IN_DATA_FAILURE      : (errorMessage) => ({ ...initialState, errorMessage, meta: { isFetching: false }}),

    LOG_OUT: () => ({ ...initialState }),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const authReducer = handleActions<IAuthReducer>(
  {
    // @ts-ignore
    [combineActions(
      saveLogInData,
      saveLogInDataSuccessfully,
      saveLogInDataFailure,
      logOut,
    )]: (
      state  : IAuthReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default authReducer;

interface IAuthSelectors {
  getToken        : (state: IState) => IAuthReducer['token'];
  getType         : (state: IState) => IAuthReducer['type'];
  getAuthStatus   : (state: IState) => IAuthReducer['isAuthorized'];
  getErrorMessage : (state: IState) => IAuthReducer['errorMessage'];
  getMeta         : (state: IState) => IAuthReducer['meta'];
}

export const getToken: IAuthSelectors['getToken']               = (state: IState) => state[STATE_KEY].token;
export const getType: IAuthSelectors['getType']                 = (state: IState) => state[STATE_KEY].type;
export const getAuthStatus: IAuthSelectors['getAuthStatus']     = (state: IState) => state[STATE_KEY].isAuthorized;
export const getErrorMessage: IAuthSelectors['getErrorMessage'] = (state: IState) => state[STATE_KEY].errorMessage;
export const getMeta: IAuthSelectors['getMeta']                 = (state: IState) => state[STATE_KEY].meta;
