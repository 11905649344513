import { useCallback } from 'react';
import styled          from 'styled-components';

import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

import { IMediaItem } from './MultiMediaUploader';

interface IMediaBlockProps {
  media          : IMediaItem;
  onClickDelete  : (mediaUri: string) => void;
  onClickPreview : (mediaUri: string) => void;
}

export const MediaBlockTag = styled.div`
  align-items     : center;
  border-radius   : 2px;
  box-shadow      : 0 0 2px 0 lightgray;
  display         : flex;
  height          : 95px;
  justify-content : center;
  padding         : 10px;
  position        : relative;
  width           : 95px;

  > img,
  video {
    border-radius : 2px;
    height        : 100%;
    object-fit    : cover;
    width         : 100%;
  }
`;

export const MediaBlock = ({
  media,
  onClickDelete,
  onClickPreview,
}: IMediaBlockProps) => {
  const getYoutubeVideoId = useCallback(() => {
    return media.uri && media.uri.split('/')[4];
  }, [media]);

  return (
    <MediaBlockTag>
      <MediaBlock.BlockControls>
        <EyeOutlined onClick={() => onClickPreview(media.uri)} />
        <DeleteOutlined onClick={() => onClickDelete(media.uri)} />
      </MediaBlock.BlockControls>
      {media.mimeType === 'image' && (
        <img src={media.uri} />
      )}
      {media.mimeType === 'video' && (
        <video>
          <source src={`${media.uri}`} type="video/mp4" />
        </video>
      )}
      {media.mimeType === 'youtube' && (
        <img src={`https://img.youtube.com/vi/${getYoutubeVideoId()}/hqdefault.jpg`} />
      )}
    </MediaBlockTag>
  );
};

MediaBlock.BlockControls = styled.div`
  align-items      : center;
  background-color : rgba(0, 0, 0, 0.6);
  color            : #fff;
  display          : flex;
  gap              : 15px;
  height           : 75px;
  justify-content  : center;
  opacity          : 0;
  position         : absolute;
  transition       : all 0.1s;
  width            : 75px;
  z-index          : 999;

  span { cursor: pointer; }

  &:hover { opacity: 1; }
`;
