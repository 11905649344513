import { format }                         from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import styled                             from 'styled-components';

import { TOption }    from '../../models';
import { DeniedIcon } from '../../../public/svg';

import { TSingleFilterValue } from './FilterForm';
import { ITableDataRules }    from './FiltrationSorting';

interface IFilterRule {
  key   : string;
  label : string;
  value : NonNullable<TSingleFilterValue>;
}

interface IFiltrationInfoProps {
  deleteRule : (ruleKey: string) => void;
  filtration : ITableDataRules['filtration'];
}

const getPrettyRuleValue = (rawValue: IFilterRule['value']) => {
  const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

  if (typeof rawValue === 'object') {
    return rawValue.label;
  }
  if (ISO_8601_FULL.test(rawValue)) {
    return format(new Date(rawValue), 'MMM dd, yyyy');
  }

  return rawValue;
};

export const FiltrationInfo = ({ deleteRule, filtration }: IFiltrationInfoProps) => {
  const [mouseHoverRule, setMouseStatus] = useState(false);

  const filterRules = useMemo<IFilterRule[]>(() => {
    const keys = Object.keys(filtration);

    const result = keys.reduce((acc: IFilterRule[], key) => {
      if (Array.isArray(filtration[key].value)) {
        const valuesArray = filtration[key].value as (string | TOption)[];

        const parsedArrayRules: IFilterRule[] = valuesArray.map((item) => ({
          key,
          label : filtration[key].label,
          value : item,
        }));

        return [...acc, ...parsedArrayRules];
      }

      return [...acc, {
        key,
        label : filtration[key].label,
        value : filtration[key].value as string | TOption,
      }];
    }, []);

    return result;
  }, [filtration]);

  const onClickDelete = useCallback((ruleKey: string) => {
    deleteRule(ruleKey);
    setMouseStatus(false);
  }, [deleteRule]);

  if (Object.keys(filtration).length === 0) { return null; }

  return (
    <FiltrationInfo.Wrapper>
      {filterRules.map((rule) => (
        <FiltrationInfo.Rule
          key          = {`${rule.key}_${rule.value}`}
          onMouseEnter = {() => setMouseStatus(true)}
          onMouseLeave = {() => setMouseStatus(false)}
        >
          <p>{rule.label}: <span>{getPrettyRuleValue(rule.value)}</span></p>
          <FiltrationInfo.DeleteButton
            onClick = {() => onClickDelete(rule.key)}
            opacity = {mouseHoverRule ? 1 : 0}
          >
            <DeniedIcon
              color  = "#000"
              height = "12px"
              width  = "12px"
            />
          </FiltrationInfo.DeleteButton>
        </FiltrationInfo.Rule>
      ))}
    </FiltrationInfo.Wrapper>
  );
};

FiltrationInfo.DeleteButton = styled.div<{ opacity: number }>`
  background    : #fff;
  border-radius : 3px;
  box-shadow    : 0 0 3px 1px #fff;
  cursor        : pointer;
  opacity       : ${({ opacity }) => opacity};
  padding       : 3px 6px;
  position      : absolute;
  right         : 1px;
  top           : 1px;
  transition    : all 0.2s;

  svg {
    color       : ${({ theme }) => theme.color.blue};
    padding-top : 2px;
  }
`;

FiltrationInfo.Rule = styled.div`
  background-color : #fff;
  box-shadow       : 0 0 2px 2px #15223214;
  border-radius    : 3px;
  overflow         : hidden;
  position         : relative;

  p {
    font-size     : 12px;
    line-height   : 15px;
    margin        : 5px 10px;
    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
  }

  span { font-weight: 600; }
`;

FiltrationInfo.Wrapper = styled.div`
  display    : flex;
  gap        : 10px;
  margin-top : 10px;
`;
