import { Field, Form } from 'formik';
import { useCallback } from 'react';
import styled          from 'styled-components';

import { Card, FormButtons, Input } from '@common/components';
import { MultipleAutoSuggest }      from '@components/Autosuggest/MultipleAutoSuggest';
import { Title }                    from '@components/Title/Title';

import { ILicenseModel, ITerritoryModel } from '@src/models';
import { useFetchEntity }                 from '@utils/fetchEntity';

interface ILicenseFormProps {
  isEditMode : boolean;
  isFetching : boolean;
}

export const LicenseForm = ({ isEditMode, isFetching }: ILicenseFormProps) => {
  const mapTerritoriesForChips = useCallback((data: ITerritoryModel[]) => {
    return data.map((item) => ({ label: item.name, value: item.territoryId }));
  }, []);

  const [license, isLicenseFetching] = useFetchEntity<ILicenseModel>('LicenseAccount');

  return (
    <Card>
      <Title name={`${license ? 'Edit' : 'Add new'} client`} />
      <LicenseForm.Wrapper>
        <LicenseForm.Column>
          <Field
            label       = "First name"
            name        = "firstName"
            placeholder = "Client first name"
            component   = {Input}
          />
          <Field
            label       = "Email"
            name        = "email"
            placeholder = "Client email"
            component   = {Input}
          />
          {!isEditMode && (
            <>
              <Field
                label       = "Password"
                name        = "password"
                type        = "password"
                placeholder = "Client password"
                component   = {Input}
              />
              <LicenseForm.Note>
                <p>*Make sure you remember the password, it won't be saved as plain text</p>
              </LicenseForm.Note>
            </>
          )}
        </LicenseForm.Column>

        <LicenseForm.Column rightSide>
          <Field
            label       = "Last name"
            name        = "lastName"
            placeholder = "Client last name"
            component   = {Input}
          />
          <Field
            label                = "Assigned areas"
            name                 = "territories"
            placeholder          = "Client areas"
            endpoint             = "feature/territory/search?name"
            searchResponseMapper = {mapTerritoriesForChips}
            responseMapper       = {mapTerritoriesForChips}
            component            = {MultipleAutoSuggest}
          />
          {!isEditMode && (
            <Field
              label       = "Password copy"
              name        = "passwordCopy"
              placeholder = "Repeat the password"
              type        = "password"
              component   = {Input}
            />
          )}
          <LicenseForm.ButtonWrapper>
            <FormButtons
              disabled      = {{ main: isFetching || isLicenseFetching }}
              onClickCancel = {() => history.back()}
            />
          </LicenseForm.ButtonWrapper>
        </LicenseForm.Column>
      </LicenseForm.Wrapper>
    </Card>
  );
};

LicenseForm.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

LicenseForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;

LicenseForm.Note = styled.div`
  font-size  : 10px;
  margin-top : 5px;
`;

LicenseForm.ButtonWrapper = styled.div`
  display         : flex;
  justify-content : end;
`;
