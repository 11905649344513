import styled from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

interface ICircularLoader {
  alignItems? : 'center' | 'flex-start';
  padding?    : string;
}

export const CircularLoader = ({
  alignItems = 'flex-start',
  padding    = '100px 0 0 0',
}: ICircularLoader) => {
  return (
    <CircularLoader.Wrapper
      alignItems = {alignItems}
      padding    = {padding}
    >
      <LoadingOutlined style={{ fontSize: 40 }} />
    </CircularLoader.Wrapper>
  );
};

CircularLoader.Wrapper = styled.div<{ alignItems: string; padding: string }>`
  align-items     : ${({ alignItems }) => alignItems};
  display         : flex;
  justify-content : center;
  height          : 100%;
  padding         : ${({ padding }) => padding};
  width           : 100%;
  color          : ${({ theme }) => theme.color.orange};
`;
