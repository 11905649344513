import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }        from '@utils/apiCaller';
import { Notifications }  from '@components/Notifications/Notifications';
import { mapArrayForUrl } from '@utils/mappers';
import { browserHistory } from '@src/store';

import {
  fetchEvents,
  fetchEventsSuccessfully,
  fetchEventsFailure,
  addEvent,
  addEventSuccessfully,
  addEventFailure,
  editEvent,
  editEventSuccessfully,
  editEventFailure,
  deleteEvents,
  deleteEventsSuccessfully,
  deleteEventsFailure,

  getMeta,

  EventTypes,
  IEventsActions,
  IEventsReducer,
} from './eventsReducer';

function* handleFetchEvents() {
  try {
    const { page, rowsPerPage, orderBy, order, name, businessName, dateFrom, dateTo, territory, tags }: IEventsReducer['meta'] = yield select(getMeta);
    const { data: { items, totalCount } } = yield callApi(`feature/event\
?name=${name}\
&businessName=${businessName}\
&dateFrom=${dateFrom}\
&dateTo=${dateTo}\
&page=${page}\
&itemsPerPage=${rowsPerPage}\
&sortingKey=${orderBy}\
&sortingOrder=${order}\
&territoryId=${territory?.value || ''}\
${mapArrayForUrl(tags, 'Tags=')}`);

    yield put(fetchEventsSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchEventsFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Events. Please try again', 'error');
  }
}

function* handleAddEvent(action: IEventsActions[EventTypes.addEvent]) {
  try {
    const values          = action.payload.attributes;
    const isSeveralEvents = !!(values.eventRecurrence && Object.keys(values.eventRecurrence).length);

    if (isSeveralEvents) {
      yield call(Notifications.enqueueSnackbar, 'Events will be created soon, you will see the message when it is done', 'info');
    }
    yield callApi('feature/event', 'post', values);
    yield put(addEventSuccessfully());
    if (browserHistory.location.pathname.split('/').includes('events') && browserHistory.location.pathname.split('/').includes('add')) {
      yield put(replace('/admin/events'));
    }
    yield put(fetchEvents());
    yield call(Notifications.enqueueSnackbar, `${isSeveralEvents ? 'Events were' : 'Event was'} added successfully`, 'success');
  } catch (e) {
    yield put(addEventFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding an Event. Please try again', 'error');
  }
}

function* handleEditEvent(action: IEventsActions[EventTypes.editEvent]) {
  try {
    const { values, withRecurring } = action.payload.attributes;

    yield callApi(`feature/event?withRecurring=${withRecurring}`, 'put', values);
    yield put(editEventSuccessfully());
    yield put(replace('/admin/events'));
    yield call(Notifications.enqueueSnackbar, 'Event was edited successfully', 'success');
  } catch (e) {
    yield put(editEventFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing an Event. Please try again', 'error');
  }
}

function* handleDeleteEvents(action: IEventsActions[EventTypes.deleteEvents]) {
  try {
    const { eventIds, withRecurring } = action.payload.attributes;

    for (let i = 0; i < eventIds.length; i++) {
      yield callApi(`feature/event/${eventIds[i]}?withRecurring=${!!withRecurring}`, 'delete');
    }

    yield put(deleteEventsSuccessfully());
    yield put(fetchEvents());
    yield call(Notifications.enqueueSnackbar, `${eventIds.length > 1 ? 'Events were' : 'Event was'} deleted successfully`, 'success');
  } catch (e) {
    const { eventIds } = action.payload.attributes;

    yield put(deleteEventsFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${eventIds.length > 1 ? 'Events' : 'an Event'}. Please try again`, 'error');
  }
}

function* eventsSagas() {
  yield all([
    takeLatest(fetchEvents, handleFetchEvents),
    takeLatest(addEvent, handleAddEvent),
    takeLatest(editEvent, handleEditEvent),
    takeLatest(deleteEvents, handleDeleteEvents),
  ]);
}

export { eventsSagas };
