import { Radio as AntRadio } from 'antd';
import styled                from 'styled-components';

import { Label } from '../Label/Label';

export interface IBaseRadioProps {
  label?    : string;
  onChange  : (value: string) => void;
  required? : boolean;
  value     : string;
  options   : {
    label : string;
    value : string;
  }[];
}

export const BaseRadio = ({
  label,
  onChange,
  value,
  options,
  required = false,
}: IBaseRadioProps) => (
  <div>
    {label && (
      <Label
        label    = {label}
        required = {required}
      />
    )}
    <BaseRadio.Group
      onChange = {(e) => onChange(e.target.value)}
      value    = {value}
    >
      {options.map((option) => (
        <AntRadio
          key   = {option.value}
          value = {option.value}
        >
          {option.label}
        </AntRadio>
      ))}
    </BaseRadio.Group>
  </div>
);

BaseRadio.Group = styled(AntRadio.Group)`
  display        : flex;
  flex-direction : column;
  gap            : 5px;
  margin-top     : 8px;
`;
