import { Skeleton } from 'antd';
import styled       from 'styled-components';

import { CardHeader }    from './CardHeader';
import { CardStatus }    from './CardStatus';
import { ECardStatuses } from '../../enums';

export interface ICard {
  children : React.ReactNode;
  footer?  : JSX.Element;
  header?  : React.ReactNode;
  status?  : ECardStatuses; // TODO: when all card are ready - set this prop to required
  style?   : {
    padding? : string;
    width?   : string;
  }
}

export const Card = ({
  footer,
  header,
  children,
  status,
  style,
}: ICard) => {
  if (status === ECardStatuses.Initialization) {
    return <Skeleton active />;
  }

  return (
    <Card.Wrapper {...style}>
      <CardStatus status={status || ECardStatuses.None} />
      {(typeof header === 'string' && <CardHeader title={header} />) || header || null}
      {children}
      {footer}
    </Card.Wrapper>
  );
};

Card.Wrapper = styled.div<{
  padding? : string;
  width?   : string;
}>`
  align-self       : flex-start;
  background-color : #fff;
  border-radius    : 6px;
  box-shadow       : 0 1px 4px #15223214;
  max-width        : 100%;
  min-width        : 465px;
  padding          : ${({ padding }) => padding || '21px 30px 27px'};
  position         : relative;
  width            : ${({ width }) => (width || 'auto')};
`;
