import styled from 'styled-components';

import { DeniedIcon } from '../../../public/svg';

interface IDeniedCircle {
  margin? : string;
  size?   : string;
  icon?    : {
    width  : string;
    height : string;
  }
}

export const DeniedCircle = ({ icon, margin, size }: IDeniedCircle) => (
  <DeniedCircle.Wrapper margin={margin} size={size}>
    <DeniedIcon height={icon?.height || '14px'} width={icon?.width || '14px'} />
  </DeniedCircle.Wrapper>
);

DeniedCircle.Wrapper = styled.div<{ margin?: string; size?: string }>`
  align-items      : center;
  background-color : ${({ theme }) => theme.color.red};
  border-radius    : 50%;
  display          : flex;
  justify-content  : center;
  height           : ${({ size }) => size || '32px'};
  margin           : ${({ margin }) => margin || '0'};
  width            : ${({ size }) => size || '32px'};
`;
