import { useCallback }              from 'react';
import { Form, Field, FormikProps } from 'formik';
import styled                       from 'styled-components';

import { Card, Input, FormButtons }        from '@common/components';
import { MultipleAutoSuggest }             from '@components/Autosuggest/MultipleAutoSuggest';
import { AreaSelection }                   from '@components/AreaSelection/AreaSelection';
import { AutoSuggest }                     from '@components/Autosuggest/Autosuggest';
import { usePubliherHandlers }             from '@components/Modal/AddPublisher';
import { Title }                           from '@components/Title/Title';

import { IFeedModel, IPublisherModel }     from '@src/models';
import { useFetchEntity }                  from '@utils/fetchEntity';

interface IFormFeed {
  isFetching       : boolean;
  publisherValues? : Partial<IPublisherModel>;
  values           : any;
  submitForm       : () => void;
}

export const FormFeed = ({
  isFetching,
  publisherValues,
  setFieldValue,
  submitForm,
}: FormikProps<any> & IFormFeed) => {
  const mapSearchResponseChannels = useCallback((data: any[]) => {
    return data.map((p: any) => ({ value: p.channelId, label: p.name }));
  }, []);

  const [mapSearchResponsePublisher, onAddPublisher] = usePubliherHandlers(setFieldValue);
  const [feed] = useFetchEntity('feature/Feed') as [IFeedModel | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${feed ? 'edit the' : 'add a'} feed`} />
      <FormFeed.Wrapper noValidate>
        <FormFeed.Column>
          <Field
            required
            label       = "title"
            name        = "name"
            placeholder = "Enter a title"
            component   = {Input}
          />
          <Field
            required
            label              = "publisher"
            name               = "publisher"
            placeholder        = "Search for a Publisher ..."
            endpoint           = "feature/publisher/search?name"
            responseMapper     = {mapSearchResponsePublisher}
            component          = {AutoSuggest}
            onClickNotFound    = {() => onAddPublisher(publisherValues)}
          />
          <Field
            required
            label       = "rss/content url"
            name        = "rssUri"
            placeholder = "Link to story"
            component   = {Input}
          />
        </FormFeed.Column>

        <FormFeed.Column rightSide>
          <Field
            required       = {false}
            label          = "add to channel"
            name           = "rFeedChannel"
            placeholder    = "Assign Channel(s)"
            endpoint       = "feature/channel/search?name"
            responseMapper = {mapSearchResponseChannels}
            component      = {MultipleAutoSuggest}
          />
          <Field
            required    = {false}
            label       = "Assigned Areas"
            name        = "rFeedTerritory"
            placeholder = "Start searching areas..."
            component   = {AreaSelection}
          />
          <FormButtons
            disabled      = {{main: isFetching}}
            onClickCancel = {() => history.back()}
            onSubmit      = {submitForm}
          />
        </FormFeed.Column>
      </FormFeed.Wrapper>
    </Card>
  );
};

FormFeed.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

FormFeed.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
