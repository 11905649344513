import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }        from '@utils/apiCaller';
import { mapArrayForUrl } from '@utils/mappers';

import { store }                  from '@store/index';
import { ERequestedReviewStatus } from '@models/enums';

import { Modal }         from '@src/App';
import { IFeedModel }    from '@src/models';
import { Notifications } from '@components/Notifications/Notifications';
import { Success }       from '@components/Modal/Success';

import {
  fetchFeeds,
  fetchFeedsSuccessfully,
  fetchFeedsFailure,
  addFeed,
  addFeedSuccessfully,
  addFeedFailure,
  editFeed,
  editFeedSuccessfully,
  editFeedFailure,
  deleteFeeds,
  deleteFeedsSuccessfully,
  deleteFeedsFailure,

  getMeta,

  IFeedsActions,
  IFeedsReducer,
} from './feedsReducer';

import { updateRequestedFeedStatus } from './requestedFeedsReducer';

function* handleFetchFeeds() {
  try {
    const { channels, name, page, publisher, rowsPerPage, territory, orderBy, order }: IFeedsReducer['meta'] = yield select(getMeta);
    const { data: { items, totalCount } } = yield callApi(`feature/feed?page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}&name=${name}&territoryId=${territory?.value || ''}&PublisherName=${publisher || ''}${mapArrayForUrl(channels, 'channelIds=')}`);

    yield put(fetchFeedsSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchFeedsFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Feeds. Please try again', 'error');
  }
}

function* handleAddFeed(action: IFeedsActions['addFeed']) {
  const { requestedFeedId, ...restValues } = action.payload.attributes;

  try {
    const { data: { feedId, rFeedChannel, rFeedTerritory } } = yield callApi('feature/feed', 'post', restValues);

    if (requestedFeedId) {
      yield put(updateRequestedFeedStatus({
        feedRequestId : requestedFeedId,
        Status        : ERequestedReviewStatus.Approved,
        EmailText     : '',
      }));
    }

    yield put(replace(`/admin/feeds/${feedId}`));
    yield put(addFeedSuccessfully());
    yield call(Modal.open, Success, {
      primaryText : 'Feed was added',
      actions     : [
        {
          name    : 'View All Feeds',
          onClick : () => {
            store.createdStore.dispatch(replace('/admin/feeds'));
            Modal.close();
          },
        },
        {
          name    : 'View Blank Feed',
          onClick : () => {
            store.createdStore.dispatch(replace('/admin/feeds/add', {
              initialValues : {},
              REFRESH       : true,
            }));
            Modal.close();
          },
        },
        {
          name    : 'Add Another Feed to Channels',
          onClick : () => {
            store.createdStore.dispatch(replace('/admin/feeds/add', {
              initialValues : {
                rFeedTerritory,
                rFeedChannel: rFeedChannel.map((item: IFeedModel['rFeedChannel'][0]) => ({
                  label : item.channelName,
                  value : item.channelId,
                })),
              },
            }));
            Modal.close();
          },
        },
      ],
    });
    yield call(Notifications.enqueueSnackbar, 'Feed was added successfully', 'success');
  } catch (e) {
    yield put(addFeedFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Feed. Please try again', 'error');
  }
}

function* handleEditFeed(action: IFeedsActions['editFeed']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/feed', 'put', values);
    yield put(editFeedSuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Feed was edited successfully', 'success');
  } catch (e) {
    yield put(editFeedFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Feed. Please try again', 'error');
  }
}

function* handleDeleteFeeds(action: IFeedsActions['deleteFeeds']) {
  try {
    const { feedIds } = action.payload.attributes;

    for (let i = 0; i < feedIds.length; i++) {
      yield callApi('feature/feed', 'delete', feedIds[i]);
    }

    yield put(fetchFeeds());
    yield put(deleteFeedsSuccessfully());
    yield call(Notifications.enqueueSnackbar, `${feedIds.length > 1 ? 'Feeds were' : 'Feed was'} deleted successfully`, 'success');
  } catch (e) {
    const { feedIds } = action.payload.attributes;

    yield put(deleteFeedsFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${feedIds.length > 1 ? 'Feeds' : 'a Feed'}. Please try again`, 'error');
  }
}

function* feedsSagas() {
  yield all([
    takeLatest(fetchFeeds, handleFetchFeeds),
    takeLatest(addFeed, handleAddFeed),
    takeLatest(editFeed, handleEditFeed),
    takeLatest(deleteFeeds, handleDeleteFeeds),
  ]);
}

export { feedsSagas };
