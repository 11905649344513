import { useCallback, useEffect } from 'react';
import styled                     from 'styled-components';

import { BaseInput, Label } from '@common/components';
import { BaseSelect }       from '@common/components/Select';

import { theme as Theme } from '@styles/theme';

export enum COLOR_TYPE {
  HEX  = 'hex',
  RGB  = 'rgb',
  RGBA = 'rgba'
}

export const COLOR_TYPES = [
  { label: 'Hex', value: COLOR_TYPE.HEX },
  { label: 'RGB', value: COLOR_TYPE.RGB },
  { label: 'RGBA', value: COLOR_TYPE.RGBA },
];

export interface IColorSelectValue {
  color : string;
  type  : COLOR_TYPE;
}

export interface IBaseColorSelectProps {
  label?        : string;
  onChange      : (newValue: IColorSelectValue) => void;
  required?     : boolean;
  value         : IColorSelectValue;
  wrapperStyle? : { margin?: string; };
}

const getInitialColor = (type: COLOR_TYPE) => {
  if (type === COLOR_TYPE.HEX) {
    return '#F0552A';
  } else if (type === COLOR_TYPE.RGB) {
    return 'rgb(240, 85, 42)';
  }

  return 'rgba(240, 85, 42, 0.9)';
};

const INITIAL_WRAPPER_STYLE = { margin: Theme.field.gap };

export const BaseColorSelect = ({ label, onChange, required, value, wrapperStyle }: IBaseColorSelectProps) => {
  const onChangeColorType = useCallback((newType: COLOR_TYPE) => {
    onChange({ color: getInitialColor(newType), type: newType });
  }, [onChange]);

  useEffect(() => {
    if (!value.color) {
      onChange({ ...value, color: getInitialColor(value.type) });
    }
  }, []);

  return (
    <BaseColorSelect.Wrapper
      {...INITIAL_WRAPPER_STYLE}
      {...wrapperStyle}
    >
      {label && (
        <Label
          label    = {label}
          required = {required}
        />
      )}
      <BaseColorSelect.FlexContainer>
        <BaseSelect
          onChange = {(type) => onChangeColorType(type as COLOR_TYPE)}
          options  = {COLOR_TYPES}
          value    = {value.type}
        />
        <BaseInput
          onChange = {(color) => onChange({ ...value, color })}
          value    = {value.color}
        />
        <BaseColorSelect.ExampleBlock color={value.color} />
      </BaseColorSelect.FlexContainer>
    </BaseColorSelect.Wrapper>
  );
};

BaseColorSelect.ExampleBlock = styled.div<{ color: string }>`
  background-color : ${({ color }) => (color || 'transparent')};
  border-radius    : 2px;
  box-shadow       : 0 0 2px 1px lightgray;
  height           : 25px;
`;

BaseColorSelect.FlexContainer = styled.div`
  align-items : flex-end;
  display     : flex;
  gap         : 10px;
  margin-top  : 8px;

  > div { flex: 1; }

  > div:last-child { flex: 0 0 50px; }

  .ant-select { margin-top: 0; }
`;

BaseColorSelect.Wrapper = styled.div<{ margin?: string; }>`
  margin: ${({ margin }) => margin};
`;
