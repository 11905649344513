import { Select as SelectAntd, SelectProps } from 'antd';
import { Key }                               from 'antd/lib/table/interface';
import { useCallback, useMemo, useState }    from 'react';
import { useTranslation }                    from 'react-i18next';
import styled                                from 'styled-components';

import { NotFoundWindow } from './NotFoundWindow';
import { Label }          from '../Label';
import { TOption }        from '../../models';
import { theme as Theme } from '../../styles/theme';

const { Option } = SelectAntd;

export interface IAntOption {
  children? : React.ReactNode;
  value     : Key;
}

export interface BaseSelectProps extends Omit<SelectProps<number | string>, 'onChange' | 'onSelect'> {
  label?           : string;
  onChange?        : (value: Key | Key[], ...args: unknown[]) => void;
  onClickNotFound? : () => void;
  onSelect?        : (optionValue: Key, option: TOption | unknown) => void;
  options          : TOption[];
  placeholder?     : string;
  required?        : boolean;
  value            : Key;
}

const NOT_FOUND_FLAG = 'notFoundClickHere';

export const BaseSelect = ({
  label,
  options,
  onChange,
  onClickNotFound,
  onSelect,
  value,
  required = true,
  ...componentProps
}: BaseSelectProps) => {
  const { t } = useTranslation();

  const [isFieldFocused, setFieldStatus] = useState<boolean>(false);

  const NotFoundContent = useMemo(() => (
    <NotFoundWindow
      message = {t(NOT_FOUND_FLAG)}
      onClick = {onClickNotFound}
    />
  ), [onClickNotFound]);

  const onSelectOption = useCallback((optionValue: Key, option: TOption | unknown) => {
    if (optionValue === NOT_FOUND_FLAG && onClickNotFound) {
      onClickNotFound();
    } else if (onSelect) {
      onSelect(optionValue, option);
    }
  }, [onClickNotFound, onSelect]);

  return (
    <BaseSelect.Wrapper isFieldFocused={isFieldFocused}>
      {label && (
        <Label
          required = {required}
          label    = {label}
        />
      )}
      <SelectAntd
        bordered                = {false}
        onDropdownVisibleChange = {setFieldStatus}
        onSelect                = {onSelectOption}
        notFoundContent         = {!!onClickNotFound && NotFoundContent}
        value                   = {value}
        onChange                = {onChange
          ? (newValue, option) => onChange(newValue, option as IAntOption | IAntOption[])
          : undefined}
        {...componentProps}
      >
        {options.map((option) => (
          <Option
            key   = {option.value}
            value = {option.value}
          >
            {option.label}
          </Option>
        ))}
        {!!options.length && !!onClickNotFound && (
          <Option style={{ color: Theme.color.blue }} value={NOT_FOUND_FLAG}>{t(NOT_FOUND_FLAG)}</Option>
        )}
      </SelectAntd>
    </BaseSelect.Wrapper>
  );
};

BaseSelect.Wrapper = styled.div<{ isFieldFocused: boolean }>`
  .ant-select {
    border     : none;
    box-shadow : 0 1px 0 0 ${({ theme }) => theme.color.lightGray};
    color      : ${({ theme }) => theme.field.placeholderColor};
    flex       : 1;
    margin-top : 8px;
    transition : all 0.2s;
    width      : 100%;

    ${({ isFieldFocused, theme }) => isFieldFocused && `
      box-shadow : 0 3px 0 0 ${theme.color.blue};
      color      : ${theme.color.blue};
    `}

    &:hover { box-shadow: ${({ isFieldFocused, theme }) => `0 ${isFieldFocused ? 3 : 1}px 0 0 ${theme.color.blue}`}; }

    .ant-select-selector { padding : 0 0 3px 0; }
  }

  .ant-select-multiple .ant-select-selection-item {
    color      : ${({ isFieldFocused, theme }) => (isFieldFocused ? theme.color.blue : theme.field.placeholderColor)};
    transition : all 0.3s;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { height: 29px; }
`;
