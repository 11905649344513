import React  from 'react';
import styled from 'styled-components';

import { BaseCopyButton, IBaseCopyButtonProps } from './BaseCopyButton';

interface ICopyButtonProps extends IBaseCopyButtonProps {
  children: React.ReactNode;
}

export const CopyButton = ({ children, valueToCopy } : ICopyButtonProps) => {
  if (!valueToCopy) {
    return <>{children}</>;
  }

  return (
    <CopyButton.Wrapper>
      {children}
      <BaseCopyButton valueToCopy={valueToCopy} />
    </CopyButton.Wrapper>
  );
};

CopyButton.Wrapper = styled.div`
  align-items : baseline;
  display     : flex;
  gap         : 5px;

  > div { opacity: 0; }

  &:hover { > div { opacity: 1; } }
`;
