import { useCallback }    from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field }    from 'formik';
import styled             from 'styled-components';

import {
  Card,
  Select,
  Checkbox,
  Input,
  RecurrenceEditor,
  DatePicker,
  FormButtons,
}                                        from '@common/components';
import { TIME_ZONE_OPTIONS }             from '@common/components/DatePicker/DatePicker';

import { AreaSelection }                 from '@components/AreaSelection/AreaSelection';
import { MultipleAutoSuggest }           from '@components/Autosuggest/MultipleAutoSuggest';
import { MediaUploader }                 from '@components/MediaUploader/MediaUploader';
import { Title }                         from '@components/Title/Title';

import { useFetchEntity }                from '@utils/fetchEntity';
import { ISlideModel }                   from '@src/models';

interface IFormSlide {
  isFetching : boolean;
  values     : any;
  submitForm : () => void;
}

export const FormSlide = ({ values, isFetching, submitForm }: IFormSlide) => {
  const { t } = useTranslation();

  const mapSearchResponseChannels = useCallback((data) => {
    return data.map((d: any) => ({ value: d.channelId, label: d.name }));
  }, []);

  const [slide] = useFetchEntity('feature/Slide') as [ISlideModel | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${slide ? 'edit' : 'add'} a slide`} />
      <FormSlide.Wrapper noValidate>
        <FormSlide.Column>
          <Field
            label       = "name"
            name        = "name"
            placeholder = "Enter a name"
            component   = {Input}
          />
          <Field
            withTime  = {!values.allDay}
            timeZone  = {values.timeZone}
            label     = "starts"
            name      = "dateStart"
            component = {DatePicker}
          />
          <Field
            withTime  = {!values.allDay}
            timeZone  = {values.timeZone}
            label     = "ends"
            name      = "dateEnd"
            component = {DatePicker}
          />
          <Field
            required    = {false}
            label       = "time zone"
            name        = "timeZone"
            placeholder = ""
            options     = {TIME_ZONE_OPTIONS}
            component   = {Select}
          />
          <Field
            required      = {false}
            label         = "text"
            name          = "text"
            nameSecondary = "(optional)"
            placeholder   = "Enter a text"
            component     = {Input}
          />
          <Field
            required      = {false}
            label         = "url"
            name          = "websiteUri"
            nameSecondary = "(optional)"
            placeholder   = "http://"
            component     = {Input}
          />
          <FormSlide.Checkboxes>
            <Field
              label     = {t('allDay')}
              name      = "allDay"
              component = {Checkbox}
            />
            <Field
              label     = {t('addRegularRepeats')}
              name      = "withRegularRepeats"
              component = {Checkbox}
            />
          </FormSlide.Checkboxes>
          {values.withRegularRepeats && (
            <Field
              withResizer = {false}
              name        = "slideRecurrence.recurrenceRule"
              component   = {RecurrenceEditor}
            />
          )}
        </FormSlide.Column>
        <FormSlide.Column rightSide>
          <Field
            media
            multi      = {false}
            label      = "image or video"
            name       = "image"
            container  = "slides"
            component  = {MediaUploader}
            imageStyle = {{
              backgroundColor : '#E6E9F4',
              height          : '167px',
            }}
          />
          <Field
            label          = "add to channel(s)"
            name           = "rSlideChannel"
            placeholder    = "Assign Channel(s)"
            endpoint       = "feature/channel/search?name"
            responseMapper = {mapSearchResponseChannels}
            component      = {MultipleAutoSuggest}
          />
          <Field
            component = {AreaSelection}
            name      = "rSlideTerritory"
          />
        </FormSlide.Column>
      </FormSlide.Wrapper>

      <FormButtons
        disabled      = {{main: isFetching}}
        onClickCancel = {() => history.back()}
        onSubmit      = {submitForm}
      />
    </Card>
  );
};

FormSlide.Checkboxes = styled.div`
  display         : flex;
  justify-content : space-between;

  > div { margin: 28px 0 9px; }
`;

FormSlide.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : start;
  `}
`;

FormSlide.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;
  margin-top : 40px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
