import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation }                  from 'react-i18next';
import { connect }                         from 'react-redux';
import { useHistory }                      from 'react-router-dom';

import { Card, CardHeader, Table } from '@common/components';
import { TableProps }              from '@common/components/Table/Table';
import { ECardStatuses }           from '@common/enums';
import { routesConfig }            from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }             from '@components/Modal/DeleteModal';

import { Modal }     from '@src/App';
import { ITagModel } from '@src/models';
import { IState }    from '@store/rootReducer';

import {
  deleteTags as deleteTagsAction,
  fetchTags as fetchTagsAction,
  getMeta,
  getTags,
  ITagsActionsCreators,
  ITagsReducer,
} from '../tagsReducer';

interface ITagsListProps {
  deleteTags : ITagsActionsCreators['deleteTags'];
  fetchTags  : ITagsActionsCreators['fetchTags'];
  isFetching : boolean;
  tags       : ITagsReducer['tags'];
}

const TagsListC = ({
  deleteTags,
  fetchTags,
  isFetching,
  tags,
}: ITagsListProps) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const onClickTag = useCallback((tag: ITagModel) => {
    history.push(routesConfig.tagsEdit.endpoint.replace(':id', tag.id.toString()));
  }, []);

  const onClickDeleteTags = useCallback(async (tagsIds: (number | string)[]) => {
    deleteTags(tagsIds as number[]);
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons = {[{ action: () => history.push(routesConfig.tagsAdd.endpoint), label: t('createTag') }]}
      title   = {t('tags')}
    />
  ), []);

  const tableHeaders = useMemo<TableProps['headers']>(() => [{ id: 'tag',  label: t('tag') }], []);

  const tableContent = useMemo<TableProps['content']>(() => ((tags || []).map((tag) => ({
    title : {
      onClick : () => onClickTag(tag),
      tag     : 'link',
      title   : tag.name || '',
    },
    id   : tag.id | 0,
  }))), [tags]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [{ flex : 1 }],
    pagination : {
      current : 0,
      setPage : (() => {
        fetchTags();
      }),
    },
  }), []);

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '1200px' }}
    >
      {!isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            Modal.open(DeleteModal, {
              onDeleteConfirm : () => {
                onClickDeleteTags(ids);
                Modal.close();
              },
              confirmMessage    : (ids.length === 1) ? 'The tag will be removed' : 'You cannot restore deleted data',
              entityNameMessage : `For removing enter the tag ID "${ids}"`,
              name              : (ids.length === 1) ? `${ids}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  isFetching : getMeta(state).isFetching,
  tags       : getTags(state),
});

const mapDispatchToProps = {
  deleteTags : deleteTagsAction,
  fetchTags  : fetchTagsAction,
};

export const TagsList = connect(mapStateToProps, mapDispatchToProps)(TagsListC);
