import GoogleMapReact, { Coords } from 'google-map-react';
import { useMemo, useState }      from 'react';
import styled                     from 'styled-components';

import { DeleteOutlined, EnvironmentOutlined, HomeFilled } from '@ant-design/icons';

import { ILocation } from '@src/models';

interface IGoogleMapPoints {
  height? : string;
  margin? : string;
  points  : ILocation[];
}

interface IPointIcon extends Coords {
  isMainPoint : boolean;
}

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PointIcon = ({ isMainPoint, lat, lng }: IPointIcon) => {
  const [isMouseOnIcon, setMousePosition] = useState(false);

  return isMainPoint
    ? (
      <PointIcon.Wrapper>
        <HomeFilled />
      </PointIcon.Wrapper>
    ) : (
      <PointIcon.Wrapper
        isClickable
        color        = "black"
        onMouseEnter = {() => setMousePosition(true)}
        onMouseLeave = {() => setMousePosition(false)}
      >
        {isMouseOnIcon ? <DeleteOutlined /> : <EnvironmentOutlined /> }
      </PointIcon.Wrapper>
    );
};

export const GoogleMapPoints = ({
  points,
  height = '400px',
  margin = '15px 0 0 0',
}: IGoogleMapPoints) => {

  const mainPoint = useMemo(() => (
    (points.length && points[0].geog.coordinate)
      ? { lat: points[0].geog.coordinate.y, lng: points[0].geog.coordinate.x }
      : undefined
  ), [points]);

  const filteredPoints = useMemo(() => (
    points.filter((item) => (typeof item.googlePlaceId === 'string')) as (Exclude<ILocation, 'googlePlaceId'> & { googlePlaceId: string })[]
  ), [points]);

  if (!mainPoint) return null;

  return (
    <GoogleMapPoints.Wrapper margin={margin}>
      <div style={{ height }}>
        <GoogleMapReact
          bootstrapURLKeys = {{ key: GOOGLE_API_KEY as string }}
          center           = {mainPoint}
          defaultZoom      = {13}
          options          = {{
            clickableIcons    : false,
            fullscreenControl : false,
          }}
        >
          {filteredPoints.map((item) => (
            <PointIcon
              isMainPoint = {(item.geog.coordinate?.x === mainPoint.lng && item.geog.coordinate?.y === mainPoint.lat)}
              key         = {item.googlePlaceId as string}
              lat         = {item.geog.coordinate?.y as number}
              lng         = {item.geog.coordinate?.x as number}
            />
          ))}
        </GoogleMapReact>
      </div>
    </GoogleMapPoints.Wrapper>
  );
};

GoogleMapPoints.PointAddress = styled.div`
  align-items     : center;
  display         : flex;
  font-size       : 16px;
  justify-content : space-between;

  > svg { cursor: pointer; }
`;

GoogleMapPoints.Wrapper = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
`;

PointIcon.Wrapper = styled.div<{ color?: string; isClickable?: boolean }>`
  align-items     : center;
  color           : ${({ color }) => (color || 'black')};
  cursor          : ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  display         : flex;
  font-size       : 20px;
  height          : 35px;
  justify-content : center;
  margin          : -17.5px 0 0 -17.5px;
  transition      : all 0.2s;
  width           : 35px;

  > svg { font-size: 35px; }
`;
