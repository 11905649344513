import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }          from '@utils/apiCaller';
import { parseServerError } from '@utils/parseServerError';
import { Notifications }    from '@components/Notifications/Notifications';
import { Modal }            from '@src/App';
import { IChannelModel }    from '@src/models';

import {
  fetchCategories,
  fetchCategoriesSuccessfully,
  fetchCategoriesFailure,
  addCategory,
  addCategorySuccessfully,
  addCategoryFailure,
  editCategory,
  editCategorySuccessfully,
  editCategoryFailure,
  deleteCategories,
  deleteCategoriesSuccessfully,
  deleteCategoriesFailure,

  fetchChannels,
  fetchChannelsSuccessfully,
  fetchChannelsFailure,
  addChannel,
  addChannelSuccessfully,
  addChannelFailure,
  editChannel,
  editChannelSuccessfully,
  editChannelFailure,
  deleteChannel,
  deleteChannelSuccessfully,
  deleteChannelFailure,

  getMeta,

  IChannelsActions,
} from './channelsReducerL';

function* handleFetchCategories() {
  try {
    const { page, rowsPerPage, orderBy, order } = yield select(getMeta);
    const { data: { items, totalCount } }       = yield callApi(`feature/channelCategory?page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}`);

    yield put(fetchCategoriesSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchCategoriesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching channels categories. Please try again', 'error');
  }
}

function* handleAddCategory(action: IChannelsActions['addCategory']) {
  try {
    const { values }                       = action.payload.attributes;
    const { data : { channelCategoryId } } = yield callApi('feature/channelCategory', 'post', values);

    yield put(fetchCategories());
    yield put(replace(`/admin/channels/${channelCategoryId}`));
    yield put(addCategorySuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Channels category was added successfully', 'success');
  } catch (e) {
    yield put(addCategoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a channels category. Please try again', 'error');
  }
}

function* handleEditCategory(action: IChannelsActions['editCategory']) {
  try {
    const { values } = action.payload.attributes;
    const { data }   = yield callApi('feature/channelCategory', 'put', values);

    yield put(editCategorySuccessfully(data));
    yield call(Notifications.enqueueSnackbar, 'Channels category was edited successfully', 'success');
  } catch (e) {
    yield put(editCategoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a channels category. Please try again', 'error');
  }
}

function* handleDeleteCategories(action: IChannelsActions['deleteCategories']) {
  try {
    const { categoryIds } = action.payload.attributes;

    for (let i = 0; i < categoryIds.length; i++) {
      yield callApi(`feature/channelCategory/${categoryIds[i]}`, 'delete');
    }

    yield put(fetchCategories());
    yield put(deleteCategoriesSuccessfully());
    yield call(Notifications.enqueueSnackbar, `${categoryIds.length > 1 ? 'Channels categories were' : 'Channels category was'} deleted successfully`, 'success');
  } catch (e) {
    const { categoryIds } = action.payload.attributes;

    yield put(deleteCategoriesFailure());
    yield call(Notifications.enqueueSnackbar, parseServerError(e) || `Ooops... Something went wrong while deleting ${categoryIds.length > 1 ? 'channels categories' : 'channels category'}. Please try again`, 'error');
  }
}

function* handleFetchChannels(action: IChannelsActions['fetchChannels']) {
  try {
    const { channelCategoryId }               = action.payload.attributes ;
    const { data }: { data: IChannelModel[] } = yield callApi(`feature/channel/category/${channelCategoryId }`);

    yield put(fetchChannelsSuccessfully(data));
  } catch (e) {
    yield put(fetchChannelsFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching channels. Please try again', 'error');
  }
}

function* handleAddChannel(action: IChannelsActions['addChannel']) {
  try {
    const { values } = action.payload.attributes;

    yield callApi('feature/channel', 'post', values);
    yield put(fetchChannels(values.channelCategoryId));
    yield put(addChannelSuccessfully());
    yield call(Modal.close);
    yield call(Notifications.enqueueSnackbar, 'Channel was added successfully', 'success');
  } catch (e) {
    yield put(addChannelFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a channel. Please try again', 'error');
  }
}

function* handleEditChannel(action: IChannelsActions['editChannel']) {
  try {
    const { initialCategoryId, values } = action.payload.attributes;

    yield callApi('feature/channel', 'put', values);
    yield put(fetchChannels(initialCategoryId));
    yield put(editChannelSuccessfully());
    yield call(Modal.close);
    yield call(Notifications.enqueueSnackbar, 'Channel was edited successfully', 'success');
  } catch (e) {
    yield put(editChannelFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a channel. Please try again', 'error');
  }
}

function* handleDeleteChannel(action: IChannelsActions['deleteChannel']) {
  try {
    const { channelId, channelCategoryId  } = action.payload.attributes;

    yield callApi('feature/channel', 'delete', channelId);
    yield put(deleteChannelSuccessfully());
    yield put(fetchChannels(channelCategoryId));
    yield call(Modal.close);
    yield call(Notifications.enqueueSnackbar, 'Channel was deleted successfully', 'success');
  } catch (e) {
    yield put(deleteChannelFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while deleting a channel. Please try again', 'error');
  }
}

function* channelsSagas() {
  yield all([
    takeLatest(fetchCategories, handleFetchCategories),
    takeLatest(addCategory, handleAddCategory),
    takeLatest(editCategory, handleEditCategory),
    takeLatest(deleteCategories, handleDeleteCategories),

    takeLatest(fetchChannels, handleFetchChannels),
    takeLatest(addChannel, handleAddChannel),
    takeLatest(editChannel, handleEditChannel),
    takeLatest(deleteChannel, handleDeleteChannel),
  ]);
}

export { channelsSagas };
