import { ISvgIconOptions } from './index';

export default ({ height = '29px', width = '29px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 465 465">
    <g>
      <circle
        fill = "#e6e9f4"
        cx   = "232"
        cy   = "232"
        r    = "232"
      />
      <g>
        <path
          fill     = "#fff"
          fillRule = "evenodd"
          clipRule = "evenodd"
          d        = "M285.33,306.67V336H152V128h133.33v29.33c0,4.42,3.58,8,8,8s8-3.58,8-8V120c0-4.42-3.58-8-8-8H144 c-4.42,0-8,3.58-8,8v224c0,4.42,3.58,8,8,8h149.33c4.42,0,8-3.58,8-8v-37.33c0-4.42-3.58-8-8-8S285.33,302.25,285.33,306.67z"
        />
        <path
          fill     = "#fff"
          fillRule = "evenodd"
          clipRule = "evenodd"
          d        = "M340.69,224H216c-4.42,0-8,3.58-8,8s3.58,8,8,8h124.69l-23.68,23.68c-3.07,3.18-2.98,8.24,0.2,11.31 c3.1,2.99,8.02,2.99,11.12,0l37.34-37.33c3.12-3.12,3.12-8.19,0-11.31l-37.34-37.34c-3.18-3.07-8.24-2.98-11.31,0.2 c-2.99,3.1-2.99,8.02,0,11.12L340.69,224z"
        />
      </g>
    </g>
  </svg>
);
