import styled from 'styled-components';

import { IMediaItem } from './MultiMediaUploader';

interface IMediaPreviewProps {
  media: IMediaItem
}

export const MediaPreview = ({ media }: IMediaPreviewProps) => (
  <MediaPreview.PreviewWrapper>
    {media.mimeType === 'image' && (
      <img src={media.uri} />
    )}
    {media.mimeType === 'video' && (
      <video controls key={media.uri}>
        <source src={`${media.uri}`} type="video/mp4" />
      </video>
    )}
    {media.mimeType === 'youtube' && (
      <MediaPreview.YoutubePreview>
        <iframe width="100%" height="100%" src={media.uri} title="Youtube iframe" />
      </MediaPreview.YoutubePreview>
    )}
  </MediaPreview.PreviewWrapper>
);

MediaPreview.YoutubePreview = styled.div`
  height : 100%;
  width  : 100%;
`;

MediaPreview.PreviewWrapper = styled.div`
  height : 480px;
  width  : 720px;

  img,
  video {
    height     : 100%;
    object-fit : contain;
    width      : 100%;
  }
`;
