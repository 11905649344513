import React  from 'react';
import styled from 'styled-components';

export interface IErrorNotificationData {
  entityData   : {
    id   : number;
    name : string;
  }[];
  entityName   : 'forms';
  errorCode    : number;
  errorMessage : string;
}

export const ErrorResponseParser = ({ data }: { data: string | IErrorNotificationData }) => {
  if (typeof data === 'string') return <p>{data}</p>;

  return (
    <div>
      <p>{data.errorMessage}:</p>
      {data.entityData.map((item) => (
        <ErrorResponseParser.Entity
          href   = {`/admin/${data.entityName}/${item.id}?withDelete=true`}
          key    = {item.id}
          rel    = 'noopener noreferrer'
          style  = {{ color: '#000' }}
          target = "_blank"
        >
          {item.name}
        </ErrorResponseParser.Entity>
      ))}
    </div>
  );
};

ErrorResponseParser.Entity = styled.a`
  margin-left : 10px;
  margin-top  : 10px;

  &::before {
    content      : ">";
    margin-right : 10px;
  }
`;
