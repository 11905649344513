import { Key }         from 'antd/lib/table/interface';
import { useCallback } from 'react';
import { FieldProps }  from 'formik';
import styled          from 'styled-components';

import { BaseSelectProps, BaseSelect } from './BaseSelect';

interface ISelect extends BaseSelectProps, Partial<FieldProps> {
  wrapperStyle?: { margin?: string };
}

export const Select = ({
  field,
  form,
  onChange,
  wrapperStyle,
  value,
  ...componentProps
}: ISelect) => {
  const onSelect = useCallback((option: Key) => {
    if (field && form) {
      form.setFieldValue(field.name, option);
    } else if (onChange) {
      onChange(option);
    }
  }, [form, field, onChange]);

  return (
    <Select.Wrapper {...wrapperStyle}>
      <BaseSelect
        {...componentProps}
        onSelect = {onSelect}
        value    = {field?.value ?? value}
      />
    </Select.Wrapper>
  );
};

Select.Wrapper = styled.div<{ margin?: string; }>`
  margin: ${({ margin, theme }) => margin || theme.field.gap};
`;
