import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';

import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
} from '@common/components/FiltrationSorting';
import { Table, TableProps }         from '@common/components/Table/Table';
import { Card, CardHeader }          from '@common/components/Card';
import { ECardStatuses, ESortOrder } from '@common/enums';
import { routesConfig }              from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }               from '@components/Modal/DeleteModal';

import { IBusinessModel }     from '@src/models';
import { Modal }              from '@src/App';
import { IState }             from '@store/rootReducer';
import { useFetchQueryRules } from '@hooks/useFetchQueryRules';

import {
  fetchBusinesses  as fetchBusinessesAction,
  deleteBusinesses as deleteBusinessesAction,
  IBusinessesActionsCreators,
  getBusinesses,
  getMeta,
  IBusinessesReducer,
} from '../businessesReducer';

interface IBusinesses {
  businesses       : Required<IBusinessModel>[];
  businessMeta     : IBusinessesReducer['meta'];
  totalBusinesses  : number;
  fetchBusinesses  : IBusinessesActionsCreators['fetchBusinesses'];
  deleteBusinesses : IBusinessesActionsCreators['deleteBusinesses'];
  isFetching       : boolean;
}

const sortOptions = [{ label: 'Title', key: 'Name', value: 'Name' }];

const filterOptions: IFilterOption[] = [];

const BusinessList = ({
  businesses,
  businessMeta,
  totalBusinesses,
  fetchBusinesses,
  deleteBusinesses,
  isFetching,
}: IBusinesses) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [popoverVisible, setPopoverStatus] = useState(false);
  
  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'businesses-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Asc, label: sortOptions[0].label } },
  );

  const onApplyFilters = useCallback((newFilter: any) => {
    if (newFilter.sorting) {
      fetchBusinesses({ ...businessMeta, orderBy: newFilter.sorting.value, order: newFilter.sorting.order });
    } else {
      fetchBusinesses({ ...businessMeta });
    }
  }, [businessMeta]);

  const onClickBusiness = useCallback((business: IBusinessModel) => {
    history.push(routesConfig.businessesEdit.endpoint.replace(':id', business.businessId?.toString() || ''));
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchBusinesses({ page: page !== businessMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [businessMeta.page, businessMeta.rowsPerPage]);

  const deleteBusiness = useCallback(async (businessId: (number | string)[]) => {
    deleteBusinesses(businessId as number[]);
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons           = {[{ action: () => history.push(routesConfig.businessesAdd.endpoint), label: t('createBusiness') }]}
      title             = {t('Businesses')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {handlers.deleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title',  label: t('title') },
    { id: 'status', label: t('status') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (businesses.map((business) => ({
    title : {
      onClick : () => onClickBusiness(business),
      tag     : 'link',
      title   : business.name || '',
    },
    status : {
      component : business.userIsBlocked ? <p key={business.businessId} style={{ color: 'red' }}>blocked</p> : <p></p>,
      tag       : 'component',
    },
    id : business.businessId,
  }))), [businesses]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 5, minWidth: 300 },
      { flex: 2 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : businessMeta.page + 1,
      total           : totalBusinesses,
      pageSize        : businessMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [businessMeta.page, totalBusinesses, businessMeta.rowsPerPage]);

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    setPopoverStatus(false);
    onApplyFilters(rules);
  }, [rules]);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!businessMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            const businessForDeleting = businesses.find(obj => (obj.businessId === ids[0]));
            Modal.open(DeleteModal, {
              onDeleteConfirm : () => {
                deleteBusiness(ids);
                Modal.close();
              },
              confirmMessage    : (ids.length === 1) ? 'The business will be removed and unlinked from all channels' : 'You cannot restore deleted data',
              entityNameMessage : `For removing enter the business fullname "${businessForDeleting?.name}"`,
              name              : (ids.length === 1) ? `${businessForDeleting?.name}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  businesses      : getBusinesses(state),
  businessMeta    : getMeta(state),
  totalBusinesses : getMeta(state).totalBusinesses,
  isFetching      : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  fetchBusinesses  : fetchBusinessesAction,
  deleteBusinesses : deleteBusinessesAction,
};

export const BusinessesList = connect(mapStateToProps, mapDispatchToProps)(BusinessList);
