import { TFilterValue } from './FilterForm';

interface IBaseFilterFieldProps {
  onChange    : (value: TFilterValue) => void;
  label       : string;
  placeholder : string;
  required    : boolean;
  value       : TFilterValue;
}

interface ICommonFilterFieldProps extends IBaseFilterFieldProps {
  Field: unknown;
}

const CommonFilterField = ({ Field, ...props }: ICommonFilterFieldProps) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Field {...props} />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setFitFilterField = (Field: unknown, props: IBaseFilterFieldProps & any) => (
  <CommonFilterField {...props} Field={Field} />
);
