import { ISvgIconOptions } from './index';

export default ({
  color  = '#575f66',
  height = '16px',
  width  = '16px',
}: ISvgIconOptions) => (
  <svg height={height} width={width} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule = "evenodd"
      fill     = {color}
      fillRule = "evenodd"
      d        = "m229.297 238.933h265.636c9.426 0 17.067 7.641 17.067 17.067s-7.641 17.067-17.067 17.067h-265.637c-7.54 28.549-33.539 49.599-64.458 49.599-30.943 0-56.96-21.083-64.476-49.666l-83.295.001c-9.389 0-17-7.612-17-17 0-9.389 7.611-17 17-17l83.295-.001c7.516-28.584 33.533-49.667 64.476-49.667 30.92 0 56.919 21.05 64.459 49.6zm189.856-189.333h75.78c9.426 0 17.067 7.641 17.067 17.067s-7.641 17.067-17.067 17.067h-75.78c-7.539 28.55-33.539 49.6-64.458 49.6s-56.919-21.05-64.458-49.6h-273.17c-9.426 0-17.067-7.642-17.067-17.067 0-9.426 7.641-17.067 17.067-17.067h273.17c7.539-28.55 33.539-49.6 64.458-49.6s56.919 21.05 64.458 49.6zm-142.538 462.4c-30.92 0-56.919-21.05-64.459-49.6h-195.089c-9.426 0-17.067-7.641-17.067-17.067s7.641-17.067 17.067-17.067h195.09c7.54-28.55 33.539-49.6 64.459-49.6 30.919 0 56.919 21.05 64.458 49.6h153.86c9.426 0 17.067 7.641 17.067 17.067s-7.641 17.067-17.067 17.067h-153.86c-7.54 28.55-33.54 49.6-64.459 49.6z"
    />
  </svg>
);
