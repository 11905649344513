import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import styled                                        from 'styled-components';

import { ESortOrder }      from '../../enums';
import { ITableDataRules } from './FiltrationSorting';

import { FormButtons } from '../Button';
import { BaseRadio }   from '../Radio';
import { BaseSelect }  from '../Select';

type TRule = NonNullable<ITableDataRules['sorting']>;

interface ISortingFormProps {
  onChange    : (newRule: TRule) => void;
  onCancel?   : () => void;
  sortOptions : {
    label : string;
    value : string;
  }[];
}

export const SortingForm = ({ onCancel, onChange, sortOptions }: ISortingFormProps) => {
  const { t } = useTranslation();

  const [rule, setRule] = useState<TRule | null>(null);

  const orderOptions = useMemo(() => ([
    { label: t('ascending'), value: ESortOrder.Asc },
    { label: t('descending'), value: ESortOrder.Desc },
  ]), []);

  const onSelectRule = useCallback((ruleKey: string) => {
    if (!rule) {
      return;
    }

    const newRule = sortOptions.find((option) => option.value === ruleKey);

    setRule({ ...rule, ...newRule });
  }, [rule, sortOptions]);

  useEffect(() => {
    setRule({ ...sortOptions[0], order: orderOptions[0].value });
  }, [sortOptions]);

  if (!rule) { return null; }

  return (
    <SortingForm.Wrapper>
      <BaseSelect
        label    = {t('sortBy')}
        onChange = {(value) => onSelectRule(value.toString())}
        options  = {sortOptions}
        required = {false}
        value    = {rule.value}
      />
      <BaseRadio
        label    = {t('orderBy')}
        onChange = {(order) => setRule({ ...rule, order: order as ESortOrder })}
        options  = {orderOptions}
        value    = {rule.order}
      />
      <FormButtons
        mainButtonLabel = {t('apply')}
        onClickCancel   = {onCancel}
        onSubmit        = {() => onChange(rule)}
      />
    </SortingForm.Wrapper>
  );
};

SortingForm.Wrapper = styled.div`
  display        : flex;
  flex-direction : column;
  gap            : 20px;
  padding        : 10px 0;
  width          : 250px;

  > div:last-child { margin-top: 0; }
`;
