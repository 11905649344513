import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';
import { format }                                    from 'date-fns';

import { routesConfig }           from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }            from '@components/Modal/DeleteModal';
import { Table, TableProps }      from '@common/components/Table/Table';
import { Card, CardHeader }       from '@common/components/Card';
import { ECardStatuses }          from '@common/enums';
import { BaseInput }              from '@common/components';
import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
  setFitFilterField,
} from '@common/components/FiltrationSorting';

import { IStoryModel }        from '@src/models';
import { Modal }              from '@src/App';
import { IState }             from '@store/rootReducer';
import { useFetchQueryRules } from '@hooks/useFetchQueryRules';
import { ESortOrder }         from '@models/enums';
import {
  fetchStories  as fetchStoriesAction,
  deleteStories as deleteStoriesAction,
  getStories,
  getMeta,
  IStoriesActionsCreators,
  IStoriesReducer,
} from '../storiesReducer';

interface IStories {
  stories       : IStoryModel[];
  storyMeta     : IStoriesReducer['meta'];
  totalStories  : number;
  fetchStories  : IStoriesActionsCreators['fetchStories'];
  deleteStories : IStoriesActionsCreators['deleteStories'];
  isFetching    : boolean;
}

const sortOptions = [
  { label: 'Created', key: 'created', value: 'created' },
  { label: 'Title', key: 'Title', value: 'Title' },
  { label: 'Author', key: 'Author', value: 'Author' },
];

const filterOptions: IFilterOption[] = [{
  component          : (props) => setFitFilterField(BaseInput, props),
  initialFilterValue : '',
  label              : 'Author',
  value              : 'author',
}];

const StoryList = ({
  stories,
  storyMeta,
  totalStories,
  fetchStories,
  deleteStories,
  isFetching,
}: IStories) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [popoverVisible, setPopoverStatus] = useState(false);
  
  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'stories-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Desc, label: sortOptions[0].label } },
  );

  const onApplyFilters = useCallback((newFilter: any) => {
    const nextFilters = Object.keys(newFilter.filtration).reduce((accum: any, curr) => {
      accum[curr] = newFilter.filtration[curr].value;
      return accum;
    }, {} );
    if (newFilter.sorting) {
      fetchStories({ ...storyMeta, ...nextFilters, orderBy: newFilter.sorting.value, order: newFilter.sorting.order });
    } else {
      fetchStories({ ...storyMeta, ...nextFilters });
    }
  }, [storyMeta]);

  const onClickStory = useCallback((story: IStoryModel) => {
    history.push(routesConfig.storiesEdit.endpoint.replace(':id', story.storyId?.toString() || ''));
  }, []);

  const onDeleteFiltrationRule = useCallback((ruleKey: string) => {
    handlers.deleteFiltrationRule(ruleKey);
    fetchStories({...storyMeta, [ruleKey]: ''});
  }, [storyMeta, handlers]);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchStories({ page: page !== storyMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [storyMeta.page, storyMeta.rowsPerPage]);

  const deleteSlide = useCallback(async (storyId: (number | string)[]) => {
    deleteStories(storyId as number[]);
  }, []);
  
  const header = useMemo(() => (
    <CardHeader
      buttons           = {[{ action: () => history.push(routesConfig.storiesAdd.endpoint), label: t('createStory') }]}
      title             = {t('stories')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {onDeleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title',   label: t('title') },
    { id: 'author',  label: t('author') },
    { id: 'created', label: t('created') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (stories.map((story) => ({
    title : {
      onClick : () => onClickStory(story),
      tag     : 'link',
      title   : story.title || '',
    },
    author  : story.author || '',
    created : format(new Date(story.created), 'PP | p'),
    id      : story.storyId || '',
  }))), [stories]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 1 },
      { flex: 1 },
      { flex: 1 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : storyMeta.page + 1,
      total           : totalStories,
      pageSize        : storyMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [storyMeta.page, totalStories, storyMeta.rowsPerPage]);

  useEffect(() => {
    fetchStories();
  }, []);

  useEffect(() => {
    setPopoverStatus(false);
    onApplyFilters(rules);
  }, [rules]);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!storyMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            const storyForDeleting = stories.find(obj => (obj.storyId === ids[0]));
            Modal.open(DeleteModal, {
              onDeleteConfirm : () => {
                deleteSlide(ids);
                Modal.close();
              },
              confirmMessage    : (ids.length === 1) ? 'The story will be removed and unlinked from all channels' : 'You cannot restore deleted data',
              entityNameMessage : `For removing enter the Story full name "${storyForDeleting?.title}"`,
              name              : (ids.length === 1) ? `${storyForDeleting?.title}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  stories      : getStories(state),
  storyMeta    : getMeta(state),
  totalStories : getMeta(state).totalStories,
  isFetching   : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  fetchStories  : fetchStoriesAction,
  deleteStories : deleteStoriesAction,
};

export const StoriesList = connect(mapStateToProps, mapDispatchToProps)(StoryList);
