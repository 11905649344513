import styled from 'styled-components';

import { UploadIcon } from '../../../public/svg';

export const CameraIcon = () => (
  <CameraIcon.UploadIconWrapper>
    <div>
      <UploadIcon />
    </div>
  </CameraIcon.UploadIconWrapper>
);

CameraIcon.UploadIconWrapper = styled.div`
  align-items     : center;
  display         : flex;
  justify-content : center;
  height          : 100%;

  > div {
    cursor : pointer;
    width  : 25px;
  }
`;
