import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState }          from '@store/rootReducer';
import { ITerritoryModel } from '@models/index';
import { ITableMeta }      from '@components/Table/ClickableItem';

export const STATE_KEY = 'territories';

export interface ITerritoriesReducer {
  territories : ITerritoryModel[];
  meta        : Required<ITableMeta> & {
    isFetching       : boolean;
    parentId         : number | null;
    totalTerritories : number;
  };
}

export enum TerritoryTypes {
  fetchTerritories              = 'fetchTerritories',
  fetchTerritoriesSuccessfully  = 'fetchTerritoriesSuccessfully',
  fetchTerritoriesFailure       = 'fetchTerritoriesFailure',
  addTerritory                  = 'addTerritory',
  addTerritorySuccessfully      = 'addTerritorySuccessfully',
  addTerritoryFailure           = 'addTerritoryFailure',
  editTerritory                 = 'editTerritory',
  editTerritorySuccessfully     = 'editTerritorySuccessfully',
  editTerritoryFailure          = 'editTerritoryFailure',
  deleteTerritories             = 'deleteTerritories',
  deleteTerritoriesSuccessfully = 'deleteTerritoriesSuccessfully',
  deleteTerritoriesFailure      = 'deleteTerritoriesFailure',
}

export interface ITerritoriesActions {
  [TerritoryTypes.fetchTerritories]              : Action<{ meta: Partial<ITerritoriesReducer['meta']> }>;
  [TerritoryTypes.fetchTerritoriesSuccessfully]  : Action<{ territories: ITerritoryModel[]; meta: { totalTerritories: number } }>;
  [TerritoryTypes.fetchTerritoriesFailure]       : Action<{}>;
  [TerritoryTypes.addTerritory]                  : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [TerritoryTypes.addTerritorySuccessfully]      : Action<{ meta: { isFetching: false }}>;
  [TerritoryTypes.addTerritoryFailure]           : Action<{ meta: { isFetching: false }}>;
  [TerritoryTypes.editTerritory]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [TerritoryTypes.editTerritorySuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [TerritoryTypes.editTerritoryFailure]          : Action<{ meta: { isFetching: false }}>;
  [TerritoryTypes.deleteTerritories]             : Action<{ attributes: { territoryIds: number[] }; meta: { isFetching: true } }>;
  [TerritoryTypes.deleteTerritoriesSuccessfully] : Action<{}>;
  [TerritoryTypes.deleteTerritoriesFailure]      : Action<{}>;
}

export interface ITerritoriesActionsCreators {
  [TerritoryTypes.fetchTerritories]              : (territoryMeta?: Partial<ITerritoriesReducer['meta']>) => ITerritoriesActions[TerritoryTypes.fetchTerritories];
  [TerritoryTypes.fetchTerritoriesSuccessfully]  : (territories: ITerritoryModel[], totalCount: number) => ITerritoriesActions[TerritoryTypes.fetchTerritoriesSuccessfully];
  [TerritoryTypes.fetchTerritoriesFailure]       : () => ITerritoriesActions[TerritoryTypes.fetchTerritoriesFailure];
  [TerritoryTypes.addTerritory]                  : (values: any) => ITerritoriesActions[TerritoryTypes.addTerritory];
  [TerritoryTypes.addTerritorySuccessfully]      : () => ITerritoriesActions[TerritoryTypes.addTerritorySuccessfully];
  [TerritoryTypes.addTerritoryFailure]           : () => ITerritoriesActions[TerritoryTypes.addTerritoryFailure];
  [TerritoryTypes.editTerritory]                 : (values: any) => ITerritoriesActions[TerritoryTypes.editTerritory];
  [TerritoryTypes.editTerritorySuccessfully]     : () => ITerritoriesActions[TerritoryTypes.editTerritorySuccessfully];
  [TerritoryTypes.editTerritoryFailure]          : () => ITerritoriesActions[TerritoryTypes.editTerritoryFailure];
  [TerritoryTypes.deleteTerritories]             : (territoryIds: number[]) => ITerritoriesActions[TerritoryTypes.deleteTerritories];
  [TerritoryTypes.deleteTerritoriesSuccessfully] : () => ITerritoriesActions[TerritoryTypes.deleteTerritoriesSuccessfully];
  [TerritoryTypes.deleteTerritoriesFailure]      : () => ITerritoriesActions[TerritoryTypes.deleteTerritoriesFailure];
}

type Actions = ITerritoriesActions[TerritoryTypes.fetchTerritoriesSuccessfully]
| ITerritoriesActions[TerritoryTypes.fetchTerritoriesFailure]
| ITerritoriesActions[TerritoryTypes.addTerritory]
| ITerritoriesActions[TerritoryTypes.addTerritorySuccessfully]
| ITerritoriesActions[TerritoryTypes.addTerritoryFailure]
| ITerritoriesActions[TerritoryTypes.editTerritorySuccessfully]
| ITerritoriesActions[TerritoryTypes.editTerritoryFailure]
| ITerritoriesActions[TerritoryTypes.deleteTerritories]
| ITerritoriesActions[TerritoryTypes.deleteTerritoriesSuccessfully]
| ITerritoriesActions[TerritoryTypes.deleteTerritoriesFailure];

const initialState: ITerritoriesReducer = {
  territories : [],
  meta        : {
    isFetching       : false,
    parentId         : null,
    totalTerritories : 0,
    page             : 0,
    rowsPerPage      : 50,
    orderBy          : 'Name',
    order            : 'Asc',
  },
};

export const {
  fetchTerritories,
  fetchTerritoriesSuccessfully,
  fetchTerritoriesFailure,
  addTerritory,
  addTerritorySuccessfully,
  addTerritoryFailure,
  editTerritory,
  editTerritorySuccessfully,
  editTerritoryFailure,
  deleteTerritories,
  deleteTerritoriesSuccessfully,
  deleteTerritoriesFailure,
}: ITerritoriesActionsCreators = createActions(
  {
    FETCH_TERRITORIES              : (
      territoryMeta = {},
    ) => ({ meta: { ...territoryMeta, isFetching: true } }),
    FETCH_TERRITORIES_SUCCESSFULLY : (territories, totalTerritories) => ({ territories, meta: { totalTerritories, isFetching: false } }),
    FETCH_TERRITORIES_FAILURE      : () => ({ meta: { isFetching: false } }),

    ADD_TERRITORY              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    ADD_TERRITORY_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    ADD_TERRITORY_FAILURE      : () => ({ meta: { isFetching: false }}),

    EDIT_TERRITORY              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    EDIT_TERRITORY_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    EDIT_TERRITORY_FAILURE      : () => ({ meta: { isFetching: false }}),

    DELETE_TERRITORIES              : (territoryIds) => ({ attributes: { territoryIds }, meta: { isFetching: true }}),
    DELETE_TERRITORIES_SUCCESSFULLY : () => ({}),
    DELETE_TERRITORIES_FAILURE      : () => ({ meta: { isFetching: false }}),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const territoriesReducer = handleActions<ITerritoriesReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchTerritories,
      fetchTerritoriesSuccessfully,
      addTerritory,
      addTerritorySuccessfully,
      addTerritoryFailure,
      editTerritory,
      editTerritorySuccessfully,
      editTerritoryFailure,
      deleteTerritories,
      deleteTerritoriesSuccessfully,
      deleteTerritoriesFailure,
    )]: (
      state  : ITerritoriesReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchTerritories.toString()): {
          const actionTyped = action as ITerritoriesActions['fetchTerritories'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, territories: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchTerritoriesSuccessfully.toString()): {
          const actionTyped = action as ITerritoriesActions['fetchTerritoriesSuccessfully'];
        
          return ({ ...state, territories: actionTyped.payload.territories, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default territoriesReducer;

interface ITerritoriesSelectors {
  getTerritories : (state: IState) => ITerritoryModel[];
  getMeta        : (state: IState) => ITerritoriesReducer['meta'];
}

export const getTerritories: ITerritoriesSelectors['getTerritories'] = (state: IState) => state[STATE_KEY].territories;
export const getMeta: ITerritoriesSelectors['getMeta']               = (state: IState) => state[STATE_KEY].meta;
