import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                                   from 'react-i18next';
import { useHistory }                                       from 'react-router-dom';
import styled                                               from 'styled-components';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { IRouteItem, routesConfig } from '../Breadcrumbs/routesConfig';

export const menuItems: Omit<IRouteItem, 'path'>[] = [
  {
    endpoint        : routesConfig.affiliations.endpoint,
    localizationKey : routesConfig.affiliations.localizationKey,
  },
  {
    endpoint        : routesConfig.areas.endpoint,
    localizationKey : routesConfig.areas.localizationKey,
  },
  {
    endpoint        : routesConfig.businesses.endpoint,
    localizationKey : routesConfig.businesses.localizationKey,
  },
  {
    endpoint        : routesConfig.slider.endpoint,
    localizationKey : routesConfig.slider.localizationKey,
  },
  {
    endpoint        : routesConfig.categories.endpoint,
    localizationKey : routesConfig.categories.localizationKey,
  },
  {
    endpoint        : routesConfig.channels.endpoint,
    localizationKey : routesConfig.channels.localizationKey,
  },
  {
    endpoint        : routesConfig.clients.endpoint,
    localizationKey : routesConfig.clients.localizationKey,
  },
  {
    endpoint        : routesConfig.events.endpoint,
    localizationKey : routesConfig.events.localizationKey,
  },
  {
    endpoint        : routesConfig.feeds.endpoint,
    localizationKey : routesConfig.feeds.localizationKey,
    nestedItems     : [{
      endpoint        : routesConfig.feeds.nestedItems ? routesConfig.feeds.nestedItems[0].endpoint : '',
      localizationKey : routesConfig.feeds.nestedItems ? routesConfig.feeds.nestedItems[0].localizationKey : '',
      path            : '',
    }],
  },
  {
    endpoint        : routesConfig.forms.endpoint,
    localizationKey : routesConfig.forms.localizationKey,
  },
  {
    endpoint        : routesConfig.ad.endpoint,
    localizationKey : routesConfig.ad.localizationKey,
  },
  {
    endpoint        : routesConfig.stories.endpoint,
    localizationKey : routesConfig.stories.localizationKey,
  },
  {
    endpoint        : routesConfig.tips.endpoint,
    localizationKey : routesConfig.tips.localizationKey,
  },
  {
    endpoint        : routesConfig.tags.endpoint,
    localizationKey : routesConfig.tags.localizationKey,
  },
];

export const Menu = () => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [activeMenuTab, setMenuTab]             = useState<string | null>(null);
  const [openedNestedItem, setOpenedNestedItem] = useState<string | null | undefined>(null);

  const currentPageUrl = useMemo(() => history.location.pathname, [history.location.pathname]);

  const getActiveMenuTab  = useCallback(() => {
    const menuEndpoints   = menuItems.map((item) => item.endpoint);
    const currentUrlArray = currentPageUrl.split('/').filter((item: any) => item.length);
    const pageKey         = currentUrlArray[1];
    let newActiveMenuTab  = routesConfig.feeds.nestedItems ? routesConfig.feeds.nestedItems[0].endpoint : routesConfig.feeds.endpoint;

    menuEndpoints.forEach((endpoint) => {
      if (endpoint.includes(pageKey)) {
        newActiveMenuTab = endpoint;
      }
    });

    setMenuTab(newActiveMenuTab);
  }, [currentPageUrl]);

  const onClickMenuItem = useCallback((endpoint: string) => {
    if (endpoint !== currentPageUrl) {
      history.replace(endpoint);
    }
  }, [currentPageUrl]);

  const onClickTabArrow = useCallback((tabName: string) => {
    setMenuTab(activeMenuTab === tabName ? null : tabName);
  }, [activeMenuTab]);

  useEffect(() => {
    getActiveMenuTab();
  }, [currentPageUrl]);

  return (
    <Menu.Wrapper>
      {menuItems.map((item) => (
        <React.Fragment key={item.endpoint}>
          <Menu.ItemWrapper
            onClick  = {() => onClickMenuItem(item.endpoint)}
            selected = {item.endpoint === currentPageUrl}
          >
            <Menu.ItemLeftBorder selected={item.endpoint === currentPageUrl} />
            <Menu.ItemLabel>
              {t(item.localizationKey)}
              <span onClick={() => onClickTabArrow(item.localizationKey)} style={{ marginLeft: '140px' }}>
                {item.nestedItems
                  && (activeMenuTab === item.localizationKey
                    ? <UpOutlined   onClick={() => setOpenedNestedItem(null)} />
                    : <DownOutlined onClick={() => setOpenedNestedItem(item.endpoint)} />)}
              </span>
            </Menu.ItemLabel>
          </Menu.ItemWrapper>

          {openedNestedItem === item.endpoint &&
            <Menu.ItemWrapper
              key      = {item.nestedItems ? item.nestedItems[0].endpoint : item.localizationKey}
              selected = {activeMenuTab === (item.nestedItems ? item.nestedItems[0].endpoint : item.endpoint)}
              onClick  = {() => onClickMenuItem(item.nestedItems ? item.nestedItems[0].endpoint : '')}
            >
              <Menu.ItemLeftBorder selected={activeMenuTab === (item.nestedItems ? item.nestedItems[0].endpoint : item.endpoint)} />
              <Menu.ItemLabel style={{ marginLeft: '25px' }}>
                {t(item.nestedItems ? item.nestedItems[0].localizationKey : '')}
              </Menu.ItemLabel>
            </Menu.ItemWrapper>
          }
        </React.Fragment>
      ))}
    </Menu.Wrapper>
  );
};

Menu.ItemLabel = styled.h4`
  font-weight : 500;
  margin-left : 14px;
  white-space : nowrap;
`;

Menu.ItemLeftBorder = styled.div<{ selected: boolean }>`
  background-color : ${({ theme }) => theme.color.blue};
  border-radius    : 2px;
  height           : 100%;
  left             : ${({ selected }) => (selected ? '0' : '-4px')};
  position         : absolute;
  transition       : all 0.2s;
  width            : 4px;
`;

Menu.ItemWrapper = styled.div<{ selected: boolean }>`
  align-items      : center;
  background-color : ${({ selected, theme }) => (selected ? theme.color.ultraLightBlue : 'transparent')};
  border-radius    : 2px;
  cursor           : pointer;
  display          : flex;
  height           : 53px;
  margin-right     : 22px;
  padding-left     : 20px;
  position         : relative;
  transition       : all 0.3s;
  user-select      : none;
  width            : 228px;

  > h4 {
    color      : ${({ selected, theme }) => (selected ? theme.color.blue : '#000')};
    transition : all 0.3s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.ultraLightBlue};

    > h4 {
      color: ${({ theme }) => theme.color.blue};
    }
  }
`;

Menu.Wrapper = styled.div`
  background-color : #fff;
  padding-top      : 20px;
`;
