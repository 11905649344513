import { ISvgIconOptions } from './index';

export default ({ color = '#f0552a', height, width = '106px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 535 133">
    <g>
      <path fill={color} d="M4.46,84.48c0-27.68,16.44-41.95,40.94-41.95s40.94,14.09,40.94,41.95v43.12H66.37V84.48 c0-15.77-8.39-23.32-20.97-23.32c-12.58,0-20.97,7.55-20.97,23.32v43.12H4.46V84.48z" />
      <path fill={color} d="M96.07,86.16c0-25.17,18.46-43.62,44.63-43.62c26.17,0,44.3,18.46,44.3,43.62v7.05h-67.95 c2.68,12.08,11.91,18.46,23.66,18.46c8.89,0,15.44-2.52,19.63-7.89h21.31c-6.21,15.77-21.31,26.01-40.94,26.01 C114.53,129.79,96.07,111.33,96.07,86.16z M163.86,77.27c-3.19-11.07-12.25-16.61-23.15-16.61c-11.24,0-19.97,5.7-23.15,16.61 H163.86z" />
      <path fill={color} d="M194.73,92.03V44.72h19.97v47.32c0,13.59,7.05,19.13,16.78,19.13c9.56,0,16.78-5.7,16.78-19.13V44.72h19.97 v47.32c0,13.42,7.05,19.13,16.61,19.13c9.73,0,16.78-5.54,16.78-19.13V44.72h19.97v47.32c0,26.01-15.1,37.75-34.73,37.75 c-12.58,0-22.82-5.2-28.69-14.93c-6.04,9.73-16.28,14.93-28.69,14.93C209.83,129.79,194.73,118.04,194.73,92.03z" />
      <path fill={color} d="M331.48,103.11h20.13c0.17,5.54,5.03,9.23,14.77,9.23c10.23,0,14.6-4.19,14.6-9.23 c0-6.38-7.38-7.21-16.28-8.39c-12.75-1.68-31.54-3.86-31.54-24.16c0-16.95,12.92-28.02,32.89-28.02c20.3,0,32.72,11.41,33.22,26.01 h-19.8c-0.5-5.03-5.2-8.56-13.09-8.56c-8.73,0-13.09,4.19-13.09,9.06c0,6.38,7.55,7.38,16.44,8.56 c11.07,1.51,31.38,3.69,31.38,24.33c0,16.78-13.59,27.85-34.73,27.85C345.24,129.79,331.98,118.54,331.48,103.11z" />
      <rect x="411.86" y="44.72" fill={color} width="19.97" height="82.89" />
      <path fill={color} d="M443.05,86.16c0-25.17,18.46-43.62,44.63-43.62s44.3,18.46,44.3,43.62v7.05h-67.95 c2.68,12.08,11.91,18.46,23.66,18.46c8.89,0,15.44-2.52,19.63-7.89h21.31c-6.21,15.77-21.31,26.01-40.94,26.01 C461.51,129.79,443.05,111.33,443.05,86.16z M510.84,77.27c-3.19-11.07-12.25-16.61-23.15-16.61c-11.24,0-19.97,5.7-23.15,16.61 H510.84z" />
      <path fill={color} d="M435.75,25.24c0-2.37-1.54-4.36-3.67-5.07c-1.73-3.62-5.3-6.18-9.51-6.48v-1.04l2.83-1.4l-2.83-1.41 l-0.06-1.29c1.11-0.28,1.93-1.27,1.93-2.46c0-1.41-1.14-2.54-2.54-2.54c-1.41,0-2.54,1.14-2.54,2.54c0,1.24,0.88,2.27,2.06,2.5 l0.09,1.96l1.42,0.71l-1.45,0.72v1.7c-4.65,0.12-8.61,3-10.29,7.06c-2.14,0.71-3.7,2.7-3.7,5.08c0,2.68,1.97,4.88,4.54,5.29 c2.02,3.27,5.62,5.46,9.74,5.46c4.43,0,8.26-2.51,10.16-6.19C434.13,29.7,435.75,27.67,435.75,25.24z" />
    </g>
  </svg>
);
