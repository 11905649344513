import { useCallback } from 'react';
import styled          from 'styled-components';
import {
  Pagination as PaginationAnt,
  PaginationProps,
} from 'antd';

type TRequiredPaginationProps = 'current' | 'setPage'

export interface IPaginationProps extends Omit<PaginationProps, TRequiredPaginationProps | 'showTotal'> {
  current    : number;
  margin?    : string;
  setPage    : (page: number, pageSize?: number) => void;
  showTotal? : PaginationProps['showTotal'] | boolean;
}

export const Pagination = ({
  margin,
  setPage,
  pageSizeOptions,
  showTotal,
  pageSize = 10,
  ...rest
}: IPaginationProps) => {
  const handleShowTotal = useCallback((total: number, range: [number, number]) => {
    if (typeof showTotal === 'boolean') {
      return `${range[0]}-${range[1]} of ${total}`;
    }
    if (showTotal) {
      return showTotal(total, range);
    }

    return undefined;
  }, [showTotal]);

  return (
    <Pagination.Wrapper margin={margin || '0'}>
      <PaginationAnt
        hideOnSinglePage = {!pageSizeOptions}
        onChange         = {setPage}
        pageSize         = {pageSize}
        pageSizeOptions  = {pageSizeOptions}
        showSizeChanger  = {!!pageSizeOptions}
        showTotal        = {handleShowTotal}
        {...rest}
      />
    </Pagination.Wrapper>
  );
};

Pagination.Wrapper = styled.div<{ margin: string; }>`
  > ul {
    font-size   : 16px;
    margin      : ${({ margin }) => margin};
    user-select : none;
  }

  a,
  span {
    color       : ${({ theme }) => theme.color.darkGray};
    font-family : 'Poppins';
    font-size   : 16px;

    &:hover { color: ${({ theme }) => theme.color.blue}; }
  }

  button,
  li { border: none !important; }

  li {
    border-radius : 4px;
    margin-right  : 0;
  }

  .ant-pagination-total-text {
    color       : ${({ theme }) => theme.color.darkGray};
    font-family : 'Poppins';
    font-size   : 16px;
  }

  .ant-pagination-disabled span { color: ${({ theme }) => theme.color.lightGray}; }

  .ant-pagination-item-active {
    background-color: ${({ theme }) => theme.color.blue};

    > a { color: #fff; }
  }
`;
