import React, { useCallback, useMemo }  from 'react';
import styled                           from 'styled-components';
import { Formik, Form, Field }          from 'formik';
import * as Yup                         from 'yup';

import { Checkbox, FormButtons, Input } from '@common/components';

import { Modal } from '@src/App';

export interface IDeleteModal {
  confirmMessage         : string;
  entityCheckboxMessage? : string;
  entityNameMessage      : string;
  name                   : string;
  onDeleteConfirm        : (isChecked: boolean) => void;
}

const useValidationSchema = (name?: string) => {
  const validationSchema = useMemo(() => (
    Yup.object().shape({
      itemName: Yup.string().test(
        'value equals name',
        'Enter element name you want to delete',
        (value) => (value === `${name}`),
      ).typeError('Enter element name you want to delete'),
    })
  ), [name]);

  return validationSchema;
};

export const DeleteModal: any = React.forwardRef(({
  confirmMessage,
  entityCheckboxMessage,
  entityNameMessage,
  name,
  onDeleteConfirm,
}: IDeleteModal, ref) => {
  const validationSchema = useValidationSchema(name);

  const onSubmit = useCallback(({ isChecked }) => {
    onDeleteConfirm(isChecked);
  }, [onDeleteConfirm]);

  return (
    <DeleteModal.Container ref={ref} tabIndex={1}>
      <DeleteModal.Title>Are you sure?</DeleteModal.Title>
      <DeleteModal.Body>{confirmMessage}</DeleteModal.Body>
      <Formik
        initialValues    = {{ itemName: null, isChecked: false }}
        validateOnBlur   = {true}
        validationSchema = {name.length ? validationSchema : ''}
        onSubmit         = {onSubmit}
      >
        <Form>
          {!!name && (
            <DeleteModal.InputWrapper>
              <p>{entityNameMessage}</p>
              <Field
                name      = "itemName"
                component = {Input}
              />
              {entityCheckboxMessage && (
                <DeleteModal.CheckboxWrapper>
                  <Field
                    name      = "isChecked"
                    component = {Checkbox}
                  />
                  <p>{entityCheckboxMessage}</p>
                </DeleteModal.CheckboxWrapper>
              )}
            </DeleteModal.InputWrapper>
          )}
          <FormButtons
            mainButtonLabel = "Delete"
            onClickCancel   = {Modal.close}
          />
        </Form>
      </Formik>
    </DeleteModal.Container>
  );
});

DeleteModal.Container = styled.div<{ ref: any }>`
  width            : 520px;
  background-color : #fff;
`;

DeleteModal.Title = styled.h1`
  color         : red;
  text-align    : center;
  margin-bottom : 0;
  font-size     : 25px;
`;

DeleteModal.Body = styled.p`
  text-align    : center;
  margin-bottom : 20px;
  font-size     : 18px;
  font-weight   : bold;
`;

DeleteModal.InputWrapper = styled.div`
  > p { white-space: pre-wrap; }
`;

DeleteModal.CheckboxWrapper = styled.div`
  display     : flex;
  align-items : center;

  > p { margin-left: 10px; }
`;