import { Modal as ModalAnt } from 'antd';
import styled                from 'styled-components';

import { CardHeader, CardStatus }  from '../Card';
import { ECardStatuses }           from '../../enums';
import { theme as Theme }          from '../../styles';
import { DeniedIcon as CloseIcon } from '../../../public/svg';

export interface IModalProps {
  bodyStyle?    : (theme: typeof Theme) => string;
  children      : React.ReactNode;
  closable?     : boolean;
  maskClosable? : boolean;
  onCancel      : () => void;
  parentNodeId? : string;
  status?       : ECardStatuses;
  title?        : string;
  visible?      : boolean;
  width?        : string;
}

export const Modal = ({
  bodyStyle,
  children,
  onCancel,
  parentNodeId,
  status,
  title,
  width,
  closable     = false,
  maskClosable = false,
  visible      = true,
}: IModalProps) => (
  <ModalAnt
    centered
    visible      = {visible}
    getContainer = {parentNodeId}
    bodyStyle    = {{ padding: '0' }}
    closable     = {closable}
    footer       = {null}
    maskClosable = {maskClosable}
    onCancel     = {onCancel}
    width        = {width}
  >
    <Modal.Body bodyStyle={bodyStyle}>
      {status && (
        <CardStatus
          status       = {status || ECardStatuses.None}
          wrapperStyle = {() => 'transform: translateY(-10px);'}
        />
      )}
      {title && (
        <Modal.Header>
          <CardHeader title={title} />
          <Modal.CloseIconWrapper onClick={onCancel}>
            <CloseIcon
              color  = {Theme.color.darkGray}
              height = "14px"
              width  = "14px"
            />
          </Modal.CloseIconWrapper>
        </Modal.Header>
      )}
      <Modal.Content paddingTop={title ? '31px' : '0'}>
        {children}
      </Modal.Content>
    </Modal.Body>
  </ModalAnt>
);

Modal.CloseIconWrapper = styled.div`
  cursor: pointer;
`;

Modal.Content = styled.div<{ paddingTop: string }>`
  padding: ${({ paddingTop }) => `${paddingTop} 40px 0`};
`;

Modal.Header = styled.div`
  align-items     : center;
  border-bottom   : 1px solid ${({ theme }) => theme.color.lightGray};
  display         : flex;
  justify-content : space-between;
  padding         : 0 45px 20px 40px;
  text-transform  : uppercase;

  h2 {
    font-size   : 16px;
    line-height : 21px;
  }

  > div:first-child { margin-bottom: 0; }
`;

Modal.Title = styled.h2`
  color       : ${({ theme }) => theme.color.black};
  font-size   : 16px;
  line-height : 21px;
`;

Modal.Body = styled.div<{ bodyStyle: IModalProps['bodyStyle'] }>`
  overflow : hidden;
  padding  : 21px 0 41px;
  position : relative;

  ${({ bodyStyle, theme }) => bodyStyle && bodyStyle(theme)}
`;
