import { useCallback, useEffect, useMemo, useState } from 'react';

import { TableProps } from '../components/Table/Table';

type TGetStatusFunc = (itemId: number | string) => boolean;
type TOnSelectFunc  = (newItem: boolean, itemDetails: TableProps['content'][0] | 'main') => void;

type TUseSelectedItems = (
  key   : number,
  items : TableProps['content']
) => [
  { [id: number]: TableProps['content']; },
  TOnSelectFunc,
  TGetStatusFunc,
];

export const useSelectedItems: TUseSelectedItems = (key, items) => {
  const [selected, setSelected] = useState<{ [id: number]: TableProps['content'] }>({});

  const availableItems = useMemo(() => (
    items.filter((item) => !item.deletingDisabled)
  ), [items]);

  const selectedIds = useMemo(() => (
    (selected[key] || []).map((item) => item.id)
  ), [selected]);

  const getStatus = useCallback<TGetStatusFunc>((itemId) => {
    if (!selected[key]) {
      return false;
    } else if (itemId === 'main') {
      return selected[key].length === availableItems.length;
    }

    return selectedIds.includes(itemId);
  }, [availableItems, key, selected, selectedIds]);

  const onSelect = useCallback<TOnSelectFunc>((newItem, itemDetails) => {
    if (itemDetails === 'main') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: _prevSelectedByKey, ...rest } = selected;

      setSelected(newItem
        ? { ...rest, [key]: availableItems }
        : rest);
    } else {
      const { [key]: prevSelectedByKey, ...rest } = selected;

      setSelected(newItem
        ? { ...rest, [key]: [...(prevSelectedByKey || []), itemDetails] }
        : { ...rest, [key]: prevSelectedByKey.filter((item) => item.id !== itemDetails.id) });
    }
  }, [availableItems, key, selected]);

  useEffect(() => {
    const itemsIds           = availableItems.map((item) => item.id);
    const selectedItemMissed = selected[key]?.some((item) => !itemsIds.includes(item.id));

    if (selectedItemMissed) {
      setSelected({});
    }
  }, [availableItems]);

  return [selected, onSelect, getStatus];
};
