import { TApiCaller, TFileUploader } from '../models';

const regexpImg   = /^.*\.(jpg|JPG|gif|GIF|png|PNG|jpeg|JPEG)$/;
const regexpVideo = /^.*\.(mp4|MP4|mov|MOV)$/;

type mimeType = 'image' | 'mp4' | null;

type TFileUploadingInitializer = (apiHandler : TApiCaller) => TFileUploader

// eslint-disable-next-line arrow-body-style
export const initializeFileUploader: TFileUploadingInitializer = (apiHandler) => {
  return async (file, params) => {
    try {
      const formData = new FormData();

      formData.append('file', file);

      let response       = null;
      let type: mimeType = null;

      if (regexpImg.test(file.name)) {
        response = await apiHandler('images/upload_image', 'post', { formData, params }, { isFormData: true });
        type     = 'image';
      } else if (regexpVideo.test(file.name)) {
        response = await apiHandler('images/upload', 'post', { formData, params: { ...params, path: 'video' } }, { isFormData: true });
        type     = 'mp4';
      }

      return response && type ? { uri: response.data, type } : null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e, 'error utils uploadFile');
    }

    return null;
  };
};
