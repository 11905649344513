import { getIn }                      from 'formik';
import { useEffect, useMemo, useRef } from 'react';

import { FieldErrorProps } from '../components/FieldError/FieldError';

interface UseFieldErrorResult {
  isErrorVisible : boolean;
  fieldError     : string | { [key: string]: string } | null;
}

export const useFieldError = ({
  errorMessage,
  field,
  form,
  value,
}: FieldErrorProps): UseFieldErrorResult => {
  const errorFieldValue = useRef<string | number | null>(null);

  const fieldError = useMemo<string | null>(() => {
    if (form && field && getIn(form.touched, field.name) && getIn(form.errors, field.name)) {
      return getIn(form.errors, field.name);
    }

    return errorMessage || null;
  }, [errorMessage, field]);

  const isErrorVisible = useMemo(() => {
    if (field) {
      return errorFieldValue.current === field.value && !!fieldError;
    }

    return !!fieldError;
  }, [fieldError, errorFieldValue, field]);

  useEffect(() => {
    errorFieldValue.current = field ? field.value : value;
  }, [form?.isSubmitting]);

  return { fieldError, isErrorVisible };
};
