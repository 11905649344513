import React  from 'react';
import styled from 'styled-components';

import { Menu }   from './Menu';
import { Header } from '../Header/Header';

interface IMenuItemContainer {
  children : React.ReactNode;
}

export const MenuItemContainer = ({ children }: IMenuItemContainer) => (
  <MenuItemContainer.Wrapper>
    <MenuItemContainer.HeaderWrapper>
      <Header></Header>
    </MenuItemContainer.HeaderWrapper>
    <MenuItemContainer.Body>
      <MenuItemContainer.ScrollArea>
        <div>
          <MenuItemContainer.SidebarWrapper>
            <Menu />
          </MenuItemContainer.SidebarWrapper>
          <MenuItemContainer.Content>
            <div>
              {children}
            </div>
          </MenuItemContainer.Content>
        </div>
      </MenuItemContainer.ScrollArea>
    </MenuItemContainer.Body>
  </MenuItemContainer.Wrapper>
);

MenuItemContainer.SidebarWrapper = styled.div`
  background-color : #fff;
  height           : 100%;
  overflow         : scroll;

  ::-webkit-scrollbar-thumb {
    background-color : transparent;
  }
`;

MenuItemContainer.ScrollArea = styled.div`
  background-color : #f4f7fc;
  height           : 100%;

  > div:first-child {
    display    : flex;
    height     : 101%;
  }
`;

MenuItemContainer.Content = styled.div`
  align-content : flex-start;
  flex          : 1;
  overflow      : auto;
  position      : relative;

  > div:first-child {
    height  : 100%;
    padding : 20px;
  }

  > div:nth-child(2) {
    display   : flex;
    flex-flow : row wrap;
    gap       : 20px;
    padding   : 0 40px 40px;
  }
`;

MenuItemContainer.Body = styled.div`
  flex     : 1;
  overflow : hidden;
`;

MenuItemContainer.HeaderWrapper = styled.div`
  box-shadow : 0 1px 4px #15223214;
  flex       : 0 0 68px;
  z-index    : 300;
`;

MenuItemContainer.Wrapper = styled.div`
  display        : flex;
  flex-direction : column;
  height         : 100%;
  min-width      : 1024px;
`;
