import { ISvgIconOptions } from './index';

export default ({ color, height, width }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 512 512" fill={color}>
    <circle
      cx   = "256"
      cy   = "256"
      fill = "#ffc107"
      r    = "256"
    />
    <path
      d         = "m215.285 193.293a25.688 25.688 0 1 0 -25.685-25.688 25.71 25.71 0 0 0 25.689 25.688zm166.364 212.707a24.373 24.373 0 0 0 24.351-24.346v-62.016l-99.372-99.374-62.223 62.22 28.055 23.66a10.711 10.711 0 1 1 -13.81 16.377l-36.821-31.042a.144.144 0 0 0 -.036-.031l-44.232-37.288-71.561 71.554v55.94a24.375 24.375 0 0 0 24.351 24.346zm-251.3-300h251.3a24.373 24.373 0 0 1 24.351 24.356v158.977l-91.8-91.8a10.722 10.722 0 0 0 -15.157 0l-71.082 71.087-44.108-37.19a10.7 10.7 0 0 0 -14.48.615l-63.373 63.371v-165.06a24.375 24.375 0 0 1 24.351-24.356z"
      fill      = "#fff"
      fillRule = "evenodd"
    />
  </svg>
);
