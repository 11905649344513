// eslint-disable-next-line
import { hot }              from 'react-hot-loader/root';
import { Provider }         from 'react-redux';
import { Helmet }           from 'react-helmet';
import { PersistGate }      from 'redux-persist/integration/react';
import { ThemeProvider }    from 'styled-components';
import { ConnectedRouter }  from 'connected-react-router';
import { compose }          from 'redux';
import { SnackbarProvider } from 'notistack';

import { createModalInstance } from '@components/Modal/Modal';
import { Notifications }       from '@components/Notifications/Notifications';

import { BaseStyles, theme}      from './styles';
import { store, browserHistory } from './store';
import { Routes }                from './routes';

const { createdStore, persistor }: any = store;
export const Modal = createModalInstance();

const AppContainer = () => {

  return (
    <ThemeProvider theme={theme}>
      <Provider store={createdStore}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider maxSnack={3} hideIconVariant>
            <>
              <Helmet titleTemplate="Newsie Admin Panel" defaultTitle="Newsie Admin Panel" />
              <BaseStyles />
              <ConnectedRouter history={browserHistory}>
                <Routes />
              </ConnectedRouter>
              <Modal />
              <Notifications />
            </>
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

const App = compose(
  hot,
)(AppContainer);

export { App };
