import { useMemo, useCallback }      from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { connect }                   from 'react-redux';
import { Formik, FormikProps }       from 'formik';
import * as Yup                      from 'yup';
import styled                        from 'styled-components';

import { CircularLoader }                   from '@components/Loaders/CircularLoader';
import { useConfirmRecurringModalHandlers } from '@components/RecurrenceEditor/ConfirmRecurringModal';
import {
  IAreaSelectionItem,
  mapAreasToBannedType,
  validateSelectedAreas,
} from '@components/AreaSelection/AreaSelection';
import {
  validateStringForSpecialSymbolsOnly,
  validateTheCorrectTimeDirection,
} from '@common/utils';

import { IEventModel }            from '@models/index';
import { ERequestedReviewStatus } from '@models/enums';
import { INITIAL_LOCATION }       from '@modules/territories/pages/AddTerritoryContainerL';
import { IState }                 from '@store/rootReducer';
import { useFetchEntity }         from '@utils/fetchEntity';

import { FormEvent } from '../components/FormEvent';

import {
  addEvent  as addEventAction,
  editEvent as editEventAction,
  getMeta,
  IEventsActionsCreators,
} from '../eventsReducer';

interface IEvents {
  addEvent   : IEventsActionsCreators['addEvent'];
  editEvent  : IEventsActionsCreators['editEvent'];
  isFetching : boolean;
  location   : any;
}

export type TEventFormValues = Omit<IEventModel, 'dateEnd' | 'eventId' | 'tags' | 'rEventImage' | 'rEventTerritory' | 'businessLocationId' | 'businessLocation'>
& {
  businessLocation   : {value?: number | null, label?: string} | null;
  dateEnd            : string | null;
  eventId?           : number;
  eventRecurrence    : NonNullable<IEventModel['eventRecurrence']>;
  rEventImage        : { imageUri?: string; mimeType: string; };
  rEventTerritory    : IAreaSelectionItem[];
  tags               : string;
  withRegularRepeats : boolean;
}

type TEventPostModel = Omit<IEventModel, 'dateEnd'> & {
  dateEnd              : string | null;
  businessLocationName : string | null;
}

const useValidationSchema = (t: TFunction<'translation'>) => Yup.object().shape({
  allDay           : Yup.bool().required(),
  businessLocation : Yup.object().nullable(),
  rEventTerritory  : validateSelectedAreas(),
  dateStart        : Yup.string()
    .required('Select the start date of the event'),
  dateEnd : Yup.string()
    .test(...validateTheCorrectTimeDirection(t, 'dateStart'))
    .nullable(),
  description : Yup.string()
    .test(...validateStringForSpecialSymbolsOnly((value) => value))
    .required('Enter event description'),
  location : Yup.object().shape({
    address1      : Yup.string().nullable(),
    googlePlaceId : Yup.string().nullable(),
  }),
  rEventImage          : Yup.object()
    .shape({ imageUri: Yup.string().required('Select event image') })
    .typeError('Select event image')
    .required(),
  tags : Yup.array()
    .ensure(),
  timeZone : Yup.string()
    .max(50, 'Max character count is 50')
    .required('Select the time zone where the event will take place'),
  title : Yup.string()
    .max(100, 'Max character count is 100')
    .test(...validateStringForSpecialSymbolsOnly((value) => value))
    .required('Enter event title'),
  websiteUri : Yup.string()
    .max(200, 'Max character count is 200')
    .nullable()
    .url('It does not look like a website, but it should be'),
  withRegularRepeats : Yup.bool()
    .required(),
});

const EventsComponent = ({
  addEvent,
  editEvent,
  isFetching,
  location,
}: IEvents) => {
  const [event, isEventFetching] = useFetchEntity('feature/Event') as [IEventModel | null, boolean, () => Promise<void>];

  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const initialValues = useMemo<TEventFormValues>(() => {
    const values: IEventModel | null = event || location?.state?.event || null;

    return {
      allDay             : !!values?.allDay,
      businessLocation   : { value: values?.businessLocationId, label: values?.businessLocation?.name } || null,
      description        : values?.description || '',
      dateEnd            : values?.dateEnd || null,
      dateStart          : values?.dateStart || '',
      eventId            : values?.eventId,
      rEventImage        : {imageUri: values?.rEventImage[0].image.imageUri, mimeType: 'image'} || {imageUri: event?.rEventImage[0].image.imageUri, mimeType: 'image'} || null,
      status             : typeof values?.status === 'number' ? values.status : ERequestedReviewStatus.Approved,
      location           : values?.location || INITIAL_LOCATION,
      locationId         : values?.locationId || null,
      eventRecurrenceId  : event?.eventRecurrenceId || null,
      tags               : values?.tags.length ? `#${values.tags.map((item) => item.replace(/ /g, '')).join(' #')}` : '',
      rEventTerritory    : (values?.rEventTerritory || []) as unknown as IAreaSelectionItem[],
      timeZone           : values?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      title              : values?.title || '',
      websiteUri         : values?.websiteUri || '',
      withRegularRepeats : !!values?.eventRecurrenceId,
      eventRecurrence    : values?.eventRecurrence || {
        recurrenceRule      : '',
        recurrenceDateStart : '',
      },
    };
  }, [event]);

  const [openConfirmRecurringModal] = useConfirmRecurringModalHandlers('event');

  const onSubmit = useCallback(async (values: TEventFormValues) => {
    const { businessLocation, ...restValues } = values;

    const requestData: TEventPostModel = {
      ...restValues,
      eventId              : values.eventId as unknown as number,
      eventRecurrence      : values.eventRecurrence?.recurrenceRule ? values.eventRecurrence : null,
      eventRecurrenceId    : values.eventRecurrenceId ? values.eventRecurrenceId : null,
      location             : values.location?.googlePlaceId ? values.location : null,
      businessLocationId   : businessLocation?.value || null,
      businessLocationName : businessLocation?.label || null,
      rEventImage          : [{
        eventId   : event?.eventId || null,
        imageId   : event?.rEventImage[0].imageId || null,
        order     : 1,
        isEnabled : true,
        image     : {
          imageId      : event?.rEventImage[0].imageId || null,
          title        : null,
          description  : null,
          thumbnailUri : null,
          previewUri   : null,
          imageUri     : (event?.rEventImage[0].image.imageUri as unknown as any)?.imageUri || (values.rEventImage as unknown as any)?.imageUri,
          mimeType     : 'image',
          sizeInBytes  : null,
          credit       : null,
        },
      }],
      rEventTerritory : mapAreasToBannedType(values.rEventTerritory, { eventId: event?.eventId }) as IEventModel['rEventTerritory'],
      tags            : values.tags.length ? values.tags.replace(' ', '').split('#').filter((item: any) => item.length) : [],
    };

    if (event) {
      let isAll = !!requestData.eventRecurrence;
      if (event.eventRecurrenceId && isAll) {
        isAll = await openConfirmRecurringModal();
      }

      editEvent(requestData, isAll);
    } else {
      addEvent(requestData);
    }
  }, [event]);

  const updateValuesIfNeeded = useCallback((() => {
    let { dateStart, withRegularRepeats } = initialValues;

    return (values: TEventFormValues) => {
      if (values.dateStart && values.withRegularRepeats && (values.dateStart !== dateStart)) {
        values.eventRecurrence.recurrenceDateStart = values.dateStart;
      }
      if (!values.withRegularRepeats && (values.withRegularRepeats !== withRegularRepeats)) {
        values.eventRecurrence  = { recurrenceRule: '', recurrenceDateStart: '' };
        values.eventRecurrenceId = null;
      }
      if (values.withRegularRepeats && values.dateStart && (values.withRegularRepeats !== withRegularRepeats)) {
        values.eventRecurrence  = { recurrenceRule: '', recurrenceDateStart: values.dateStart };
      }

      dateStart          = values.dateStart;
      withRegularRepeats = values.withRegularRepeats;

      return values;
    };
  })(), [initialValues]);

  if (isEventFetching) return (
    <Container>
      <CircularLoader />
    </Container>
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<TEventFormValues>) => (
          <FormEvent
            {...props}
            isFetching = {isFetching}
            values     = {updateValuesIfNeeded(props.values)}
          />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 947px; }
`;

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addEvent  : addEventAction,
  editEvent : editEventAction,
};

export const Events = connect(mapStateToProps, mapDispatchToProps)(EventsComponent);
