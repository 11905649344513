import { Popover }        from 'antd';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';
import {
  ChangeEvent,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CameraIcon, MediaUploaderProps } from './index';
import { Button, FormButtons, Input }     from '../index';
import { setPopupContainer }              from '../../utils';

export interface IMimeTypeSelector extends Pick<MediaUploaderProps, 'clickableTrigger'> {
  acceptedFormats?     : string;
  disabled?            : boolean;
  mimeTypeOptions?     : ('image' | 'video' | 'youtube')[];
  multiple?            : boolean;
  onSelectFile         : (e: ChangeEvent<HTMLInputElement>, mimeType: 'image' | 'video') => void;
  onSubmitYouTubeVideo : (youtubeVideoId: string) => void;
  fieldName            : string;
}

export const MimeTypeSelector = ({
  acceptedFormats,
  clickableTrigger,
  disabled,
  onSelectFile,
  onSubmitYouTubeVideo,
  fieldName,
  mimeTypeOptions = ['image', 'video', 'youtube'],
  multiple        = false,
}: IMimeTypeSelector) => {
  const { t } = useTranslation();

  const [key, setKey]                       = useState(Math.random().toString(36));
  const [isPopoverActive, setPopoverStatus] = useState<boolean>(false);
  const [isInputVisible, setInputStatus]    = useState<boolean>(false);
  const [inputValue, setInputValue]         = useState<string>('');
  const [inputError, setInputError]         = useState<string>('');

  const innerClickableTrigger = useMemo<JSX.Element>(() => (
    clickableTrigger ? cloneElement(clickableTrigger) : <CameraIcon />
  ), [clickableTrigger]);

  const submitYouTubeVideoUrl = useCallback(() => {
    const isYoutubeUrlRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const urlMatch          = inputValue.match(isYoutubeUrlRegex);

    if (urlMatch && urlMatch[7] && urlMatch[7].length === 11) {
      const youtubeVideoId  = urlMatch[7];

      setPopoverStatus(false);
      onSubmitYouTubeVideo(youtubeVideoId);
    } else {
      setInputError(t('itDoesNotLookLikeYouTubeVideoUrl'));
    }
  }, [inputValue, onSubmitYouTubeVideo]);

  const selectFile = useCallback((e: ChangeEvent<HTMLInputElement>, mimeType: 'image' | 'video') => {
    setKey(Math.random().toString(36));
    onSelectFile(e, mimeType);
    setPopoverStatus(false);
  }, [onSelectFile]);

  const onClickMimeType = useCallback((id: string) => {
    document.getElementById(id)?.click();
  }, []);

  const renderFileInput = useCallback((fileType: string) => (
    <MimeTypeSelector.InputLabel htmlFor={`${fieldName}_${fileType}`}>
      <input
        accept   = {acceptedFormats || (fileType === 'image' ? '.jpg, .jpeg, .png' : 'video/mp4')}
        id       = {`${fieldName}_${fileType}`}
        key      = {key}
        multiple = {multiple}
        onChange = {(e) => selectFile(e, fileType as 'image' | 'video')}
        style    = {{ display: 'none' }}
        type     = {disabled ? 'input' : 'file'}
      />
      {mimeTypeOptions.length === 1 ? innerClickableTrigger : (
        <Button
          label   = {t(fileType)}
          onClick = {disabled ? undefined : () => onClickMimeType(`${fieldName}_${fileType}`)}
          type    = "button"
        />
      )}
    </MimeTypeSelector.InputLabel>
  ), [fieldName, innerClickableTrigger, multiple, selectFile, mimeTypeOptions, disabled]);

  useEffect(() => {
    if (isPopoverActive) {
      setInputStatus(false);
      setInputError('');
      setInputValue('');
    }
  }, [isPopoverActive]);

  if (mimeTypeOptions.length === 1 && mimeTypeOptions[0] !== 'youtube') {
    return renderFileInput(mimeTypeOptions[0]);
  }

  return (
    <MimeTypeSelector.Wrapper>
      <Popover
        getPopupContainer = {setPopupContainer}
        onVisibleChange   = {setPopoverStatus}
        trigger           = "click"
        visible           = {isPopoverActive}
        content           = {(
          <div>
            <MimeTypeSelector.Buttons display={isInputVisible ? 'none' : 'flex'}>
              {mimeTypeOptions.includes('image') && (
                <div>
                  {renderFileInput('image')}
                </div>
              )}
              {mimeTypeOptions.includes('video') && (
                <div>
                  {renderFileInput('video')}
                </div>
              )}
              {mimeTypeOptions.includes('youtube') && (
                <div>
                  <Button
                    label   = {t('youtube')}
                    onClick = {() => setInputStatus(!isInputVisible)}
                    type    = "button"
                  />
                </div>
              )}
            </MimeTypeSelector.Buttons>
            <MimeTypeSelector.InputWrapper display={isInputVisible ? 'block' : 'none'}>
              {isInputVisible && (
                <Input
                  bordered
                  changeFieldValue = {setInputValue}
                  placeholder      = "https://youtu.be/qwerty"
                  value            = {inputValue}
                  errorMessage     = {inputError}
                  wrapperStyle     = {() => 'margin: 0'}
                />
              )}
              <FormButtons
                mainButtonLabel = {t('submit')}
                onSubmit        = {submitYouTubeVideoUrl}
                onClickCancel   = {() => setInputStatus(false)}
              />
            </MimeTypeSelector.InputWrapper>
          </div>
        )}
      >
        <div>
          {innerClickableTrigger}
        </div>
      </Popover>
    </MimeTypeSelector.Wrapper>
  );
};

MimeTypeSelector.Buttons = styled.div<{ display: 'flex' | 'none' }>`
  display         : ${({ display }) => display};
  justify-content : space-between;

  > div { &:not(:first-child) { margin-left: 8px; } }
`;

MimeTypeSelector.InputLabel = styled.label`
  height : 100%;
  width  : 100%;
`;

MimeTypeSelector.InputWrapper = styled.div<{ display: 'block' | 'none' }>`
  display   : ${({ display }) => display};
  min-width : 250px;

  input {
    border-radius : 3px;
    height        : 36px;
  }

  div:last-child { margin-top: 18px; }
`;

MimeTypeSelector.Wrapper = styled.div`
  button {
    border-radius : 3px;
    height        : 32px;
    padding       : 0 10px;
  }
`;
