import { useCallback, useEffect, useState } from 'react';
import styled                               from 'styled-components';

import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';

export interface IBaseCopyButtonProps {
  valueToCopy: string | null;
}

enum ECopyButtonIcon {
  Default,
  Success,
}

export const BaseCopyButton = ({ valueToCopy } : IBaseCopyButtonProps) => {
  const [currentIcon, setIcon] = useState(ECopyButtonIcon.Default);

  const onClickCopy = useCallback(() => {
    if (valueToCopy) {
      navigator.clipboard.writeText(valueToCopy);
      setIcon(ECopyButtonIcon.Success);
    }
  }, [valueToCopy]);

  useEffect(() => {
    if (currentIcon !== ECopyButtonIcon.Success) {
      return undefined;
    }

    const timeout = setTimeout(() => {
      setIcon(ECopyButtonIcon.Default);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [currentIcon]);

  if (!valueToCopy) {
    return null;
  }

  return (
    <BaseCopyButton.Component onClick={currentIcon === ECopyButtonIcon.Default ? onClickCopy : undefined}>
      <BaseCopyButton.CopyIcon currentIcon={currentIcon}>
        <CopyOutlined />
      </BaseCopyButton.CopyIcon>
      <BaseCopyButton.SuccessIcon currentIcon={currentIcon}>
        <CheckCircleOutlined />
      </BaseCopyButton.SuccessIcon>
    </BaseCopyButton.Component>
  );
};

BaseCopyButton.CopyIcon = styled.div<{ currentIcon: ECopyButtonIcon }>`
  cursor      : pointer;
  margin-left : ${({ currentIcon }) => (currentIcon === ECopyButtonIcon.Default ? 4 : -17.5)}px;
  opacity     : ${({ currentIcon }) => (currentIcon === ECopyButtonIcon.Default ? 1 : 0.2)};
`;

BaseCopyButton.SuccessIcon = styled.div<{ currentIcon: ECopyButtonIcon }>`
  opacity: ${({ currentIcon }) => (currentIcon === ECopyButtonIcon.Success ? 1 : 0.2)};
`;

BaseCopyButton.Component = styled.div`
  color      : #000;
  display    : flex;
  gap        : 5px;
  overflow-x : hidden;
  transition : all 0.2s;
  width      : 24.5px;

  > div { transition: all ease-in-out 0.3s, opacity 0.15s; }

  > div svg {
    height : 16px;
    width  : 16px;
  }
`;
