export const debounce = (() => {
  let timerId: number | ReturnType<typeof setTimeout> | null = null;

  return (fn: () => unknown, ms = 600) => {
    if (timerId) {
      clearTimeout(timerId as number);
    }

    timerId = setTimeout(() => {
      timerId = null;
      fn();
    }, ms);
  };
})();
