export interface IRouteItem {
  endpoint        : string,
  localizationKey : string,
  path            : IRouteItem[],
  nestedItems?    : {
    endpoint        : string,
    localizationKey : string,
    path : string,
  }[];
}

const affiliations: IRouteItem = {
  endpoint        : '/admin/affiliations',
  localizationKey : 'affiliations',
  path            : [],
};

const affiliationsEdit: IRouteItem = {
  endpoint        : '/admin/affiliations/:id',
  localizationKey : 'editAffiliation',
  path            : [affiliations],
};

const affiliationAdd: IRouteItem = {
  endpoint        : '/admin/affiliations/add',
  localizationKey : 'createAffiliation',
  path            : [affiliations],
};

const ad: IRouteItem = {
  endpoint        : '/admin/sponsors',
  localizationKey : 'sponsors',
  path            : [],
};

const adEdit: IRouteItem = {
  endpoint        : '/admin/sponsors/:id',
  localizationKey : 'editSponsor',
  path            : [ad],
};

const adAdd: IRouteItem = {
  endpoint        : '/admin/sponsors/add',
  localizationKey : 'createSponsor',
  path            : [ad],
};

const areas: IRouteItem = {
  endpoint        : '/admin/areas',
  localizationKey : 'areas',
  path            : [],
};

const feeds: IRouteItem = {
  endpoint        : '/admin/feeds',
  localizationKey : 'feeds',
  path            : [],
  nestedItems     : [{
    endpoint        : '/admin/requested-feeds',
    localizationKey : 'requests',
    path            : 'feeds',
  }],
};

const feedsAdd: IRouteItem = {
  endpoint        : '/admin/feeds/add',
  localizationKey : 'createFeed',
  path            : [feeds],
};

const feedsEdit: IRouteItem = {
  endpoint        : '/admin/feeds/:id',
  localizationKey : 'editFeed',
  path            : [feeds],
};

const areasEdit: IRouteItem = {
  endpoint        : '/admin/areas/:id',
  localizationKey : 'editArea',
  path            : [areas],
};

const areasAdd: IRouteItem = {
  endpoint        : '/admin/areas',
  localizationKey : 'createArea',
  path            : [areas],
};

const businesses: IRouteItem = {
  endpoint        : '/admin/businesses',
  localizationKey : 'businesses',
  path            : [],
};

const businessesAdd: IRouteItem = {
  endpoint        : '/admin/businesses/add',
  localizationKey : 'createBusiness',
  path            : [businesses],
};

const businessesEdit: IRouteItem = {
  endpoint        : '/admin/businesses/:id',
  localizationKey : 'editBusiness',
  path            : [businesses],
};

const categories: IRouteItem = {
  endpoint        : '/admin/categories',
  localizationKey : 'categories',
  path            : [],
};

const categoriesAdd: IRouteItem = {
  endpoint        : '/admin/categories/add',
  localizationKey : 'createCategory',
  path            : [categories],
};

const categoriesEdit: IRouteItem = {
  endpoint        : '/admin/categories/:id',
  localizationKey : 'editCategory',
  path            : [categories],
};

const channels: IRouteItem = {
  endpoint        : '/admin/channels',
  localizationKey : 'channels',
  path            : [],
};

const channelsAdd: IRouteItem = {
  endpoint        : '/admin/channels/add',
  localizationKey : 'createChannel',
  path            : [channels],
};

const channelsEdit: IRouteItem = {
  endpoint        : '/admin/channels/:id',
  localizationKey : 'editChannel',
  path            : [channels],
};

const clients: IRouteItem = {
  endpoint        : '/admin/clients',
  localizationKey : 'clients',
  path            : [],
};

const clientsAdd: IRouteItem = {
  endpoint        : '/admin/clients/add',
  localizationKey : 'createClient',
  path            : [clients],
};

const clientsEdit: IRouteItem = {
  endpoint        : '/admin/clients/:id',
  localizationKey : 'editClient',
  path            : [clients],
};

const events: IRouteItem = {
  endpoint        : '/admin/events',
  localizationKey : 'events',
  path            : [],
};

const eventsAdd: IRouteItem = {
  endpoint        : '/admin/events/add',
  localizationKey : 'createEvent',
  path            : [events],
};

const eventsEdit: IRouteItem = {
  endpoint        : '/admin/events/:id',
  localizationKey : 'editEvent',
  path            : [events],
};

const eventsClone: IRouteItem = {
  endpoint        : '/admin/events/add',
  localizationKey : 'cloneEvent',
  path            : [events],
};

const forms: IRouteItem = {
  endpoint        : '/admin/forms',
  localizationKey : 'forms',
  path            : [],
};

const formsAdd: IRouteItem = {
  endpoint        : '/admin/forms/add',
  localizationKey : 'createForm',
  path            : [forms],
};

const formsEdit: IRouteItem = {
  endpoint        : '/admin/forms/:id',
  localizationKey : 'editForm',
  path            : [forms],
};

const profile: IRouteItem = {
  endpoint        : '/admin/profile',
  localizationKey : 'editProfile',
  path            : [],
};

const slider: IRouteItem = {
  endpoint        : '/admin/carousel',
  localizationKey : 'carousel',
  path            : [],
};

const sliderAdd: IRouteItem = {
  endpoint        : '/admin/carousel/add',
  localizationKey : 'createCarousel',
  path            : [slider],
};

const sliderEdit: IRouteItem = {
  endpoint        : '/admin/carousel/:id',
  localizationKey : 'editCarousel',
  path            : [slider],
};

const stories: IRouteItem = {
  endpoint        : '/admin/stories',
  localizationKey : 'stories',
  path            : [],
};

const storiesAdd: IRouteItem = {
  endpoint        : '/admin/stories/add',
  localizationKey : 'createStory',
  path            : [stories],
};

const storiesEdit: IRouteItem = {
  endpoint        : '/admin/stories/:id',
  localizationKey : 'editStory',
  path            : [stories],
};

const tips: IRouteItem = {
  endpoint        : '/admin/tips',
  localizationKey : 'tips',
  path            : [],
};

const tipsAdd: IRouteItem = {
  endpoint        : '/admin/tips/add',
  localizationKey : 'createTip',
  path            : [tips],
};

const tipsEdit: IRouteItem = {
  endpoint        : '/admin/tips/:id',
  localizationKey : 'editTip',
  path            : [tips],
};

const tags: IRouteItem = {
  endpoint        : '/admin/tags',
  localizationKey : 'tags',
  path            : [],
};

const tagsAdd: IRouteItem = {
  endpoint        : '/admin/tags/add',
  localizationKey : 'createTip',
  path            : [tags],
};

const tagsEdit: IRouteItem = {
  endpoint        : '/admin/tags/:id',
  localizationKey : 'editTip',
  path            : [tags],
};

export const routesConfig = {
  affiliations,
  affiliationAdd,
  affiliationsEdit,
  ad,
  adAdd,
  adEdit,
  areas,
  areasAdd,
  areasEdit,
  businesses,
  businessesAdd,
  businessesEdit,
  categories,
  categoriesAdd,
  categoriesEdit,
  channels,
  channelsAdd,
  channelsEdit,
  clients,
  clientsAdd,
  clientsEdit,
  events,
  eventsAdd,
  eventsEdit,
  eventsClone,
  feeds,
  feedsAdd,
  feedsEdit,
  forms,
  formsAdd,
  formsEdit,
  profile,
  slider,
  sliderAdd,
  sliderEdit,
  stories,
  storiesAdd,
  storiesEdit,
  tips,
  tipsAdd,
  tipsEdit,
  tags,
  tagsAdd,
  tagsEdit,
};
