import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { ITableMeta }    from '@components/Table/ClickableItem';
import { ILicenseModel } from '@models/index';
import { IState }        from '@store/rootReducer';

import { TLicensePutModel, TLicensePostModel } from './pages/License';

export const STATE_KEY = 'licenses';

export interface ILicensesReducer {
  licenses : ILicenseModel[];
  meta     : Required<Omit<ITableMeta, 'order' | 'orderBy'>> & {
    isFetching    : boolean;
    totalLicenses : number;
  };
}

export enum ELicenseTypes {
  addLicense             = 'addLicense',
  addLicenseSuccessfully = 'addLicenseSuccessfully',
  addLicenseFailure      = 'addLicenseFailure',

  fetchLicenses             = 'fetchLicenses',
  fetchLicensesSuccessfully = 'fetchLicensesSuccessfully',
  fetchLicensesFailure      = 'fetchLicensesFailure',

  updateLicense             = 'updateLicense',
  updateLicenseSuccessfully = 'updateLicenseSuccessfully',
  updateLicenseFailure      = 'updateLicenseFailure',

  deleteLicense             = 'deleteLicense',
  deleteLicenseSuccessfully = 'deleteLicenseSuccessfully',
  deleteLicenseFailure      = 'deleteLicenseFailure',
}

export interface ILicensesActions {
  [ELicenseTypes.addLicense]             : Action<{ attributes: TLicensePostModel; meta: { isFetching: true } }>;
  [ELicenseTypes.addLicenseSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ELicenseTypes.addLicenseFailure]      : Action<{ meta: { isFetching: false } }>;

  [ELicenseTypes.fetchLicenses]             : Action<{ meta: Partial<ILicensesReducer['meta']> & { isFetching: true } }>;
  [ELicenseTypes.fetchLicensesSuccessfully] : Action<{ licenses: ILicenseModel[]; meta: { isFetching: false; totalLicenses: number } }>;
  [ELicenseTypes.fetchLicensesFailure]      : Action<{ meta: { isFetching: false } }>;

  [ELicenseTypes.updateLicense]             : Action<{ attributes: TLicensePutModel; meta: { isFetching: true } }>;
  [ELicenseTypes.updateLicenseSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ELicenseTypes.updateLicenseFailure]      : Action<{ meta: { isFetching: false } }>;

  [ELicenseTypes.deleteLicense]             : Action<{ attributes: { licenseUserId: string }; meta: { isFetching: true }}>;
  [ELicenseTypes.deleteLicenseSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ELicenseTypes.deleteLicenseFailure]      : Action<{ meta: { isFetching: false } }>;
}

export interface ILicensesActionsCreators {
  [ELicenseTypes.addLicense]             : (values: TLicensePostModel) => ILicensesActions[ELicenseTypes.addLicense];
  [ELicenseTypes.addLicenseSuccessfully] : () => ILicensesActions[ELicenseTypes.addLicenseSuccessfully];
  [ELicenseTypes.addLicenseFailure]      : () => ILicensesActions[ELicenseTypes.addLicenseFailure];

  [ELicenseTypes.fetchLicenses]             : (meta?: Partial<ILicensesReducer['meta']>) => ILicensesActions[ELicenseTypes.fetchLicenses];
  [ELicenseTypes.fetchLicensesSuccessfully] : (result: ILicenseModel[], total: number) => ILicensesActions[ELicenseTypes.fetchLicensesSuccessfully];
  [ELicenseTypes.fetchLicensesFailure]      : () => ILicensesActions[ELicenseTypes.fetchLicensesFailure];

  [ELicenseTypes.updateLicense]             : (values: TLicensePutModel) => ILicensesActions[ELicenseTypes.updateLicense];
  [ELicenseTypes.updateLicenseSuccessfully] : () => ILicensesActions[ELicenseTypes.updateLicenseSuccessfully];
  [ELicenseTypes.updateLicenseFailure]      : () => ILicensesActions[ELicenseTypes.updateLicenseFailure];

  [ELicenseTypes.deleteLicense]             : (licenseUserId: string) => ILicensesActions[ELicenseTypes.deleteLicense];
  [ELicenseTypes.deleteLicenseSuccessfully] : () => ILicensesActions[ELicenseTypes.deleteLicenseSuccessfully];
  [ELicenseTypes.deleteLicenseFailure]      : () => ILicensesActions[ELicenseTypes.deleteLicenseFailure];
}

type Actions = ILicensesActions[ELicenseTypes.fetchLicenses]
| ILicensesActions[ELicenseTypes.fetchLicensesSuccessfully]
| ILicensesActions[ELicenseTypes.fetchLicensesFailure]
| ILicensesActions[ELicenseTypes.addLicense]
| ILicensesActions[ELicenseTypes.addLicenseSuccessfully]
| ILicensesActions[ELicenseTypes.addLicenseFailure]
| ILicensesActions[ELicenseTypes.updateLicense]
| ILicensesActions[ELicenseTypes.updateLicenseSuccessfully]
| ILicensesActions[ELicenseTypes.updateLicenseFailure]
| ILicensesActions[ELicenseTypes.deleteLicense]
| ILicensesActions[ELicenseTypes.deleteLicenseSuccessfully]
| ILicensesActions[ELicenseTypes.deleteLicenseFailure];

const initialState: ILicensesReducer = {
  licenses : [],
  meta     : {
    isFetching    : false,
    page          : 0,
    rowsPerPage   : 50,
    totalLicenses : 0,
  },
};

export const {
  addLicense,
  addLicenseFailure,
  addLicenseSuccessfully,

  fetchLicenses,
  fetchLicensesSuccessfully,
  fetchLicensesFailure,

  updateLicense,
  updateLicenseSuccessfully,
  updateLicenseFailure,

  deleteLicense,
  deleteLicenseSuccessfully,
  deleteLicenseFailure,
}: ILicensesActionsCreators = createActions({
  addLicense             : (params: TLicensePostModel) => ({ attributes: { ...params }, meta: { isFetching: true } }),
  addLicenseSuccessfully : () => ({ meta: { isFetching: false } }),
  addLicenseFailure      : () => ({ meta: { isFetching: false } }),

  fetchLicenses             : (meta = {}) => ({ meta: { ...meta, isFetching: true } }),
  fetchLicensesSuccessfully : (licenses, totalLicenses) => ({ licenses, meta: { totalLicenses, isFetching: false } }),
  fetchLicensesFailure      : () => ({ meta: { isFetching: false } }),

  updateLicense             : (params: TLicensePutModel) => ({ attributes: { ...params }, meta: { isFetching: true } }),
  updateLicenseSuccessfully : () => ({ meta: { isFetching: false } }),
  updateLicenseFailure      : () => ({ meta: { isFetching: false } }),

  deleteLicense              : (licenseUserId) => ({ attributes: { licenseUserId }, meta: { isFetching: true }}),
  deleteLicenseSuccessfully  : () => ({ meta: { isFetching: false } }),
  deleteLicenseFailure       : () => ({ meta: { isFetching: false } }),
}, {
  prefix: STATE_KEY,
}) as any;

const licensesReducer = handleActions<ILicensesReducer>({
  // @ts-ignore
  [combineActions(
    addLicense,
    addLicenseFailure,
    addLicenseSuccessfully,

    fetchLicenses,
    fetchLicensesSuccessfully,
    fetchLicensesFailure,

    updateLicense,
    updateLicenseSuccessfully,
    updateLicenseFailure,

    deleteLicense,
    deleteLicenseSuccessfully,
    deleteLicenseFailure,
  )]: (
    state  : ILicensesReducer,
    action : Actions,
  ) => {
    switch (action.type) {
      case (fetchLicenses.toString()): {
        const actionTyped = action as ILicensesActions['fetchLicenses'];

        if (actionTyped.payload.meta.page === 0) {
          return ({
            ...state,
            licenses : [],
            meta     : { ...state.meta, ...actionTyped.payload.meta },
          });
        }

        return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
      }
      case (fetchLicensesSuccessfully.toString()): {
        const actionTyped = action as ILicensesActions['fetchLicensesSuccessfully'];

        return ({
          ...state,
          licenses : actionTyped.payload.licenses,
          meta     : { ...state.meta, ...actionTyped.payload.meta },
        });
      }
      default: {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const { attributes, meta, ...data } = action.payload as any;
        const nextMeta = { ...state.meta, ...meta };

        return ({ ...state, ...data, meta: nextMeta });
      }
    }
  },
}, initialState);

interface ILicensesSelectors {
  getLicenses : (state: IState) => ILicenseModel[];
  getMeta     : (state: IState) => ILicensesReducer['meta'];
}

export const {
  getLicenses,
  getMeta,
}: ILicensesSelectors = {
  getLicenses : (state: IState) => state[STATE_KEY].licenses,
  getMeta     : (state: IState) => state[STATE_KEY].meta,
};

export default licensesReducer;
