import { useCallback, useEffect, useState } from 'react';
import { Form, Field, FormikProps }         from 'formik';
import { AxiosResponse }                    from 'axios';
import styled                               from 'styled-components';

import {
  Card,
  Input,
  DatePicker,
  Select,
  FormButtons,
}                                    from '@common/components';

import { AreaSelection }             from '@components/AreaSelection/AreaSelection';
import { usePubliherHandlers }       from '@components/Modal/AddPublisher';
import { MultipleAutoSuggest }       from '@components/Autosuggest/MultipleAutoSuggest';
import { AutoSuggest }               from '@components/Autosuggest/Autosuggest';
import { MediaUploader }             from '@components/MediaUploader/MediaUploader';
import { Title }                     from '@components/Title/Title';
import { Wysiwyg }                   from '@components/Wysiwyg/Wysiwyg';

import { IPublisherModel, IStoryModel, IOptionType } from '@src/models';
import { callApi }                                   from '@utils/apiCaller';
import { useFetchEntity }                            from '@utils/fetchEntity'; 

interface IFormStory {
  isFetching : boolean;
  values     : any;
  submitForm : () => void;
  publisherValues? : Partial<IPublisherModel>;
}

const STATUSES = [
  { label: 'Draft', value: 0 },
  { label: 'Active',   value: 1 },
];

export const FormStory = ({ isFetching, values, setFieldValue, submitForm, publisherValues }: FormikProps<any> & IFormStory) => {
  const [businessBranchesOptions, setBusinessBranchesOptions] = useState<IOptionType[]>([]);

  const mapSearchResponseBusiness    = useCallback((data) => {
    return data.map((d: any) => ({ value: d.businessId, label: d.name }));
  }, []);

  const mapSearchResponseChannels    = useCallback((data) => {
    return data.map((d: any) => ({ value: d.channelId, label: d.name }));
  }, []);

  const mapBusinessBranchesSearchResponse = useCallback((data) => {
    return data.map((d: any) => ({ value: d.businessLocationId, label: d.name }));
  }, []);

  const fetchBusinessBranches = useCallback(async (businessId) => {
    callApi(`feature/businesslocation/${businessId.value}`).then((response: AxiosResponse) => {
      setBusinessBranchesOptions(mapBusinessBranchesSearchResponse(response.data));
    });
  }, []);

  const [story] = useFetchEntity('feature/Story') as [IStoryModel | null, boolean, () => Promise<void>];

  const [mapSearchResponsePublisher, onAddPublisher] = usePubliherHandlers(setFieldValue);

  useEffect(() => {
    if (values.businessName) {
      fetchBusinessBranches(values.businessName);
    } else {
      setFieldValue('businessLocationId', null);
      setBusinessBranchesOptions([]);
    }
  }, [values.businessName]);

  useEffect(() => {
    if (businessBranchesOptions.length === 1) {
      setFieldValue('businessLocationId', businessBranchesOptions[0].value);
    }
  }, [businessBranchesOptions]);

  return (
    <Card>
      <Title name={`${story ? 'edit the' : 'add a'} story`} />
      <FormStory.Wrapper noValidate>
        <FormStory.Column>
          <Field
            label       = "title"
            name        = "title"
            placeholder = "Enter a title"
            component   = {Input}
          />
          <Field
            label       = "author"
            name        = "author"
            placeholder = "Author's full name"
            component   = {Input}
          />
          <Field
            required
            label           = "publisher"
            name            = "publisher"
            placeholder     = "Search for a Publisher ..."
            endpoint        = "feature/publisher/search?name"
            responseMapper  = {mapSearchResponsePublisher}
            component       = {AutoSuggest}
            onClickNotFound = {() => onAddPublisher(publisherValues)}
          />
          <Field
            required
            label     = "status"
            name      = "status"
            component = {Select}
            options   = {STATUSES}
          />
          <Field
            required
            isWithoutFormatting
            label     = "starts (UTC)"
            name      = "startDateUtc"
            component = {DatePicker}
          />
          <Field
            isWithoutFormatting
            required  = {false}
            label     = "ends (UTC)"
            name      = "endDateUtc"
            component = {DatePicker}
          />
          <Field
            required    = {false}
            label       = "tags"
            name        = "tags"
            placeholder = "#tag #tag"
            component   = {Input}
          />
          <Field
            required        = {false}
            label          = "add to channel"
            name           = "rStoryChannel"
            placeholder    = "Assign Channel(s)"
            endpoint       = "feature/channel/search?name"
            responseMapper = {mapSearchResponseChannels}
            component      = {MultipleAutoSuggest}
          />
          <Field
            component = {AreaSelection}
            required  = {false}
            name      = "rStoryTerritory"
          />
        </FormStory.Column>

        <FormStory.Column rightSide>
          <Field
            imageAvailable
            label       = "description"
            name        = "description"
            placeholder = "Enter a description"
            component   = {Wysiwyg}
          />
          <Field
            required
            name            = "rStoryImage"
            label           = "preview image"
            container       = "stories"
            component       = {MediaUploader}
            mimeTypeOptions = "image"
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '210px',
            }}
          />
          
          <Field
            required        = {false}
            label           = "assign business"
            name            = "businessName"
            placeholder     = "Search for a business by name ..."
            endpoint        = "feature/business/search?name"
            fieldName       = "businessId"
            responseMapper  = {mapSearchResponseBusiness}
            component       = {AutoSuggest}
          />
          {!!businessBranchesOptions.length && (
            <Field
              required    = {false}
              label       = "business branch"
              name        = "businessLocationId"
              placeholder = "Select a business branch"
              component   = {Select}
              options     = {businessBranchesOptions}
            />
          )}
          <FormButtons
            disabled      = {{main: isFetching}}
            onClickCancel = {() => history.back()}
            onSubmit      = {submitForm}
          />
        </FormStory.Column>
      </FormStory.Wrapper>
    </Card>
  );
};

FormStory.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

FormStory.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
