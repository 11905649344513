import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { ITableDataRules } from './FiltrationSorting';

export const SortingInfo = ({ sorting }: { sorting: ITableDataRules['sorting'] }) => {
  const { t } = useTranslation();

  if (!sorting) { return null; }

  return (
    <SortingInfo.Wrapper>
      <p>{t('sortedBy')} &apos;{t(sorting.label).toLowerCase()}&apos;  {t(sorting.order === 'Asc' ? 'inAscendingOrder' : 'inDescendingOrder').toLowerCase()}</p>
    </SortingInfo.Wrapper>
  );
};

SortingInfo.Wrapper = styled.div`
  > p { font-size: 12px; }
`;
