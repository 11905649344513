import { useCallback, useEffect, useMemo, useState } from 'react';

import { browserHistory } from '@src/store';

import { callApi } from './apiCaller';

export const useFetchEntity = <T = any>(route: string): [T | null, boolean, () => Promise<void>] => {
  const arrayPath = browserHistory.location.pathname.split('/');

  const [isFetching, setFetchingStatus]   = useState<boolean>(true);
  const [fetchedEntity, setFetchedEntity] = useState<T | null>(null);

  const entityId = useMemo(() => {
    if (arrayPath[arrayPath.length - 1] === 'add') return null;

    return arrayPath[arrayPath.length - 1];
  }, [arrayPath]);

  const fetchEntity = useCallback(async () => {
    try {
      if (!entityId) {
        setFetchingStatus(false);
        setFetchedEntity(null);
      } else {
        setFetchingStatus(true);
        const res = await callApi(`${route}/${entityId}`);
        setFetchedEntity(res.data);
        setFetchingStatus(false);
      }
    } catch (error) {
      console.error(error, 'useFetchEntity');
    }
  }, [entityId]);

  useEffect(() => {
    fetchEntity();
  }, [fetchEntity]);

  return [fetchedEntity, isFetching, fetchEntity];
};
