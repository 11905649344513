import React, { useCallback } from 'react';
import styled                 from 'styled-components';
import { useTranslation }     from 'react-i18next';

import { Button } from '@common/components';
import { Modal }  from '@src/App';

const Container = styled.div<{ ref: any }>`
  width            : 500px;
  background-color : #fff;
  padding          : 8px 30px 20px 20px;
`;

const Title = styled.h1`
  text-align     : center;
  text-transform : uppercase;
  font-size      : 20px;
`;

const Text = styled.p`
  text-align : center;
  margin-top : 10px;
`;

const ButtonsWrapper = styled.div`
  display         : flex;
  justify-content : center;
  margin-top      : 20px;

  > div {
    background-color : ${({ theme }) => theme.color.lightGray};
    width            : 1px;
  }
`;

export const ConfirmRecurringModal = React.forwardRef(({
  onClick,
  name,
}: any, ref) => {
  const { t } = useTranslation();

  return (
    <Container ref={ref} tabIndex={1}>
      <Title>{`Updating a repeating ${name}`}</Title>
      <hr />
      <Text>
        {`You’re updating a recurring ${name}. Do you want to update only this occurrence of the ${name}, or all occurrences?`}
      </Text>
      <ButtonsWrapper>
        <Button
          bordered = {false}
          label    = {t('allOccurrences')}
          onClick  = {() => onClick(true)}
        />
        <div />
        <Button
          bordered = {false}
          label    = {t('onlyThisOccurrence')}
          onClick  = {() => onClick(false)}
        />
      </ButtonsWrapper>
    </Container>
  );
});

export function useConfirmRecurringModalHandlers(name: string) {
  const onOptionClick = useCallback((resolve, isAll) => {
    resolve(isAll);
    Modal.close();
  }, []);

  const openConfirmRecurringModal = useCallback<() => Promise<boolean>>(async () => {
    let resolveInner: (value: boolean | PromiseLike<boolean>) => void;
    const promise = new Promise<boolean>((resolve) => resolveInner = resolve);

    Modal.open(ConfirmRecurringModal, { name, onClick: (isAll: boolean) => onOptionClick(resolveInner, isAll) });

    return promise;
  }, []);

  return [openConfirmRecurringModal];
}
