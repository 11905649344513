import { createGlobalStyle } from 'styled-components';
import reset                 from 'styled-reset';

import { theme as Theme } from './theme';

const BaseStyles = createGlobalStyle`
  ${reset.toString()}

  html, body, #root {
    width            : 100%;
    height           : 100%;
    min-height       : 100%;
    background-color : #fff;
    font-family      : Arial, Helvetica, sans-serif;
  }

  button,
  input,
  h1,
  h2,
  h3,
  h4,
  p { font-family : Arial, Helvetica, sans-serif; }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition-delay: 99999s;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance : none;
    margin             : 0;
  }

  a {
    color           : blue;
    text-decoration : none;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  div,
  textarea {
    ::-webkit-scrollbar { width: 4px; }

    ::-webkit-scrollbar-track { background: transparent; }

    ::-webkit-scrollbar-thumb {
      background-color : ${Theme.color.gray};
      border-radius    : 5px;
    }
  }

  h2 { font-weight: 700; } // bold
  h3 { font-weight: 600; } // semi-bold
  h4 { font-weight: 500; } // medium
  p { font-weight: 400; } // regular

  .clearfix:after {
     content    : " "; /* Older browser do not support empty content */
     visibility : hidden;
     display    : block;
     height     : 0;
     clear      : both;
  }
`;

export { BaseStyles };
