import axios, { AxiosRequestConfig } from 'axios';

import { TApiCaller }   from '../models';
import { getTokenData } from '../utils/tokenHandlers';

type TTApiCallerInitializer = (
  envVariables: {
    apiUrl            : string;
    identityServerUrl : string;
  },
) => TApiCaller;

axios.interceptors.request.use((config) => {
  const { tokenType, accessToken } = getTokenData();

  if (tokenType && accessToken) {
    return ({ ...config, headers: { ...config.headers, Authorization: `${tokenType} ${accessToken}` } });
  }

  return config;
});

const defaultOptions = {
  isJSON     : true,
  isFormData : false,
  isIdentity : false,
};

// eslint-disable-next-line arrow-body-style
export const initializeApiCaller: TTApiCallerInitializer = (envVariables) => {
  return (endpoint, method = 'get', data, _options) => {
    const options                            = { ...defaultOptions, ..._options };
    const headers: { [key: string]: string } = {};

    if (options.isJSON) {
      headers['Content-Type'] = 'application/json';
    }

    if (options.isFormData) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    const requestData: AxiosRequestConfig = {
      headers,
      method,
    };

    if (method === 'get') {
      requestData.params = data;
    } else if (data) {
      if (options.isFormData) {
        requestData.data   = data.formData;
        requestData.params = data.params;
      } else {
        requestData.data = data;
      }
    }

    if (options.isIdentity) {
      return axios({ url: `${envVariables.identityServerUrl}/${endpoint}`, ...requestData });
    }

    return axios({ url: `${envVariables.apiUrl}/${endpoint}`, ...requestData });
  };
};
