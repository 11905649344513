import * as Yup from 'yup';

import { COLOR_TYPE, IColorSelectValue } from '@components/ColorSelect/BaseColorSelect';

export const splitColor = (color: string): IColorSelectValue => {
  const initialValues = {
    type  : COLOR_TYPE.HEX,
    color : '',
  };

  if (color.startsWith('#')) {
    return { color, type: COLOR_TYPE.HEX };
  }
  if (color.startsWith('rgb(')) {
    const match = color.match(/\(([^)]+)\)/);

    if (match) {
      return { color, type: COLOR_TYPE.RGB };
    }
  }
  if (color.startsWith('rgba(')) {
    const match = color.match(/\(([^)]+)\)/);

    if (match) {
      return { color, type: COLOR_TYPE.RGBA };
    }
  }

  return initialValues;
};

const REG_EXP_HEX  = /^#?([(a-f)|(A-F)\d]{3}|[(a-f)|(A-F)\d]{6})$/;
const REG_EXP_RGB  = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
const REG_EXP_RGBA = /rgba\((\d{1,3}), (\d{1,3}), (\d{1,3}), (0|0\.\d|1)\)/;

export const validateColor = () => Yup
  .object()
  .test(
    'is-color-valid',
    'Color is not valid',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (value: IColorSelectValue) => {
      if (value.type === COLOR_TYPE.HEX) {
        return !!value.color.match(REG_EXP_HEX);
      }
      if (value.type === COLOR_TYPE.RGB) {
        return !!value.color.match(REG_EXP_RGB);
      }
      if (value.type === COLOR_TYPE.RGBA) {
        return !!value.color.match(REG_EXP_RGBA);
      }

      return false;
    },
  )
  .required();
