import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { ITableMeta } from '@components/Table/ClickableItem';

import { IEventModel, IOptionType } from '@src/models';

import { IState } from '@store/rootReducer';

export const STATE_KEY = 'events';

export interface IEventsReducer {
  events : IEventModel[];
  meta   : Required<ITableMeta> & {
    isFetching   : boolean;
    totalEvents  : number;
    name         : string;
    businessName : string;
    dateFrom     : string;
    dateTo       : string;
    tags         : string[];
    territory    : IOptionType | null;
  };
}

export enum EventTypes {
  fetchEvents              = 'fetchEvents',
  fetchEventsSuccessfully  = 'fetchEventsSuccessfully',
  fetchEventsFailure       = 'fetchEventsFailure',
  addEvent                 = 'addEvent',
  addEventSuccessfully     = 'addEventSuccessfully',
  addEventFailure          = 'addEventFailure',
  editEvent                = 'editEvent',
  editEventSuccessfully    = 'editEventSuccessfully',
  editEventFailure         = 'editEventFailure',
  deleteEvents             = 'deleteEvents',
  deleteEventsSuccessfully = 'deleteEventsSuccessfully',
  deleteEventsFailure      = 'deleteEventsFailure',
}

export interface IEventsActions {
  [EventTypes.fetchEvents]              : Action<{ meta: ITableMeta }>;
  [EventTypes.fetchEventsSuccessfully]  : Action<{ events: IEventModel[]; meta: { totalEvents: number } }>;
  [EventTypes.fetchEventsFailure]       : Action<{}>;
  [EventTypes.addEvent]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [EventTypes.addEventSuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [EventTypes.addEventFailure]          : Action<{ meta: { isFetching: false }}>;
  [EventTypes.editEvent]                : Action<{ attributes: { values: { [f: string]: any }; withRecurring: boolean }; meta: { isFetching: true }}>;
  [EventTypes.editEventSuccessfully]    : Action<{ meta: { isFetching: false }}>;
  [EventTypes.editEventFailure]         : Action<{ meta: { isFetching: false }}>;
  [EventTypes.deleteEvents]             : Action<{ attributes: { eventIds: number[]; withRecurring?: boolean }; meta: { isFetching: true }}>;
  [EventTypes.deleteEventsSuccessfully] : Action<{}>;
  [EventTypes.deleteEventsFailure]      : Action<{}>;
}

export interface IEventsActionsCreators {
  [EventTypes.fetchEvents]             : (eventMeta?: Partial<IEventsReducer['meta']>) => IEventsActions[EventTypes.fetchEvents];
  [EventTypes.fetchEventsSuccessfully] : (events: IEventModel[], totalCount: number) => IEventsActions[EventTypes.fetchEventsSuccessfully];
  [EventTypes.fetchEventsFailure]      : () => IEventsActions[EventTypes.fetchEventsFailure];
  [EventTypes.addEvent]                : (values: any) => IEventsActions[EventTypes.addEvent];
  [EventTypes.addEventSuccessfully]    : () => IEventsActions[EventTypes.addEventSuccessfully];
  [EventTypes.addEventFailure]         : () => IEventsActions[EventTypes.addEventFailure];
  [EventTypes.editEvent]               : (values: any, withRecurring: boolean) => IEventsActions[EventTypes.editEvent];
  [EventTypes.editEventSuccessfully]   : () => IEventsActions[EventTypes.editEventSuccessfully];
  [EventTypes.editEventFailure]        : () => IEventsActions[EventTypes.editEventFailure];
  [EventTypes.deleteEvents]             : (eventIds: number[], withRecurring?: boolean) => IEventsActions[EventTypes.deleteEvents];
  [EventTypes.deleteEventsSuccessfully] : () => IEventsActions[EventTypes.deleteEventsSuccessfully];
  [EventTypes.deleteEventsFailure]      : () => IEventsActions[EventTypes.deleteEventsFailure];
}

type Actions = IEventsActions[EventTypes.fetchEventsSuccessfully]
| IEventsActions[EventTypes.fetchEventsFailure]
| IEventsActions[EventTypes.addEvent]
| IEventsActions[EventTypes.addEventSuccessfully]
| IEventsActions[EventTypes.addEventFailure]
| IEventsActions[EventTypes.editEventSuccessfully]
| IEventsActions[EventTypes.editEventFailure]
| IEventsActions[EventTypes.deleteEvents]
| IEventsActions[EventTypes.deleteEventsSuccessfully]
| IEventsActions[EventTypes.deleteEventsFailure];

const initialState: IEventsReducer = {
  events : [],
  meta   : {
    isFetching   : false,
    totalEvents  : 0,
    page         : 0,
    rowsPerPage  : 50,
    orderBy      : 'DateStart',
    order        : 'Asc',
    name         : '',
    businessName : '',
    dateFrom     : '',
    dateTo       : '',
    tags         : [],
    territory    : null,
  },
};

export const {
  fetchEvents,
  fetchEventsSuccessfully,
  fetchEventsFailure,
  addEvent,
  addEventSuccessfully,
  addEventFailure,
  editEvent,
  editEventSuccessfully,
  editEventFailure,
  deleteEvents,
  deleteEventsSuccessfully,
  deleteEventsFailure,
}: IEventsActionsCreators = createActions(
  {
    FETCH_EVENTS              : (
      eventMeta = {},
    ) => ({ meta: { ...eventMeta, isFetching: true } }),
    FETCH_EVENTS_SUCCESSFULLY : (events, totalEvents) => ({ events, meta: { totalEvents, isFetching: false } }),
    FETCH_EVENTS_FAILURE      : () => ({ meta: { isFetching: false } }),

    ADD_EVENT              : (values) => ({ attributes: values, meta: { isFetching: true }}),
    ADD_EVENT_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    ADD_EVENT_FAILURE      : () => ({ meta: { isFetching: false }}),

    EDIT_EVENT              : (values, withRecurring) => ({ attributes: { values, withRecurring  }, meta: { isFetching: true }}),
    EDIT_EVENT_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    EDIT_EVENT_FAILURE      : () => ({ meta: { isFetching: false }}),

    DELETE_EVENTS              : (eventIds, withRecurring) => ({ attributes: { eventIds, withRecurring }, meta: { isFetching: true }}),
    DELETE_EVENTS_SUCCESSFULLY : () => ({}),
    DELETE_EVENTS_FAILURE      : () => ({}),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const eventsReducer = handleActions<IEventsReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchEvents,
      fetchEventsSuccessfully,
      addEvent,
      addEventSuccessfully,
      addEventFailure,
      editEvent,
      editEventSuccessfully,
      editEventFailure,
      deleteEvents,
      deleteEventsSuccessfully,
      deleteEventsFailure,
    )]: (
      state  : IEventsReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchEvents.toString()): {
          const actionTyped = action as IEventsActions['fetchEvents'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, events: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchEventsSuccessfully.toString()): {
          const actionTyped = action as IEventsActions['fetchEventsSuccessfully'];

          return ({ ...state, events: actionTyped.payload.events, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default eventsReducer;

interface IEventsSelectors {
  getEvents : (state: IState) => IEventModel[];
  getMeta   : (state: IState) => IEventsReducer['meta'];
}

export const getEvents: IEventsSelectors['getEvents'] = (state: IState) => state[STATE_KEY].events;
export const getMeta: IEventsSelectors['getMeta']     = (state: IState) => state[STATE_KEY].meta;
