import styled from 'styled-components';

import { theme } from '@src/styles';

interface ITitle {
  name  : string;
  type? : string;
}

export const Title = ({ name, type = 'primary' }: ITitle) =>  (
  <Title.Name type={type}>
    {type === 'primary' ? name.toUpperCase() : name}
  </Title.Name>
);

Title.Name = styled.h1<{ type: string, theme: any }>`
  font-weight : bold;
  font-size   : 20px;
  font-family : HelveticaNeue-CondensedBold;
  color       : ${theme.textColor.orange};
  ${({ type }) => type === 'secondary' && `
    font-size : 18px;
  `}
`;
