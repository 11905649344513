import { Form, Field, FormikProps }   from 'formik';
import { useCallback, useRef }        from 'react';
import styled                         from 'styled-components';

import { BarcodeOutlined } from '@ant-design/icons';

import { 
  Card,
  Input, 
  Button,
  Label,
  FormButtons,
} from '@common/components';
import { CircularLoader }     from '@components/Loaders/CircularLoader';
import { FormList, IElement } from '@components/List/FormList';
import { Title }              from '@components/Title/Title';

import { Modal }                             from '@src/App';
import { IBusinessLocation, IBusinessModel } from '@src/models';
import { useFetchEntity }                    from '@utils/fetchEntity';

import { PromoCodes }                 from './PromoCodes';
import { IBusinessesActionsCreators } from '../businessesReducer';
import { AddBranchContainer }         from '../pages/AddBranchContainer';

interface IFormBusiness {
  addBranch        : IBusinessesActionsCreators['addBranch'];
  branchesData     : IElement[];
  deleteBranch     : IBusinessesActionsCreators['deleteBranch'];
  editBranch       : IBusinessesActionsCreators['editBranch'];
  isBranchFetching : boolean;
  isEditMode       : boolean;
  isFetching       : boolean;
}

export const FormBusiness = ({
  isFetching,
  branchesData,
  addBranch,
  editBranch,
  deleteBranch,
  isEditMode,
  values,
  isBranchFetching,
  submitForm,
}: FormikProps<IBusinessModel> & IFormBusiness) => {
  const modalDataRef = useRef<IBusinessLocation | null>(null);

  const onClickAddBranch = useCallback(() => {
    Modal.open(AddBranchContainer, {
      initialValues : { businessId: values.businessId },
      onAdd         : addBranch,
    });
  }, [values]);

  const onEditBranch = useCallback((el: IElement) => {
    modalDataRef.current = el.source as IBusinessLocation;

    Modal.open(AddBranchContainer, {
      initialValues : el.source,
      onAdd         : editBranch,
      onDelete      : deleteBranch,
    });
  }, [values]);

  const onClickPromoCodes = useCallback((el: IElement) => {
    Modal.open(PromoCodes, {
      initialValues: {
        businessLocationId: (el.source as IBusinessLocation).businessLocationId,
      },
    });
  }, [values]);

  const [business] = useFetchEntity('feature/Business') as [IBusinessModel | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${business ? 'edit the' : 'add a'} business`} />
      <FormBusiness.Wrapper noValidate>
        <Field
          label       = "name"
          name        = "name"
          placeholder = "Enter a name"
          component   = {Input}
        />

        <FormBusiness.Column>
          {values.userIsBlocked && (
            <FormBusiness.StatusWarning>* This business is blocked!</FormBusiness.StatusWarning>
          )}
          {isEditMode && (
            <>
              <FormBusiness.LabelBusinessWrapper>
                <Label label = "Branches" />
                <FormBusiness.ButtonWrapper>
                  <Button
                    label   = "Add Branch"
                    type    = "button"
                    onClick = {onClickAddBranch}
                  />
                </FormBusiness.ButtonWrapper>
              </FormBusiness.LabelBusinessWrapper>
              {isBranchFetching ? (
                <CircularLoader padding="30px 0 0 160px" />
              ) : (
                <FormList
                  data               = {branchesData}
                  onEdit             = {onEditBranch}
                  PrimaryIcon        = {BarcodeOutlined}
                  onPrimaryIconClick = {onClickPromoCodes}
                />
              )}
            </>
          )}
        </FormBusiness.Column>

        <FormButtons
          disabled      = {{main: isFetching || isBranchFetching}}
          onClickCancel = {() => history.back()}
          onSubmit      = {submitForm}
        />
      </FormBusiness.Wrapper>
    </Card>
  );
};

FormBusiness.StatusWarning = styled.p`
  color     : red;
  font-size : 14px;
`;

FormBusiness.LabelBusinessWrapper = styled.div`
  margin-top      : 16px;
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
`;

FormBusiness.ButtonWrapper = styled.div`
  @media (min-width: 1284px) {
    position : relative;
    left     : 490px;
  }
`;

FormBusiness.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

FormBusiness.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
