import { Tooltip as TooltipAnt } from 'antd';
import React                     from 'react';
import styled                    from 'styled-components';

import { setPopupContainer } from '@common/utils';

interface ITooltip {
  children : React.ReactNode;
  title?   : string;
}

export const Tooltip = ({ children, title }: ITooltip) => {
  return (
    <Tooltip.Window>
      <TooltipAnt
        getPopupContainer = {setPopupContainer}
        title             = {title}
        visible           = {title ? undefined : false}
      >
        {children}
      </TooltipAnt>
    </Tooltip.Window>
  );
};

Tooltip.Window = styled.div`
  height : 100%;
  width  : 100%;
`;
