import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }       from '@utils/apiCaller';
import { Notifications } from '@components/Notifications/Notifications';

import {
  addAdvertising,
  addAdvertisingFailure,
  addAdvertisingSuccessfully,

  deleteAdvertising,
  deleteAdvertisingFailure,
  deleteAdvertisingSuccessfully,

  fetchAdvertising,
  fetchAdvertisingFailure,
  fetchAdvertisingSuccessfully,

  updateAdvertising,
  updateAdvertisingFailure,
  updateAdvertisingSuccessfully,

  getMeta,

  IAdvertisingActions,
  EAdvertisingTypes,
} from './advertisingReducer';

function* handleAddAdvertising(action: IAdvertisingActions[EAdvertisingTypes.addAdvertising]) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/Ad', 'post', values);
    yield put(addAdvertisingSuccessfully());
    yield put(replace('/admin/sponsors'));
    yield put(fetchAdvertising());
    yield call(Notifications.enqueueSnackbar, 'Sponsors was added successfully', 'success');
  } catch (e) {
    yield put(addAdvertisingFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding Sponsor. Please try again', 'error');
  }
}

function* handleDeleteAdvertising(action: IAdvertisingActions[EAdvertisingTypes.deleteAdvertising]) {
  try {
    const { advertisingIds } = action.payload.attributes;

    for (let i = 0; i < advertisingIds.length; i++) {
      yield callApi(`feature/Ad/${advertisingIds[i]}`, 'delete');
    }
    yield put(deleteAdvertisingSuccessfully());
    yield put(fetchAdvertising());
    yield call(Notifications.enqueueSnackbar, `${advertisingIds.length > 1 ? 'Sponsors were' : 'Sponsor was'} deleted successfully`, 'success');

  } catch (e) {
    const { advertisingIds } = action.payload.attributes;

    yield put(deleteAdvertisingFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${advertisingIds.length > 1 ? 'Sponsors' : 'Sponsor'}. Please try again`, 'error');
  }
}

function* handleFetchAdvertising() {
  try {
    const { page, rowsPerPage, orderBy, order } = yield select(getMeta);
    const { data: { items, totalCount } }       = yield callApi(`feature/Ad?page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}`);

    yield put(fetchAdvertisingSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchAdvertisingFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Sponsors. Please try again', 'error');
  }
}

function* handleUpdateAdvertising(action: IAdvertisingActions[EAdvertisingTypes.updateAdvertising]) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/Ad', 'put', values);
    yield put(updateAdvertisingSuccessfully());
    yield put(replace('/admin/sponsors'));
    yield call(Notifications.enqueueSnackbar, 'Sponsor was edited successfully', 'success');
  } catch (e) {
    yield put(updateAdvertisingFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing Sponsor. Please try again', 'error');
  }
}

function* advertisingSagas() {
  yield all([
    takeLatest(fetchAdvertising, handleFetchAdvertising),
    takeLatest(addAdvertising, handleAddAdvertising),
    takeLatest(updateAdvertising, handleUpdateAdvertising),
    takeLatest(deleteAdvertising, handleDeleteAdvertising),
  ]);
}

export { advertisingSagas };
