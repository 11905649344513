import { isBefore, isEqual } from 'date-fns';
import * as Yup              from 'yup';

import { TFunction } from '../models';

type TYupTestArgs = [string, string, (value?: string) => boolean];

export const validatePassword = (t: TFunction) => Yup
  .string()
  .min(8, `${t('minCharactersLength')} 8`)
  .required(t('requiredField'))
  .test(
    'is-password-valid',
    `${t('passwordContentRules')}`,
    (value?: string) => !!value && !!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
  );

export const validatePasswordCopy = (t: TFunction) => Yup
  .string()
  .oneOf([Yup.ref('password')], t('passwordsMustMatch'))
  .required(t('requiredField'));

export const validatePhoneNumber = (t: TFunction, required?: boolean) => {
  if (required) {
    return Yup.string()
      .required(t('requiredField'))
      .test(
        'is-valid-phone-number',
        t('itDoesNotLookLikePhoneNumber'),
        (value?: string) => (value ? value.length >= 8 : false),
      );
  }

  return Yup.string()
    .test(
      'is-valid-phone-number',
      t('itDoesNotLookLikePhoneNumber'),
      (value?: string) => (value ? value.length >= 8 : true),
    );
};

export const validateStringForSpecialSymbolsOnly = (t: TFunction): TYupTestArgs => [
  'is-value-valid',
  t('specialSymbolsOnlyAreForbidden'),
  (value) => (value ? !/^[^a-zA-Z0-9]+$/.test(value) : true),
];

export const validateTheTimeDifference = (t: TFunction, startDateFieldName: string): TYupTestArgs => [
  'start-and-end-equal',
  t('startDateAndEndDateCannotBeEqual'),
  function validate(value) {
    const startDate = (this as { parent: { [key: string]: string | null } }).parent[startDateFieldName];

    if (!value || !startDate) {
      return true;
    }

    return !isEqual(new Date(startDate), new Date(value));
  },
];

export const validateTheCorrectTimeDirection = (t: TFunction, startDateFieldName: string): TYupTestArgs => [
  'end-before-start',
  t('endDateCannotBeEarlierThanStartDate'),
  function validate(value) {
    const startDate = (this as { parent: { [key: string]: string | null } }).parent[startDateFieldName];

    if (!value || !startDate) {
      return true;
    }

    return !isBefore(new Date(value), new Date(startDate));
  },
];
