import { createGlobalStyle } from 'styled-components';
import { reset }             from 'styled-reset';

import './fonts/HelveticaNeueCondensedBold.ttf';
import './fonts/HelveticaNeueCondensedBold.otf';
import './fonts/HelveticaNeueCondensedBold.woff';
import './fonts/HelveticaNeueCondensedBold.woff2';
import './fonts/FuturaPT_Demi.ttf';
import './fonts/FuturaPT_Demi.woff';
import './fonts/FuturaPT_Demi.woff2';
import './fonts/FuturaPT_Medium.ttf';
import './fonts/FuturaPT_Medium.woff';
import './fonts/FuturaPT_Medium.woff2';
import './fonts/RobotoCondensed-Regular.ttf';
import './fonts/RobotoCondensed-Regular.otf';
import './fonts/RobotoCondensed-Regular.woff';
import './fonts/RobotoCondensed-Regular.woff2';

const fonts = `
  @font-face {
    font-family: "HelveticaNeue-CondensedBold";
    src:
      url("/fonts/HelveticaNeueCondensedBold.otf") format("opentype"), /* Open Type Font */
      url("/fonts/HelveticaNeueCondensedBold.ttf") format("truetype"), /* Safari, Android, iOS */
      url("/fonts/HelveticaNeueCondensedBold.woff") format("woff"), /* Modern Browsers */
      url("/fonts/HelveticaNeueCondensedBold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPT-Demi";
    src:
      url("/fonts/FuturaPT_Demi.woff2") format("woff2"),
      url("/fonts/FuturaPT_Demi.woff") format("woff"),
      url("/fonts/FuturaPT_Demi.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaPT_Medium";
    src:
      url("/fonts/FuturaPT_Medium.woff2") format("woff2"),
      url("/fonts/FuturaPT_Medium.woff") format("woff"),
      url("/fonts/FuturaPT_Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "RobotoCondensed-Regular";
    src:
      url("/fonts/RobotoCondensed-Regular.otf") format("opentype"), /* Open Type Font */
      url("/fonts/RobotoCondensed-Regular.woff2") format("woff2"),
      url("/fonts/RobotoCondensed-Regular.woff") format("woff"),
      url("/fonts/RobotoCondensed-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
`;

const BaseStyles = createGlobalStyle`
  ${reset}
  ${fonts}

  html, body, #root {
    width            : 100%;
    height           : 100%;
    min-height       : 100%;
    background-color : #fff;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }

  b {
    font-weight: 500;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition-delay: 99999s;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: blue;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  h1 {
    font-family   : HelveticaNeue-CondensedBold;
    font-size     : 36px;
    margin-bottom : 10px;
  }

  h2 {
    font-size     : 24px;
    font-weight   : 500;
    margin-bottom : 5px;
  }

  h3 {
    font-size   : 18px;
    margin      : 10px 0;
    font-weight : 700;
    cursor      : pointer;
  }

  h4 {
    font-family : HelveticaNeue-CondensedBold;
    font-size   : 14px;
  }

  div,
  textarea {
    ::-webkit-scrollbar {
      height : 4px;
      width  : 4px;
    }

    ::-webkit-scrollbar-thumb {
      background-color : #a1a7c4;
      border-radius    : 5px;
    }
  }

  .clearfix:after {
     content    : " "; /* Older browser do not support empty content */
     visibility : hidden;
     display    : block;
     height     : 0;
     clear      : both;
  }
`;

export { BaseStyles };
