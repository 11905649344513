import { all, takeLatest, put, call } from 'redux-saga/effects';
import { replace }                    from 'connected-react-router';

import { Notifications } from '@components/Notifications/Notifications';
import { callApi }       from '@utils/apiCaller';

import {
  addTag,
  addTagFailure,
  addTagSuccessfully,

  deleteTags,
  deleteTagsFailure,
  deleteTagsSuccessfully,

  fetchTags,
  fetchTagsFailure,
  fetchTagsSuccessfully,

  updateTag,
  updateTagFailure,
  updateTagSuccessfully,

  ETagsTypes,
  ITagsActions,
} from './tagsReducer';

function* handleAddTag(action: ITagsActions[ETagsTypes.addTag]) {
  try {
    const values = action.payload.attributes;

    yield callApi('api/Tags', 'post', values);
    yield put(addTagSuccessfully());
    yield put(replace('/admin/Tags'));
    yield put(fetchTags());
    yield call(Notifications.enqueueSnackbar, 'Tag was added successfully', 'success');
  } catch (e) {
    yield put(addTagFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding Tag. Please try again', 'error');
  }
}

function* handleDeleteTag(action: ITagsActions[ETagsTypes.deleteTags]) {
  const { tagsIds } = action.payload.attributes;

  try {
    for (let i = 0; i < tagsIds.length; i++) {
      yield callApi(`api/Tags/${tagsIds[i]}`, 'delete');
    }
    yield put(deleteTagsSuccessfully());
    yield put(fetchTags());
    yield call(Notifications.enqueueSnackbar, `${tagsIds.length > 1 ? 'Tags were' : 'Tag was'} deleted successfully`, 'success');
  } catch (e) {
    yield put(deleteTagsFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${tagsIds.length > 1 ? 'Tags' : 'Tag'}. Please try again`, 'error');
  }
}

function* handleFetchTags() {
  try {
    const { data } = yield callApi('api/Tags');

    yield put(fetchTagsSuccessfully(data));
  } catch (e) {
    yield put(fetchTagsFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Tags. Please try again', 'error');
  }
}

function* handleUpdateTag(action: ITagsActions[ETagsTypes.updateTag]) {
  try {
    const values = action.payload.attributes;

    yield callApi('api/Tags', 'put', values);
    yield put(updateTagSuccessfully());
    yield put(replace('/admin/tags'));
    yield call(Notifications.enqueueSnackbar, 'Tag was edited successfully', 'success');
  } catch (e) {
    yield put(updateTagFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing Tag. Please try again', 'error');
  }
}

function* tagsSagas() {
  yield all([
    takeLatest(fetchTags, handleFetchTags),
    takeLatest(addTag, handleAddTag),
    takeLatest(updateTag, handleUpdateTag),
    takeLatest(deleteTags, handleDeleteTag),
  ]);
}

export { tagsSagas };
