import { Formik }                                    from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import * as Yup                                      from 'yup';

import { ErrorModal, LoginForm, LoginWrapper }    from './components';
import { TApiCaller, TFunction }                  from '../../models';
import { validatePassword, validatePasswordCopy } from '../../utils';

interface IValues {
  email        : string;
  password     : string;
  passwordCopy : string;
  token        : string;
}

interface PasswordRecoveryProps {
  apiHandler        : TApiCaller;
  email             : string;
  onResetPassword   : () => void;
  resetPasswordLink : string;
  token             : string;
  validateTokenLink : string;
}

const useValidationSchema = (t: TFunction) => Yup.object().shape({
  password     : validatePassword(t),
  passwordCopy : validatePasswordCopy(t),
});

export const PasswordRecovery = ({
  apiHandler,
  email,
  onResetPassword,
  resetPasswordLink,
  token,
  validateTokenLink,
}: PasswordRecoveryProps) => {
  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const [currentModal, setModal]        = useState<'recoveryFailed' | 'tokenExpired' | 'validationFailed' | null>(null);
  const [isFetching, setFetchingStatus] = useState<boolean>(false);
  const [isTokenValid, setTokenStatus]  = useState<boolean>(true);

  const initialValues = useMemo<IValues>(() => ({
    email,
    token,
    password     : '',
    passwordCopy : '',
  }), []);

  const modalComment = useMemo(() => {
    if (currentModal === 'recoveryFailed') {
      return t('requestFailedTryAgain');
    } else if (currentModal) {
      return t('linkIsNotValidTryResetPasswordAgain');
    }

    return '';
  }, [currentModal]);

  const resetPassword = useCallback(async (values: IValues) => {
    setFetchingStatus(true);
    try {
      const { password } = values;

      await apiHandler(resetPasswordLink, 'put', { email, password, token });

      onResetPassword();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setModal('recoveryFailed');
    }
    setFetchingStatus(false);
  }, []);

  const validateUserToken = useCallback(async () => {
    setFetchingStatus(true);
    try {
      const validation = await apiHandler(validateTokenLink, 'post', { email, token });

      if (!validation.data.isValid) {
        setTokenStatus(false);
        setModal('tokenExpired');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setModal('validationFailed');
      setTokenStatus(false);
    }
    setFetchingStatus(false);
  }, []);

  useEffect(() => {
    validateUserToken();
  }, []);

  return (
    <LoginWrapper>
      {currentModal && (
        <ErrorModal
          closeModal = {currentModal !== 'recoveryFailed' ? onResetPassword : () => setModal(null)}
          comment    = {modalComment}
        />
      )}
      <Formik
        initialValues    = {initialValues}
        onSubmit         = {isTokenValid ? resetPassword : () => undefined}
        validateOnChange = {false}
        validationSchema = {validationSchema}
      >
        <LoginForm
          passwordRecovery
          isFetching = {isFetching}
        />
      </Formik>
    </LoginWrapper>
  );
};
