import { AxiosResponse }            from 'axios';
import { useRef }                   from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { TApiCaller } from '../models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TUnknownType = any;

type TFetcherByIdHook = <T>(
  route      : string,
  entityId?  : number,
  options?   : SWRConfiguration,
) => [T | null, boolean]

type TFetcherByIdInitializer = (apiHandler: TApiCaller) => TFetcherByIdHook;

// eslint-disable-next-line arrow-body-style
export const initializeFetchByIdHook: TFetcherByIdInitializer = (apiHandler) => {
  return (route, entityId, options) => {
    const entityData = useRef<TUnknownType | null>(null);

    const { data, isValidating } = useSWR<AxiosResponse<TUnknownType>>(
      entityId ? `${route}/${entityId}` : null,
      apiHandler,
      { revalidateOnFocus: false, ...(options || null) },
    );

    if (data?.data) {
      entityData.current = data.data;
    }

    return [entityData.current, isValidating];
  };
};
