import { useCallback, useEffect, useMemo, useState } from 'react';
import styled                                        from 'styled-components';

import { IAutoSuggestProps }       from './AutoSuggest';
import { FieldError }              from '../FieldError';
import { BaseSelect, IAntOption }  from '../Select';
import { useUpdateComponentValue } from '../../hooks';
import { TOption }                 from '../../models';
import { setPopupContainer }       from '../../utils';
import { TDebounceFetcherHook }    from '../../initializers/debounceFetchHook';

type TValue = number | string;

export interface IMultipleAutoSuggest extends Omit<IAutoSuggestProps, 'onChange'> {
  onChange: (newValues: TOption[]) => void;
}

interface ICommonMultipleAutoSuggest extends IMultipleAutoSuggest {
  debounceFetcher: TDebounceFetcherHook;
}

const filter = (newOptions: TOption[], initial: TOption[]) => {
  const newOptionsValues = newOptions.map((item) => item.value);
  const initialValues = initial.map((item) => item.value);

  const filtered = initialValues.filter((item) => !newOptionsValues.includes(item));

  return [...newOptions, ...initial.filter((item) => filtered.includes(item.value))];
};

const mapToOptionType = (dirtyOptions: IAntOption[]): TOption[] => dirtyOptions.map((item) => ({
  label : (item.children || '').toString(),
  value : item.value,
}));

export const MultipleAutoSuggest = ({
  debounceFetcher,
  endpoint,
  field,
  form,
  onChange,
  responseMapper,
  value,
  wrapperStyle,
  ...componentProps
}: ICommonMultipleAutoSuggest) => {
  const [initialOptions, setInitialOptions] = useState<TOption[]>([]);

  const [options, isFetching, onSearch] = debounceFetcher(endpoint, responseMapper);
  const { updateComponentValue }        = useUpdateComponentValue<TOption[]>(field, form, onChange);

  const internalOptions = useMemo(() => filter(options, field?.value || value), [initialOptions.length, options]);
  const internalValue   = useMemo(() => (field?.value || value).map((item: TOption) => item.value), [field, value]);

  const clearField = useCallback(() => {
    updateComponentValue([]);
  }, [updateComponentValue]);

  const onSelect = useCallback((_: TValue[], newOptions: IAntOption[]) => {
    updateComponentValue(mapToOptionType(newOptions));
  }, [updateComponentValue]);

  useEffect(() => {
    if (!initialOptions.length && (field?.value || value)) {
      setInitialOptions(field?.value || value);
    }
  }, [field, value]);

  return (
    <MultipleAutoSuggest.Wrapper {...wrapperStyle}>
      <BaseSelect
        allowClear
        showSearch
        {...componentProps}
        getPopupContainer = {setPopupContainer}
        filterOption      = {false}
        loading           = {isFetching}
        mode              = "multiple"
        onClear           = {clearField}
        onSearch          = {onSearch}
        onChange          = {(newValue, option) => onSelect(newValue as TValue[], option as IAntOption[])}
        options           = {internalOptions}
        value             = {internalValue}
      />
      <FieldError
        field = {field}
        form  = {form}
      />
    </MultipleAutoSuggest.Wrapper>
  );
};

MultipleAutoSuggest.Wrapper = styled.div<{ margin?: string; }>`
  margin   : ${({ margin, theme }) => margin || theme.field.gap};
  position : relative;

  .ant-select-single .ant-select-selector .ant-select-selection-search { left: 0; }

  .ant-select-selection-search { margin : 0; }

  .ant-select-selection-placeholder { overflow: visible; }

  .ant-select-multiple .ant-select-selection-placeholder {
    bottom : 0;
    left   : 0;
  }
`;
