import { useCallback, useRef } from 'react';
import { useTranslation }      from 'react-i18next';
import styled                  from 'styled-components';

import { Button, Modal }                            from '../index';
import { BaseImageResizer, IBaseImageResizerProps } from './BaseImageResizer';

interface IImageResizerProps extends Omit<IBaseImageResizerProps, 'aspect' | 'file' | 'getResizeFile'> {
  aspectRatio : IBaseImageResizerProps['aspect'];
  image       : IBaseImageResizerProps['file'];
  onClose     : () => void;
  onSubmit    : (image: File) => void;
}

export const ImageResizer = ({
  aspectRatio,
  image,
  onClose,
  onSubmit,
  ...props
}: IImageResizerProps) => {
  const { t } = useTranslation();

  const resizeFunctionsRef = useRef<(() => Promise<File>) | null>(null);

  const onClickSave = useCallback(async () => {
    if (resizeFunctionsRef.current) {
      const resizedFiles = await resizeFunctionsRef.current();
      onSubmit(resizedFiles);
    }
  }, [resizeFunctionsRef, onSubmit]);

  const getResizeFile = useCallback((resizeFile: () => Promise<File>) => {
    resizeFunctionsRef.current = resizeFile;
  }, []);

  return (
    <Modal
      onCancel  = {() => onClose()}
      title     = {t('resizer')}
      width     = "auto"
      bodyStyle = {() => `
        max-height : 600px;
        overflow   : auto;
      `}
    >
      <ImageResizer.Wrapper>
        <BaseImageResizer
          aspect        = {aspectRatio}
          file          = {image}
          getResizeFile = {getResizeFile}
          {...props}
        />
        <Button
          label   = {t('submit')}
          onClick = {onClickSave}
          type    = "button"
        />
      </ImageResizer.Wrapper>
    </Modal>
  );
};

ImageResizer.Wrapper = styled.div`
  align-items    : center;
  display        : flex;
  flex-direction : column;
  gap            : 10px;
  max-width      : 600px;

  > button,
  div { width: fit-content; }
`;
