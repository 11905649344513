import { Popover }   from 'antd';
import { ReactNode } from 'react';
import styled        from 'styled-components';

import { setPopupContainer } from '../../utils';
import { FilterIcon }        from '../../../public/svg';

interface ICardHeader {
  additionalContent? : JSX.Element;
  comment?           : string;
  entityCounter?     : number;
  filter?            : { icon: ReactNode; };
  title              : string;
  popoverOptions?    : {
    content          : JSX.Element,
    onVisibleChange? : (visible: boolean) => void;
    visible?         : boolean;
  };
  buttons? : {
    action : () => void;
    label  : string;
  }[];
}

export const CardHeader = ({
  additionalContent,
  buttons,
  comment,
  entityCounter,
  popoverOptions,
  title,
  filter = { icon: <FilterIcon /> },
}: ICardHeader) => (
  <CardHeader.Wrapper>
    <CardHeader.Container>
      <CardHeader.TitleWrapper>
        <h2>{title}{!!entityCounter && <span>{` (${entityCounter})`}</span>}</h2>
        {comment && <p>{comment}</p>}
      </CardHeader.TitleWrapper>
      <CardHeader.ButtonsWrapper>
        {(buttons || []).map((item) => (
          <CardHeader.Button key={item.label} onClick={item.action}>
            {item.label}
          </CardHeader.Button>
        ))}
        {popoverOptions && (
          <Popover
            getPopupContainer = {setPopupContainer}
            trigger           = {['click']}
            {...popoverOptions}
          >
            <CardHeader.Filter className="card-header-filter-icon-wrapper">
              {filter.icon}
            </CardHeader.Filter>
          </Popover>
        )}
      </CardHeader.ButtonsWrapper>
    </CardHeader.Container>
    {additionalContent}
  </CardHeader.Wrapper>
);

CardHeader.Button = styled.p`
  color       : ${({ theme }) => theme.color.blue};
  cursor      : pointer;
  font-size   : 12px;
  line-height : 20px;
  margin-left : 14px;
  user-select : none;
`;

CardHeader.ButtonsWrapper = styled.div`
  align-items : center;
  display     : flex;
`;

CardHeader.Container = styled.div`
  align-items     : center;
  display         : flex;
  justify-content : space-between;
`;

CardHeader.Filter = styled.div`
  align-items     : center;
  border          : 2px solid ${({ theme }) => theme.color.lightGray};
  border-radius   : 6px;
  cursor          : pointer;
  display         : flex;
  height          : 40px;
  justify-content : center;
  margin-left     : 20px;
  width           : 40px;
`;

CardHeader.TitleWrapper = styled.div`
  flex     : 1;
  position : relative;

  h2 { color: #171725; }

  > h2,
  span {
    font-size   : 18px;
    font-weight : bold;
    line-height : 25px;
  }

  > h2 span { color: ${({ theme }) => theme.color.red}; }

  > p {
    bottom    : -12px;
    color     : ${({ theme }) => theme.color.gray};
    font-size : 12px;
    position  : absolute;
  }
`;

CardHeader.Wrapper = styled.div`
  margin-bottom: 21px;
`;
