import { AxiosResponse }                             from 'axios';
import { FieldProps }                                from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled                                        from 'styled-components';
import * as Yup                                      from 'yup';

import {
  CaretRightOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { TERRITORY_TYPES } from '@modules/territories/pages/AddTerritoryContainerL';
import { ITerritoryModel } from '@src/models';
import { callApi }         from '@utils/apiCaller';
import { debounce }        from '@utils/debounce';

import { Checkbox }      from '@common/components';
import { TOption }       from '@common/models';
import { useFieldError } from '@common/hooks';

import { AutoSuggest } from '../Autosuggest/Autosuggest';
import { Tooltip }     from '../Tooltip/Tooltip';

interface IAreaSelection extends FieldProps<(IAreaSelectionItem & ISelectedArea)[]> {
  required?       : boolean;
  singleCityOnly? : boolean;
}

interface IAreasChild {
  areaId     : number;
  isSelected : boolean;
  parentId   : number;
}

export interface ISelectedArea {
  blockedTerritories : number[];
  territoryId        : number;
  territoryName      : string;
}

export interface IAreaSelectionItem {
  area      : ITerritoryModel;
  children? : ({ isSelected: boolean } & ITerritoryModel)[];
}

export const mapAreasToBannedType = (values: IAreaSelectionItem[], entityData: { [entityId: string]: number | undefined }) => {
  return values.map((item) => ({
    ...entityData,
    blockedTerritories : item.children?.filter((child) => !child.isSelected).map((child) => child.territoryId) || [],
    territoryName      : item.area.name,
    territoryId        : item.area.territoryId,
  }));
};

export const validateSelectedAreas = (min = 1) => {
  return Yup.array()
    .min(min, `You need to add at least ${min > 1 ? `${min} areas` : '1 area'}`)
    .ensure()
    .of(Yup.object().shape({
      area     : Yup.object(),
      children : Yup.array().test(
        'is-nothing-selected',
        'Children empty',
        (value: IAreaSelectionItem['children']) => !value?.every((item) => !item.isSelected),
      ),
    }));
};

export const AreaSelection = ({
  field,
  form,
  singleCityOnly,
  required = true,
}: IAreaSelection) => {
  const { fieldError } = useFieldError({ field, form });

  const [expandedAreas, setExpandedAreas]        = useState<number[]>([]);
  const [isFetching, setFetchingStatus]          = useState<boolean>(false);
  const [isValuesTypeValid, setValuesTypeStatus] = useState<boolean>(false);

  const emptyAreaIds = useMemo<number[]>(() => (
    field.value.filter((item: IAreaSelectionItem) => item.children && !item.children.find((child) => child.isSelected)).map((item: IAreaSelectionItem) => item.area.territoryId)
  ), [field]);

  const availableTypes = useMemo<TERRITORY_TYPES[] | undefined>(() => {
    const commonAreas: TERRITORY_TYPES[] = [TERRITORY_TYPES.CITY, TERRITORY_TYPES.CITY_COLLECTION, TERRITORY_TYPES.DIVIDED_CITY];

    if (singleCityOnly) return commonAreas;

    const areaType: TERRITORY_TYPES | undefined = field.value.find((item: IAreaSelectionItem) => item.children)?.area.type;

    return areaType ? [...commonAreas, areaType] : undefined;
  }, [field, singleCityOnly]);

  const defineChildrenExistence = useCallback((area: ITerritoryModel) => {
    return [TERRITORY_TYPES.COUNTRY, TERRITORY_TYPES.STATE].includes(area.type);
  }, []);

  const deleteArea = useCallback((areaId: number) => {
    form.setFieldValue(field.name, field.value.filter((item: IAreaSelectionItem) => item.area.territoryId !== areaId));
  }, [field, form]);

  const getAreasChildren = useCallback((newValues?: IAreaSelectionItem[]): IAreasChild[] => {
    return (newValues || field.value).reduce((accumulator: IAreasChild[], currentValue: IAreaSelectionItem) => {
      if (!currentValue.children) return accumulator;

      return [...accumulator, ...currentValue.children.map((item) => ({ areaId: item.territoryId, isSelected: item.isSelected, parentId: currentValue.area.territoryId }))];
    }, []);
  }, [field, form]);

  const checkAreaExistingAndUpdateValuesIfNecessary = useCallback((area: ITerritoryModel) => {
    const areasChildren = getAreasChildren();

    if (areasChildren) {
      const existingCity = areasChildren.find((item) => item.areaId === area.territoryId || item.areaId === area.parentId);

      if (existingCity) {
        const setSelectedStatus = () => field.value.map((item: IAreaSelectionItem) => ((item.children && item.area.territoryId === existingCity.parentId)
          ? { ...item, children: item.children.map((child) => (child.territoryId === existingCity.areaId) ? { ...child, isSelected: true } : child) }
          : item));

        const newValues = existingCity.isSelected ? field.value : setSelectedStatus();

        form.setFieldValue(field.name, newValues);
        return true;
      }
    }

    if (field.value.find((item: IAreaSelectionItem) => item.area.territoryId === area.territoryId)) {
      return true;
    }

    return false;
  }, [getAreasChildren, field, form]);

  const findDuplicates = useCallback((updatedFieldValues: IAreaSelectionItem[]) => {
    const areasChildren                   = getAreasChildren(updatedFieldValues);
    const newValues: IAreaSelectionItem[] = updatedFieldValues.reduce((accumulator: IAreaSelectionItem[], currentValue) => {
      if (areasChildren.find((item) => item.areaId === currentValue.area.territoryId || item.areaId === currentValue.area.parentId)) return accumulator;

      return [...accumulator, currentValue];
    }, []);

    form.setFieldValue(field.name, newValues);
  }, [form, getAreasChildren]);

  const mapSearchResponseTerritories = useCallback((data: ITerritoryModel[]) => {
    return data.map((item) => ({ value: item.territoryId, label: item.name, source: item }));
  }, []);

  const onAddArea = useCallback(async (area: TOption<ITerritoryModel>) => {
    if (!area.source || checkAreaExistingAndUpdateValuesIfNecessary(area.source)) return;

    setFetchingStatus(true);
    try {
      const { data }: AxiosResponse<ITerritoryModel> = await callApi(`feature/territory/${area.value}`);

      if (defineChildrenExistence(data)) {
        const { data: { items } }: AxiosResponse<{ items: ITerritoryModel[]; totalCount: number }> = await callApi(`feature/territory?parentId=${data.territoryId}&itemsPerPage=1000`);
        const newValues: IAreaSelectionItem[] = [...field.value, { area: data, children: items.map((child) => ({ ...child, isSelected: true })) }];

        [TERRITORY_TYPES.COUNTRY, TERRITORY_TYPES.STATE].includes(data.type) ? findDuplicates(newValues) : form.setFieldValue(field.name, newValues);
      } else {
        form.setFieldValue(field.name, [...field.value, { area: data }]);
      }
    } catch (e) {
      console.error(e);
    }
    setFetchingStatus(false);
  }, [checkAreaExistingAndUpdateValuesIfNecessary, field, findDuplicates, form]);

  const onCheckAreaChild = useCallback((childId: number, parentId: number) => {
    const newValue = field.value.map((item: IAreaSelectionItem) => (item.area.territoryId === parentId
      ? { ...item, children: item.children?.map((itemChild) => itemChild.territoryId === childId
        ? { ...itemChild, isSelected: !itemChild.isSelected }
        : itemChild) }
      : item));

    form.setFieldValue(field.name, newValue);
  }, [field, form]);

  const onClickExpandIcon = useCallback((territoryId: number) => {
    setExpandedAreas(expandedAreas.includes(territoryId)
      ? expandedAreas.filter((item) => item !== territoryId)
      : [...expandedAreas, territoryId]);
  }, [expandedAreas]);

  const parseInitialValues = useCallback((values: { blockedTerritories: number[]; territoryId: number }[]) => {
    try {
      const validValues = values.map(async (item) => {
        const { data }: AxiosResponse<ITerritoryModel> = await callApi(`feature/territory/${item.territoryId}`);

        if (defineChildrenExistence(data)) {
          const { data: { items } }: AxiosResponse<{ items: ITerritoryModel[]; totalCount: number }> = await callApi(`feature/territory?parentId=${data.territoryId}&itemsPerPage=1000`);
          const children: ({ isSelected: boolean } & ITerritoryModel)[] = items.map((child) => ({ ...child, isSelected: !item.blockedTerritories.includes(child.territoryId) }));

          return { area: data, children };
        } else {
          return { area: data, children: undefined };
        }
      });

      Promise.all(validValues).then((result) => {
        form.setFieldValue(field.name, result);
        setValuesTypeStatus(true);
        setFetchingStatus(false);
      });
    } catch (e) {
      console.error(e);
    }
  }, [form]);

  useEffect(() => {
    debounce(() => {
      if (field.value.length && field.value[0].blockedTerritories) {
        setFetchingStatus(true);
        parseInitialValues(field.value);
      } else {
        setValuesTypeStatus(true);
        setFetchingStatus(false);
      }
    });
  }, [form.initialValues]);

  return (
    <AreaSelection.Wrapper>
      <AreaSelection.Body>
        <AutoSuggest
          disabled       = {singleCityOnly && field.value.length === 1}
          endpoint       = {`feature/territory/search${availableTypes ? `?${availableTypes.map((type) => `type=${type}`).join('&')}&` : '?'}name`}
          errorMessage   = {typeof fieldError === 'string' ? fieldError : undefined}
          label          = "Assigned areas"
          onChange       = {(newValue) => onAddArea(newValue as TOption<ITerritoryModel>)}
          placeholder    = "Start searching area..."
          required       = {required}
          responseMapper = {(data) => mapSearchResponseTerritories(data as ITerritoryModel[])}
        />
        {isFetching || !isValuesTypeValid || !!(field.value.length && !field.value[0].area) ? (
          <AreaSelection.Loader>
            <LoadingOutlined />
          </AreaSelection.Loader>
        ) : (
          <AreaSelection.Content padding={isValuesTypeValid && !!field.value.length ? '10px 0 0' : '0'}>
            {field.value.map((item: IAreaSelectionItem) => (
              <Tooltip key={item.area.territoryId} title={emptyAreaIds.includes(item.area.territoryId) ? 'Select at least 1 area' : undefined}>
                <AreaSelection.Area isError={emptyAreaIds.includes(item.area.territoryId)}>
                  <AreaSelection.AreaName onClick={() => onClickExpandIcon(item.area.territoryId)}>
                    {defineChildrenExistence(item.area) && (
                      <AreaSelection.ExpandIcon isExpand = {expandedAreas.includes(item.area.territoryId)}>
                        <CaretRightOutlined />
                      </AreaSelection.ExpandIcon>
                    )}
                    <p>{item.area.name}{item.children && ` (${item.children.filter((child) => child.isSelected).length}/${item.children.length})`}</p>
                    <AreaSelection.DeleteIconWrapper bgColor={(item.area.type === TERRITORY_TYPES.COUNTRY && '#D98880')
                    || (item.area.type === TERRITORY_TYPES.STATE && '#F5CBA7')
                    || '#A2D9CE'}>
                      <CloseOutlined size={10} style={{fontSize: '10px'}} onClick={() => deleteArea(item.area.territoryId)} />
                    </AreaSelection.DeleteIconWrapper>
                  </AreaSelection.AreaName>
                  {expandedAreas.includes(item.area.territoryId) && item.children && item.children.map((child) => (
                    <AreaSelection.AreaChild key={child.name}>
                      <Checkbox
                        label    = {child.name}
                        value    = {child.isSelected}
                        setValue = {() => onCheckAreaChild(child.territoryId, item.area.territoryId)}
                      />
                    </AreaSelection.AreaChild>
                  ))}
                </AreaSelection.Area>
              </Tooltip>
            ))}
          </AreaSelection.Content>
        )}
      </AreaSelection.Body>
    </AreaSelection.Wrapper>
  );
};

AreaSelection.DeleteIconWrapper = styled.div<{ bgColor: string }>`
  align-items      : center;
  display          : flex;
  height           : 22px;
  justify-content  : center;
  color            : #7e84a3;

  span {
    font-size : 15px;
    padding   : 0 5px;
  }
`;

AreaSelection.Body = styled.div`
  width: 100%;
`;

AreaSelection.Loader = styled.div`
  display         : flex;
  justify-content : center;
  margin          : 10px 0;
`;

AreaSelection.AreaChild = styled.div`
  align-items : center;
  display     : flex;
  height      : 25px;
  padding     : 5px 10px 0 26px;

  &:last-child { margin-bottom: 10px; }
`;

AreaSelection.ExpandIcon = styled.div<{ isExpand: boolean }>`
  align-items     : center;
  display         : flex;
  justify-content : center;
  transform       : rotate(${({ isExpand }) => (isExpand ? '90' : '0')}deg);
  transition      : all 0.3s;

  svg { font-size: 18px; }
`;

AreaSelection.Content = styled.div<{ padding: string }>`
  display   : flex;
  flex-wrap : wrap;
  padding   : ${({ padding }) => padding};

  > div { width: auto; }
`;

AreaSelection.AreaName = styled.div`
  border-radius : 2px;
  display       : flex;
  align-items   : center;
  padding-left  : 5px;
  user-select   : none;
  background-color: #f5f5f5;

  > p {
    cursor      : pointer;
    font-size   : 13px;
    line-height : 13px;
    margin      : 0 auto 0 3px;
    color       : #7e84a3;
  }

  svg { cursor: pointer; }
`;

AreaSelection.Area = styled.div<{ isError: boolean }>`
  border        : 1px solid ${({ isError }) => (isError ? 'red' : 'lightgray')};
  border-radius : 3px;
  margin        : 0 10px 10px 0;
  max-width     : 100%;
  width         : auto;
`;

AreaSelection.Wrapper = styled.div`
  display: flex;
`;
