import { useCallback, useEffect, useMemo } from 'react';
import { connect }                         from 'react-redux';
import { useHistory }                      from 'react-router-dom';
import { useTranslation }                  from 'react-i18next';

import { DeleteOutlined } from '@ant-design/icons';

import { Card, CardHeader }  from '@common/components';
import { Table, TableProps } from '@common/components/Table/Table';
import { ECardStatuses }     from '@common/enums';
import { routesConfig }      from '@components/Breadcrumbs/routesConfig';

import { browserHistory } from '@store/index';
import { IState }         from '@store/rootReducer';
import { ILicenseModel }  from '@src/models';

import { DeleteModal } from '@components/Modal/DeleteModal';
import { Modal }       from '@src/App';

import {
  deleteLicense as deleteLicenseAction,
  fetchLicenses as fetchLicensesAction,

  getLicenses,
  getMeta,

  ILicensesActionsCreators,
  ILicensesReducer,
} from '../licensesReducer';

interface ILicenseAccountsList {
  deleteLicense : ILicensesActionsCreators['deleteLicense'];
  fetchLicenses : ILicensesActionsCreators['fetchLicenses'];
  isFetching    : boolean;
  licenses      : ILicenseModel[];
  licensesMeta  : ILicensesReducer['meta'];
}

const LicensesListC = ({
  deleteLicense,
  fetchLicenses,
  isFetching,
  licenses,
  licensesMeta,
}: ILicenseAccountsList) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const onClickLicense = useCallback((licenses: ILicenseModel) => {
    browserHistory.push(routesConfig.clientsEdit.endpoint.replace(':id', licenses.userId));
  }, []);

  const onClickDelete = useCallback((license: ILicenseModel) => {
    Modal.open(DeleteModal, {
      confirmMessage    : `The client ${license.firstName} ${license.lastName} will be removed`,
      entityNameMessage : `To confirm this change, enter the client's email address (${license.email}) below.`,
      name              : license.email,
      onDeleteConfirm   : () => {
        deleteLicense(license.userId);
        Modal.close();
      },
    });
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchLicenses({ page: page !== licensesMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [licensesMeta.page, licensesMeta.rowsPerPage]);

  const header = useMemo(() => (
    <CardHeader
      buttons = {[{ action: () => history.push(routesConfig.clientsAdd.endpoint), label: t('addClient') }]}
      title   = {t('clients')}
    />
  ), []);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'icon', label: '' },
    { id: 'email', label: t('email') },
    { id: 'fullName', label: t('fullName') },
    { id: 'territories', label: t('areas') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (licenses.map((license) => ({
    icon  : {
      icon    : <DeleteOutlined />,
      onClick : () => onClickDelete(license),
      tag     : 'icon',
    },
    email : {
      onClick : () => onClickLicense(license),
      tag     : 'link',
      title   : license.email || '',
    },
    fullName :  `${license.firstName} ${license.lastName}` || '',
    areas    : license.territories.map((item) => item.territoryName).join(', '),
    id       : license.userId,
  }))), [licenses]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 0.01, minWidth: 30 },
      { flex: 3 },
      { flex: 2 },
      { flex: 3 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : licensesMeta.page + 1,
      total           : licensesMeta.totalLicenses,
      pageSize        : licensesMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [licensesMeta.page, licensesMeta.totalLicenses, licensesMeta.rowsPerPage]);

  useEffect(() => {
    fetchLicenses();
  }, []);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!licensesMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  licenses     : getLicenses(state),
  licensesMeta : getMeta(state),
  isFetching   : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  deleteLicense : deleteLicenseAction,
  fetchLicenses : fetchLicensesAction,
};

export const LicensesList = connect(mapStateToProps, mapDispatchToProps)(LicensesListC);
