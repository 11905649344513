import { createElement, useCallback, useMemo, useState } from 'react';
import { useTranslation }                                from 'react-i18next';
import styled                                            from 'styled-components';

import { IFilterOption } from './FiltrationSorting';
import { FormButtons }   from '../Button';
import { BaseSelect }    from '../Select';
import { TOption }       from '../../models';

export type TSingleFilterValue = string | TOption | null;
export type TFilterValue       = string | TOption | (TOption | string)[];

export interface INewFiltrationRule {
  key   : string;
  label : string;
  value : TFilterValue;
}

interface IFilterFormProps {
  filterOptions : IFilterOption[];
  onChange      : (newRule: INewFiltrationRule) => void;
  onCancel?     : () => void;
}

export const FilterForm = ({ filterOptions, onCancel, onChange }: IFilterFormProps) => {
  const { t } = useTranslation();

  const [rule, setRule] = useState<Omit<INewFiltrationRule, 'value'> & { value: TSingleFilterValue }>({
    key   : filterOptions[0].value,
    label : filterOptions[0].label,
    value : filterOptions[0].initialFilterValue,
  });

  const valueField = useMemo(() => (
    filterOptions.find((option) => option.value === rule.key)?.component
  ), [rule]);

  const onSelectFilter = useCallback((optionValue: string) => {
    const selectedOption = filterOptions.find((option) => option.value === optionValue);

    if (selectedOption) {
      setRule({
        key   : selectedOption.value,
        label : selectedOption.label,
        value : selectedOption.initialFilterValue,
      });
    }
  }, [filterOptions, rule]);

  const onChangeFilterValue = useCallback((value: TSingleFilterValue) => {
    setRule({ ...rule, value });
  }, [rule]);

  const onSubmit = useCallback(() => {
    const multiple = filterOptions.find((option) => option.value === rule.key)?.multiple;

    if (!rule || !rule.value) {
      return;
    }

    onChange({ ...rule, value: multiple  ? [rule.value] : rule.value });
  }, [filterOptions, rule]);

  return (
    <FilterForm.Wrapper>
      <BaseSelect
        disabled = {filterOptions.length === 1}
        label    = {t('filterBy')}
        onChange = {(value) => onSelectFilter(value.toString())}
        options  = {filterOptions}
        required = {false}
        value    = {rule.key}
      />
      {valueField && createElement(valueField, {
        label       : t('withNextValue'),
        onChange    : onChangeFilterValue,
        placeholder : t('filterValue'),
        required    : false,
        value       : rule.value,
      })}
      <FormButtons
        disabled        = {{ cancel: false, main: !rule.value }}
        mainButtonLabel = {t('apply')}
        onClickCancel   = {onCancel}
        onSubmit        = {onSubmit}
      />
    </FilterForm.Wrapper>
  );
};

FilterForm.Wrapper = styled.div`
  display        : flex;
  flex-direction : column;
  gap            : 20px;
  padding        : 10px 0;
  width          : 250px;

  > div:last-child,
  > div:nth-child(2) { margin-top: 0; }
`;
