import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi } from '@utils/apiCaller';
import { propOr }  from '@utils/propOr';
import { store }   from '@store/index';

import { Notifications } from '@components/Notifications/Notifications';
import { Modal }         from '@src/App';
import { Success }       from '@components/Modal/Success';

import { getChildType }    from '@modules/territories/components/FormTerritoryL';
import { TERRITORY_TYPES } from '@modules/territories/pages/AddTerritoryContainerL';

import {
  fetchTerritories,
  fetchTerritoriesSuccessfully,
  fetchTerritoriesFailure,
  addTerritory,
  addTerritorySuccessfully,
  addTerritoryFailure,
  editTerritory,
  editTerritorySuccessfully,
  editTerritoryFailure,
  deleteTerritories,
  deleteTerritoriesSuccessfully,
  deleteTerritoriesFailure,

  getMeta,

  ITerritoriesActions,
} from './territoriesReducerL';

function* handleFetchTerritories() {
  try {
    const { page, parentId, rowsPerPage, orderBy, order } = yield select(getMeta);
    const { data: { items, totalCount } } = yield callApi(`feature/territory?page=${page === 0 ? 0 : page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}${parentId ? `&parentId=${parentId}` : ''}`);

    yield put(fetchTerritoriesSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchTerritoriesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching areas. Please try again', 'error');
  }
}

function* handleAddTerritory(action: ITerritoriesActions['addTerritory']) {
  try {
    const values = action.payload.attributes;

    const { data: { territoryId, name, parentId, parentName, type }} = yield callApi('feature/territory', 'post', values);
    yield put(replace(`/admin/areas/${territoryId}`));
    yield put(addTerritorySuccessfully());
    yield call(Modal.open, Success, {
      primaryText : `${name} was added.`,
      actions     : [
        {
          name    : 'View All Areas',
          onClick : () => {
            store.createdStore.dispatch(replace('/admin/areas'));
            Modal.close();
          },
        },
        {
          name    : [TERRITORY_TYPES.CITY, TERRITORY_TYPES.CITY_COLLECTION].includes(type) ? 'Add a City Channel' : `Add to ${name}`,
          onClick : () => {
            if ([TERRITORY_TYPES.CITY, TERRITORY_TYPES.CITY_COLLECTION].includes(type)) {
              store.createdStore.dispatch(replace('/admin/channels/add', {
                initialValues: {
                  rChannelTerritory: [{ territoryId, name, blockedTerritories: [] }],
                },
              }));
            } else {
              store.createdStore.dispatch(replace('/admin/areas/add', {
                initialValues: {
                  parent : { value: territoryId, label: name },
                  type   : getChildType(type),
                },
              }));
            }
            Modal.close();
          },
        },
        {
          name    : `Add Another ${[type.charAt(0).toUpperCase(), type.slice(1)].join('').replace('_c', ' C')}`,
          onClick : () => {
            store.createdStore.dispatch(replace('/admin/areas/add', {
              initialValues: {
                type,
                parent : {value: parentName, label: parentId},
              },
            }));
            Modal.close();
          },
        },
      ],
    });
    yield call(Notifications.enqueueSnackbar, 'Area was added successfully', 'success');
  } catch (e) {
    yield put(addTerritoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding an areas. Please try again', 'error');
  }
}

function* handleEditTerritory(action: ITerritoriesActions['editTerritory']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/territory', 'put', values);
    yield put(editTerritorySuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Area was edited successfully', 'success');
  } catch (e) {
    yield put(editTerritoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing an area. Please try again', 'error');
  }
}

const REMOVE_CHILDS_ERROR_MESSAGE = 'Before deleting parent area you have to delete all childs';
function* handleDeleteTerritories(action: ITerritoriesActions['deleteTerritories']) {
  try {
    const { territoryIds } = action.payload.attributes;

    for (let i = 0; i < territoryIds.length; i++) {
      yield callApi('feature/territory', 'delete', territoryIds[i]);
    }

    yield put(fetchTerritories());
    yield put(deleteTerritoriesSuccessfully());
    yield call(Notifications.enqueueSnackbar, `${territoryIds.length > 1 ? 'Areas were' : 'Area was'} deleted successfully`, 'success');
  } catch (e) {
    const error: any = e;

    yield put(deleteTerritoriesFailure());
    if (error.isAxiosError && propOr(error.response, ['data', 'errorMessage'], null)) {
      if (REMOVE_CHILDS_ERROR_MESSAGE === error.response.data.errorMessage) {
        const childTerritoryNames = error.response.data.childs
          .map((t: any) => t.name)
          .join(', ');

        yield call(Notifications.enqueueSnackbar, `${error.response.data.errorMessage}: ${childTerritoryNames}`, 'warning');
      } else {
        yield call(Notifications.enqueueSnackbar, error.response.data.errorMessage, 'info');
      }
    } else {
      const { territoryIds } = action.payload.attributes;

      yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${territoryIds.length > 1 ? 'Areas' : 'an Area'}. Please try again`, 'error');
    }
  }
}

function* territoriesSagas() {
  yield all([
    takeLatest(fetchTerritories, handleFetchTerritories),
    takeLatest(addTerritory, handleAddTerritory),
    takeLatest(editTerritory, handleEditTerritory),
    takeLatest(deleteTerritories, handleDeleteTerritories),
  ]);
}

export { territoriesSagas };
