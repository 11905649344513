import { Form, Field, FormikProps } from 'formik';
import { useMemo }                  from 'react';

import { Card, FormButtons, Input, Select } from '@common/components';
import { Title }                            from '@components/Title/Title';

import { ETipType }       from '@models/enums';
import { ITipModel }      from '@src/models';
import { useFetchEntity } from '@src/utils/fetchEntity';

import { TTipFormModel } from '../pages/Tips';

interface ITipFormComponentProps extends FormikProps<TTipFormModel> {
  isFetching : boolean;
}
export const FormTip = ({ isFetching, values }: ITipFormComponentProps) => {
  const tipTypes = useMemo(() => {
    const dirtyTypesArray = Object.keys(ETipType);
    const types           = dirtyTypesArray.slice(dirtyTypesArray.length / 2);
  
    return types.map((type, index) => ({
      label: type,
      value: index,
    }));
  }, []);

  const [tip] = useFetchEntity('api/EssentialTip') as [ITipModel | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${tip ? 'edit the' : 'add a'} tip`} />
      <Form noValidate>
        <Field
          disabled  = {!!values.id}
          label     = "Type"
          name      = "type"
          options   = {tipTypes}
          component = {Select}
        />
        <Field
          isRequired
          multiline
          label       = "Tip"
          name        = "tip"
          placeholder = "Enter a tip"
          component   = {Input}
        />
        <FormButtons
          disabled      = {{main: isFetching}}
          onClickCancel = {() => history.back()}
        />
      </Form>
    </Card>
  );
};
