import { Formik }               from 'formik';
import { useMemo, useCallback } from 'react';
import { connect }              from 'react-redux';
import styled                   from 'styled-components';
import * as Yup                 from 'yup';

import { validateStringForSpecialSymbolsOnly } from '@common/utils';
import { CircularLoader }                      from '@components/Loaders/CircularLoader';

import { ITagModel }      from '@src/models';
import { IState }         from '@store/rootReducer';
import { useFetchEntity } from '@utils/fetchEntity';

import { TagForm } from '../components/TagForm';

import {
  getMeta,
  ITagsActionsCreators,
  addTag as addTagAction,
  updateTag as updateTagAction,
} from '../tagsReducer';

export interface ITagFormModel {
  id?     : number;
  iconId? : number;
  name    : string;
  icon    : null | {
    mimeType? : string,
    imageUri? : string;
    imageId?  : number;
  };
}

interface ITagFormViewProps {
  addTag     : ITagsActionsCreators['addTag'];
  isFetching : boolean;
  updateTag  : ITagsActionsCreators['updateTag'];
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(600, 'Max character count is 600')
    .test(...validateStringForSpecialSymbolsOnly((value) => value))
    .required('It\' a required field'),
  icon : Yup.object()
    .shape({ imageUri: Yup.string().required() })
    .typeError('Select photo')
    .required('It\' a required field'),
});

const TagsC = ({
  addTag,
  isFetching,
  updateTag,
}: ITagFormViewProps) => {
  const [tag, tagFetching] = useFetchEntity('api/Tags') as [ITagModel | null, boolean, () => Promise<void>];

  const initialValues = useMemo<ITagFormModel>(() => ({
    name : '',
    ...tag,
    icon : tag ? { imageUri: tag.icon.imageUri, mimeType: 'image' } : null,
  }), [tag]);

  const onSubmit = useCallback(async (values: ITagFormModel) => {
    const { icon, ...restValues } = values;
    const requestData = {
      ...restValues,
      icon: {
        ...icon,
        imageId: tag?.iconId,
      },
    };
  
    if (tag) {
      updateTag({ ...requestData, iconId: tag.iconId, id: tag.id }); 
    } else {
      addTag(requestData);
    }
  }, [tag]);

  if (tagFetching) return (
    <CircularLoader />
  );

  return (
    <TagsC.Wrapper>
      <Formik
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validateOnBlur   = {false}
        validationSchema = {validationSchema}
      >
        <TagForm isFetching={isFetching} tag={tag} />
      </Formik>
    </TagsC.Wrapper>
  );
};

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addTag    : addTagAction,
  updateTag : updateTagAction,
};

export const Tags = connect(mapStateToProps, mapDispatchToProps)(TagsC);

TagsC.Wrapper = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 948px; }
`;
