import { AxiosResponse }                    from 'axios';
import { useCallback, useEffect, useState } from 'react';
import styled                               from 'styled-components';

import { CloseOutlined } from '@ant-design/icons';

import { ITerritoryModel } from '@src/models';
import { callApi }         from '@utils/apiCaller';

interface ITerritoryFilter {
  onClickClearIcon : (parentTerritoryId: number | null) => void;
  parentId         : number | null;
}

interface ITerritoryFilterItem {
  onClickClearIcon : () => void;
  label            : string;
}

export const TerritoryFilterItem = ({ onClickClearIcon, label }: ITerritoryFilterItem) => {
  return (
    <TerritoryFilterItem.Item>
      <p>{label}</p>
      <CloseOutlined onClick={onClickClearIcon} />
    </TerritoryFilterItem.Item>
  );
};

export const TerritoryFilter = ({ onClickClearIcon, parentId }: ITerritoryFilter) => {
  const [parents, setParents] = useState<{
    country : { name: string; territoryId: number } | null;
    state   : { name: string; territoryId: number } | null;
  }>({ country: null, state: null });

  const fetchParents = useCallback(async (territoryId: number | null) => {
    if (!territoryId) {
      setParents({ country: null, state: null });
      return;
    }

    const { data: areaInStore } = await callApi(`feature/Territory/${territoryId}`) as AxiosResponse<ITerritoryModel>;

    if (!areaInStore.parentId) {
      setParents({ country: { name: areaInStore.name, territoryId: areaInStore.territoryId }, state: null });
    } else {
      setParents({
        country: { name: areaInStore.parentName as string, territoryId: areaInStore.parentId },
        state: { name: areaInStore.name, territoryId: areaInStore.territoryId },
      });
    }
  }, []);

  useEffect(() => {
    fetchParents(parentId);
  }, [parentId]);

  if (!parentId) return null;

  return (
    <TerritoryFilter.Wrapper>
      {parents.country && (
        <TerritoryFilterItem
          label            = {parents.country.name}
          onClickClearIcon = {() => onClickClearIcon(null)}
        />
      )}
      {parents.state && (
        <TerritoryFilterItem
          label            = {parents.state.name}
          onClickClearIcon = {() => onClickClearIcon(parents.country?.territoryId as number)}
        />
      )}
    </TerritoryFilter.Wrapper>
  );
};

TerritoryFilter.Wrapper = styled.div`
  display       : flex;
  position      : relative;
  top           : -15px;
  margin-bottom : 10px;

  > div:not(:first-child) { margin-left: 10px; }
`;

TerritoryFilterItem.Item = styled.div`
  display       : flex;
  align-items   : center;
  font-size     : 13px;
  padding       : 5px 10px;
  background    : #fff;
  border-radius : 3px;
  box-shadow    : 0 0 2px 2px #15223214;
  cursor        : pointer;

  > p { margin-right: 5px; }

  > svg {
    cursor : pointer;
    height : 10px;
    width  : 10px;
  }
`;
