import { isBefore, isEqual } from 'date-fns';
import * as Yup              from 'yup';

type TYupTestArgs = [string, string, (value?: string) => boolean];

type TStartEndDatesEqualValidator = (startDateFieldName: string) => TYupTestArgs;

export const isStartEndDatesEqual: TStartEndDatesEqualValidator = (startDateFieldName) => {
  return [
    'start-and-end-equal',
    'Start date and End date cannot be equal.',
    function (value?: string | null) {
      // @ts-ignore
      const startDate = (this as { parent: { [key: string]: string | null } }).parent[startDateFieldName];

      if (!value || !startDate) {
        return true;
      }

      return !isEqual(new Date(startDate), new Date(value));
    },
  ];
};

type TEndDateBeforeStartDateValidator = (startDateFieldName?: string) => TYupTestArgs;

export const isEndDateBeforeStartDate: TEndDateBeforeStartDateValidator = (startDateFieldName) => {
  return [
    'end-before-start',
    'End date cannot be earlier than Start date.',
    function (value?: string | null) {
      // @ts-ignore
      const startDate = (this as { parent: { [key: string]: string | null } }).parent[startDateFieldName];

      if (!value || !startDate) {
        return true;
      }

      return !isBefore(new Date(value), new Date(startDate));
    },
  ];
};

type TFindForbiddenSymbolsFunc = (customRegExpString?: string) => TYupTestArgs;

export const findForbiddenSymbols: TFindForbiddenSymbolsFunc = (customRegExpString) => {
  const forbiddenByDefault = '<>/{}';
  const forbiddenSymbols   = customRegExpString || forbiddenByDefault;
  const regEx              = new RegExp(`[${forbiddenSymbols}]`);

  return [
    'are-forbidden-symbols-exist',
    `You can not use next symbols: '${forbiddenSymbols.split('').join('\', \'')}'.`,
    (value) => (value ? !regEx.test(value) : true),
  ];
};

export const validatePassword = () => Yup
  .string()
  .min(8, 'Min character count is 8')
  .test(
    'is-password-valid',
    'Password should contain at least one upper case letter, one lower case, one digit, one special character (#?!@$%^&*-), length minimum 8 digits',
    (value?: string) => !!value && !!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
  )
  .required('This field cannot be empty');

export const validatePasswordCopy = () => Yup
  .string()
  .oneOf([Yup.ref('password')], 'Passwords must match')
  .required('This field cannot be empty');
