import { IOptionType } from '@src/models';

export const mapArrayForUrl = (array: (string | number | IOptionType)[], key = '', firstSymbol = '&') => {
  if (!array.length) return '';

  const isArrayOfObjects = (typeof array[0] === 'object' && array[0] !== null);
  const encodedArray     = array.map((item) => `${key}${encodeURIComponent(isArrayOfObjects ? (item as IOptionType).value : item)}`);
  const result           = encodedArray.join('&');

  return `${firstSymbol}${result}`;
};
