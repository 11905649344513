import { routerMiddleware as createRouterMiddleware }  from 'connected-react-router';
import {
  createStore,
  applyMiddleware,
  compose,
  Dispatch,
  Action,
}                                                      from 'redux';
import { persistStore }                                from 'redux-persist';
import { createBrowserHistory }                        from 'history';
import createSagaMiddleware                            from 'redux-saga';

import loggerMiddleware from './middlewares/loggerMiddleware';
import { rootReducer }  from './rootReducer';
import { rootSaga }     from './rootSaga';

const composeEnhancers = (process.env.NODE_ENV === 'development'
  && window
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) /* eslint-disable-line no-underscore-dangle */
    || compose;

export const browserHistory      = createBrowserHistory();
export const routerMiddleware    = createRouterMiddleware(browserHistory);
const sagaMiddleware             = createSagaMiddleware();
const persistedReducer           = rootReducer(browserHistory);

interface IStore {
  dispatch: Dispatch<Action<any>>;
  getState: () => any;
}

interface IConfigureStore {
  createdStore : IStore;
  persistor    : any;
}

function configureStore(initialState?: any): IConfigureStore {
  // configure middlewares
  const middlewares = [routerMiddleware, sagaMiddleware];

  // redux-logger
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(loggerMiddleware);
  }

  // compose enhancers
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  // create store
  const createdStore = createStore(persistedReducer, initialState, enhancer);

  // persist store
  const persistor = persistStore(createdStore);

  // run sagas
  sagaMiddleware.run(rootSaga);

  return { createdStore, persistor };
}

// pass an optional param to rehydrate state on app start
export const store = configureStore();

// export store singleton instance
export default store;
