import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { theme as Theme } from '../../styles';

import { Button } from './Button';

interface FormButtonsProps {
  mainButtonLabel? : string;
  onClickCancel?   : () => void;
  onSubmit?        : () => void;
  wrapperStyle?    : (theme: typeof Theme) => string;
  disabled?        : {
    cancel? : boolean;
    main    : boolean;
  };
}

export const FormButtons = ({
  disabled,
  mainButtonLabel,
  onClickCancel,
  onSubmit,
  wrapperStyle,
}: FormButtonsProps) => {
  const { t } = useTranslation();

  return (
    <FormButtons.Wrapper wrapperStyle={wrapperStyle}>
      {onClickCancel && (
      <Button
        bordered = {false}
        disabled = {disabled?.cancel}
        label    = {t('cancel')}
        onClick  = {onClickCancel}
        type     = "button"
      />
      )}
      <Button
        disabled = {disabled?.main}
        label    = {mainButtonLabel || t('save')}
        onClick  = {onSubmit}
        type     = {onSubmit ? 'button' : 'submit'}
      />
    </FormButtons.Wrapper>
  );
};

FormButtons.Wrapper = styled.div<{ wrapperStyle: FormButtonsProps['wrapperStyle'] }>`
  display         : flex;
  justify-content : flex-end;
  margin-top      : 27px;
  width           : 100%;

  ${({ wrapperStyle, theme }) => wrapperStyle && wrapperStyle(theme)}
`;
