import { format }                                  from 'date-fns';
import { FieldProps }                              from 'formik';
import { useCallback, useRef, useEffect, useMemo } from 'react';
import styled                                      from 'styled-components';
import { RecurrenceEditorComponent }               from '@syncfusion/ej2-react-schedule';

const DAY_INDEX = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

export const RecurrenceEditor = ({ field, form }: FieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rEditorRef = useRef<any>(null);

  const onChange = useCallback((args) => {
    if (args.value === '') {
      form.setFieldValue(field.name, null, false);

      return;
    }

    if (
      form.values.dateStart
        && args.value.indexOf('FREQ=WEEKLY') >= 0
        && args.value.indexOf('BYDAY=') >= 0
        && args.value !== field.value
        && field.value.indexOf('FREQ=WEEKLY') === -1
    ) {
      const elements                       = args.value.split(';');
      const startDate                      = new Date(form.values.dateStart);
      const startDateWithoutTimeZoneOffset = new Date(startDate.valueOf() + startDate.getTimezoneOffset() * 60 * 1000);
      const weekDayIndex                   = startDateWithoutTimeZoneOffset.getDay() - 1;
      const nextElements                   = [elements[0], `BYDAY=${DAY_INDEX[weekDayIndex]}`, ...elements.splice(2)];
      const nextRule                       = nextElements.join(';');

      form.setFieldValue(field.name, nextRule, false);

      setTimeout(() => {
        rEditorRef.current.setRecurrenceRule(nextRule);
      }, 1);
    } else if (args.value.indexOf('FREQ=WEEKLY') >= 0 && args.value.indexOf('BYDAY=') >= 0 && args.value !== field.value) {
      const elements = args.value.split(';');
      const BYDAY    = elements[1];
      const BYDAYs   = BYDAY.split('BYDAY=')[1].split(',');

      if (BYDAYs.length === 1) {
        form.setFieldValue(field.name, args.value, false);

        return;
      }

      const nextDay      = BYDAYs.filter((d: string) => !field.value || !field.value.includes(`BYDAY=${d}`));
      const nextElements = [elements[0], `BYDAY=${nextDay[0]}`, ...elements.splice(2)];
      const nextRule     = nextElements.join(';');

      form.setFieldValue(field.name, nextRule, false);

      setTimeout(() => {
        rEditorRef.current.setRecurrenceRule(nextRule);
      }, 1);
    } else {
      form.setFieldValue(field.name, args.value, false);
    }
  }, [field]);

  const ruleSummary = useMemo(() => {
    let rule = 'Never';
    if (field.value && rEditorRef && rEditorRef.current) {
      try {
        rule = rEditorRef.current.getRuleSummary();
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    const fieldName = field.name.split('.')[0];
    const fromDate = form.values[fieldName].recurrenceDateStart
      ? format(new Date(form.values[fieldName].recurrenceDateStart), 'MMM dd, yyyy')
      : null;

    return `Rule Summary: From ${fromDate || ''} ${rule}`;
  }, [field, form, rEditorRef.current]);

  useEffect(() => {
    if (rEditorRef && rEditorRef.current) {
      setTimeout(() => {
        rEditorRef.current.setRecurrenceRule(field.value);
      }, 1000);
    }
  }, [rEditorRef]);

  return (
    <RecurrenceEditor.Container>
      <RecurrenceEditorComponent
        ref            = {rEditorRef}
        firstDayOfWeek = {1}
        dateFormat     = "MMM dd, yyyy"
        change         = {onChange}
      />
      <RecurrenceEditor.RuleSummary>{ruleSummary}</RecurrenceEditor.RuleSummary>
    </RecurrenceEditor.Container>
  );
};

RecurrenceEditor.Container = styled.div`
  max-width: 400px;

  input::selection {
    background: ${({ theme }) => theme.color.blue} !important;
  }

  .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text {
    color: ${({ theme }) => theme.color.blue} !important;
  }

  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
    background: ${({ theme }) => theme.color.blue} !important;
  }

  .e-list-item.e-active {
    color: ${({ theme }) => theme.color.blue} !important;
  }

  .e-radio:checked + label::before {
    border-color: ${({ theme }) => theme.color.blue} !important;
  }

  .e-radio:checked + label::after {
    background-color: ${({ theme }) => theme.color.blue} !important;
    color: ${({ theme }) => theme.color.blue} !important;
  }

  .e-round.e-lib.e-btn.e-control {
    font-size: 0;
    position: relative;

    ::after {
      align-items     : center;
      display         : flex;
      font-family     : Poppins;
      font-size       : 11px;
      justify-content : center;
      height          : 35px;
      left            : -1px;
      position        : absolute;
      top             : 0;
      text-transform  : capitalize;
      width           : 35px;
    }
  }

  .e-round.e-lib.e-btn.e-control.e-primary,
  .e-round.e-lib.e-btn.e-control.e-active.e-primary {
    background-color: ${({ theme }) => theme.color.blue} !important;
  }

  .e-round:nth-child(2)::after {
    content: "Mon";
  }

  .e-round:nth-child(3)::after {
    content: "Tue";
  }

  .e-round:nth-child(4)::after {
    content: "Wed";
  }

  .e-round:nth-child(5)::after {
    content: "Thu";
  }

  .e-round:nth-child(6)::after {
    content: "Fri";
  }

  .e-round:nth-child(7)::after {
    content: "Sat";
  }

  .e-round:nth-child(8)::after {
    content: "Sun";
  }
`;

RecurrenceEditor.RuleSummary = styled.p`
  font-size: 14px;
`;
