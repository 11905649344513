import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }       from '@utils/apiCaller';
import { Notifications } from '@components/Notifications/Notifications';
import { Modal }         from '@src/App';

import {
  fetchBusinesses,
  fetchBusinessesSuccessfully,
  fetchBusinessesFailure,
  addBusiness,
  addBusinessSuccessfully,
  addBusinessFailure,
  editBusiness,
  editBusinessSuccessfully,
  editBusinessFailure,
  deleteBusinesses,
  deleteBusinessesSuccessfully,
  deleteBusinessesFailure,

  fetchBranches,
  fetchBranchesSuccessfully,
  fetchBranchesFailure,
  addBranch,
  addBranchSuccessfully,
  addBranchFailure,
  editBranch,
  editBranchSuccessfully,
  editBranchFailure,
  deleteBranch,
  deleteBranchSuccessfully,
  deleteBranchFailure,

  getMeta,

  IBusinessesActions,
} from './businessesReducer';

function* handleFetchBusinesses() {
  try {
    const { page, rowsPerPage, orderBy, order } = yield select(getMeta);
    const { data: { items, totalCount } }       = yield callApi(`feature/business?page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}`);

    yield put(fetchBusinessesSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchBusinessesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Businesses. Please try again', 'error');
  }
}

function* handleAddBusiness(action: IBusinessesActions['addBusiness']) {
  try {
    const values = action.payload.attributes;

    const { data } = yield callApi('feature/business', 'post', values);
    yield put(fetchBusinesses());
    yield put(replace(`/admin/businesses/${data.businessId}`, data));
    yield put(addBusinessSuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Business was added successfully', 'success');
  } catch (e) {
    yield put(addBusinessFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Business. Please try again', 'error');
  }
}

function* handleEditBusiness(action: IBusinessesActions['editBusiness']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/business', 'put', values);
    yield put(editBusinessSuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Business was edited successfully', 'success');
  } catch (e) {
    yield put(editBusinessFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Business. Please try again', 'error');
  }
}

function* handleDeleteBusinesses(action: IBusinessesActions['deleteBusinesses']) {
  try {
    const { businessIds } = action.payload.attributes;

    for (let i = 0; i < businessIds.length; i++) {
      yield callApi('feature/business', 'delete', businessIds[i]);
    }

    yield put(fetchBusinesses());
    yield put(deleteBusinessesSuccessfully());
    yield call(Notifications.enqueueSnackbar, `${businessIds.length > 1 ? 'Businesses were' : 'Business was'} deleted successfully`, 'success');
  } catch (e) {
    const { businessIds } = action.payload.attributes;

    yield put(deleteBusinessesFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${businessIds.length > 1 ? 'Businesses' : 'a Business'}. Please try again`, 'error');
  }
}

// handlers for branches

function* handleFetchBranches(action: IBusinessesActions['fetchBranches'] ) {
  try {
    const { businessId } = action.payload.attributes;

    const { data } = yield callApi(`feature/BusinessLocation/${businessId}`);

    yield put(fetchBranchesSuccessfully(data));
  } catch (e) {
    yield put(fetchBranchesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Branches. Please try again', 'error');
  }
}

function* handleAddBranch(action: IBusinessesActions['addBranch']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/BusinessLocation', 'post', values);
    yield put(fetchBranches(values.businessId));
    yield call(Modal.close);
    yield put(addBranchSuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Branch was added successfully', 'success');
  } catch (e) {
    yield put(addBranchFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Branch. Please try again', 'error');
  }
}

function* handleEditBranch(action: IBusinessesActions['editBranch']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/BusinessLocation', 'put', values);
    yield put(editBranchSuccessfully());
    yield put(fetchBranches(values.businessId));
    yield call(Modal.close);
    yield call(Notifications.enqueueSnackbar, 'Branch was edited successfully', 'success');
  } catch (e) {
    yield put(editBranchFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Branch. Please try again', 'error');
  }
}

function* handleDeleteBranch(action: IBusinessesActions['deleteBranch']) {
  try {
    const { businessLocationId, businessId } = action.payload.attributes;

    yield callApi('feature/BusinessLocation', 'delete', businessLocationId);
    yield put(fetchBranches(businessId));
    yield put(deleteBranchSuccessfully());
    yield call(Modal.close);
    yield call(Notifications.enqueueSnackbar, 'Branch was deleted successfully', 'success');
  } catch (e) {
    yield put(deleteBranchFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while deleting a Branch. Please try again', 'error');
  }
}

function* businessesSagas() {
  yield all([
    takeLatest(fetchBusinesses, handleFetchBusinesses),
    takeLatest(addBusiness, handleAddBusiness),
    takeLatest(editBusiness, handleEditBusiness),
    takeLatest(deleteBusinesses, handleDeleteBusinesses),

    takeLatest(fetchBranches, handleFetchBranches),
    takeLatest(addBranch, handleAddBranch),
    takeLatest(editBranch, handleEditBranch),
    takeLatest(deleteBranch, handleDeleteBranch),
  ]);
}

export { businessesSagas };
