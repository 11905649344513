import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';
import styled                                        from 'styled-components';

import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
} from '@common/components/FiltrationSorting';
import { Table, TableProps }         from '@common/components/Table/Table';
import { Card, CardHeader }          from '@common/components/Card';
import { ECardStatuses, ESortOrder } from '@common/enums';
import { routesConfig }              from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }               from '@components/Modal/DeleteModal';

import {IChannelsCategory }   from '@src/models';
import { Modal }              from '@src/App';
import { IState }             from '@store/rootReducer';
import { useFetchQueryRules } from '@hooks/useFetchQueryRules';
import {
  fetchCategories  as fetchCategoriesAction,
  deleteCategories as deleteCategoriesAction,
  getCategories,
  getMeta,
  IChannelsActionsCreators,
  IChannelsReducer,
} from '../channelsReducerL';

interface IChannelsCategories {
  categories       : IChannelsReducer['categories'];
  channelMeta      : IChannelsReducer['meta'];
  deleteCategories : IChannelsActionsCreators['deleteCategories'];
  fetchCategories  : IChannelsActionsCreators['fetchCategories'];
  isFetching       : boolean;
  totalCategories  : number;
}

const sortOptions = [{ label: 'Title', key: 'Name', value: 'Name' }];

const filterOptions: IFilterOption[] = [];

export const ChannelsList = ({
  categories,
  channelMeta,
  deleteCategories,
  fetchCategories,
  totalCategories,
}: IChannelsCategories) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [popoverVisible, setPopoverStatus] = useState(false);
  
  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'channels-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Asc, label: sortOptions[0].label } },
  );

  const onApplyFilters = useCallback((newFilter: any) => {
    if (newFilter.sorting) {
      fetchCategories({ ...channelMeta, orderBy: newFilter.sorting.value, order: newFilter.sorting.order });
    } else {
      fetchCategories({ ...channelMeta });
    }
  }, [channelMeta]);
  
  const header = useMemo(() => (
    <CardHeader
      buttons           = {[{ action: () => history.push(routesConfig.channelsAdd.endpoint), label: t('createChannel') }]}
      title             = {t('channels')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {handlers.deleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const Color = ({ color }: { color: string }) => (
    <Color.Container color={color} />
  );

  Color.Container = styled.div<{ color: string }>`
    height           : 24px;
    width            : 48px;
    background-color : ${({ theme, color }) => color || theme.backgroundColor.mainOrange};
  `;

  const onClickACategory = useCallback((category: IChannelsCategory) => {
    history.push(routesConfig.channelsEdit.endpoint.replace(':id', category.channelCategoryId.toString()));
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchCategories({ page: page !== channelMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [channelMeta.page, channelMeta.rowsPerPage]);

  const deleteCategory = useCallback(async (channelCategoryId: (number | string)[]) => {
    deleteCategories(channelCategoryId as number[]);
  }, []);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title', label: t('title') },
    { id: 'color', label: t('color') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (categories.map((category) => ({
    title : {
      onClick : () => onClickACategory(category),
      tag     : 'link',
      title   : category.name || '',
    },
    color : {
      component : <Color key={3} color={category.color} />,
      tag       : 'component',
    },
    id               : category.channelCategoryId,
    deletingDisabled : category.isCitySpecific,
  }))), [categories]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 5, minWidth: 300 },
      { flex: 3 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : channelMeta.page + 1,
      total           : totalCategories,
      pageSize        : channelMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [channelMeta.page, totalCategories, channelMeta.rowsPerPage]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    setPopoverStatus(false);
    onApplyFilters(rules);
  }, [rules]);

  return (
    <Card
      header = {header}
      status = {channelMeta.isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!channelMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            const categoryForDeleting = categories.find(obj => (obj.channelCategoryId === ids[0]));
            Modal.open(DeleteModal, {
              onDeleteConfirm : () => {
                deleteCategory(ids);
                Modal.close();
              },
              confirmMessage    : (ids.length === 1) ? 'The channel will be removed from all territories' : 'You cannot restore deleted data',
              entityNameMessage : `For removing enter the channel name "${categoryForDeleting?.name}"`,
              name              : (ids.length === 1) ? `${categoryForDeleting?.name}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  categories      : getCategories(state),
  channelMeta     : getMeta(state),
  totalCategories : getMeta(state).totalCategories,
});

const mapDispatchToProps = {
  fetchCategories  : fetchCategoriesAction,
  deleteCategories : deleteCategoriesAction,
};

export const ChannelsCategoriesList = connect(mapStateToProps, mapDispatchToProps)(ChannelsList);
