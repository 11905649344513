import { push }                                      from 'connected-react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';

import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
}                                    from '@common/components/FiltrationSorting';
import { Table, TableProps }         from '@common/components/Table/Table';
import { Card, CardHeader }          from '@common/components/Card';
import { ECardStatuses, ESortOrder } from '@common/enums';
import { routesConfig }              from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }               from '@components/Modal/DeleteModal';

import { ITerritoryModel }    from '@src/models';
import { Modal }              from '@src/App';
import { IState }             from '@store/rootReducer';
import { store }              from '@store/index';
import { useFetchQueryRules } from '@hooks/useFetchQueryRules';

import { TERRITORY_TYPES } from './AddTerritoryContainerL';
import { getChildType }    from '../components/FormTerritoryL';
import { TerritoryFilter } from '../components/TerritoryFilter';

import {
  getMeta,
  getTerritories,
  ITerritoriesActionsCreators,
  ITerritoriesReducer,
  fetchTerritories  as fetchTerritoriesAction,
  deleteTerritories as deleteTerritoriesAction,
} from '../territoriesReducerL';

export interface ITerritoriesListContainer {
  territories       : ITerritoryModel[];
  territoryMeta     : ITerritoriesReducer['meta'];
  totalTerritories  : number;
  fetchTerritories  : ITerritoriesActionsCreators['fetchTerritories'];
  deleteTerritories : ITerritoriesActionsCreators['deleteTerritories'];
  isFetching        : boolean;
}

const sortOptions = [{ label: 'Title', key: 'Name', value: 'Name' }];

const filterOptions: IFilterOption[] = [];

const TerritoriesList = ({
  territories,
  territoryMeta,
  totalTerritories,
  fetchTerritories,
  deleteTerritories,
  isFetching,
}: ITerritoriesListContainer) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [popoverVisible, setPopoverStatus] = useState(false);
  
  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'areas-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Asc, label: sortOptions[0].label } },
  );

  const changeTerritoryParentId  = useCallback((territoryId: number | null) => {
    fetchTerritories({ ...territoryMeta, parentId: territoryId });
  }, []);

  const deleteTerritory = useCallback(async (territoryId: (number | string)[]) => {
    deleteTerritories(territoryId as number[]);
  }, []);

  const onApplyFilters = useCallback((newFilter: any) => {
    if (newFilter.sorting) {
      fetchTerritories({ ...territoryMeta, orderBy: newFilter.sorting.value, order: newFilter.sorting.order });
    } else {
      fetchTerritories({ ...territoryMeta });
    }
  }, [territoryMeta]);
  
  const onClickEditTerritories = useCallback((territory: ITerritoryModel) => {
    history.push(routesConfig.areasEdit.endpoint.replace(':id', territory.territoryId.toString()));
  }, []);

  const onClickActionAdd = useCallback((parentTerritory: ITerritoryModel) => {
    store.createdStore.dispatch(push('/admin/areas/add', {
      initialValues: {
        type   : getChildType(parentTerritory.type),
        parent : {
          parentName : parentTerritory.name || null,
          parentId   : parentTerritory.territoryId || null,
        },
      },
    }));
  }, []);

  const onClickActionFeeds = useCallback((territory: ITerritoryModel) => {
    store.createdStore.dispatch(push('/admin/feeds', {
      meta: { territory: { label: territory.name, value: territory.territoryId } },
    }));
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchTerritories({ page: page !== territoryMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [territoryMeta.page, territoryMeta.rowsPerPage]);
  
  const header = useMemo(() => (
    <CardHeader
      buttons           = {[{ action: () => history.push('/admin/areas/add'), label: t('createArea') }]}
      title             = {t('areas')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {handlers.deleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title',       label: t('title') },
    { id: 'type',        label: t('type') },
    { id: 'addButton',   label: t('')},
    { id: 'editButton',  label: t('')},
    { id: 'feedsButton', label: t('')},
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (territories.map((territory) => ({
    title     : [TERRITORY_TYPES.COUNTRY, TERRITORY_TYPES.STATE].includes(territory.type) ? {
      onClick : () => changeTerritoryParentId(territory.territoryId),
      tag     : 'link',
      title   : territory.name || '',
    } : territory.name || '',
    type      : territory.type || '',
    addButton : [TERRITORY_TYPES.COUNTRY, TERRITORY_TYPES.STATE].includes(territory.type) ? {
      onClick : () => onClickActionAdd(territory),
      tag     : 'link',
      title   : 'add',
    } : ' ',
    editButton : {
      onClick  : () => onClickEditTerritories(territory),
      tag      : 'link',
      title    : 'edit',
    },
    feedsButton : {
      onClick   : () => onClickActionFeeds(territory),
      tag       : 'link',
      title     : 'feeds',
    },
    id : territory.territoryId,
  }))), [territories]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 1.5 },
      { flex: 1 },
      { flex: 0.3 },
      { flex: 0.3 },
      { flex: 0.3 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : territoryMeta.page + 1,
      total           : totalTerritories,
      pageSize        : territoryMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },

  }), [territoryMeta.page, totalTerritories, territoryMeta.rowsPerPage]);

  useEffect(() => {
    fetchTerritories();
  }, []);

  useEffect(() => {
    setPopoverStatus(false);
    onApplyFilters(rules);
  }, [rules]);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!territoryMeta.isFetching && (
        <>
          <TerritoryFilter
            onClickClearIcon = {changeTerritoryParentId}
            parentId         = {territoryMeta.parentId}
          />
          <Table
            content  = {tableContent}
            headers  = {tableHeaders}
            settings = {tableSettings}
            onDelete = {(ids: (string | number)[]) => {
              const territoryForDeleting = territories.find(obj => (obj.territoryId === ids[0]));
              Modal.open(DeleteModal, {
                onDeleteConfirm : () => {
                  deleteTerritory(ids);
                  Modal.close();
                },
                confirmMessage    : (ids.length === 1) ? 'The area will be removed and unlinked from all channels' : 'You cannot restore deleted data',
                entityNameMessage : `For removing enter the area full name "${territoryForDeleting?.name}"`,
                name              : (ids.length === 1) ? `${territoryForDeleting?.name}` : '',
              });
            }}
          />
        </>
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  territories      : getTerritories(state),
  territoryMeta    : getMeta(state),
  totalTerritories : getMeta(state).totalTerritories,
  isFetching       : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  fetchTerritories  : fetchTerritoriesAction,
  deleteTerritories : deleteTerritoriesAction,
};

export const TerritoryList = connect(mapStateToProps, mapDispatchToProps)(TerritoriesList);
