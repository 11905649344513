import { Formik }               from 'formik';
import { useMemo, useCallback } from 'react';
import { connect }              from 'react-redux';
import styled                   from 'styled-components';
import * as Yup                 from 'yup';

import { validateStringForSpecialSymbolsOnly } from '@common/utils';
import { CircularLoader }                      from '@components/Loaders/CircularLoader';

import { ETipType }       from '@models/enums';
import { ITipModel }      from '@src/models';
import { IState }         from '@store/rootReducer';
import { useFetchEntity } from '@utils/fetchEntity';

import { FormTip } from '../components/FormTip';
import {
  addTip as addTipAction,
  updateTip as updateTipAction,
  getMeta,
  ITipsActionsCreators,
} from '../tipsReducer';

export type TTipFormModel = Omit<ITipModel, 'id'> & {
  id?: number;
};

interface ITipFormViewProps {
  addTip     : ITipsActionsCreators['addTip'];
  isFetching : boolean;
  updateTip  : ITipsActionsCreators['updateTip'];
}

const validationSchema = Yup.object().shape({
  tip: Yup.string()
    .max(600, 'Max character count is 600')
    .test(...validateStringForSpecialSymbolsOnly((value) => value))
    .required('It\' a required field'),
});

const TipsC = ({
  addTip,
  isFetching,
  updateTip,
}: ITipFormViewProps) => {
  const [tip, tipFetching] = useFetchEntity('api/EssentialTip') as [ITipModel | null, boolean, () => Promise<void>];

  const initialValues = useMemo<TTipFormModel>(() => ({
    tip  : '',
    type : ETipType.Organization,
    ...tip,
  }), [tip]);

  const onSubmit = useCallback(async (values: TTipFormModel) => {
    if (values.id) {
      updateTip(values);
    } else {
      addTip(values);
    }
  }, []);

  if (tipFetching) return (
    <CircularLoader />
  );

  return (
    <TipsC.Wrapper>
      <Formik
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validateOnBlur   = {false}
        validationSchema = {validationSchema}
      >
        {({ ...props }) => (
          <FormTip
            {...props}
            isFetching = {isFetching}
          />
        )}
      </Formik>
    </TipsC.Wrapper>
  );
};

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addTip    : addTipAction,
  updateTip : updateTipAction,
};

export const Tips = connect(mapStateToProps, mapDispatchToProps)(TipsC);

TipsC.Wrapper = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 948px; }
`;
