import { useCallback, useEffect, useMemo, useState } from 'react';
import styled                                        from 'styled-components';
import { Form, Field }                               from 'formik';

import { callApi }                                   from '@utils/apiCaller';
import { IChannelModel, IForms }                     from '@models/index';

import { CopyButton }          from '@components/CopyButton/CopyButton';
import { MultipleAutoSuggest } from '@components/Autosuggest/MultipleAutoSuggest';
import { MultiMediaUploader }  from '@components/MultiMediaUploader/MultiMediaUploader';
import { MediaUploader }       from '@components/MediaUploader/MediaUploader';
import { Title }               from '@components/Title/Title';
import {
  Card,
  Input,
  Button,
  Label,
  FormButtons,
  Select as CommonSelect,
} from '@common/components';

import { IFormsActionsCreators } from '../formsReducer';

const { PROJECT_URL } = process.env;

interface IFormForms {
  deleteForm? : IFormsActionsCreators['deleteForms'];
  submitForm  : () => void;
  form        : IForms | null;
  values      : any;
  isFetching  : boolean;
}

const LOCALIZATIONS = [
  { label: 'United States', value: 'us' },
  { label: 'United States Éspanol', value: 'usEs' },
  { label: 'United Kingdom', value: 'gb' },
];

const URLS_LOCALES_MAP: { [x: string]: string } = {
  'us'   : 'united-states',
  'usEs' : 'united-states-espanol',
  'gb'   : 'united-kingdom',
};

export const FormForms = ({ deleteForm, submitForm, values, setFieldValue, isFetching, form }: IFormForms & any) => {
  const [confirmDelete, setConfirmDeleteStatus] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions]   = useState(values.categoryId ? [{ label: values.categoryName, value: values.categoryId }] : []);

  const footerLinks = useMemo(() => ({
    mainPage         : `${PROJECT_URL}/category/${encodeURIComponent(values.categoryName || values.name)}/${URLS_LOCALES_MAP[values.localization]}`,
    registrationPage : `${PROJECT_URL}/registration/${encodeURIComponent(values.categoryName || values.name)}/${URLS_LOCALES_MAP[values.localization]}`,
  }), [values]);

  const mapChannelsForChips = useCallback((channels: IChannelModel[]) => {
    return channels.map((item) => ({ value: item.channelId, label: item.name }));
  }, []);

  const fecthAvailableCategories = useCallback(async () => {
    const { data: { items } } = await callApi('settings/categories/ui', 'get', { emptyOnly: true, page: 0, itemsPerPage: 1000 });

    let options = items.map((category: { categoryId: number; name: string }) => ({ label: category.name, value: category.categoryId }));

    if (values.categoryId) {
      options = [{ label: values.categoryName, value: values.categoryId }, ...options];
    }

    options = [{ label: 'None', value: null }, ...options];

    setCategoryOptions(options);
  }, []);

  const onCategorySelect = useCallback((categoryId) => {
    const category = categoryOptions.find((c) => c.value === categoryId);

    setFieldValue('name', category && category.value ? category.label : null);
  }, [categoryOptions]);

  const onClickDelete = useCallback(() => {
    if (form && deleteForm && confirmDelete) {
      deleteForm([form.settingsId], '/admin/forms');
    } else {
      setConfirmDeleteStatus(true);
    }
  }, [confirmDelete, form]);

  useEffect(() => {
    fecthAvailableCategories();
  }, []);

  useEffect(() => {
    if (values.categoryId) {
      onCategorySelect(values.categoryId);
    }
  }, [values.categoryId]);

  return (
    <Card>
      <Title name={`${form ? 'edit' : 'add'} a form`} />
      <FormForms.Wrapper noValidate>
        <FormForms.Column>
          <Field
            required    = {false}
            name        = "localization"
            label       = "localization"
            options     = {LOCALIZATIONS}
            component   = {CommonSelect}
          />
          <Field
            required
            label     = "name"
            name      = "name"
            disabled  = {!!values.categoryId}
            component = {Input}
          />
          <Field
            required    = {false}
            label       = "category"
            name        = "categoryId"
            options     = {categoryOptions}
            component   = {CommonSelect}
          />
          <FormForms.ChannelsWrapper>
            <Field
              required       = {false}
              label          = "Channel(s)"
              name           = "rSettingProfileChannel"
              placeholder    = "Assign Channel(s)"
              endpoint       = "feature/channel/search?name"
              responseMapper = {mapChannelsForChips}
              component      = {MultipleAutoSuggest}
            />
          </FormForms.ChannelsWrapper>
          <Field
            required
            label         = "title (Header)"
            name          = {`strings.${values.localization}.headerTitle`}
            placeholder   = "Enter a text"
            component     = {Input}
          />
          <Field
            required
            withResizer     = {false}
            label           = "image (Header)"
            name            = "mainPage.headerImageUri"
            container       = "forms"
            component       = {MediaUploader}
            mimeTypeOptions = "image"
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '200px',
            }}
          />
          <Field
            required
            withoutImagesLabel
            limit           = {4}
            label           = "4 images (Block 1)"
            mimeTypeOptions = "image"
            name            = "mainPage.blockOneImages"
            container       = "forms"
            component       = {MultiMediaUploader}
          />
          <Field
            required
            label         = "title (Block 1)"
            name          = {`strings.${values.localization}.blockOneTitle`}
            placeholder   = "Enter a title"
            component     = {Input}
          />
          <Field
            required
            multiline
            label         = "description (Block 1)"
            name          = {`strings.${values.localization}.blockOneDescription`}
            placeholder   = "Enter a description"
            component     = {Input}
          />
          <Field
            required
            withResizer     = {false}
            label           = "image or video (Block 2)"
            name            = "mainPage.blockTwoImage"
            container       = "forms"
            component       = {MediaUploader}
            mimeTypeOptions = {['image', 'video']}
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '200px',
            }}
          />
        </FormForms.Column>

        <FormForms.Column rightSide>
          <Field
            required
            label         = "title (Block 2)"
            name          = {`strings.${values.localization}.blockTwoTitle`}
            placeholder   = "Enter a title"
            component     = {Input}
          />
          <Field
            required
            multiline
            label         = "description (Block 2)"
            name          = {`strings.${values.localization}.blockTwoDescription`}
            placeholder   = "Enter a description"
            component     = {Input}
          />
          <Field
            required
            label         = "title (Block 3)"
            name          = {`strings.${values.localization}.blockThreeTitle`}
            placeholder   = "Enter a title"
            component     = {Input}
          />
          <Field
            required
            multiline
            label         = "description (Block 3)"
            name          = {`strings.${values.localization}.blockThreeDescription`}
            placeholder   = "Enter a description"
            component     = {Input}
          />
          <Field
            required
            label         = "title (Side bar)"
            name          = {`strings.${values.localization}.registrationFlowTitle`}
            placeholder   = "Enter a title"
            component     = {Input}
          />
          {Array.from({ length: 5 }, (_, i) => (
            <Field
              required      = {false}
              key           = {`${values.localization}_${i}`}
              label         = {`list item (${i + 1}) (Side bar)`}
              name          = {`strings.${values.localization}.registrationFlowListItems[${i}]`}
              placeholder   = "Enter a text"
              component     = {Input}
            />
          ))}
          <Field
            withoutImagesLabel
            required        = {false}
            limit           = {14}
            label           = "14 images (Side bar)"
            mimeTypeOptions = "image"
            name            = "registrationFlow.images"
            container       = "forms"
            component       = {MultiMediaUploader}
          />
          {form && (
            <div style={{ marginTop: '30px' }}>
              <FormForms.UrlWrapper>
                <div><Label label="main page url" /></div>
                <div style={{width: '340px'}}>
                  <CopyButton valueToCopy={footerLinks.mainPage}>
                    <FormForms.Url target="_blank" href={footerLinks.mainPage}>{footerLinks.mainPage}</FormForms.Url>
                  </CopyButton>
                </div>
              </FormForms.UrlWrapper>

              <FormForms.UrlWrapper style={{marginTop: '10px'}}>
                <Label label="registration url" />
                <div style={{width: '330px'}}>
                  <CopyButton valueToCopy={footerLinks.registrationPage}>
                    <FormForms.Url target="_blank" href={footerLinks.registrationPage} style={{marginLeft: '-23px'}}>{footerLinks.registrationPage}</FormForms.Url>
                  </CopyButton>
                </div>
              </FormForms.UrlWrapper>
            </div>
          )}
        </FormForms.Column>   
      </FormForms.Wrapper>
      <FormForms.ButtonWrapper>
        {deleteForm && (
          <Button
            type       = "button"
            onClick    = {onClickDelete}
            label      = {confirmDelete ? 'Confirm' : 'Delete'}
            disabled   = {isFetching}
          />
        )}
        <FormButtons
          disabled      = {isFetching}
          onClickCancel = {() => history.back()}
          onSubmit      = {submitForm}
        />
      </FormForms.ButtonWrapper>
    </Card>
  );
};

FormForms.ButtonWrapper = styled.div`
  display         : flex;
  justify-content : space-around;
`;

FormForms.ChannelsWrapper = styled.div`
  margin-top: 16px;
`;

FormForms.Separator = styled.div`
  border-bottom : 1px solid ${({ theme }) => theme.borderColor.gray};
  margin-top    : 12px;
  margin-left   : 20px;
  margin-bottom : 20px;
`;

FormForms.UrlWrapper = styled.div<{ marginTop?: number }>`
  align-items : center;
  display     : flex;
  margin-top  : ${({ marginTop }) => `${marginTop}px` || 0};

  h4 { margin-top: 0; }

  > div:nth-child(2) { align-items: center; }
`;

FormForms.Url = styled.a`
  color  : blue;
  cursor : pointer;
  width  : 400px;
  padding-left: 30px;
  word-break  : break-all;

  a:first-child {
    color: red;
    font-weight: bold;
  }
`;

FormForms.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : start;
  `}
`;

FormForms.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;