import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';
import { ITipModel } from '@models/index';
import { IState }    from '@store/rootReducer';
import { TTipFormModel } from './pages/Tips';

export const STATE_KEY = 'tips';

export interface ITipsReducer {
  tips : ITipModel[];
  meta : {
    isFetching : boolean;
  };
}

export enum ETipsTypes {
  addTip             = 'addTip',
  addTipSuccessfully = 'addTipSuccessfully',
  addTipFailure      = 'addTipFailure',

  deleteTips             = 'deleteTips',
  deleteTipsSuccessfully = 'deleteTipsSuccessfully',
  deleteTipsFailure      = 'deleteTipsFailure',

  fetchTips             = 'fetchTips',
  fetchTipsSuccessfully = 'fetchTipsSuccessfully',
  fetchTipsFailure      = 'fetchTipsFailure',

  updateTip             = 'updateTip',
  updateTipSuccessfully = 'updateTipSuccessfully',
  updateTipFailure      = 'updateTipFailure',
}

export interface ITipsActions {
  [ETipsTypes.addTip]             : Action<{ attributes: TTipFormModel; meta: { isFetching: true } }>;
  [ETipsTypes.addTipSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ETipsTypes.addTipFailure]      : Action<{ meta: { isFetching: false } }>;

  [ETipsTypes.deleteTips]             : Action<{ attributes: { tipIds: number[] }; meta: { isFetching: true } }>;
  [ETipsTypes.deleteTipsSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ETipsTypes.deleteTipsFailure]      : Action<{ meta: { isFetching: false } }>;

  [ETipsTypes.fetchTips]             : Action<{ meta: Partial<ITipsReducer['meta']> & { isFetching: true } }>;
  [ETipsTypes.fetchTipsSuccessfully] : Action<{ tips: ITipsReducer['tips']; meta: { isFetching: false } }>;
  [ETipsTypes.fetchTipsFailure]      : Action<{ meta: { isFetching: false } }>;

  [ETipsTypes.updateTip]             : Action<{ attributes: TTipFormModel; meta: { isFetching: true } }>;
  [ETipsTypes.updateTipSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ETipsTypes.updateTipFailure]      : Action<{ meta: { isFetching: false } }>;
}

export interface ITipsActionsCreators {
  [ETipsTypes.addTip]             : (values: TTipFormModel) => ITipsActions[ETipsTypes.addTip];
  [ETipsTypes.addTipSuccessfully] : () => ITipsActions[ETipsTypes.addTipSuccessfully];
  [ETipsTypes.addTipFailure]      : () => ITipsActions[ETipsTypes.addTipFailure];

  [ETipsTypes.deleteTips]             : (TipsIds: number[]) => ITipsActions[ETipsTypes.deleteTips];
  [ETipsTypes.deleteTipsSuccessfully] : () => ITipsActions[ETipsTypes.deleteTipsSuccessfully];
  [ETipsTypes.deleteTipsFailure]      : () => ITipsActions[ETipsTypes.deleteTipsFailure];

  [ETipsTypes.fetchTips]             : (meta?: Partial<ITipsReducer['meta']>) => ITipsActions[ETipsTypes.fetchTips];
  [ETipsTypes.fetchTipsSuccessfully] : (result: ITipsReducer['tips']) => ITipsActions[ETipsTypes.fetchTipsSuccessfully];
  [ETipsTypes.fetchTipsFailure]      : () => ITipsActions[ETipsTypes.fetchTipsFailure];

  [ETipsTypes.updateTip]             : (values: TTipFormModel) => ITipsActions[ETipsTypes.updateTip];
  [ETipsTypes.updateTipSuccessfully] : () => ITipsActions[ETipsTypes.updateTipSuccessfully];
  [ETipsTypes.updateTipFailure]      : () => ITipsActions[ETipsTypes.updateTipFailure];
}

type Actions = ITipsActions[ETipsTypes.fetchTips]
| ITipsActions[ETipsTypes.fetchTipsSuccessfully]
| ITipsActions[ETipsTypes.fetchTipsFailure]
| ITipsActions[ETipsTypes.addTip]
| ITipsActions[ETipsTypes.addTipSuccessfully]
| ITipsActions[ETipsTypes.addTipFailure]
| ITipsActions[ETipsTypes.deleteTips]
| ITipsActions[ETipsTypes.deleteTipsSuccessfully]
| ITipsActions[ETipsTypes.deleteTipsFailure]
| ITipsActions[ETipsTypes.updateTip]
| ITipsActions[ETipsTypes.updateTipSuccessfully]
| ITipsActions[ETipsTypes.updateTipFailure];

const initialState: ITipsReducer = {
  tips : [],
  meta : {
    isFetching : false,
  },
};

export const {
  addTip,
  addTipFailure,
  addTipSuccessfully,

  deleteTips,
  deleteTipsFailure,
  deleteTipsSuccessfully,

  fetchTips,
  fetchTipsSuccessfully,
  fetchTipsFailure,

  updateTip,
  updateTipSuccessfully,
  updateTipFailure,
}: ITipsActionsCreators = createActions({
  addTip             : (params: TTipFormModel, tipsMeta = {}) => ({ attributes: { ...params }, meta: { ...tipsMeta, isFetching: true } }),
  addTipSuccessfully : () => ({ meta: { isFetching: false } }),
  addTipFailure      : () => ({ meta: { isFetching: false } }),

  deleteTips             : (tipIds: number[]) => ({ attributes: { tipIds }, meta: { isFetching: true } }),
  deleteTipsFailure      : () => ({ meta: { isFetching: false } }),
  deleteTipsSuccessfully : () => ({ meta: { isFetching: false } }),

  fetchTips             : () => ({ meta: { isFetching: true } }),
  fetchTipsSuccessfully : (tips: ITipsReducer['tips']) => ({ tips, meta: { isFetching: false } }),
  fetchTipsFailure      : () => ({ meta: { isFetching: false } }),

  updateTip             : (params: TTipFormModel) => ({ attributes: { ...params }, meta: { isFetching: true } }),
  updateTipSuccessfully : () => ({ meta: { isFetching: false } }),
  updateTipFailure      : () => ({ meta: { isFetching: false } }),
}, {
  prefix: STATE_KEY,
}) as any;

const tipsReducer = handleActions<ITipsReducer>({
  // @ts-ignore
  [combineActions(
    addTip,
    addTipFailure,
    addTipSuccessfully,

    deleteTips,
    deleteTipsFailure,
    deleteTipsSuccessfully,

    fetchTips,
    fetchTipsSuccessfully,
    fetchTipsFailure,

    updateTip,
    updateTipSuccessfully,
    updateTipFailure,
  )]: (
    state  : ITipsReducer,
    action : Actions,
  ) => {
    switch (action.type) {
      default: {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const { attributes, meta, ...data } = action.payload as any;
        const nextMeta = { ...state.meta, ...meta };

        return ({ ...state, ...data, meta: nextMeta });
      }
    }
  },
}, initialState);

interface ITipsSelectors {
  getTips : (state: IState) => ITipsReducer['tips'];
  getMeta : (state: IState) => ITipsReducer['meta'];
}

export const {
  getTips,
  getMeta,
}: ITipsSelectors = {
  getTips : (state: IState) => state[STATE_KEY].tips,
  getMeta : (state: IState) => state[STATE_KEY].meta,
};

export default tipsReducer;
