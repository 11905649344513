import styled from 'styled-components';

export interface IFormNotes {
  notes: string | null;
}

export const FormNotes = ({ notes }: IFormNotes) => {
  if (!notes) { return null; }

  return (
    <FormNotes.Wrapper>
      <FormNotes.Title>Notes</FormNotes.Title>
      <p>{notes}</p>
    </FormNotes.Wrapper>
  );
};

FormNotes.Title = styled.h3`
  cursor      : default;
  font-family : RobotoCondensed-Regular;
  font-size   : 20px;
  font-weight : 600;
  opacity     : 0.8;
`;

FormNotes.Wrapper = styled.div`
  p {
    cursor       : text;
    font-size    : 16px;
    line-height  : 18px;
    padding-left : 10px;
  }
`;
