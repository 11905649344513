import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState }         from '@store/rootReducer';
import { ICategoryModel } from '@models/index';
import { ITableMeta }     from '@components/Table/ClickableItem';

export const STATE_KEY = 'categories';

export interface ICategoriesReducer {
  categories    : ICategoryModel[];
  subcategories : ICategoryModel[];
  meta          : Required<ITableMeta> & {
    isFetching      : boolean;
    isSubFetching   : boolean;
    totalCategories : number;
  };
}

export enum CategoryTypes {
  fetchCategories             = 'fetchCategories',
  fetchCategoriesSuccessfully = 'fetchCategoriesSuccessfully',
  fetchCategoriesFailure      = 'fetchCategoriesFailure',
  addCategory                 = 'addCategory',
  addCategorySuccessfully     = 'addCategorySuccessfully',
  addCategoryFailure          = 'addCategoryFailure',
  editCategory                = 'editCategory',
  editCategorySuccessfully    = 'editCategorySuccessfully',
  editCategoryFailure         = 'editCategoryFailure',
  deleteCategories              = 'deleteCategories',
  deleteCategoriesSuccessfully  = 'deleteCategoriesSuccessfully',
  deleteCategoriesFailure       = 'deleteCategoriesFailure',

  fetchSubcategories             = 'fetchSubcategories',
  fetchSubcategoriesSuccessfully = 'fetchSubcategoriesSuccessfully',
  fetchSubcategoriesFailure      = 'fetchSubcategoriesFailure',
  addSubcategory                 = 'addSubcategory',
  addSubcategorySuccessfully     = 'addSubcategorySuccessfully',
  addSubcategoryFailure          = 'addSubcategoryFailure',
  editSubcategory                = 'editSubcategory',
  editSubcategorySuccessfully    = 'editSubcategorySuccessfully',
  editSubcategoryFailure         = 'editSubcategoryFailure',
  deleteSubcategory              = 'deleteSubcategory',
  deleteSubcategorySuccessfully  = 'deleteSubcategorySuccessfully',
  deleteSubcategoryFailure       = 'deleteSubcategoryFailure',
}

export interface ICategoriesActions {
  [CategoryTypes.fetchCategories]              : Action<{ meta: ITableMeta }>;
  [CategoryTypes.fetchCategoriesSuccessfully]  : Action<{ categories: ICategoryModel[]; meta: { totalCategories: number } }>;
  [CategoryTypes.fetchCategoriesFailure]       : Action<{}>;
  [CategoryTypes.addCategory]                  : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [CategoryTypes.addCategorySuccessfully]      : Action<{ meta: { isFetching: false }}>;
  [CategoryTypes.addCategoryFailure]           : Action<{ meta: { isFetching: false }}>;
  [CategoryTypes.editCategory]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [CategoryTypes.editCategorySuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [CategoryTypes.editCategoryFailure]          : Action<{ meta: { isFetching: false }}>;
  [CategoryTypes.deleteCategories]             : Action<{ attributes: { categoryIds: number[] }; meta: { isFetching: true }}>;
  [CategoryTypes.deleteCategoriesSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [CategoryTypes.deleteCategoriesFailure]      : Action<{ meta: { isFetching: false } }>;

  [CategoryTypes.fetchSubcategories]             : Action<{ attributes: { categoryId: number }}>;
  [CategoryTypes.fetchSubcategoriesSuccessfully] : Action<{ subcategories: ICategoryModel[] }>;
  [CategoryTypes.fetchSubcategoriesFailure]      : Action<{}>;
  [CategoryTypes.addSubcategory]                 : Action<{ attributes: { [values: string]: any }; meta: { isSubFetching: true }}>;
  [CategoryTypes.addSubcategorySuccessfully]     : Action<{ meta: { isSubFetching: false }}>;
  [CategoryTypes.addSubcategoryFailure]          : Action<{ meta: { isSubFetching: false }}>;
  [CategoryTypes.editSubcategory]                : Action<{ attributes: { [values: string]: any }; meta: { isSubFetching: true }}>;
  [CategoryTypes.editSubcategorySuccessfully]    : Action<{ meta: { isSubFetching: false }}>;
  [CategoryTypes.editSubcategoryFailure]         : Action<{ meta: { isSubFetching: false }}>;
  [CategoryTypes.deleteSubcategory]              : Action<{ attributes: { parentId: number; categoryId: number }}>;
  [CategoryTypes.deleteSubcategorySuccessfully]  : Action<{ meta: { isSubFetching: false } }>;
  [CategoryTypes.deleteSubcategoryFailure]       : Action<{ meta: { isSubFetching: false } }>;
}

export interface ICategoriesActionsCreators {
  [CategoryTypes.fetchCategories]             : (categoryMeta?: ITableMeta) => ICategoriesActions[CategoryTypes.fetchCategories];
  [CategoryTypes.fetchCategoriesSuccessfully] : (categories: ICategoryModel[], totalCount: number) => ICategoriesActions[CategoryTypes.fetchCategoriesSuccessfully];
  [CategoryTypes.fetchCategoriesFailure]      : () => ICategoriesActions[CategoryTypes.fetchCategoriesFailure];
  [CategoryTypes.addCategory]                 : (values: any) => ICategoriesActions[CategoryTypes.addCategory];
  [CategoryTypes.addCategorySuccessfully]     : () => ICategoriesActions[CategoryTypes.addCategorySuccessfully];
  [CategoryTypes.addCategoryFailure]          : () => ICategoriesActions[CategoryTypes.addCategoryFailure];
  [CategoryTypes.editCategory]                : (values: any) => ICategoriesActions[CategoryTypes.editCategory];
  [CategoryTypes.editCategorySuccessfully]    : () => ICategoriesActions[CategoryTypes.editCategorySuccessfully];
  [CategoryTypes.editCategoryFailure]         : () => ICategoriesActions[CategoryTypes.editCategoryFailure];
  [CategoryTypes.deleteCategories]              : (categoryIds: number[]) => ICategoriesActions[CategoryTypes.deleteCategories];
  [CategoryTypes.deleteCategoriesSuccessfully]  : () => ICategoriesActions[CategoryTypes.deleteCategoriesSuccessfully];
  [CategoryTypes.deleteCategoriesFailure]       : () => ICategoriesActions[CategoryTypes.deleteCategoriesFailure];

  [CategoryTypes.fetchSubcategories]             : (categoryId: number) => ICategoriesActions[CategoryTypes.fetchSubcategories];
  [CategoryTypes.fetchSubcategoriesSuccessfully] : (categories: ICategoryModel[]) => ICategoriesActions[CategoryTypes.fetchSubcategoriesSuccessfully];
  [CategoryTypes.fetchSubcategoriesFailure]      : () => ICategoriesActions[CategoryTypes.fetchSubcategoriesFailure];
  [CategoryTypes.addSubcategory]                 : (values: any) => ICategoriesActions[CategoryTypes.addSubcategory];
  [CategoryTypes.addSubcategorySuccessfully]     : () => ICategoriesActions[CategoryTypes.addSubcategorySuccessfully];
  [CategoryTypes.addSubcategoryFailure]          : () => ICategoriesActions[CategoryTypes.addSubcategoryFailure];
  [CategoryTypes.editSubcategory]                : (values: any) => ICategoriesActions[CategoryTypes.editSubcategory];
  [CategoryTypes.editSubcategorySuccessfully]    : () => ICategoriesActions[CategoryTypes.editSubcategorySuccessfully];
  [CategoryTypes.editSubcategoryFailure]         : () => ICategoriesActions[CategoryTypes.editSubcategoryFailure];
  [CategoryTypes.deleteSubcategory]              : (values: any) => ICategoriesActions[CategoryTypes.deleteSubcategory];
  [CategoryTypes.deleteSubcategorySuccessfully]  : () => ICategoriesActions[CategoryTypes.deleteSubcategorySuccessfully];
  [CategoryTypes.deleteSubcategoryFailure]       : () => ICategoriesActions[CategoryTypes.deleteSubcategoryFailure];
}

type Actions = ICategoriesActions[CategoryTypes.fetchCategoriesSuccessfully]
| ICategoriesActions[CategoryTypes.fetchCategoriesFailure]
| ICategoriesActions[CategoryTypes.addCategory]
| ICategoriesActions[CategoryTypes.addCategorySuccessfully]
| ICategoriesActions[CategoryTypes.addCategoryFailure]
| ICategoriesActions[CategoryTypes.editCategorySuccessfully]
| ICategoriesActions[CategoryTypes.editCategoryFailure]
| ICategoriesActions[CategoryTypes.deleteCategories]
| ICategoriesActions[CategoryTypes.deleteCategoriesSuccessfully]
| ICategoriesActions[CategoryTypes.deleteCategoriesFailure]
| ICategoriesActions[CategoryTypes.fetchSubcategoriesSuccessfully]
| ICategoriesActions[CategoryTypes.fetchSubcategoriesFailure]
| ICategoriesActions[CategoryTypes.addSubcategory]
| ICategoriesActions[CategoryTypes.addSubcategorySuccessfully]
| ICategoriesActions[CategoryTypes.addSubcategoryFailure]
| ICategoriesActions[CategoryTypes.editSubcategorySuccessfully]
| ICategoriesActions[CategoryTypes.editSubcategoryFailure]
| ICategoriesActions[CategoryTypes.deleteSubcategory]
| ICategoriesActions[CategoryTypes.deleteSubcategorySuccessfully]
| ICategoriesActions[CategoryTypes.deleteSubcategoryFailure];

const initialState: ICategoriesReducer = {
  categories    : [],
  subcategories : [],
  meta          : {
    isFetching      : false,
    isSubFetching   : false,
    totalCategories : 0,
    page            : 0,
    rowsPerPage     : 50,
    orderBy         : 'Name',
    order           : 'Asc',
  },
};

export const {
  fetchCategories,
  fetchCategoriesSuccessfully,
  fetchCategoriesFailure,
  addCategory,
  addCategorySuccessfully,
  addCategoryFailure,
  editCategory,
  editCategorySuccessfully,
  editCategoryFailure,
  deleteCategories,
  deleteCategoriesSuccessfully,
  deleteCategoriesFailure,

  fetchSubcategories,
  fetchSubcategoriesSuccessfully,
  fetchSubcategoriesFailure,
  addSubcategory,
  addSubcategorySuccessfully,
  addSubcategoryFailure,
  editSubcategory,
  editSubcategorySuccessfully,
  editSubcategoryFailure,
  deleteSubcategory,
  deleteSubcategorySuccessfully,
  deleteSubcategoryFailure,
}: ICategoriesActionsCreators = createActions(
  {
    FETCH_CATEGORIES              : (
      categoryMeta = {},
    ) => ({ meta: { ...categoryMeta, isFetching: true } }),
    FETCH_CATEGORIES_SUCCESSFULLY : (categories, totalCategories) => ({ categories, meta: { totalCategories, isFetching: false } }),
    FETCH_CATEGORIES_FAILURE      : () => ({ meta: { isFetching: false } }),

    ADD_CATEGORY              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    ADD_CATEGORY_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    ADD_CATEGORY_FAILURE      : () => ({ meta: { isFetching: false }}),

    EDIT_CATEGORY              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    EDIT_CATEGORY_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    EDIT_CATEGORY_FAILURE      : () => ({ meta: { isFetching: false }}),

    DELETE_CATEGORIES              : (categoryIds) => ({ attributes: { categoryIds }, meta: { isFetching: true }}),
    DELETE_CATEGORIES_SUCCESSFULLY : () => ({ meta: { isFetching: false } }),
    DELETE_CATEGORIES_FAILURE      : () => ({ meta: { isFetching: false } }),

    FETCH_SUBCATEGORIES              : (categoryId) => ({ subcategories: [], attributes: { categoryId }, meta: { isSubFetching: true } }),
    FETCH_SUBCATEGORIES_SUCCESSFULLY : (subcategories) => ({ subcategories, meta: { isSubFetching: false } }),
    FETCH_SUBCATEGORIES_FAILURE      : () => ({ meta: { isSubFetching: false } }),

    ADD_SUBCATEGORY              : (values) => ({ attributes: { ...values }, meta: { isSubFetching: true }}),
    ADD_SUBCATEGORY_SUCCESSFULLY : () => ({ meta: { isSubFetching: false }}),
    ADD_SUBCATEGORY_FAILURE      : () => ({ meta: { isSubFetching: false }}),

    EDIT_SUBCATEGORY              : (values) => ({ attributes: { ...values }, meta: { isSubFetching: true }}),
    EDIT_SUBCATEGORY_SUCCESSFULLY : () => ({ meta: { isSubFetching: false }}),
    EDIT_SUBCATEGORY_FAILURE      : () => ({ meta: { isSubFetching: false }}),

    DELETE_SUBCATEGORY              : (values) => ({ attributes: values, meta: { isSubFetching: true }}),
    DELETE_SUBCATEGORY_SUCCESSFULLY : () => ({ meta: { isSubFetching: false } }),
    DELETE_SUBCATEGORY_FAILURE      : () => ({ meta: { isSubFetching: false } }),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const categoriesReducer = handleActions<ICategoriesReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchCategories,
      fetchCategoriesSuccessfully,
      addCategory,
      addCategorySuccessfully,
      addCategoryFailure,
      editCategory,
      editCategorySuccessfully,
      editCategoryFailure,
      deleteCategories,
      deleteCategoriesSuccessfully,
      deleteCategoriesFailure,

      fetchSubcategories,
      fetchSubcategoriesSuccessfully,
      addSubcategory,
      addSubcategorySuccessfully,
      addSubcategoryFailure,
      editSubcategory,
      editSubcategorySuccessfully,
      editSubcategoryFailure,
      deleteSubcategory,
      deleteSubcategorySuccessfully,
      deleteSubcategoryFailure,
    )]: (
      state  : ICategoriesReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchCategories.toString()): {
          const actionTyped = action as ICategoriesActions['fetchCategories'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, categories: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchCategoriesSuccessfully.toString()): {
          const actionTyped = action as ICategoriesActions['fetchCategoriesSuccessfully'];

          return ({ ...state, categories: actionTyped.payload.categories, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default categoriesReducer;

interface ICategoriesSelectors {
  getCategories    : (state: IState) => ICategoryModel[];
  getSubcategories : (state: IState) => ICategoryModel[];
  getMeta          : (state: IState) => ICategoriesReducer['meta'];
}

export const getCategories: ICategoriesSelectors['getCategories']       = (state: IState) => state[STATE_KEY].categories;
export const getSubcategories: ICategoriesSelectors['getSubcategories'] = (state: IState) => state[STATE_KEY].subcategories;
export const getMeta: ICategoriesSelectors['getMeta']                   = (state: IState) => state[STATE_KEY].meta;
