import 'react-phone-input-2/lib/style.css';

import { FieldProps } from 'formik';
import PhoneInputLib  from 'react-phone-input-2';
import styled         from 'styled-components';
import {
  FormEvent,
  useCallback,
  useState,
} from 'react';

import { Label }      from '../Label';
import { FieldError } from '../FieldError';

type InputProps = Partial<FieldProps> & {
  bordered?         : boolean;
  errorMessage?     : string;
  label?            : string;
  required?         : boolean;
  value?            : string;
  changeFieldValue? : (newValue: string) => void;
}

const PhoneInput = ({
  errorMessage,
  field,
  form,
  label,
  value,
  bordered = false,
  required = true,
  changeFieldValue,
}: InputProps) => {
  const [isFieldFocused, setFocusStatus] = useState<boolean>(false);
  const [isFieldOnHover, setHoverStatus] = useState<boolean>(false);

  const onBlurInner = useCallback((e: FormEvent<HTMLInputElement>) => {
    setFocusStatus(false);

    field?.onBlur(e);
  }, [field?.onBlur]);

  const onChange = useCallback((nextValue: string) => {
    if (form && field) {
      form.setFieldValue(field.name, nextValue);
    } else if (changeFieldValue) {
      changeFieldValue(nextValue);
    }
  }, [field, form, changeFieldValue]);

  return (
    <PhoneInput.Container
      bordered       = {bordered}
      isFieldFocused = {isFieldFocused}
      isFieldOnHover = {isFieldOnHover}
    >
      {label && (
        <Label
          label    = {label}
          required = {required}
        />
      )}

      <PhoneInput.Field
        bordered     = {bordered}
        isLabel      = {!!label}
        onMouseEnter = {() => setHoverStatus(true)}
        onMouseLeave = {() => setHoverStatus(false)}
      >
        <PhoneInputLib
          country  = "us"
          value    = {field?.value || value}
          onBlur   = {onBlurInner}
          onChange = {onChange}
          onFocus  = {() => setFocusStatus(true)}
        />
      </PhoneInput.Field>

      <FieldError
        errorMessage = {errorMessage}
        field        = {field}
        form         = {form}
        value        = {value}
      />
    </PhoneInput.Container>
  );
};

PhoneInput.Container = styled.div<{
  bordered       : boolean;
  isFieldFocused : boolean;
  isFieldOnHover : boolean;
}>`
  display          : flex;
  flex-direction   : column;
  justify-content  : center;
  margin           : ${({ theme }) => theme.field.gap};
  position         : relative;
  transition       : all 0.2s;
  width            : 100%;
  z-index          : 201;

  ${({ bordered, isFieldFocused, isFieldOnHover, theme }) => !bordered && `
    box-shadow : 0 1px 0 0 ${theme.color.lightGray};
    padding    : 0 0 3px 0;

    ${isFieldOnHover ? `box-shadow: 0 1px 0 0 ${theme.color.blue};` : ''}
    ${isFieldFocused ? `box-shadow : 0 3px 0 0 ${theme.color.blue};` : ''}
  `}
`;

PhoneInput.Field = styled.div<{ bordered: boolean; isLabel: boolean }>`
  z-index: 202;

  input {
    color      : ${({ theme }) => theme.field.placeholderColor};
    margin-top : ${({ bordered, isLabel }) => ((!isLabel && '0') || (isLabel && bordered && '7px') || '14px')};
    transition : all 0.3s;

    &:focus { color: ${({ theme }) => theme.color.blue}; }

    ::placeholder { color: ${({ theme }) => theme.color.gray}; }
  }

  input,
  span { padding: ${({ bordered }) => (!bordered && '0')}; }

  > input,
  > span {
    background-color : #fff;
    border-radius    : 0;
    font-size        : 14px;
    line-height      : 20px;
    transition       : all 0.2s;

    ::placeholder { color: ${({ theme }) => theme.color.gray}; }

    &:hover,
    &:focus { background-color: #fff; }

    ${({ bordered, theme }) => (bordered ? `
      border        : 1px solid ${theme.color.lightGray};
      border-radius : 4px;
      height        : 44px;
      transition    : all 0.2s;

      &:hover { border: 1px solid ${theme.color.blue} }
      &:focus {
        border     : 1px solid ${theme.color.blue};
        box-shadow : 0 0 3px 0 ${theme.color.blue};
      }
    ` : `
    `)}
  }

  .react-tel-input {
    margin-top: 14px;
  }

  .form-control {
    background     : transparent;
    border         : none;
    border-radius  : 0;
    height         : auto;
    letter-spacing : normal;
    line-height    : normal;
    padding-left   : 40px;
    width          : 100%;
  }

  .flag-dropdown {
    background-color : transparent;
    border           : none;
    border-radius    : 0;
  }

  .selected-flag {
    border-radius : 0;
    padding       : 0;
  }

  .country-list {
    margin: 4px 0 0;
  }
`;

export { PhoneInput };
