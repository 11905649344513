import styled from 'styled-components';

import { ApprovedIcon } from '../../../public/svg';

interface IApprovedCircle {
  margin? : string;
  size?   : string;
  icon?    : {
    width  : string;
    height : string;
  }
}

export const ApprovedCircle = ({ icon, margin, size }: IApprovedCircle) => (
  <ApprovedCircle.Wrapper margin={margin} size={size}>
    <ApprovedIcon height={icon?.height || '14px'} width={icon?.width || '18px'} />
  </ApprovedCircle.Wrapper>
);

ApprovedCircle.Wrapper = styled.div<{ margin?: string; size?: string }>`
  align-items      : center;
  background-color : #e0ffcc;
  border-radius    : 50%;
  display          : flex;
  justify-content  : center;
  height           : ${({ size }) => size || '32px'};
  margin           : ${({ margin }) => margin || '0'};
  width            : ${({ size }) => size || '32px'};
`;
