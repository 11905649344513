import { all, takeLatest, put, call } from 'redux-saga/effects';
import { replace }                    from 'connected-react-router';

import { ETipType }      from '@models/enums';
import { callApi }       from '@utils/apiCaller';
import { Notifications } from '@components/Notifications/Notifications';
import {
  addTip,
  addTipFailure,
  addTipSuccessfully,

  deleteTips,
  deleteTipsFailure,
  deleteTipsSuccessfully,

  fetchTips,
  fetchTipsFailure,
  fetchTipsSuccessfully,

  updateTip,
  updateTipFailure,
  updateTipSuccessfully,

  ETipsTypes,
  ITipsActions,
} from './tipsReducer';

function* handleAddTip(action: ITipsActions[ETipsTypes.addTip]) {
  try {
    const values = action.payload.attributes;

    yield callApi('api/EssentialTip', 'post', values);
    yield put(addTipSuccessfully());
    yield put(replace('/admin/tips'));
    yield put(fetchTips());
    yield call(Notifications.enqueueSnackbar, 'Tip was added successfully', 'success');
  } catch (e) {
    yield put(addTipFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding Tip. Please try again', 'error');
  }
}

function* handleDeleteTip(action: ITipsActions[ETipsTypes.deleteTips]) {
  const { tipIds } = action.payload.attributes;

  try {
    for (let i = 0; i < tipIds.length; i++) {
      yield callApi(`api/EssentialTip/${tipIds[i]}`, 'delete');
    }
    yield put(deleteTipsSuccessfully());
    yield put(fetchTips());
    yield call(Notifications.enqueueSnackbar, `${tipIds.length > 1 ? 'Tips were' : 'Tip was'} deleted successfully`, 'success');
  } catch (e) {
    yield put(deleteTipsFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${tipIds.length > 1 ? 'Tips' : 'Tip'}. Please try again`, 'error');
  }
}

function* handleFetchTips() {
  try {
    const licenseTips      = callApi(`api/EssentialTip?type=${ETipType.License}`);
    const organizationTips = callApi(`api/EssentialTip?type=${ETipType.Organization}`);

    const [{ data: license }, { data: organization }] = yield Promise.all([licenseTips, organizationTips]);

    yield put(fetchTipsSuccessfully([...organization, ...license]));
  } catch (e) {
    yield put(fetchTipsFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Tips. Please try again', 'error');
  }
}

function* handleUpdateTip(action: ITipsActions[ETipsTypes.updateTip]) {
  try {
    const values = action.payload.attributes;

    yield callApi('api/EssentialTip', 'put', values);
    yield put(updateTipSuccessfully());
    yield put(replace('/admin/tips'));
    yield call(Notifications.enqueueSnackbar, 'Tip was edited successfully', 'success');
  } catch (e) {
    yield put(updateTipFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing Tip. Please try again', 'error');
  }
}

function* tipsSagas() {
  yield all([
    takeLatest(fetchTips, handleFetchTips),
    takeLatest(addTip, handleAddTip),
    takeLatest(updateTip, handleUpdateTip),
    takeLatest(deleteTips, handleDeleteTip),
  ]);
}

export { tipsSagas };
