import { Avatar, Button, List } from 'antd';
import React, { useCallback }   from 'react';
import styled                   from 'styled-components';

import { Modal } from '@src/App';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { DeleteModal } from '../Modal/DeleteModal';

export interface IElement {
  primary    : string;
  secondary? : string;
  icon?      : string | {imageUri: string, mimeType: 'image'};
  key        : number;
  source?    : { [key: string]: any };
}

export interface IList {
  data                : IElement[];
  onEdit?             : (el: IElement) => void;
  deleteConfig?       : {
    action  : (el: IElement) => void;
    message : string;
  };
  PrimaryIcon?        : React.ComponentType<any>;
  onPrimaryIconClick? : (el: IElement) => void;
}

export const FormList = ({
  data,
  onEdit,
  deleteConfig,
  PrimaryIcon,
  onPrimaryIconClick,
}: IList) => {
  const onClickDelete = useCallback((el: IElement) => {
    if (!deleteConfig) return;

    Modal.open(DeleteModal, {
      confirmMessage    : deleteConfig.message,
      entityNameMessage : `Enter the following value to confirm: "${el.primary}".`,
      name              : el.primary,
      onDeleteConfirm   : () => {
        deleteConfig.action(el);
        Modal.close();
      }});
  }, [deleteConfig]);

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <FormList.ListWrapper>
      <FormList.InnerListWrapper
        itemLayout = "horizontal"
        dataSource = {data}
        renderItem = {(el: any) => (
          <List.Item
            actions={[
              PrimaryIcon && onPrimaryIconClick &&
                <Button
                  icon    = {<PrimaryIcon />} 
                  onClick = {() => onPrimaryIconClick(el)}
                  type    = "text" 
                />,
              onEdit &&
                <Button
                  icon    = {<EditOutlined />}
                  onClick = {() => onEdit(el)}
                  type    = "text"
                />,
              deleteConfig &&
                <Button
                  type    = "text"
                  icon    = {<DeleteOutlined />}
                  onClick = {() => onClickDelete(el)}
                />,
            ]}
          >
            <List.Item.Meta
              avatar      = {el.icon && <FormList.AvatarStyled src={el.icon as string} />}
              title       = {el.primary}
              description = {el.secondary}
            />
          </List.Item>
        )}
      />
    </FormList.ListWrapper>
  );
};

FormList.AvatarStyled = styled(Avatar)`
  border       : 1px solid ${({ theme }) => theme.borderColor.gray};
  width        : 48px;
  height       : 48px;
  margin-right : 8px;
`;

FormList.InnerListWrapper = styled(List)`
  @media (min-width: 1284px) {
    width: 875px;
  }
`;

FormList.ListWrapper = styled.div`
  position    : relative;
  margin-top  : 20px;
  margin-left : 20px;
`;
