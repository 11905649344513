import { useCallback, useState } from 'react';
import { useTranslation }        from 'react-i18next';
import styled                    from 'styled-components';
import * as Yup                  from 'yup';

import { Button, Input, Label, Modal } from '../../../components';
import { TApiCaller }                  from '../../../models';

interface ILoginModals {
  apiHandler        : TApiCaller;
  closeModal        : () => void;
  resetPasswordLink : string;
}

interface IErrorModalProps extends Pick<ILoginModals, 'closeModal'> {
  comment: string | JSX.Element;
}

const ButtonWrapper = styled.div`
  display         : flex;
  justify-content : flex-end;
  margin-top      : 27px;
`;

export const ErrorModal = ({ closeModal, comment }: IErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      onCancel = {closeModal}
      title    = {t('requestFailedTryAgain')}
    >
      <p>{comment}</p>
      <ButtonWrapper>
        <Button
          label   = {t('gotIt')}
          onClick = {closeModal}
          type    = "button"
        />
      </ButtonWrapper>
    </Modal>
  );
};

export const ResetPasswordModal = ({ apiHandler, closeModal, resetPasswordLink }: ILoginModals) => {
  const { t } = useTranslation();

  const [inputValue, setValue]          = useState<string>('');
  const [isEmailSend, setEmailStatus]   = useState<boolean>(false);
  const [inputError, setError]          = useState<string | undefined>(undefined);
  const [isFetching, setFetchingStatus] = useState<boolean>(false);

  const resetPassword = useCallback(async (email: string) => {
    setFetchingStatus(true);
    try {
      await apiHandler(resetPasswordLink, 'post', { email });
      setEmailStatus(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setError(t('requestFailedTryAgain'));
    }
    setFetchingStatus(false);
  }, []);

  const onSubmit = useCallback(() => {
    const isEmailValid = Yup.string().email().isValidSync(inputValue);

    if (isEmailValid) {
      resetPassword(inputValue);
    } else {
      setError(t('itDoesNotLookLikeEmail'));
    }
  }, [inputValue]);

  return (
    <Modal
      onCancel = {closeModal}
      title    = {t('forgotPassword')}
    >
      {isEmailSend && (<p>{t('instructionsSentFindThemCheckSpamFolder')}</p>)}
      {!isEmailSend && (
        <>
          <Label
            required       = {false}
            label          = {t('enterEmailToResetPassword')}
            componentStyle = {{
              fontSize   : 14,
              fontWeight : 500,
              transform  : 'none',
            }}
          />
          <Input
            bordered
            changeFieldValue = {setValue}
            errorMessage     = {inputError}
            value            = {inputValue}
            wrapperStyle     = {() => 'margin: 7px 0 0;'}
          />
        </>
      )}
      <ButtonWrapper>
        <Button
          disabled = {isFetching || !inputValue.length}
          label    = {isEmailSend ? t('gotIt') : t('send')}
          onClick  = {isEmailSend ? closeModal : onSubmit}
          type     = "button"
        />
      </ButtonWrapper>
    </Modal>
  );
};
