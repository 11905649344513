import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { Notifications }    from '@components/Notifications/Notifications';
import { callApi }          from '@utils/apiCaller';
import { parseServerError } from '@utils/parseServerError';

import {
  addLicense,
  addLicenseFailure,
  addLicenseSuccessfully,

  fetchLicenses,
  fetchLicensesSuccessfully,
  fetchLicensesFailure,

  updateLicense,
  updateLicenseSuccessfully,
  updateLicenseFailure,

  deleteLicense,
  deleteLicenseFailure,
  deleteLicenseSuccessfully,

  getMeta,

  ELicenseTypes,
  ILicensesActions,
  ILicensesReducer,
} from './licensesReducer';

function* handleAddLicense(action: ILicensesActions[ELicenseTypes.addLicense]) {
  const values = action.payload.attributes;

  try {

    yield callApi('LicenseAccount', 'post', values);
    yield put(addLicenseSuccessfully());
    yield put(replace('/admin/clients'));
    yield put(fetchLicenses());
    yield call(Notifications.enqueueSnackbar, 'Client was added successfully', 'success');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put(addLicenseFailure());
    yield call(Notifications.enqueueSnackbar, parseServerError(error) || 'Ooops... Something went wrong while adding a client. Please try again', 'error');
  }
}

function* handleFetchLicenses() {
  try {
    const { page, rowsPerPage }: ILicensesReducer['meta'] = yield select(getMeta);
    const { data: { items, totalCount } } = yield callApi(`LicenseAccount/list?page=${page}&itemsPerPage=${rowsPerPage}`);

    yield put(fetchLicensesSuccessfully(items, totalCount));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put(fetchLicensesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching clients. Please try again', 'error');
  }
}

function* handleUpdateLicense(action: ILicensesActions[ELicenseTypes.updateLicense]) {
  try {
    yield callApi('LicenseAccount', 'PUT', action.payload.attributes);
    yield put(fetchLicenses());
    yield put(updateLicenseSuccessfully());
    yield put(replace('/admin/clients'));

  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put(updateLicenseFailure());
    yield call(Notifications.enqueueSnackbar, parseServerError(error) || 'Ooops... Something went wrong while updating the client. Please try again', 'error');
  }
}

function* handleDeleteLicense(action: ILicensesActions[ELicenseTypes.deleteLicense]) {
  const { licenseUserId } = action.payload.attributes;

  try {
    yield callApi(`LicenseAccount/${licenseUserId}`, 'DELETE');

    yield put(deleteLicenseSuccessfully());
    yield put(fetchLicenses());
    yield call(Notifications.enqueueSnackbar, 'Client was deleted successfully', 'success');
  } catch (e) {
    yield put(deleteLicenseFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while deleting the client. Please try again', 'error');
  }
}

export function* licensesSagas() {
  yield all([
    takeLatest(addLicense, handleAddLicense),
    takeLatest(fetchLicenses, handleFetchLicenses),
    takeLatest(updateLicense, handleUpdateLicense),
    takeLatest(deleteLicense, handleDeleteLicense),
  ]);
}
