const DEFAULT_PREVIEW_IMAGE_WIDTH = 400;

type TMeta = {
  title?       : string;
  description? : string;
  image?       : string;
  width?       : number;
};

export type TDynamicLinkGenerator = (
  path                     : string,
  query?                   : string,
  meta?                    : TMeta,
  withProjectFallbackLink? : boolean,
) => Promise<{ shortLink: string; }>;

type TDynamicLinkGeneratingInitializer = (envVariables : {
  firebaseWebApiKey   : string;
  projectUrl          : string;
  androidPackageName  : string;
  iosBundleId         : string;
  iosAppStoreId       : string;
  appCenterAndroidUrl : string,
  appCenterIosUrl     : string
}) => TDynamicLinkGenerator;

// eslint-disable-next-line arrow-body-style
export const initializeDynamicLinkGenerator: TDynamicLinkGeneratingInitializer = (envVariables) => {
  return async (path, query, meta, withProjectFallbackLink) => {
    const id        = null;
    const promoCode = null;

    const link = encodeURIComponent(`${envVariables.projectUrl}/shared/${path}?${query ? `${query}&` : ''}userId=${id}&promoCode=${promoCode}`);

    const requestData = {
      link,
      subdomain           : 'https://newsie.page.link',
      androidFallbackLink : withProjectFallbackLink ? link : envVariables.appCenterAndroidUrl,
      iosFallbackLink     : withProjectFallbackLink ? link : envVariables.appCenterIosUrl,
      androidPackageName  : envVariables.androidPackageName,
      iosStoreId          : envVariables.iosAppStoreId,
      iosBundleId         : envVariables.iosBundleId,
      efr                 : 1,
      ...(meta || null),
    };

    let longDynamicLink = `${requestData.subdomain}/?link=${requestData.link}&apn=${requestData.androidPackageName}&isi=${requestData.iosStoreId}&ibi=${requestData.iosBundleId}&efr=${requestData.efr}`;

    if (requestData.androidFallbackLink && requestData.iosFallbackLink) {
      longDynamicLink += `&afl=${requestData.androidFallbackLink}&ifl=${requestData.iosFallbackLink}`;
    }

    if (requestData.title) {
      longDynamicLink += `&st=${requestData.title}`;
    }

    if (requestData.description) {
      longDynamicLink += `&sd=${requestData.description}`;
    }

    if (requestData.image) {
      longDynamicLink += `&si=${encodeURIComponent(`${requestData.image}?w=${meta?.width || DEFAULT_PREVIEW_IMAGE_WIDTH}&fit=clip`)}`;
    }

    const response = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${envVariables.firebaseWebApiKey}`,
      {
        method  : 'POST',
        headers : { 'Content-Type': 'application/json' },
        body    : JSON.stringify({
          longDynamicLink,
          suffix : { option: 'SHORT' },
        }),
      },
    );

    const responseData = await response.json();

    return responseData;
  };
};
