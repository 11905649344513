import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { ITableMeta }          from '@components/Table/ClickableItem';
import { IState }              from '@store/rootReducer';
import { IAdvertisingModel }   from '@src/models';

import { TAdvertisingPostModel } from './pages/Advertising';

export const STATE_KEY = 'advertising';

export interface IAdvertisingReducer {
  advertising : IAdvertisingModel[];
  meta        : Required<ITableMeta> & {
    isFetching       : boolean;
    totalAdvertising : number;
  };
}

export enum EAdvertisingTypes {
  addAdvertising             = 'addAdvertising',
  addAdvertisingSuccessfully = 'addAdvertisingSuccessfully',
  addAdvertisingFailure      = 'addAdvertisingFailure',

  deleteAdvertising             = 'deleteAdvertising',
  deleteAdvertisingSuccessfully = 'deleteAdvertisingSuccessfully',
  deleteAdvertisingFailure      = 'deleteAdvertisingFailure',

  fetchAdvertising             = 'fetchAdvertising',
  fetchAdvertisingSuccessfully = 'fetchAdvertisingSuccessfully',
  fetchAdvertisingFailure      = 'fetchAdvertisingFailure',

  updateAdvertising             = 'updateAdvertising',
  updateAdvertisingSuccessfully = 'updateAdvertisingSuccessfully',
  updateAdvertisingFailure      = 'updateAdvertisingFailure',
}

export interface IAdvertisingActions {
  [EAdvertisingTypes.addAdvertising]             : Action<{ attributes: TAdvertisingPostModel; meta: { isFetching: true } }>;
  [EAdvertisingTypes.addAdvertisingSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [EAdvertisingTypes.addAdvertisingFailure]      : Action<{ meta: { isFetching: false } }>;

  [EAdvertisingTypes.deleteAdvertising]             : Action<{ attributes: { advertisingIds: number[] }; meta: { isFetching: true } }>;
  [EAdvertisingTypes.deleteAdvertisingSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [EAdvertisingTypes.deleteAdvertisingFailure]      : Action<{ meta: { isFetching: false } }>;

  [EAdvertisingTypes.fetchAdvertising]             : Action<{ meta: Partial<IAdvertisingReducer['meta']> & { isFetching: true } }>;
  [EAdvertisingTypes.fetchAdvertisingSuccessfully] : Action<{ advertising: IAdvertisingModel[]; meta: { isFetching: false; totalAdvertising: number } }>;
  [EAdvertisingTypes.fetchAdvertisingFailure]      : Action<{ meta: { isFetching: false } }>;

  [EAdvertisingTypes.updateAdvertising]             : Action<{ attributes: TAdvertisingPostModel; meta: { isFetching: true } }>;
  [EAdvertisingTypes.updateAdvertisingSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [EAdvertisingTypes.updateAdvertisingFailure]      : Action<{ meta: { isFetching: false } }>;
}

export interface IAdvertisingActionsCreators {
  [EAdvertisingTypes.addAdvertising]             : (values: TAdvertisingPostModel) => IAdvertisingActions[EAdvertisingTypes.addAdvertising];
  [EAdvertisingTypes.addAdvertisingSuccessfully] : () => IAdvertisingActions[EAdvertisingTypes.addAdvertisingSuccessfully];
  [EAdvertisingTypes.addAdvertisingFailure]      : () => IAdvertisingActions[EAdvertisingTypes.addAdvertisingFailure];

  [EAdvertisingTypes.deleteAdvertising]             : (advertisingIds: number[]) => IAdvertisingActions[EAdvertisingTypes.deleteAdvertising];
  [EAdvertisingTypes.deleteAdvertisingSuccessfully] : () => IAdvertisingActions[EAdvertisingTypes.deleteAdvertisingSuccessfully];
  [EAdvertisingTypes.deleteAdvertisingFailure]      : () => IAdvertisingActions[EAdvertisingTypes.deleteAdvertisingFailure];

  [EAdvertisingTypes.fetchAdvertising]             : (meta?: Partial<IAdvertisingReducer['meta']>) => IAdvertisingActions[EAdvertisingTypes.fetchAdvertising];
  [EAdvertisingTypes.fetchAdvertisingSuccessfully] : (result: IAdvertisingModel[], total: number) => IAdvertisingActions[EAdvertisingTypes.fetchAdvertisingSuccessfully];
  [EAdvertisingTypes.fetchAdvertisingFailure]      : () => IAdvertisingActions[EAdvertisingTypes.fetchAdvertisingFailure];

  [EAdvertisingTypes.updateAdvertising]             : (values: TAdvertisingPostModel) => IAdvertisingActions[EAdvertisingTypes.updateAdvertising];
  [EAdvertisingTypes.updateAdvertisingSuccessfully] : () => IAdvertisingActions[EAdvertisingTypes.updateAdvertisingSuccessfully];
  [EAdvertisingTypes.updateAdvertisingFailure]      : () => IAdvertisingActions[EAdvertisingTypes.updateAdvertisingFailure];
}

type Actions = IAdvertisingActions[EAdvertisingTypes.fetchAdvertising]
| IAdvertisingActions[EAdvertisingTypes.fetchAdvertisingSuccessfully]
| IAdvertisingActions[EAdvertisingTypes.fetchAdvertisingFailure]
| IAdvertisingActions[EAdvertisingTypes.deleteAdvertising]
| IAdvertisingActions[EAdvertisingTypes.deleteAdvertisingSuccessfully]
| IAdvertisingActions[EAdvertisingTypes.deleteAdvertisingFailure]
| IAdvertisingActions[EAdvertisingTypes.addAdvertising]
| IAdvertisingActions[EAdvertisingTypes.addAdvertisingSuccessfully]
| IAdvertisingActions[EAdvertisingTypes.addAdvertisingFailure]
| IAdvertisingActions[EAdvertisingTypes.updateAdvertising]
| IAdvertisingActions[EAdvertisingTypes.updateAdvertisingSuccessfully]
| IAdvertisingActions[EAdvertisingTypes.updateAdvertisingFailure];

const initialState: IAdvertisingReducer = {
  advertising : [],
  meta        : {
    isFetching       : false,
    orderBy          : 'DateStart',
    order            : 'Asc',
    page             : 0,
    rowsPerPage      : 50,
    totalAdvertising : 0,
  },
};

export const {
  addAdvertising,
  addAdvertisingFailure,
  addAdvertisingSuccessfully,

  deleteAdvertising,
  deleteAdvertisingFailure,
  deleteAdvertisingSuccessfully,

  fetchAdvertising,
  fetchAdvertisingSuccessfully,
  fetchAdvertisingFailure,

  updateAdvertising,
  updateAdvertisingSuccessfully,
  updateAdvertisingFailure,
}: IAdvertisingActionsCreators = createActions({
  addAdvertising             : (params: TAdvertisingPostModel) => ({ attributes: { ...params }, meta: { isFetching: true } }),
  addAdvertisingSuccessfully : () => ({ meta: { isFetching: false } }),
  addAdvertisingFailure      : () => ({ meta: { isFetching: false } }),

  deleteAdvertising             : (advertisingIds: number[]) => ({ attributes: { advertisingIds }, meta: { isFetching: true } }),
  deleteAdvertisingFailure      : () => ({ meta: { isFetching: false } }),
  deleteAdvertisingSuccessfully : () => ({ meta: { isFetching: false } }),

  fetchAdvertising             : (meta = {}) => ({ meta: { ...meta, isFetching: true } }),
  fetchAdvertisingSuccessfully : (advertising, totalAdvertising) => ({ advertising, meta: { totalAdvertising, isFetching: false } }),
  fetchAdvertisingFailure      : () => ({ meta: { isFetching: false } }),

  updateAdvertising             : (params: TAdvertisingPostModel) => ({ attributes: { ...params }, meta: { isFetching: true } }),
  updateAdvertisingSuccessfully : () => ({ meta: { isFetching: false } }),
  updateAdvertisingFailure      : () => ({ meta: { isFetching: false } }),
}, {
  prefix: STATE_KEY,
}) as any;

const advertisingReducer = handleActions<IAdvertisingReducer>({
  // @ts-ignore
  [combineActions(
    addAdvertising,
    addAdvertisingFailure,
    addAdvertisingSuccessfully,

    fetchAdvertising,
    fetchAdvertisingSuccessfully,
    fetchAdvertisingFailure,

    updateAdvertising,
    updateAdvertisingSuccessfully,
    updateAdvertisingFailure,
  )]: (
    state  : IAdvertisingReducer,
    action : Actions,
  ) => {
    switch (action.type) {
      case (fetchAdvertising.toString()): {
        const actionTyped = action as IAdvertisingActions['fetchAdvertising'];

        if (actionTyped.payload.meta.page === 0) {
          return ({ ...state, advertising : [], meta : { ...state.meta, ...actionTyped.payload.meta }});
        }

        return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
      }
      case (fetchAdvertisingSuccessfully.toString()): {
        const actionTyped  = action as IAdvertisingActions['fetchAdvertisingSuccessfully'];
  
        return ({
          ...state,
          advertising : actionTyped.payload.advertising,
          meta        : { ...state.meta, ...actionTyped.payload.meta },
        });
      }
      default: {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const { attributes, meta, ...data } = action.payload as any;
        const nextMeta = { ...state.meta, ...meta };

        return ({ ...state, ...data, meta: nextMeta });
      }
    }
  },
}, initialState);

interface ILicensesSelectors {
  getAdvertising : (state: IState) => IAdvertisingModel[];
  getMeta        : (state: IState) => IAdvertisingReducer['meta'];
}

export const {
  getAdvertising,
  getMeta,
}: ILicensesSelectors = {
  getAdvertising : (state: IState) => state[STATE_KEY].advertising,
  getMeta        : (state: IState) => state[STATE_KEY].meta,
};

export default advertisingReducer;
