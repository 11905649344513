import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { MailOutlined } from '@ant-design/icons';

interface ISupportChatProps {
  workingHours     : string;
  onClickStartChat : () => void;
}

const SupportChat = ({
  onClickStartChat,
  workingHours,
}: ISupportChatProps) => {
  const { t } = useTranslation();

  return (
    <SupportChat.Wrapper>
      <SupportChat.Info>
        <p>{t('onlineChatSupportHours')}:</p>
        <p>{workingHours}</p>
      </SupportChat.Info>
      <SupportChat.Button onClick={onClickStartChat}>
        <MailOutlined />
        <p>{t('startChat')}</p>
      </SupportChat.Button>
    </SupportChat.Wrapper>
  );
};

export { SupportChat };

SupportChat.Button = styled.div`
  align-items      : center;
  background-color : #fff;
  border           : 1px solid #d7dbec;
  border-radius    : 4px;
  color            : #5a607f;
  cursor           : pointer;
  display          : flex;
  gap              : 7px;
  height           : 36px;
  padding          : 0 15px;

  p {
    font-size      : 14px;
    line-height    : 20px;
    text-transform : uppercase;
  }
`;

SupportChat.Info = styled.div`
  p {
    color       : #7e84a3;
    font-size   : 11px;
    line-height : 17px;
  }

  p:first-of-type { font-weight: 600; }
`;

SupportChat.Wrapper = styled.div`
  display : flex;
  gap     : 9px;
`;
