import { Popover }           from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation }    from 'react-i18next';
import styled                from 'styled-components';

import { setPopupContainer } from '../../utils';

type TAction = () => void;

export interface UsernameProps {
  onClickEditProfile? : TAction;
  onClickBilling?     : TAction;
  onClickHelpCenter?  : TAction;
  onClickLogOut       : TAction;
  username            : string | null;
}

export const Username = ({
  onClickBilling,
  onClickEditProfile,
  onClickHelpCenter,
  onClickLogOut,
  username,
}: UsernameProps) => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverStatus] = useState<boolean>(false);

  const actions = useMemo(() => {
    const array = [
      { action: onClickEditProfile, label: t('editProfile') },
      { action: onClickBilling, label: t('billing') },
      { action: onClickHelpCenter, label: t('helpCenter') },
    ];

    return array.filter((item) => !!item.action);
  }, [onClickBilling, onClickEditProfile, onClickHelpCenter]);

  return (
    <Username.Wrapper>
      <Popover
        getPopupContainer = {setPopupContainer}
        onVisibleChange   = {setPopoverStatus}
        placement         = "bottom"
        trigger           = "click"
        visible           = {popoverVisible}
        content           = {(
          <>
            <Username.Items withSeparator={!!actions.length}>
              {actions.map((item) => (
                <Username.Item
                  key     = {item.label}
                  onClick = {item.action}
                >
                  {item.label}
                </Username.Item>
              ))}
            </Username.Items>
            <Username.LogOut
              marginTop = {actions.length ? '19px' : '0'}
              onClick   = {onClickLogOut}
            >
              {t('logout')}
            </Username.LogOut>
          </>
        )}
      >
        <Username.Name clicked={popoverVisible}>
          <p>{username || t('yourFullName')}</p>
          <p>&gt;</p>
        </Username.Name>
      </Popover>
    </Username.Wrapper>
  );
};

Username.Item = styled.p`
  color       : ${({ theme }) => theme.color.blue};
  cursor      : pointer;
  font-size   : 12px;
  line-height : 19px;
  transition  : all 0.2s;

  &:hover { font-size: 13px; }
`;

Username.Items = styled.div<{ withSeparator: boolean }>`
  display        : flex;
  flex-direction : column;
  gap            : 17px;

  ${({ withSeparator, theme }) => withSeparator && `
    border-bottom  : 1px solid ${theme.color.lightGray};
    padding-bottom : 19px;
  `}
`;

Username.LogOut = styled.p<{ marginTop: string }>`
  color       : #7e84a3;
  cursor      : pointer;
  margin-top  : ${({ marginTop }) => marginTop};
  font-size   : 12px;
  line-height : 19px;
  transition  : all 0.2s;

  &:hover { font-size: 13px; }
`;

Username.Name = styled.div<{ clicked: boolean }>`
  color       : ${({ theme }) => theme.color.black};
  cursor      : pointer;
  display     : flex;
  gap         : 10px;
  font-size   : 14px;
  user-select : none;

  > p:last-child {
    transform  : ${({ clicked }) => `rotate(${clicked ? '-90deg' : '90deg'})`};
    transition : all 0.3s;
  }
`;

Username.Wrapper = styled.div`
  .ant-popover-inner-content { padding: 17px 25px 23px 21px; }

  .ant-popover-content { width: 210px; }

  .ant-popover-inner { border-radius: 0 0 10px 10px; }
`;
