import { useCallback } from 'react';
import { Form, Field } from 'formik';
import styled          from 'styled-components';

import { Card, Input, FormButtons } from '@common/components';
import { MultipleAutoSuggest }      from '@components/Autosuggest/MultipleAutoSuggest';
import { AreaSelection }            from '@components/AreaSelection/AreaSelection';
import { MediaUploader }            from '@components/MediaUploader/MediaUploader';
import { Title }                    from '@components/Title/Title';

import { useFetchEntity }    from '@utils/fetchEntity';
import { IAffiliationModel } from '@src/models';

interface IFormAffiliation {
  isFetching : boolean;
  submitForm : () => void;
}

export const FormAffiliation = ({ isFetching, submitForm }: IFormAffiliation) => {
  const [affiliation] = useFetchEntity('feature/Credential') as [IAffiliationModel | null, boolean, () => Promise<void>];

  const mapSearchResponseBusinessCategories = useCallback((data) => {
    return data.map((d: any) => ({ value: d.categoryId, label: d.name }));
  }, []);

  return (
    <Card>
      <Title name={`${affiliation ? 'edit' : 'add'} an affiliation`} />

      <FormAffiliation.Wrapper noValidate>
        <FormAffiliation.Column>
          <Field
            label       = "name "
            name        = "affiliationName"
            component   = {Input}
            placeholder = "Enter name"
          />
          <Field
            label       = "website url"
            name        = "affiliationWebsiteUri"
            component   = {Input}
            placeholder = "Enter website URL"
          />
          <Field
            label          = "business category(s)"
            name           = "affiliationRCredentialBusinessCategory"
            placeholder    = "Assign business category(s)"
            endpoint       = "feature/category/search?name"
            responseMapper = {mapSearchResponseBusinessCategories}
            component      = {MultipleAutoSuggest}
          />
          <Field
            component   = {AreaSelection}
            label       = "assigned areas "
            name        = "affiliationRCredentialTerritory"
            placeholder = "Start searching area..."
          />
        </FormAffiliation.Column>

        <FormAffiliation.Column rightSide>
          <Field
            label           = "logo"
            name            = "affiliationLogoUri"
            type            = "thumbnailUri"
            labelAlignment  = "top"
            container       = "affiliations"
            component       = {MediaUploader}
            mimeTypeOptions = "image"
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '170px',
            }}
          />
          <FormButtons
            disabled      = {{ main: isFetching }}
            onClickCancel = {() => history.back()}
            onSubmit      = {submitForm}
          />
        </FormAffiliation.Column>
      </FormAffiliation.Wrapper>
    </Card>
  );
};

FormAffiliation.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

FormAffiliation.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;