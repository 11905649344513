import { useCallback, useEffect, useMemo  } from 'react';
import { useTranslation }                   from 'react-i18next';
import { useHistory }                       from 'react-router-dom';
import { connect }                          from 'react-redux';

import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';
import { ECardStatuses }     from '@common/enums';
import { routesConfig }      from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }       from '@components/Modal/DeleteModal';

import { ICategoryModel }  from '@src/models';
import { Modal }           from '@src/App';
import { IState }          from '@store/rootReducer';
import {
  fetchCategories  as fetchCategoriesAction,
  deleteCategories as deleteCategoriesAction,
  getCategories,
  getMeta,
  ICategoriesActionsCreators,
  ICategoriesReducer,
} from '../categoriesReducer';

interface ICategoriesList {
  categories       : ICategoryModel[];
  categoryMeta     : ICategoriesReducer['meta'];
  totalCategories  : number;
  fetchCategories  : ICategoriesActionsCreators['fetchCategories'];
  deleteCategories : ICategoriesActionsCreators['deleteCategories'];
  isFetching       : boolean;
}

export const CategoriesListContainer = ({
  categories,
  categoryMeta,
  totalCategories,
  fetchCategories,
  deleteCategories,
  isFetching,
}: ICategoriesList) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const onClickCategories = useCallback((category: ICategoryModel) => {
    history.push(routesConfig.categoriesEdit.endpoint.replace(':id', category.categoryId?.toString() || ''));
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchCategories({ page: page !== categoryMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [categoryMeta.page, categoryMeta.rowsPerPage]);

  const deleteCategory = useCallback(async (categoryId: (number | string)[]) => {
    deleteCategories(categoryId as number[]);
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons = {[{ action: () => history.push(routesConfig.categoriesAdd.endpoint), label: t('createCategory') }]}
      title   = {t('categories')}
    />
  ), []);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title', label: t('title') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (categories.map((category) => ({
    title : {
      onClick : () => onClickCategories(category),
      tag     : 'link',
      title   : category.name,
    },
    id : category.categoryId || 0,
  }))), [categories]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [{ flex: 1 }],
    pagination : {
      setPage         : onSetPage,
      current         : categoryMeta.page + 1,
      total           : totalCategories,
      pageSize        : categoryMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [categoryMeta.page, totalCategories, categoryMeta.rowsPerPage]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!categoryMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            const categoryForDeleting = categories.find(obj => (obj.categoryId === ids[0]));
            Modal.open(DeleteModal, {
              onDeleteConfirm : () => {
                deleteCategory(ids);
                Modal.close();
              },
              confirmMessage    : (ids.length === 1) ? 'The category will be removed and unlinked from all channels' : 'You cannot restore deleted data',
              entityNameMessage : `For removing enter the category fullname "${categoryForDeleting?.name}"`,
              name              : (ids.length === 1) ? `${categoryForDeleting?.name}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  categories      : getCategories(state),
  categoryMeta    : getMeta(state),
  totalCategories : getMeta(state).totalCategories,
  isFetching      : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  fetchCategories  : fetchCategoriesAction,
  deleteCategories : deleteCategoriesAction,
};

export const CategoriesList = connect(mapStateToProps, mapDispatchToProps)(CategoriesListContainer);
