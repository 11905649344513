import { ISvgIconOptions } from './index';

export default ({ color = '#545871', height = '21px', width = '23px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 495 450" fill={color}>
    <g>
      <g>
        <g>
          <path
            fill = {color}
            d    = "M448,106.67h-67.63L341.33,64H192v64h-64v64H64v213.33C64,428.8,83.2,448,106.67,448H448 c23.47,0,42.67-19.2,42.67-42.67v-256C490.67,125.87,471.47,106.67,448,106.67z M277.33,384c-58.88,0-106.67-47.79-106.67-106.67 s47.79-106.67,106.67-106.67S384,218.45,384,277.33S336.21,384,277.33,384z"
          />
          <polygon
            fill   = {color}
            points = "64,170.67 106.67,170.67 106.67,106.67 170.67,106.67 170.67,64 106.67,64 106.67,0 64,0 64,64  0,64 0,106.67 64,106.67"
          />
          <path
            fill = {color}
            d    = "M277.33,209.07c-37.76,0-68.27,30.51-68.27,68.27l0,0c0,37.76,30.51,68.27,68.27,68.27 s68.27-30.51,68.27-68.27S315.09,209.07,277.33,209.07z"
          />
        </g>
      </g>
    </g>
  </svg>
);
