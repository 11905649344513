import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { Button } from '../Button/Button';
import { Modal }  from '../Modal';

interface IConfirmationRecurringModalProps {
  onCancel : () => void;
  onSelect : (withRecurring: boolean) => void;
  /** First symbol should be in uppercase */
  entityName : string;
}

export const ConfirmationRecurringModal = ({
  entityName,
  onCancel,
  onSelect,
}: IConfirmationRecurringModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      onCancel = {onCancel}
      title    = {t(`updatingARepeating${entityName}`)}
    >
      <ConfirmationRecurringModal.Wrapper>
        <p>{t(`youAreUpdatingARecurring${entityName}`)} {t(`doYouWantToUpdateOnlyThis${entityName}OrAll`)}</p>
        <ConfirmationRecurringModal.Buttons>
          <Button
            bordered = {false}
            label    = {t('allOccurrences')}
            onClick  = {() => onSelect(true)}
          />
          <div />
          <Button
            bordered = {false}
            label    = {t('onlyThisOccurrence')}
            onClick  = {() => onSelect(false)}
          />
        </ConfirmationRecurringModal.Buttons>
      </ConfirmationRecurringModal.Wrapper>
    </Modal>
  );
};

ConfirmationRecurringModal.Buttons = styled.div`
  display: flex;

  > div {
    background-color : ${({ theme }) => theme.color.lightGray};
    width            : 1px;
  }
`;

ConfirmationRecurringModal.Wrapper = styled.div`
  align-items    : center;
  display        : flex;
  flex-direction : column;
  gap            : 20px;

  > p {
    color      : ${({ theme }) => theme.color.black};
    text-align : center;
  }
`;
