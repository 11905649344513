import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { NewsieRobotoAndLogo } from '../../../public/png';

export const Footer = () => {
  const { t } = useTranslation();

  const onClickContact       = () => window.open('mailto:support@localnewsie.com');
  const onClickPrivacyPolicy = () => window.open('https://localnewsie.com/privacy-policy');

  return (
    <Footer.Wrapper>
      <Footer.Container>
        <img src={NewsieRobotoAndLogo} alt="robot" />
        <Footer.Buttons>
          <p>© Newsie Inc. 2021</p>
          <Footer.Separator />
          <Footer.Policy onClick={onClickPrivacyPolicy}>
            {t('privacyPolicy')}
          </Footer.Policy>
          <Footer.Separator />
          <Footer.Contact onClick={onClickContact}>
            {t('contactNewsie')}
          </Footer.Contact>
        </Footer.Buttons>
      </Footer.Container>
    </Footer.Wrapper>
  );
};

Footer.Buttons = styled.div`
  align-items     : center;
  display         : flex;
  gap             : 5px;
  justify-content : center;
  margin-top      : 12px;

  p {
    color          : #fff;
    font-size      : 10px;
    letter-spacing : 0;
  }

  p:not(:first-child) { cursor: pointer; }
`;

Footer.Contact = styled.p``;

Footer.Container = styled.div`
  align-items     : center;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  height          : 100%;

  img { height: 62px; }
`;

Footer.Policy = styled.p``;

Footer.Separator = styled.div`
  background-color : #fff;
  height           : 9px;
  width            : 1px;
`;

Footer.Wrapper = styled.div`
  background-color : ${({ theme }) => theme.color.orange};
  height           : 179px;
  width            : 100%;
`;
