import { Form, Field, FormikProps } from 'formik';
import { useCallback }              from 'react';
import { useTranslation }           from 'react-i18next';
import styled                       from 'styled-components';

import {
  Card,
  Checkbox,
  DatePicker,
  FormButtons,
  Input,
  Select,
} from '@common/components';
import { TIME_ZONE_OPTIONS }   from '@common/components/DatePicker/DatePicker';
import { AreaSelection }       from '@components/AreaSelection/AreaSelection';
import { MultipleAutoSuggest } from '@components/Autosuggest/MultipleAutoSuggest';
import { Title }               from '@components/Title/Title';
import { MediaUploader }       from '@components/MediaUploader/MediaUploader';

import { IAdvertisingModel, IChannelModel } from '@src/models';
import { useFetchEntity }                   from '@utils/fetchEntity';

import { TAdvertisingFormModel } from '../pages/Advertising';

interface IAdvertisingFormProps extends FormikProps<TAdvertisingFormModel> {
  isFetching : boolean;
  submitForm : any;
}

export const AdvertisingForm = ({ isFetching, values, submitForm }: IAdvertisingFormProps) => {
  const { t } = useTranslation();

  const mapSearchResponseChannels = useCallback((data: IChannelModel[]) => (data.map((channel) => ({
    label : channel.name,
    value : channel.channelId,
  }))), []);

  const [advertising] = useFetchEntity('feature/ad') as [IAdvertisingModel | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${advertising ? 'edit the' : 'add a'} sponsor`} />
      <AdvertisingForm.Wrapper noValidate>
        <AdvertisingForm.Column>
          <Field
            label       = "name"
            name        = "name"
            placeholder = "Enter a name"
            component   = {Input}
          />
          <Field
            withTime  = {!values.allDay}
            timeZone  = {values.timeZone}
            label     = "starts"
            name      = "dateStart"
            component = {DatePicker}
          />
          <Field
            withTime  = {!values.allDay}
            timeZone  = {values.timeZone}
            label     = "ends"
            name      = "dateEnd"
            component = {DatePicker}
          />
          <Field
            required    = {false}
            label       = "time zone"
            name        = "timeZone"
            placeholder = ""
            options     = {TIME_ZONE_OPTIONS}
            component   = {Select}
          />
          <Field
            required      = {false}
            label         = "url"
            name          = "websiteUri"
            nameSecondary = "(optional)"
            placeholder   = "http://"
            component     = {Input}
          />
          <Field
            label     = {t('allDay')}
            name      = "allDay"
            component = {Checkbox}
          />
        </AdvertisingForm.Column>

        <AdvertisingForm.Column rightSide>
          <Field
            media
            multi           = {false}
            label           = "image or video"
            name            = "image"
            container       = "slides"
            component       = {MediaUploader}
            mimeTypeOptions = {['image', 'video']}
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '165px',
            }}
          />
          <Field
            label          = "add to channel(s)"
            name           = "rAdChannel"
            placeholder    = "Assign Channel(s)"
            endpoint       = "feature/channel/search?name"
            responseMapper = {mapSearchResponseChannels}
            component      = {MultipleAutoSuggest}
          />
          <Field
            component = {AreaSelection}
            name      = "rAdTerritory"
          />
          <FormButtons
            disabled      = {{ main: isFetching }}
            onClickCancel = {() => history.back()}
            onSubmit      = {submitForm}
          />
        </AdvertisingForm.Column>
      </AdvertisingForm.Wrapper>
    </Card>
  );
};

AdvertisingForm.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

AdvertisingForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
