import { combineReducers, Reducer, AnyAction } from 'redux';
import { History }                             from 'history';
import { connectRouter, RouterState }          from 'connected-react-router';
import { persistReducer, createMigrate }       from 'redux-persist';
import storage                                 from 'redux-persist/lib/storage';

import authReducer, { IAuthReducer }                     from '@modules/auth/authReducer';
import feedsReducer, { IFeedsReducer }                   from '@modules/feeds/feedsReducer';
import storiesReducer, { IStoriesReducer }               from '@modules/stories/storiesReducer';
import businessesReducer, { IBusinessesReducer }         from '@modules/businesses/businessesReducer';
import channelsReducer, { IChannelsReducer }             from '@modules/channels/channelsReducerL';
import territoriesReducer, { ITerritoriesReducer }       from '@modules/territories/territoriesReducerL';
import eventsReducer, { IEventsReducer }                 from '@modules/events/eventsReducer';
import categoriesReducer, { ICategoriesReducer }         from '@modules/categories/categoriesReducer';
import affiliationsReducer, { IAffiliationsReducer }     from '@modules/affiliations/affiliationsReducer';
import sliderReducer, { ISliderReducer }                 from '@modules/slider/sliderReducer';
import formsReducer, { IFormsReducer }                   from '@modules/forms/formsReducer';
import requestedFeedsReducer, { IRequestedFeedsReducer } from '@modules/feeds/requestedFeedsReducer';
import licensesReducer, { ILicensesReducer }             from '@modules/licenses/licensesReducer';
import advertisingReducer, { IAdvertisingReducer }       from '@modules/advertisments/advertisingReducer';
import tipsReducer, { ITipsReducer }                     from '@modules/tips/tipsReducer';
import tagsReducer, { ITagsReducer }                     from '@modules/tags/tagsReducer';

export interface IState {
  router         : RouterState;
  auth           : IAuthReducer;
  feeds          : IFeedsReducer;
  stories        : IStoriesReducer;
  businesses     : IBusinessesReducer;
  channels       : IChannelsReducer;
  territories    : ITerritoriesReducer;
  events         : IEventsReducer;
  categories     : ICategoriesReducer;
  affiliations   : IAffiliationsReducer;
  slider         : ISliderReducer;
  forms          : IFormsReducer;
  requestedFeeds : IRequestedFeedsReducer;
  licenses       : ILicensesReducer;
  advertising    : IAdvertisingReducer;
  tips           : ITipsReducer;
  tags           : ITagsReducer;
}

export type RootReducer = Reducer<IState, AnyAction>;

const VERSION = 14;

const migrations = {
  [VERSION]: (state: any) => {
    // migration clear out state
    return ({ auth: state.auth });
  },
};

const persistReducerHandler = (reducer: any, stateKey: string, blacklist?: string[]) => {

  return persistReducer(
    {
      storage,
      blacklist,
      key     : `root/${stateKey}`,
      version : VERSION,
      migrate : createMigrate(migrations as any, { debug: true }),
    },
    reducer,
  );
};

export const rootReducer = (history: History): RootReducer => {

  return combineReducers({
    router         : connectRouter(history),
    auth           : persistReducerHandler(authReducer, 'auth', ['meta']) as unknown as typeof authReducer,
    feeds          : feedsReducer,
    stories        : storiesReducer,
    businesses     : businessesReducer,
    channels       : channelsReducer,
    territories    : territoriesReducer,
    events         : eventsReducer,
    categories     : categoriesReducer,
    affiliations   : affiliationsReducer,
    slider         : sliderReducer,
    forms          : formsReducer,
    requestedFeeds : requestedFeedsReducer,
    licenses       : licensesReducer,
    advertising    : advertisingReducer,
    tips           : tipsReducer,
    tags           : tagsReducer,
  });
};
