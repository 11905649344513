import { useMemo, useCallback, useEffect } from 'react';
import { connect }                         from 'react-redux';
import { Formik, FormikProps }             from 'formik';
import * as Yup                            from 'yup';
import styled                              from 'styled-components';

import { CircularLoader } from '@components/Loaders/CircularLoader';
import { IElement }       from '@components/List/FormList';

import { ICategoryModel }       from '@models/index';
import { IState }               from '@store/rootReducer';
import { callApi }              from '@utils/apiCaller';
import { useFetchEntity }       from '@utils/fetchEntity';
import { findForbiddenSymbols } from '@utils/validators';

import {
  fetchSubcategories as fetchSubcategoriesAction,
  addCategory        as addCategoryAction,
  editCategory       as editCategoryAction,
  addSubcategory     as addSubcategoryAction,
  editSubcategory    as editSubcategoryAction,
  deleteSubcategory  as deleteSubcategoryAction,
  getSubcategories,
  getMeta,
  ICategoriesActionsCreators,
}                       from '../categoriesReducer';
import { FormCategory } from '../components/FormCategory';

interface ICategoriesComponent {
  subcategories      : ICategoryModel[];
  fetchSubcategories : ICategoriesActionsCreators['fetchSubcategories'];
  addCategory        : ICategoriesActionsCreators['addCategory'];
  editCategory       : ICategoriesActionsCreators['editCategory'];
  addSubcategory     : ICategoriesActionsCreators['addSubcategory'];
  editSubcategory    : ICategoriesActionsCreators['editSubcategory'];
  deleteSubcategory  : ICategoriesActionsCreators['deleteSubcategory'];
  isFetching         : boolean;
  isSubFetching      : boolean;
}

export const validateCategoryName = (isSubcategory: boolean, initialName?: string) => {
  return Yup.string()
    .test(...findForbiddenSymbols())
    .test(
      'is-uniq',
      `${isSubcategory ? 'Subcategory' : 'Category'} name already exists and should be unique`,
      async (value) => {
        if (initialName === value) return true;

        try {
          const { data: { isUnique } } = await callApi(`feature/category/is-unique?categoryName=${encodeURIComponent(value as string)}`, 'get');

          return isUnique;
        } catch (e) {
          return false;
        }
      },
    )
    .required(`Enter ${isSubcategory ? 'subcategory' : 'category'} name`);
};

const CategoriesComponent = ({
  subcategories,
  fetchSubcategories,
  addCategory,
  editCategory,
  addSubcategory,
  editSubcategory,
  deleteSubcategory,
  isFetching,
  isSubFetching,
}: ICategoriesComponent) => {
  const [category, isCategoryFetching, callFetchHook] = useFetchEntity('feature/Category') as [ICategoryModel | null, boolean, () => Promise<void>];

  interface IFormCategory {
    categoryId? : number;
    name        : string;
    parentId?   : number | null;
    iconUri     : { imageUri: string; mimeType: string } | null;
  }

  const initialValues = useMemo<IFormCategory>(() => ({
    iconUri : category && {imageUri: category.iconUri, mimeType: 'image'},
    name    : category?.name || '',
  }), [category]);

  const validationSchema = useMemo(() => Yup.object().shape({
    name    : validateCategoryName(false, category?.name),
    iconUri : Yup.object()
      .shape({ imageUri: Yup.string().required() })
      .typeError('Upload Your Image')
      .required(),
  }), [category]);

  const subcategoriesData = useMemo<IElement[]>(() => {
    return subcategories
      .map((subcategory) => ({
        key        : subcategory.categoryId as number,
        categoryId : subcategory.categoryId,
        parentId   : category?.categoryId,
        name       : subcategory.name,
        primary    : subcategory.name,
        icon       : subcategory.iconUri,
      }));
  }, [subcategories, category]);

  const onSubmit = useCallback((values: IFormCategory) => {
    const nextValue = {...values, iconUri: values.iconUri?.imageUri };
    if (category) {
      editCategory({ ...nextValue, categoryId: category.categoryId });
    } else {
      addCategory(nextValue);
    }
  }, [category]);

  useEffect(() => {
    if (category?.categoryId) {
      fetchSubcategories(category?.categoryId);
    }
  }, [category]);

  useEffect(() => {
    if (!isFetching && category) {
      callFetchHook();
    }
  }, [isFetching]);

  if (isCategoryFetching) return (
    <Container>
      <CircularLoader />
    </Container>
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        validateOnChange = {false}
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<any>) => (
          <FormCategory
            {...props}
            parentId          = {category?.categoryId}
            isFetching        = {isFetching}
            isEditMode        = {!!category}
            subcategoriesData = {subcategoriesData}
            addSubcategory    = {addSubcategory}
            editSubcategory   = {editSubcategory}
            deleteSubcategory = {deleteSubcategory}
            isSubFetching     = {isSubFetching}
          />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 940px; }
`;

const mapStateToProps = (state: IState) => ({
  subcategories : getSubcategories(state),
  isFetching    : getMeta(state).isFetching,
  isSubFetching : getMeta(state).isSubFetching,
});

const mapDispatchToProps = {
  fetchSubcategories : fetchSubcategoriesAction,
  addCategory        : addCategoryAction,
  editCategory       : editCategoryAction,
  addSubcategory     : addSubcategoryAction,
  editSubcategory    : editSubcategoryAction,
  deleteSubcategory  : deleteSubcategoryAction,
};

export const Categories = connect(mapStateToProps, mapDispatchToProps)(CategoriesComponent);
