import { useCallback }              from 'react';
import { useTranslation }           from 'react-i18next';
import { Form, Field, FormikProps } from 'formik';
import styled                       from 'styled-components';

import {
  Card,
  RecurrenceEditor,
  Checkbox,
  Select,
  DatePicker,
  Input,
  PlacesAutocomplete,
  FormButtons,
}                                                     from '@common/components';
import { TIME_ZONE_OPTIONS }                          from '@common/components/DatePicker/DatePicker';

import { AreaSelection }                              from '@components/AreaSelection/AreaSelection';
import { EntityStatusModal, IEntityStatusFormValues } from '@components/EntityStatus/EntityStatus';
import { AutoSuggest }                                from '@components/Autosuggest/Autosuggest';
import { MediaUploader }                              from '@components/MediaUploader/MediaUploader';
import { Title }                                      from '@components/Title/Title';
import { Wysiwyg }                                    from '@components/Wysiwyg/Wysiwyg';

import { IBusinessLocation, IEventModel } from '@src/models';
import { useFetchEntity }                 from '@utils/fetchEntity';
import { callApi }                        from '@utils/apiCaller';

import { TEventFormValues } from '../pages/EventsContainer';

interface IFormEvent extends FormikProps<TEventFormValues> {
  isFetching : boolean;
  submitForm : any;
}

export const FormEvent = ({ values, isFetching, setFieldValue, submitForm }: IFormEvent) => {
  const { t } = useTranslation();

  const mapSearchResponseBusinessLocation = useCallback((data: IBusinessLocation[]) => {
    return data.map((item) => ({ value: item.businessLocationId, label: item.name }));
  }, []);

  const onChangeEventStatus = useCallback(async (statusValues: IEntityStatusFormValues) => {
    if (!values.eventId) { return; }

    try {
      await callApi(`feature/event/status?eventId=${values.eventId}`, 'patch', statusValues);
      setFieldValue('status', statusValues.status);
    } catch (error) {
      console.error();
    }
  }, [setFieldValue, values]);

  const [event] = useFetchEntity('feature/Event') as [IEventModel | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${event ? 'edit' : 'add'} an event`} />
      <FormEvent.Wrapper noValidate>
        <FormEvent.Column>
          <Field
            required
            label       = "title"
            name        = "title"
            placeholder = "Enter a title"
            component   = {Input}
          />
          <Field
            component = {AreaSelection}
            name      = "rEventTerritory"
          />
          <Field
            required
            label       = "time zone"
            name        = "timeZone"
            placeholder = ""
            options     = {TIME_ZONE_OPTIONS}
            component   = {Select}
          />
          <Field
            withTime  = {!values.allDay}
            timezone  = {values.timeZone}
            label     = "starts"
            name      = "dateStart"
            component = {DatePicker}
          />
          <Field
            required  = {false}
            withTime  = {!values.allDay}
            timezone  = {values.timeZone}
            label     = "ends"
            name      = "dateEnd"
            component = {DatePicker}
          />
          <Field
            required    = {false}
            label       = "url"
            name        = "websiteUri"
            placeholder = "http://"
            component   = {Input}
          />
          <Field
            required    = {false}
            label       = "Address"
            name        = "location.address1"
            placeholder = "Start searching address..."
            component   = {PlacesAutocomplete}
          />
          <Field
            required    = {false}
            label       = "tags"
            name        = "tags"
            placeholder = "#tag #tag"
            component   = {Input}
          />
          <FormEvent.Checkboxes>
            <Field
              label     = {t('addRegularRepeats')}
              name      = "withRegularRepeats"
              component = {Checkbox}
            />
            <Field
              label     = {t('allDay')}
              name      = "allDay"
              component = {Checkbox}
            />
          </FormEvent.Checkboxes>
          {values.withRegularRepeats && (
            <Field
              withResizer = {false}
              name        = "eventRecurrence.recurrenceRule"
              component   = {RecurrenceEditor}
            />
          )}
        </FormEvent.Column>

        <FormEvent.Column rightSide>
          <Field
            required
            options     = {['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link']}
            label       = "description"
            name        = "description"
            placeholder = "Enter a description"
            component   = {Wysiwyg}
          />
          <Field
            required
            label              = "preview image"
            name               = "rEventImage"
            container          = "events"
            resizerAspectRatio = {0}
            component          = {MediaUploader}
            mimeTypeOptions    = "image"
            imageStyle       = {{
              backgroundColor : '#E6E9F4',
              height          : '207px',
            }}
          />
          <Field
            required        = {false}
            label           = "business"
            name            = "businessLocation"
            placeholder     = "Search for a business branch by name ..."
            component       = {AutoSuggest}
            endpoint        = "feature/businesslocation/search?name"
            fieldName       = "businessLocationId"
            responseMapper  = {mapSearchResponseBusinessLocation}
          />
        </FormEvent.Column>
      </FormEvent.Wrapper>
      <FormEvent.FooterButtons>
        {values.eventId && (
          <EntityStatusModal
            entityName = {values.title}
            onSubmit   = {onChangeEventStatus}
            status     = {values.status}
          />
        )}
        <FormButtons
          disabled      = {{main: isFetching}}
          onClickCancel = {() => history.back()}
          onSubmit      = {submitForm}
        />
      </FormEvent.FooterButtons>
    </Card>
  );
};

FormEvent.FooterButtons = styled.div`
  display        : flex;
  flex-direction : column;
  margin-top     : 10px;

  div { width: 100%; }
`;

FormEvent.Checkboxes = styled.div`
  display         : flex;
  justify-content : space-between;

  > div { margin: 28px 0 9px; }
`;

FormEvent.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : start;
  `}
`;

FormEvent.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
