import styled from 'styled-components';

import { NewsieRunningRobot } from '../../../../public/png';

export const LoginWrapper = ({ children }: { children: React.ReactNode }) => (
  <LoginWrapper.Wrapper>
    <LoginWrapper.Robot>
      <img src={NewsieRunningRobot} alt="newsie-character" />
    </LoginWrapper.Robot>
    {children}
  </LoginWrapper.Wrapper>
);

LoginWrapper.Robot = styled.div`
  align-items      : center;
  background-color : ${({ theme }) => theme.color.orange};
  display          : flex;
  justify-content  : center;

  > img {
    max-height : 70%;
    max-width  : 70%;
  }
`;

LoginWrapper.Wrapper = styled.div`
  align-items     : center;
  display         : flex;
  justify-content : center;
  height          : 100%;
  width           : 100%;

  > div {
    flex   : 1;
    height : 100%;
  }
`;
