import { useEffect, useState } from 'react';
import styled                  from 'styled-components';

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { theme as Theme } from '../../styles';
import { ECardStatuses }  from '../../enums';
import { debounce }       from '../../utils';

interface ICardStatus {
  status        : ECardStatuses;
  wrapperStyle? : (theme: typeof Theme) => string;
}

export const CardStatus = ({ status, wrapperStyle }: ICardStatus) => {
  const [currentStatus, setStatus] = useState<ECardStatuses>(status);

  const [isVisible, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    debounce(() => {
      if (isVisible) {
        setVisibility(false);
        debounce(() => setStatus(ECardStatuses.None), 500);
      }
    }, 2000);
  }, [status]);

  useEffect(() => {
    if (status === ECardStatuses.None) {
      setVisibility(false);
    } else {
      setStatus(status);
      setVisibility(true);
    }
  }, [status]);

  return (
    <CardStatus.Wrapper
      currentStatus = {currentStatus}
      isVisible     = {isVisible}
      wrapperStyle  = {wrapperStyle}
    >
      <div>
        {currentStatus === ECardStatuses.Pending && <LoadingOutlined color="black" />}
        {currentStatus === ECardStatuses.Success && <CheckCircleOutlined color="green" />}
        {currentStatus === ECardStatuses.Failure && <ExclamationCircleOutlined color="red" />}
      </div>
    </CardStatus.Wrapper>
  );
};

CardStatus.Wrapper = styled.div<{ isVisible: boolean; currentStatus: ECardStatuses; wrapperStyle: ICardStatus['wrapperStyle'] }>`
  display         : flex;
  justify-content : center;
  left            : 0;
  opacity         : ${({ isVisible }) => (isVisible ? '0.8' : '0')};
  position        : absolute;
  top             : ${({ isVisible }) => (isVisible ? '20px' : '0')};
  transition      : all 0.5s;
  width           : 100%;

  > div {
    border-radius : 50%;
    box-shadow    : 0 0 3px 0 ${({ theme }) => theme.color.gray};
    max-height    : 34px;
    padding       : 7px;
  }

  svg {
    height     : ${({ isVisible }) => (isVisible ? '20px' : '5px')};
    transition : all 0.7s;
    width      : ${({ isVisible }) => (isVisible ? '20px' : '5px')};
    color      : ${({ currentStatus, theme }) => (currentStatus === ECardStatuses.Failure && theme.color.red)
      || (currentStatus === ECardStatuses.Success && theme.color.green)
      || theme.color.blue};
  }

  ${({ wrapperStyle, theme }) => wrapperStyle && wrapperStyle(theme)}
`;
