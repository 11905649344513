import axios from 'axios';

type TAxiosStatusHandlers = (
  handlers: { onUnauthorized: () => void; }
) => void;

export const initializeAxiosStatusHandlers: TAxiosStatusHandlers = (handlers) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        handlers.onUnauthorized();
      }

      return Promise.reject(error);
    },
  );
};
