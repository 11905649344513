import { Badge, Popover } from 'antd';
import React, { useMemo } from 'react';
import styled             from 'styled-components';

import { setPopupContainer } from '../../utils';
import { AlertBellIcon }     from '../../../public/svg/index';

interface NotificationsBellProps {
  content            : React.ReactNode;
  notificationsCount : number;
}

export const NotificationsBell = ({ content, notificationsCount }: NotificationsBellProps) => {
  const popoverDisabled = useMemo(() => !content || notificationsCount === 0, [content, notificationsCount]);

  return (
    <NotificationsBell.Wrapper cursor={popoverDisabled ? 'default' : 'pointer'}>
      <Popover
        content           = {content}
        getPopupContainer = {setPopupContainer}
        trigger           = {['click']}
        visible           = {popoverDisabled ? false : undefined}
      >
        <Badge count={notificationsCount}>
          <AlertBellIcon height="22px" width="22px" />
        </Badge>
      </Popover>
    </NotificationsBell.Wrapper>
  );
};

NotificationsBell.Wrapper = styled.div<{ cursor: string }>`
  cursor : ${({ cursor }) => cursor};
  margin : 3px 40px 0 0;

  .ant-scroll-number-only { user-select: none; }

  .ant-popover-inner { border-radius: 0 0 10px 10px; }
`;
