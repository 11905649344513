import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  ElementType,
}             from 'react';
import styled from 'styled-components';

import { Modal as AntModal } from 'antd';

import { theme as Theme }          from '@styles/theme';
import { ECardStatuses }           from '@common/enums';
import { CardHeader, CardStatus }  from '@common/components';
import { DeniedIcon as CloseIcon } from '@common/svg';

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update      = useCallback(() => {
    setTick(tick => tick + 1);
  }, []);
  return update;
}

interface ModalProps {
  bodyStyle? : (theme: typeof Theme) => string;
  children   : React.ReactNode;
  onCancel   : () => void;
  status?    : ECardStatuses;
  title?     : string;
  width?     : string;
}

export const createModalInstance = () => {
  const Modal: any = ({
    bodyStyle,
    status,
    title,
    width,
  }: ModalProps) => {
    const forceUpdate = useForceUpdate();
    const content: any = useRef(null);
    const [open, setOpen] = useState(false);
    useEffect(() => {
      Modal.open = (Component: ElementType, data: any) => {
        content.current = <Component {...data} />;
        setOpen(true);
      };
    
      Modal.close = () => {
        setOpen(false);
        content.current = null;
      };

      Modal.update = (Component: ElementType, data: any) => {
        content.current = <Component {...data} />;
        forceUpdate();
      };
    }, []);

    useEffect(() => {
      Modal.isOpen = open;
    }, [open]);

    return (
      <AntModal
        maskClosable   = {true}
        destroyOnClose = {true}
        visible        = {open}
        footer         = {null}
        onCancel       = {Modal.close}
        width          = {width}
        style          = {{
          display        : 'flex',
          justifyContent : 'center',
        }}
      >
        <ModalBody bodyStyle={bodyStyle}>
          {status && (
            <CardStatus
              status       = {status || ECardStatuses.None}
              wrapperStyle = {() => 'transform: translateY(-10px);'}
            />
          )}
          {title && (
            <ModalHeader>
              <CardHeader title={title} />
              <CloseIconWrapper onClick={Modal.close}>
                <CloseIcon
                  color  = {Theme.color.darkGray}
                  height = "14px"
                  width  = "14px"
                />
              </CloseIconWrapper>
            </ModalHeader>
          )}
          <ModalContent paddingTop={title ? '31px' : '0'}>
            {content.current || <></>}
          </ModalContent>
        </ModalBody>
      </AntModal>
    );
  };

  return Modal;
};

const CloseIconWrapper = styled.div`
  cursor: pointer;
`;

const ModalContent = styled.div<{ paddingTop: string }>`
  padding: ${({ paddingTop }) => `${paddingTop} 40px 0`};
`;

const ModalHeader = styled.div`
  align-items     : center;
  border-bottom   : 1px solid ${({ theme }) => theme.color.lightGray};
  display         : flex;
  justify-content : space-between;
  padding         : 0 45px 20px 40px;
  text-transform  : uppercase;

  h2 {
    font-size   : 16px;
    line-height : 21px;
  }

  > div:first-child { margin-bottom: 0; }
`;

const ModalBody = styled.div<{ bodyStyle: ModalProps['bodyStyle'] }>`
  overflow : hidden;
  position : relative;

  ${({ bodyStyle, theme }) => bodyStyle && bodyStyle(theme)}
`;
