export enum ECardStatuses {
  Initialization,
  Pending,
  Success,
  Failure,
  None,
}

export enum ERequestedEntityStatus {
  New,
  Approve,
  Deny,
  RequestChanges,
}

export enum ELocales {
  Us   = 'us',
  Gb   = 'gb',
  UsEs = 'us-es'
}

export enum ESortOrder {
  Asc  = 'Asc',
  Desc = 'Desc',
}
