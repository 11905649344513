import { useCallback, useMemo } from 'react';
import { useTranslation }       from 'react-i18next';
import styled                   from 'styled-components';

import { useSelectedItems } from '../../hooks';
import { theme as Theme }   from '../../styles';

import { FooterButtons, FooterButtonsProps } from '../Button/FooterButtons';
import { Checkbox }                          from '../Checkbox';
import { Empty }                             from '../Empty';
import { Pagination, IPaginationProps }      from '../Pagination';
import {
  ClickableItem,
  StatusItem,

  IClickableItem,
  IconItem,
  IIconItem,
  IStatusItem,
  ICustomTableComponent,
} from './TableItems';

type TBasicType   = boolean | null | number | string | undefined;
type TContentItem = TBasicType | IClickableItem | IIconItem | IStatusItem | ICustomTableComponent;

export interface TableProps {
  buttons? : (FooterButtonsProps['buttons'][0] & { tag?: 'clone' })[];
  /**
   * It's better to work with fullItems because itemsIds are not as flexible as fullItems,
   * so it would be better to remove itemsIds in future.
   * */
  onDelete? : (itemsIds: (number | string)[], fullItems: TableProps['content']) => void;
  content : ({
    [key: string]     : TContentItem;
    // id and every fields after it won't be rendered
    id                : number | string;
    deletingDisabled? : boolean;
  })[];
  headers : {
    id    : string;
    label : string;
  }[];
  settings : {
    content?    : { gap: string; }
    pagination  : IPaginationProps;
    scrollable? : boolean;
    columns     : {
      flex      : number;
      minWidth? : number;
    }[];
  };
}

export const Table = ({
  content,
  headers,
  onDelete,
  settings,
  buttons = [],
}: TableProps) => {
  const { t } = useTranslation();

  const [selected, onSelect, getStatus] = useSelectedItems(settings.pagination.current, content);

  const onClickDelete = useCallback(() => {
    const itemsForDeleting = Object
      .values(selected)
      .reduce((prev, current) => [...prev, ...current], []);

    const itemsIdsForDeleting = itemsForDeleting.map((item) => item.id);

    if (itemsForDeleting.length && onDelete) {
      onDelete(itemsIdsForDeleting, itemsForDeleting);
    }
  }, [onDelete, selected]);

  const footerButtons = useMemo<FooterButtonsProps['buttons']>(() => {
    const result = [];

    if (!!content.length && onDelete) {
      result.push({ color: Theme.color.red, label: t('delete'), onClick: onClickDelete });
    }

    buttons.forEach((button) => {
      if (button.tag === 'clone') {
        const selectedIdsOnPage = Object.values(selected)[settings.pagination.current - 1];

        if (selectedIdsOnPage && selectedIdsOnPage.length === 1) {
          result.push({ ...button, onClick: () => button.onClick(selectedIdsOnPage[0]) });
        }
      } else {
        result.push(button);
      }
    });

    return result;
  }, [buttons, content, onDelete, onClickDelete, selected]);

  const renderColumnItem = useCallback((item: TContentItem) => {
    if (typeof item === 'boolean' || typeof item === 'undefined') {
      return null;
    } else if (!item) {
      return <p>-</p>;
    } else if (typeof item === 'string' || typeof item === 'number') {
      return <p>{item}</p>;
    } else if (item.tag === 'icon') {
      return <IconItem {...item} />;
    } else if (item.tag ===  'status') {
      return <StatusItem {...item} />;
    } else if (item.tag === 'component') {
      return item.component;
    }

    return <ClickableItem {...item} />;
  }, []);

  return (
    <div>
      <Table.Row headerType>
        {!!onDelete && (
          <Checkbox
            disabled     = {!content.length}
            setValue     = {(value) => onSelect(value, 'main')}
            value        = {getStatus('main')}
            wrapperStyle = {{ margin: '0 10px 0 0' }}
          />
        )}
        {headers.map((item, index) => (
          <Table.Column
            headerType
            flex     = {settings.columns[index].flex}
            key      = {item.id}
            minWidth = {settings.columns[index].minWidth}
          >
            <h3>{item.label}</h3>
          </Table.Column>
        ))}
      </Table.Row>
      <Table.Content
        gap        = {settings.content?.gap || '30px'}
        scrollable = {settings.scrollable}
      >
        {!content.length && <Empty />}
        {content.map((row) => (
          <Table.Row
            key        = {`${row.id}`}
            scrollable = {settings.scrollable}
          >
            {!!onDelete && (
              <Checkbox
                disabled     = {row.deletingDisabled}
                setValue     = {(value) => onSelect(value, row)}
                value        = {getStatus(row.id)}
                wrapperStyle = {{
                  alignSelf : 'flex-start',
                  margin    : '1px 10px 0 0',
                }}
              />
            )}
            {Object.values(row).map((column, index) => (index > Object.keys(row).indexOf('id') - 1 ? null : (
              <Table.Column
                flex     = {settings.columns[index].flex}
                key      = {`value_${column}_row_${row.id}_column_${index.toString()}`}
                minWidth = {settings.columns[index].minWidth}
              >
                {renderColumnItem(column)}
              </Table.Column>
            )))}
          </Table.Row>
        ))}
      </Table.Content>
      {!!footerButtons.length && (
        <FooterButtons
          buttons = {footerButtons}
          margin  = "30px 0 0"
        />
      )}
      {!!settings.pagination && (
        <Pagination
          {...settings.pagination}
          margin = {footerButtons.length ? '25px 0 0 8px' : '30px 0 0 8px'}
        />
      )}
    </div>
  );
};

Table.Row = styled.div<{ headerType?: boolean, scrollable?: boolean }>`
  align-items      : center;
  background-color : ${({ headerType }) => (headerType ? '#f4f7fc' : 'transparent')};
  display          : flex;
  height           : ${({ headerType }) => (headerType ? '48px' : 'auto')};
  padding-left     : 16px;

  ${({ scrollable }) => scrollable && `
    padding-bottom : 2px;
    padding-top    : 2px;

    &:hover { background-color: #f4f7fc; }; }
  `}
`;

Table.Column = styled.div<{ flex: number; headerType?: boolean, minWidth?: number }>`
  flex      : ${({ flex }) => flex};
  min-width : ${({ minWidth }) => (minWidth || 50)}px;

  > p,
  h3 {
    font-size      : ${({ headerType }) => (headerType ? 12 : 14)}px;
    line-height    : ${({ headerType }) => (headerType ? 18 : 20)}px;
    margin-right   : 15px;
    overflow       : hidden;
    text-overflow  : ellipsis;
    text-transform : ${({ headerType }) => (headerType ? 'uppercase' : 'none')};
    white-space    : nowrap;
  }
`;

Table.Checkbox = styled.div`
  background-color : blue;
  width            : 16px;
  height           : 16px;
  border-radius    : 3px;
  margin-right     : 10px;
`;

Table.Content = styled.div<{ gap: string; scrollable?: boolean }>`
  box-shadow     : ${({ theme }) => `0 2px 2px -2px ${theme.color.lightGray}`};
  display        : flex;
  flex-direction : column;
  gap            : ${({ gap }) => gap};
  padding        : ${({ gap }) => gap} 0;

  ${({ scrollable }) => scrollable && `
    max-height : calc(100vh - 500px);
    overflow-y : auto;
  `}
`;
