import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';

import { Table, TableProps }         from '@common/components/Table/Table';
import { Card, CardHeader }          from '@common/components/Card';
import { ECardStatuses, ESortOrder } from '@common/enums';
import { routesConfig }              from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }               from '@components/Modal/DeleteModal';
import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
} from '@common/components/FiltrationSorting';

import { IAffiliationModel }  from '@src/models';
import { Modal }              from '@src/App';
import { IState }             from '@store/rootReducer';
import { useFetchQueryRules } from '@hooks/useFetchQueryRules';
import {
  getMeta,
  getAffiliations,
  IAffiliationActionsCreators,
  IAffiliationsReducer,
  fetchAffiliations  as fetchAffiliationsAction,
  deleteAffiliations as deleteAffiliationsAction,
} from '../affiliationsReducer';

export interface IAffiliations {
  affiliations       : IAffiliationModel[];
  affiliationMeta    : IAffiliationsReducer['meta'];
  fetchAffiliations  : IAffiliationActionsCreators['fetchAffiliations'];
  deleteAffiliations : IAffiliationActionsCreators['deleteAffiliations'];
}

const sortOptions = [{ label: 'Name', key: 'name', value: 'name' }];

const filterOptions: IFilterOption[] = [];

const AffiliationsListContainer = ({
  affiliations,
  affiliationMeta,
  fetchAffiliations,
  deleteAffiliations,
}: IAffiliations) => {
  const history = useHistory();
  const { t }   = useTranslation();
  const [popoverVisible, setPopoverStatus] = useState(false);
  
  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'affiliations-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Asc, label: sortOptions[0].label } },
  );

  const onApplyFilters = useCallback((newFilter: any) => {
    if (newFilter.sorting) {
      fetchAffiliations({ ...affiliationMeta, orderBy: newFilter.sorting.value, order: newFilter.sorting.order });
    } else {
      fetchAffiliations({ ...affiliationMeta });
    }
  }, [affiliationMeta]);

  const onClickAffiliation = useCallback((event: IAffiliationModel) => {
    history.push(routesConfig.affiliationsEdit.endpoint.replace(':id', event.credentialId.toString()));
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchAffiliations({ page: page !== affiliationMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [affiliationMeta.page, affiliationMeta.rowsPerPage]);

  const deleteAffiliation = useCallback(async (credentialIds: (number | string)[]) => {
    deleteAffiliations(credentialIds as number[]);
    fetchAffiliations();
  }, []);
  
  const header = useMemo(() => (
    <CardHeader
      buttons = {[{ action: () => history.push(routesConfig.affiliationAdd.endpoint), label: t('createAffiliation') }]}
      title   = {t('affiliations')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {handlers.deleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title', label: t('title') },
    { id: 'icon',  label: t('logo') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (affiliations.map((affiliation) => ({
    title : {
      onClick : () => onClickAffiliation(affiliation),
      tag     : 'link',
      title   : affiliation.name || '',
    },
    icon : {
      onClick :  () => onClickAffiliation(affiliation),
      tag     : 'icon',
      icon    : <img 
        key   = {affiliation.credentialId}
        src   = {affiliation.logoUri}
        style = {{maxWidth: '100%', height: '20px'}} 
      /> || '',
    },
    id : affiliation.credentialId,
  }))), [affiliations]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 5, minWidth: 300 },
      { flex: 3 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : affiliationMeta.page + 1,
      total           : affiliationMeta?.totalAffiliations,
      pageSize        : affiliationMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [affiliationMeta.page, affiliationMeta.totalAffiliations, affiliationMeta.rowsPerPage]);

  useEffect(() => {
    fetchAffiliations();
  }, []);

  useEffect(() => {
    setPopoverStatus(false);
    onApplyFilters(rules);
  }, [rules]);

  return (
    <>
      <Card
        header = {header}
        status = {affiliationMeta.isFetching ? ECardStatuses.Pending : ECardStatuses.None}
        style  = {{ width: '100%' }}
      >
        {!affiliationMeta.isFetching && (
          <Table
            content  = {tableContent}
            headers  = {tableHeaders}
            settings = {tableSettings}
            onDelete = {(ids: (string | number)[]) => Modal.open(DeleteModal, {
              onDeleteConfirm : () => {
                deleteAffiliation(ids);
                Modal.close();
              },
              confirmMessage    : (ids.length === 1) ? 'The affiliation will be removed' : 'You cannot restore deleted data',
              entityNameMessage : `For removing enter the affiliation ID "${ids}"`,
              name              : (ids.length === 1) ? `${ids[0]}` : '',
            })}
          />
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  affiliations    : getAffiliations(state),
  affiliationMeta : getMeta(state),
});

const mapDispatchToProps = {
  fetchAffiliations  : fetchAffiliationsAction,
  deleteAffiliations : deleteAffiliationsAction,
};

export const AffiliationsList = connect(mapStateToProps, mapDispatchToProps)(AffiliationsListContainer);
