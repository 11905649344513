import React, { useCallback,  useState } from 'react';
import styled                            from 'styled-components';
import { Form, Field, Formik }           from 'formik';
import * as Yup                          from 'yup';

import { Button, Input } from '@common/components';
import { Notifications } from '@components/Notifications/Notifications';

import { Modal }           from '@src/App';
import { IPublisherModel } from '@src/models';
import { callApi }         from '@utils/apiCaller';

import { MediaUploader }   from '../MediaUploader/MediaUploader';

type TUsePublisherHandlers = [
  (data: any[]) => {
    value  : any;
    label  : any;
    action : {
      name    : string;
      onClick : () => void;
    };
  }[],
  (initialValues?: Partial<IPublisherModel> | undefined) => void,
];

const Container = styled.div<{ ref: any }>`
  background-color : #fff;
  padding-bottom   : 20px;

  @media (max-width: 1284px) { width: 402.5px; }
`;

const Title = styled.h1`
  color      : ${({ theme }) => theme.textColor.blue};
  text-align : center;
  margin     : 0;
  font-size  : 30px;
`;

const ButtonWrapper = styled.div`
  width           : 100%;
  display         : flex;
  justify-content : end;
  align-items     : end;
  padding-top     : 25px;
`;

const WarningText = styled.p`
  margin-left : 200px;
  color       : ${({ theme }) => theme.textColor.red};
`;

const AddPublisherColumn = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : start;
  `}
`;

const AddPublisherWrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;

const validationSchema = Yup.object().shape({
  name       : Yup.string()
    .typeError('Enter publisher name')
    .required(),
  iconUri    : Yup.object()
    .typeError('Upload publisher icon')
    .required(),
  websiteUri : Yup.string()
    .typeError('Enter publisher link')
    .required(),
});

export const AddPublisher = React.forwardRef(({
  onAdd,
  onDelete,
  isLoading,
  onAddOption,
  initialValues = {},
}: any, ref) => {
  const [confirmDelete, setConfirmDeleteStatus] = useState(false);
  const onSubmit = useCallback((values) => {
    onAdd({...values, iconUri: values.iconUri.imageUri});
    onAddOption();
  }, []);
  const onDeleteInternal = useCallback(() => {
    if (confirmDelete) {
      onDelete(initialValues.publisherId);
    } else {
      setConfirmDeleteStatus(true);
    }
  }, [initialValues, confirmDelete, setConfirmDeleteStatus]);

  return (
    <Container ref={ref} tabIndex={1}>
      <Formik
        initialValues    = {{
          publisherId : initialValues.publisherId || null,
          name        : initialValues.name || null,
          websiteUri  : initialValues.websiteUri || null,
          iconUri     : {imageUri: initialValues.iconUri, mimeType: 'image'} || null,
        }}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
        render           = {() => (
          <AddPublisherWrapper>
            <Title>Add Publisher</Title>
            <AddPublisherColumn>
              <Field
                isRequired
                label     = "name"
                name      = "name"
                component = {Input}
              />
              <Field
                isRequired
                label     = "url"
                name      = "websiteUri"
                component = {Input}
              />
            </AddPublisherColumn>

            <AddPublisherColumn rightSide>
              <Field
                isRequired
                label           = "icon image"
                name            = "iconUri"
                labelAlignment  = "top"
                container       = "publisher"
                component       = {MediaUploader}
                mimeTypeOptions = "image"
                imageStyle      = {{
                  backgroundColor : '#E6E9F4',
                  height          : '210px',
                }}
              />
            </AddPublisherColumn>
            
            {confirmDelete && <WarningText>Warning: all feeds from this publisher will be deleted</WarningText>}
            <ButtonWrapper>
              {initialValues.publisherId && (
                <Button
                  type           = "button"
                  onClick        = {onDeleteInternal}
                  label          = {confirmDelete ? 'Confirm' : 'Delete'}
                  disabled       = {isLoading}
                  componentStyle = {{margin: '0 20px 0 0'}}
                />
              )}
              <Button
                label      = {initialValues.publisherId ? 'Edit' : 'Add'}
                disabled   = {isLoading}
              />
            </ButtonWrapper>
          </AddPublisherWrapper>
        )}
      />
    </Container>
  );
});

export function usePubliherHandlers(setFieldValue: any): TUsePublisherHandlers {
  const onSubmitPublisher = useCallback(async (formValues, isEdit = false) => {
    try {
      Modal.update(AddPublisher, {
        initialValues : { publisherId: isEdit ? formValues.publisherId : null },
        isLoading     : true,
      });
      const { data: { publisherId, name } } = await callApi('feature/publisher', isEdit ? 'put' : 'post', formValues);
      
      setFieldValue('publisher.value', publisherId, false);
      setFieldValue('publisher.label', name, false);

      Modal.close();
      Notifications.enqueueSnackbar(`Publisher was ${isEdit ? 'edited' : 'added'} successfully`, 'success');
    } catch (e) {
      Notifications.enqueueSnackbar(`Ooops... Something went wrong while ${isEdit ? 'editing' : 'adding'} Publisher. Please try again`, 'error');
      Modal.update(AddPublisher, { onAdd: onSubmitPublisher, isLoading: false });
    }
  }, []);

  const onDeletePublisher          = useCallback(async (publisherId) => {
    try {
      Modal.update(AddPublisher, {
        onDelete      : onDeletePublisher,
        initialValues : { publisherId },
        isLoading     : true,
      });
      await callApi('feature/publisher', 'delete', publisherId);

      Modal.close();
      Notifications.enqueueSnackbar('Publisher was deleted successfully', 'success');
    } catch (e) {
      Notifications.enqueueSnackbar('Ooops... Something went wrong while deleting Publisher. Please try again', 'error');
      Modal.update(AddPublisher, { onAdd: onSubmitPublisher, isLoading: false });
    }
  }, []);

  const mapSearchResponsePublisher = useCallback((data: any[]) => {
    return data.map((p: any) => ({
      value  : p.publisherId,
      label  : p.name,
      action : {
        name    : 'edit',
        onClick : () => {
          Modal.open(AddPublisher, {
            onAdd         : (formValues: any) => onSubmitPublisher(formValues, true),
            onDelete      : onDeletePublisher,
            initialValues : p,
            isLoading     : false,
          });
        },
      },
    }));
  }, []);

  const onAddPublisher = useCallback((initialValues?: Partial<IPublisherModel>) => {
    Modal.open(AddPublisher, {
      initialValues,
      isLoading : false,
      onAdd     : onSubmitPublisher,
    });
  }, []);

  return [mapSearchResponsePublisher, onAddPublisher];
}
