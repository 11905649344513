import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }       from '@utils/apiCaller';
import { Notifications } from '@components/Notifications/Notifications';
import { Modal }         from '@src/App';

import {
  fetchCategories,
  fetchCategoriesSuccessfully,
  fetchCategoriesFailure,
  addCategory,
  addCategorySuccessfully,
  addCategoryFailure,
  editCategory,
  editCategorySuccessfully,
  editCategoryFailure,
  deleteCategories,
  deleteCategoriesSuccessfully,
  deleteCategoriesFailure,

  fetchSubcategories,
  fetchSubcategoriesSuccessfully,
  fetchSubcategoriesFailure,
  addSubcategory,
  addSubcategorySuccessfully,
  addSubcategoryFailure,
  editSubcategory,
  editSubcategorySuccessfully,
  editSubcategoryFailure,
  deleteSubcategory,
  deleteSubcategorySuccessfully,
  deleteSubcategoryFailure,

  getMeta,

  ICategoriesActions,
} from './categoriesReducer';

function* handleFetchCategories() {
  try {
    const { page, rowsPerPage, orderBy, order } = yield select(getMeta);
    const { data: { items, totalCount } }       = yield callApi(`feature/category?page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}`);

    yield put(fetchCategoriesSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchCategoriesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Categories. Please try again', 'error');
  }
}

function* handleAddCategory(action: ICategoriesActions['addCategory']) {
  try {
    const values = action.payload.attributes;

    const { data : { categoryId }} = yield callApi('feature/category', 'post', values);
    yield put(fetchCategories());
    yield put(replace(`/admin/categories/${categoryId}`));
    yield put(addCategorySuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Category was added successfully', 'success');
  } catch (e) {
    yield put(addCategoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Category. Please try again', 'error');
  }
}

function* handleEditCategory(action: ICategoriesActions['editCategory']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/category', 'put', values);
    yield put(editCategorySuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Category was edited successfully', 'success');
  } catch (e) {
    yield put(editCategoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Category. Please try again', 'error');
  }
}

function* handleDeleteCategories(action: ICategoriesActions['deleteCategories']) {
  try {
    const { categoryIds } = action.payload.attributes;

    for (let i = 0; i < categoryIds.length; i++) {
      yield callApi('feature/category', 'delete', categoryIds[i]);
    }

    yield put(deleteCategoriesSuccessfully());
    yield put(fetchCategories());
    yield call(Notifications.enqueueSnackbar, `${categoryIds.length > 1 ? 'Categories were' : 'Category was'} was deleted successfully`, 'success');
  } catch (e: any) {
    const { categoryIds } = action.payload.attributes;

    yield put(deleteCategoriesFailure());
    yield call(
      Notifications.enqueueSnackbar,
      e.response.data || `Ooops... Something went wrong while deleting ${categoryIds.length > 1 ? 'Categories' : 'a Category'}. Please try again`, 'error',
    );
  }
}

// subcategories

function* handleFetchSubcategories(action: ICategoriesActions['fetchSubcategories']) {
  try {
    const parentId = action.payload.attributes.categoryId;
    const { data } = yield callApi('feature/category/search', 'get', { parentId });

    yield put(fetchSubcategoriesSuccessfully(data));
  } catch (e) {
    yield put(fetchSubcategoriesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Subcategories. Please try again', 'error');
  }
}

function* handleAddSubcategory(action: ICategoriesActions['addSubcategory']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/category', 'post', values);
    yield put(fetchSubcategories(values.parentId));
    yield put(addSubcategorySuccessfully());
    yield call(Modal.close);
    yield call(Notifications.enqueueSnackbar, 'Subcategory was added successfully', 'success');
  } catch (e) {
    yield put(addSubcategoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Subcategory. Please try again', 'error');
  }
}

function* handleEditSubcategory(action: ICategoriesActions['editSubcategory']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/category', 'put', values);
    yield put(fetchSubcategories(values.parentId));
    yield put(editSubcategorySuccessfully());
    yield call(Modal.close);
    yield call(Notifications.enqueueSnackbar, 'Subcategory was edited successfully', 'success');
  } catch (e) {
    yield put(editSubcategoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Subcategory. Please try again', 'error');
  }
}

function* handleDeleteSubcategory(action: ICategoriesActions['deleteSubcategory']) {
  try {
    const { categoryId, parentId } = action.payload.attributes;

    yield callApi('feature/category', 'delete', categoryId);
    yield put(deleteSubcategorySuccessfully());
    yield put(fetchSubcategories(parentId));
    yield call(Modal.close);
    yield call(Notifications.enqueueSnackbar, 'Subcategory was deleted successfully', 'success');
  } catch (e) {
    yield put(deleteSubcategoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while deleting a Subcategory. Please try again', 'error');
  }
}

function* categoriesSagas() {
  yield all([
    takeLatest(fetchCategories, handleFetchCategories),
    takeLatest(addCategory, handleAddCategory),
    takeLatest(editCategory, handleEditCategory),
    takeLatest(deleteCategories, handleDeleteCategories),

    takeLatest(fetchSubcategories, handleFetchSubcategories),
    takeLatest(addSubcategory, handleAddSubcategory),
    takeLatest(editSubcategory, handleEditSubcategory),
    takeLatest(deleteSubcategory, handleDeleteSubcategory),
  ]);
}

export { categoriesSagas };
