import { useCallback }     from 'react';
import { useHistory }      from 'react-router-dom';

import styled            from 'styled-components';
import { NewsieLogo }    from '@common/svg';

interface HeaderProps {
  maxWidth?: string;
}

export const Header = ({ maxWidth }: HeaderProps) => {
  const history       = useHistory();
  const onClickLogOut = useCallback(() => {
    localStorage.clear();
    history.replace('/login');
  }, []);

  return (
    <Header.Wrapper>
      <Header.Container maxWidth={maxWidth}>
        <Header.LeftSide>
          <NewsieLogo />
        </Header.LeftSide>
        <Header.Logout onClick={onClickLogOut}>log out</Header.Logout>
      </Header.Container>
    </Header.Wrapper>
  );
};

Header.Container = styled.div<{ maxWidth?: string; }>`
  align-items     : center;
  height          : 100%;
  display         : flex;
  justify-content : space-between;
  margin          : 0 auto;
  padding         : ${({ maxWidth }) => (maxWidth ? '0' : '0 28px')};
  width           : 100%;

  ${({ maxWidth }) => `max-width: ${maxWidth};`}
`;

Header.LeftSide = styled.div`
  > img { width: 106px; }
`;

Header.Wrapper = styled.div`
  box-shadow : 0 1px 4px #15223214;
  height     : 68px;
  width      : 100%;
  z-index    : 300;
`;

Header.Logout = styled.p`
  color          : #f0552a;
  text-transform : capitalize;
  cursor         : pointer;

  &:hover {
    font-size        : 18px;
    transition-delay : 0.3s;
    font-weight      : 500;
  }
`;
