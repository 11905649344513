import { useCallback, useEffect, useMemo, useState }        from 'react';
import styled                                               from 'styled-components';

import { FormList, IElement, IList } from '@components/List/FormList';
import { CircularLoader }            from '@components/Loaders/CircularLoader';
import { ClickableItem }             from '@components/Table/ClickableItem';

import { TerritoryFilterItem } from '@modules/territories/components/TerritoryFilter';
import { TERRITORY_TYPES }     from '@modules/territories/pages/AddTerritoryContainerL';

import { IChannelModel, ITerritoryModel } from '@src/models';
import { callApi }                        from '@utils/apiCaller';

interface IChannelsList {
  categoryChannels   : IChannelModel[] | null;
  channelCategoryId  : number;
  isChannelsUpdating : boolean;
  onDelete           : (channelId: number, channelCategoryId: number) => void;
  onEdit             : (element: IElement) => void;
}

function useFetchData<TFetchedData>(url: string | null): [TFetchedData[], boolean, () => void] {
  const [data, setData]                 = useState<TFetchedData[]>([]);
  const [isFetching, setFetchingStatus] = useState(false);

  const endpoint = useMemo(() => url, [url]);

  const fetchData = useCallback(async () => {
    setFetchingStatus(true);
    try {
      if (endpoint) {
        const { data }: { data: { items: TFetchedData[]; totalCount: number } | TFetchedData[] } = await callApi(endpoint);

        setData(Array.isArray(data) ? data : data.items);
      } else {
        setData([]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error, 'Error | useFetchData');
    }
    setFetchingStatus(false);
  }, [endpoint]);

  useEffect(() => {
    if (!isFetching) {
      fetchData();
    }
  }, [endpoint]);

  return [data, isFetching, fetchData];
}

export const ChannelsList = ({ categoryChannels, channelCategoryId, isChannelsUpdating, onDelete, onEdit }: IChannelsList) => {
  const [selectedCountry, setCountry] = useState<ITerritoryModel | null>(null);
  const [selectedState, setState]     = useState<ITerritoryModel | null>(null);

  const [countries, isCountriesFetching]              = useFetchData<ITerritoryModel>(categoryChannels ? null : 'feature/territory?itemsPerPage=999');
  const [states, isStatesFetching]                    = useFetchData<ITerritoryModel>(selectedCountry ? `feature/territory?itemsPerPage=999&parentId=${selectedCountry.territoryId}` : null);
  const [channels, isChannelsFetching, fetchChannels] = useFetchData<IChannelModel>(selectedState ? `feature/channel/city-specific-by-state/${selectedState.territoryId}` : null);

  const isDataFetching = useMemo(() => (
    isCountriesFetching || isStatesFetching || isChannelsFetching || isChannelsUpdating
  ), [isCountriesFetching, isStatesFetching, isChannelsFetching, isChannelsUpdating]);

  const deleteConfig = useMemo<IList['deleteConfig']>(() => ({
    action  : (element: IElement) => onDelete(element.key, channelCategoryId),
    message : 'When deleting a channel, make sure that all other entities that use this channel will not be harmed!',
  }), [channelCategoryId]);

  const mappedChannels = useMemo(() => {
    return (categoryChannels || channels).map((channel) => ({
      key     : channel.channelId as number,
      primary : channel.name,
      icon    : channel.iconUri,
      source  : channel,
    }));
  }, [categoryChannels, channels]);

  const onClickCloseTerritory = useCallback((parentTerritoryId: number | null) => {
    if (!parentTerritoryId) {
      setCountry(null);
      setState(null);
    } else {
      setState(null);
    }
  }, []);

  const onClickTerritory = useCallback((territory: ITerritoryModel) => {
    if (territory.type === TERRITORY_TYPES.COUNTRY) {
      setCountry(territory);
    } else {
      setState(territory);
    }
  }, []);

  useEffect(() => {
    if (!isChannelsUpdating && !isChannelsFetching && selectedState) {
      fetchChannels();
    }
  }, [isChannelsUpdating]);

  return (
    <ChannelsList.Wrapper isFilterVisible={!!(selectedState || selectedCountry)}>
      <ChannelsList.TerritoryFilterItem>
        {[selectedCountry, selectedState].map((item) => !!item && (
          <TerritoryFilterItem
            key              = {item.territoryId}
            label            = {item.name}
            onClickClearIcon = {() => onClickCloseTerritory(item.parentId || null)}
          />
        ))}
      </ChannelsList.TerritoryFilterItem>
      <ChannelsList.Shadow isFilterVisible={!!(selectedState || selectedCountry)} />
      <ChannelsList.ScrollView isFilterVisible={!!(selectedState || selectedCountry)}>
        {isDataFetching ? (
          <CircularLoader padding="100px 0 0 19px" />
        ) : (
          <>
            {!mappedChannels.length && !!(selectedState || categoryChannels) && (
              <ChannelsList.NoChannelsText>
                No channels were found.
              </ChannelsList.NoChannelsText>
            )}
            {!!mappedChannels.length && (
              <FormList
                data         = {mappedChannels}
                deleteConfig = {deleteConfig}
                onEdit       = {onEdit}
              />
            )}
            {!selectedState && ((selectedCountry && states) || countries).map((item: ITerritoryModel) => (
              <ChannelsList.TerritoryItem
                key     = {item.territoryId}
                onClick = {() => onClickTerritory(item)}
              >
                <ClickableItem
                  label   = {item.name}
                  onClick = {() => null}
                />
              </ChannelsList.TerritoryItem>
            ))}
          </>
        )}
      </ChannelsList.ScrollView>
      <ChannelsList.Shadow isBottom />
    </ChannelsList.Wrapper>
  );
};

ChannelsList.NoChannelsText = styled.p`
  font-size  : 16px;
  margin-top : 30px;
  text-align : center;
`;

ChannelsList.Shadow = styled.div<{ isBottom?: boolean; isFilterVisible?: boolean }>`
  box-shadow : ${({ isBottom }) => (isBottom ? '0px -2px 2px #888;' : '0px 2px 2px #888;')};
  height     : 1px;
  margin-top : ${({ isFilterVisible }) => (isFilterVisible ? '10px' : '0')};
  width      : 100%;
`;

ChannelsList.ScrollView = styled.div<{ isFilterVisible: boolean }>`
  height     : ${({ isFilterVisible }) => (isFilterVisible ? 'calc(100vh - 446px)' : 'calc(100vh - 412px)')};
  overflow-y : auto;
  overflow-x : hidden;

  > div {
    margin-top  : 0;
    margin-left : 0;
  }
`;

ChannelsList.TerritoryFilterItem = styled.div`
  display: flex;

  > div:not(:first-child) { margin-left: 10px; }
`;

ChannelsList.TerritoryItem = styled.div`
  align-items   : center;
  border-bottom : 1px solid rgba(0, 0, 0, 0.15);
  cursor        : pointer;
  display       : flex;
  font-size     : 16px;
  height        : 30px;
  padding       : 2px 0 0 10px;

  &:first-child { border-top: 1px solid rgba(0, 0, 0, 0.15); }

  &:hover { background-color: #f3f3f3; }
`;

ChannelsList.Wrapper = styled.div<{ isFilterVisible: boolean }>`
  margin      : 0 10px 0 10px;
  padding-top : ${({ isFilterVisible }) => (isFilterVisible ? '20px' : '30px')};
`;
