import { useState, useCallback, useEffect } from 'react';

import { INewFiltrationRule } from '@common/components/FiltrationSorting';
import { TOption }            from '@common/models';
import { ESortOrder }         from '@models/enums';

export interface ITableDataRules {
  filtration : { [key: string]: Omit<INewFiltrationRule, 'key'> },
  sorting    : (TOption & { order : ESortOrder }) | null,
}

type IUseFetchQueryRules = (
  sortOptions       : TOption[],
  sessionStorageKey : string,
  initialRules?     : ITableDataRules,
) => {
  rules    : ITableDataRules,
  handlers : {
    deleteFiltrationRule : (ruleKey: string) => void;
    setSortingRule       : (newRule: NonNullable<ITableDataRules['sorting']>) => void;
    setFiltrationRule    : (newRule: INewFiltrationRule) => void;
  }
}

export const useFetchQueryRules: IUseFetchQueryRules = (sortOptions, sessionStorageKey, initialRules) => {
  const [rules, setRules] = useState<ITableDataRules>({ filtration: {}, sorting: null });

  const deleteFiltrationRule = useCallback((ruleKey: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [ruleKey]: unused, ...newFiltrationRules } = rules.filtration;

    setRules({ ...rules, filtration: newFiltrationRules });
  }, [rules]);

  const setSortingRule = useCallback((newRule: NonNullable<ITableDataRules['sorting']>) => {
    setRules({ ...rules, sorting: newRule });
  }, [rules]);

  const setFiltrationRule = useCallback((newRule: INewFiltrationRule) => {
    const { key, label, value } = newRule;

    const newValue = (Array.isArray(value))
      ? [...(rules.filtration[key]?.value || []) as (string | TOption)[], ...value]
      : value;

    setRules({ ...rules, filtration: { ...rules.filtration, [key]: { label, value: newValue } } });
  }, [rules]);

  useEffect(() => {
    const savedRules = window.sessionStorage.getItem(sessionStorageKey);
    const parsedRules: ITableDataRules | null = savedRules ? JSON.parse(savedRules) : null;

    if (!parsedRules || (sortOptions.length && !parsedRules.sorting)) {
      setRules(initialRules || { ...rules, sorting: { ...sortOptions[0], order: ESortOrder.Asc } });
      return;
    }

    setRules(parsedRules);
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(rules));
  }, [rules]);

  return {
    rules,
    handlers : {
      deleteFiltrationRule,
      setSortingRule,
      setFiltrationRule,
    },
  };
};
