import { Field, Form }    from 'formik';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

import { Button, Checkbox, Input } from '../../../components';
import { NewsieLogo }              from '../../../../public/svg';

interface LoginFormProps {
  isFetching             : boolean;
  passwordRecovery?      : boolean;
  onClickForgotPassword? : () => void;
  onClickContactNewsie?  : () => void;
}

export const LoginForm = ({
  isFetching,
  passwordRecovery,
  onClickContactNewsie,
  onClickForgotPassword,
}: LoginFormProps) => {
  const { t } = useTranslation();

  return (
    <LoginForm.Wrapper>
      <NewsieLogo />
      <h3>{passwordRecovery ? t('passwordRecovery') : t('login')}</h3>
      <LoginForm.Form fetching={isFetching}>
        <LoginForm.Fields>
          {isFetching && (
            <LoginForm.LoaderWrapper>
              <LoadingOutlined />
            </LoginForm.LoaderWrapper>
          )}
          <Field
            component    = {Input}
            disabled     = {!!passwordRecovery || isFetching}
            label        = {t('email')}
            name         = "email"
            placeholder  = {t('enterYourEmail')}
            type         = "email"
            wrapperStyle = {() => 'margin: 50px 0 0'}
          />
          <Field
            component   = {Input}
            disabled    = {isFetching}
            label       = {t('password')}
            name        = "password"
            placeholder = {t('enterYourPassword')}
            type        = "password"
          />
          {passwordRecovery && (
            <Field
              component   = {Input}
              disabled    = {isFetching}
              label       = {t('confirmPassword')}
              name        = "passwordCopy"
              placeholder = {t('enterYourPasswordAgain')}
              type        = "password"
            />
          )}
        </LoginForm.Fields>
        <LoginForm.Buttons passwordRecovery={passwordRecovery}>
          {!passwordRecovery && (
            <Field
              component = {Checkbox}
              disabled  = {isFetching}
              label     = {t('rememberMe')}
              name      = "rememberActive"
            />
          )}
          <Button
            disabled = {isFetching}
            label    = {passwordRecovery ? t('submit') : t('login')}
          />
        </LoginForm.Buttons>
      </LoginForm.Form>
      {!passwordRecovery && (
        <LoginForm.Footer>
          <Button bordered={false} label={t('forgotPassword')} onClick={onClickForgotPassword} />
          <Button bordered={false} label={t('contactNewsie')} onClick={onClickContactNewsie} />
        </LoginForm.Footer>
      )}
    </LoginForm.Wrapper>
  );
};

LoginForm.Buttons = styled.div<{ passwordRecovery?: boolean; }>`
  align-items     : center;
  display         : flex;
  justify-content : ${({ passwordRecovery }) => (passwordRecovery ? 'flex-end' : 'space-between')};
  margin-top      : 52px;
`;

LoginForm.Footer = styled.div`
  display         : flex;
  justify-content : center;
  height          : 20px;
  margin-top      : 112px;

  > button {
    background-color : transparent;
    border           : 'none';
    border-radius    : 0;
    height           : 20px;
    padding          : 0 7px;

    &:last-child { border-left: 1px solid #5a607f; }
  }
`;

LoginForm.Fields = styled.div`
  position: relative;
`;

LoginForm.Form = styled(Form)<{ fetching: boolean }>`
  width: 100%;

  > div:last-child,
  > div > div:not(:first-child) {
    opacity    : ${({ fetching }) => (fetching ? 0.1 : 1)};
    transition : all 0.3s;
  }
`;

LoginForm.LoaderWrapper = styled.div`
  align-items     : center;
  display         : flex;
  font-size       : 40px;
  height          : 100%;
  justify-content : center;
  position        : absolute;
  width           : 100%;

  > span {
    color   : ${({ theme }) => theme.color.orange};
    z-index : 999;
  }
`;

LoginForm.Wrapper = styled.div`
  align-items      : center;
  background-color : #f4f7fc;
  display          : flex;
  flex-direction   : column;
  font-family      : ${({ theme }) => theme.font.primary};
  justify-content  : center;
  min-width        : 433px;

  > h3 {
    color       : ${({ theme }) => theme.color.black};
    font-size   : 35px;
    font-weight : 600;
    line-height : 49px;
    margin-top  : 91px;
  }

  > svg,
  form,
  h3 { width: 403px; }

  input {
    background-color: #f4f7fc;

    &:hover,
    &:focus { background-color: #f4f7fc; }
  }
`;
