import { Field, Form, Formik, FormikProps }      from 'formik';
import { useCallback, useMemo, useState }        from 'react';
import styled                                    from 'styled-components';
import { Modal }                                 from 'antd';

import { ERequestedReviewStatus } from '@models/enums';
import { Button, Input, Select }  from '@common/components';

export interface IEntityStatusFormValues {
  emailText : string;
  status    : ERequestedReviewStatus;
}

interface IEntityStatusModalProps extends Pick<IEntityStatusFormValues, 'status'> {
  onSubmit   : (values: IEntityStatusFormValues) => Promise<void> | void | null;
  entityName : string;
}

const getRequestStatusOptions = () => Object
  .keys(ERequestedReviewStatus)
  .slice(4)
  .map((item, index) => ({ label: item, value: index }));

export const EntityStatusModal = ({ entityName, onSubmit, status }: IEntityStatusModalProps) => {
  const [isFetching, setFetchingStatus] = useState(false);
  const [isModalActive, setModalStatus] = useState(false);

  const currentStatus = useMemo(() => getRequestStatusOptions()[status].label, [status]);

  const initialValues = useMemo<IEntityStatusFormValues>(() => ({
    status,
    emailText : '',
  }), []);

  const saveStatus = useCallback(async (values: IEntityStatusFormValues) => {
    setFetchingStatus(true);
    await onSubmit(values);
    setModalStatus(false);
    setFetchingStatus(false);
  }, [onSubmit]);

  return (
    <EntityStatusModal.Wrapper>
      {isModalActive && (
        <Modal
          onCancel  = {() => setModalStatus(false)}
          visible   = {true}
          footer    = {null}
          style     = {{
            display        : 'flex',
            alignItems     : 'center',
            justifyContent : 'center',
          }}
        >
          <EntityStatusModal.Content>
            <EntityStatusModal.Label>
              <p>{entityName}</p>
              <p>(current: {currentStatus})</p>
            </EntityStatusModal.Label>
            <Formik
              initialValues = {initialValues}
              onSubmit      = {() => setModalStatus(true)}
            >
              {({ ...props }: FormikProps<IEntityStatusFormValues>) => (
                <Form>
                  <Field
                    component   = {Select}
                    name        = "status"
                    options     = {getRequestStatusOptions()}
                    placeholder = "Select status"
                  />
                  <Field
                    multiline
                    component   = {Input}
                    name        = "emailText"
                    placeholder = "Enter email message here if it's necessary"
                    type        = "textarea"
                  />
                  <EntityStatusModal.FormButtons>
                    <Button
                      disabled = {isFetching}
                      label    = "Save"
                      onClick  = {() => saveStatus({ ...props.values })}
                      type     = "button"
                    />
                  </EntityStatusModal.FormButtons>
                </Form>
              )}
            </Formik>
          </EntityStatusModal.Content>
        </Modal>
      )}
      <EntityStatusModal.Button>
        <Button
          label   = "Change status"
          onClick = {() => setModalStatus(true)}
          type    = "button"
        />
        <p>* Current: {currentStatus}</p>
      </EntityStatusModal.Button>
    </EntityStatusModal.Wrapper>
  );
};

EntityStatusModal.Button = styled.div`
  display         : flex;
  justify-content : start;
  position        : relative;
  margin-top      : 15px;
  margin-bottom   : 5px;

  > div { width: auto; }

  p {
    bottom    : 5px;
    font-size : 12px;
    left      : 130px;
    opacity   : 0.8;
    position  : absolute;
    overflow  : hidden;
  }
`;

EntityStatusModal.FormButtons = styled.div`
  display         : flex;
  justify-content : center;
  margin-top      : 20px;
  gap             : 20px;

  > div { width: auto; }

  button { padding: 4px 35px; }
`;

EntityStatusModal.Content = styled.div`
  background-color : white;
  max-width        : 550px;
  padding          : 20px;
`;

EntityStatusModal.Label = styled.div`
  display : flex;
  width   : 500px;
  gap     : 5px;

  p:first-child {
    font-weight   : 600;
    font-size     : 22px;
    max-width     : 300px;
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }

  p:last-child {
    font-weight    : 400;
    opacity        : 0.7;
    text-transform : lowercase;
    font-size      : 15px;
    align-self     : center;
  }
`;

EntityStatusModal.Wrapper = styled.div``;
