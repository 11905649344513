import { format }                                    from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect }                                   from 'react-redux';
import { useHistory }                                from 'react-router-dom';
import { useTranslation }                            from 'react-i18next';

import { Card, CardHeader }             from '@common/components';
import { ECardStatuses, ESortOrder }    from '@common/enums';
import { getDateISOWithTimeZoneOffset } from '@common/components/DatePicker/DatePicker';
import { Table, TableProps }            from '@common/components/Table/Table';
import { 
  FiltrationSorting, 
  FiltrationSortingInfo, 
  IFilterOption,
} from '@common/components/FiltrationSorting';

import { routesConfig }                 from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }                  from '@components/Modal/DeleteModal';

import { IAdvertisingModel }   from '@src/models';
import { Modal }               from '@src/App';
import { IState }              from '@store/rootReducer';
import { useFetchQueryRules }  from '@hooks/useFetchQueryRules';

import {
  fetchAdvertising  as fetchAdvertisingAction,
  deleteAdvertising as deleteAdvertisingAction,
  getAdvertising,
  getMeta,
  IAdvertisingActionsCreators,
  IAdvertisingReducer,
} from '../advertisingReducer';

interface IAdvertisingListProps {
  advertising       : IAdvertisingModel[];
  advertisingMeta   : IAdvertisingReducer['meta'];
  deleteAdvertising : IAdvertisingActionsCreators['deleteAdvertising'];
  fetchAdvertising  : IAdvertisingActionsCreators['fetchAdvertising'];
}

const sortOptions = [
  { label: 'Title',  key: 'Name',      value: 'Name' },
  { label: 'Starts', key: 'DateStart', value: 'DateStart' },
  { label: 'Ends',   key: 'DateEnd',   value: 'DateEnd' },
];

const filterOptions: IFilterOption[] = [];

const AdvertisingListC = ({
  advertising,
  advertisingMeta,
  deleteAdvertising,
  fetchAdvertising,
}: IAdvertisingListProps) => {
  const history = useHistory();
  const { t }   = useTranslation();
  
  const [popoverVisible, setPopoverStatus] = useState(false);
  
  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'advertising-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Asc, label: sortOptions[0].label } },
  );

  const onApplyFilters = useCallback((newFilter: any) => {
    if (newFilter.sorting) {
      fetchAdvertising({ ...advertisingMeta, orderBy: newFilter.sorting.value, order: newFilter.sorting.order });
    } else {
      fetchAdvertising({ ...advertisingMeta });
    }
  }, [advertisingMeta]);

  const onClickAdvertisement = useCallback((advertisement: IAdvertisingModel) => {
    history.push(routesConfig.adEdit.endpoint.replace(':id', advertisement.adId.toString()));
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchAdvertising({ page: page !== advertisingMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [advertisingMeta.page, advertisingMeta.totalAdvertising, advertisingMeta.rowsPerPage]);

  const deleteAdvertisement = useCallback(async (adId: (number | string)[]) => {
    deleteAdvertising(adId as number[]);
  }, []);
  
  const header = useMemo(() => (
    <CardHeader
      buttons           = {[{ action: () => history.push(routesConfig.adAdd.endpoint), label: t('addSponsor') }]}
      title             = {t('sponsors')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {handlers.deleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title',  label: t('title') },
    { id: 'starts', label: t('starts') },
    { id: 'ends',   label: t('ends') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (advertising.map((advertisement) => ({
    title : {
      onClick : () => onClickAdvertisement(advertisement),
      tag     : 'link',
      title   : advertisement.name || '',
    },
    starts: format(new Date(getDateISOWithTimeZoneOffset(new Date(advertisement.dateStart), advertisement.timeZone, true)), 'MMM dd, yyyy'),
    ends  : format(new Date(getDateISOWithTimeZoneOffset(new Date(advertisement.dateEnd), advertisement.timeZone, true)), 'MMM dd, yyyy'),
    id    : advertisement.adId,
  }))), [advertising]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 5, minWidth: 300 },
      { flex: 3 },
      { flex: 3 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : advertisingMeta.page + 1,
      total           : advertisingMeta?.totalAdvertising,
      pageSize        : advertisingMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [advertisingMeta.page, advertisingMeta.totalAdvertising, advertisingMeta.rowsPerPage]);

  useEffect(() => {
    fetchAdvertising();
  }, []);

  useEffect(() => {
    setPopoverStatus(false);
    onApplyFilters(rules);
  }, [rules]);

  return (
    <Card
      header = {header}
      status = {advertisingMeta.isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{ width: '100%' }}
    >
      {!advertisingMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => Modal.open(DeleteModal, {
            onDeleteConfirm : () => {
              deleteAdvertisement(ids);
              Modal.close();
            },
            confirmMessage    : (ids.length === 1) ? 'The sponsor will be removed' : 'You cannot restore deleted data',
            entityNameMessage : `For removing enter the sponsor ID "${ids}"`,
            name              : (ids.length === 1) ? `${ids[0]}` : '', 
          })}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  advertising     : getAdvertising(state),
  advertisingMeta : getMeta(state),
});

const mapDispatchToProps = {
  deleteAdvertising : deleteAdvertisingAction,
  fetchAdvertising  : fetchAdvertisingAction,
};

export const AdvertisingList = connect(mapStateToProps, mapDispatchToProps)(AdvertisingListC);
