import React, { useCallback, useState } from 'react';
import { useTranslation }               from 'react-i18next';
import styled                           from 'styled-components';

import { CopyOutlined } from '@ant-design/icons';

import { theme as Theme } from '../../styles/theme';

import { Card } from './Card';

interface ICardDetails {
  details   : IDetailsElement[];
  fetching? : boolean;
  title     : string;
}

export interface IDetailsElement {
  color?   : string;
  label    : string;
  onClick? : () => void;
  tags?    : string[]
  value    : string | JSX.Element | null;
}

export const CardDetails = ({ details, fetching, title }: ICardDetails) => {
  const { t } = useTranslation();

  const [copiedText, setCopiedText] = useState<string | null>(null);

  const getDetailValueColor = useCallback((item: IDetailsElement) => {
    if (fetching) {
      return Theme.color.lightGray;
    }
    if (!item.value) {
      return Theme.color.lightGray;
    }
    if (item.onClick) {
      return Theme.color.blue;
    }

    return item.color || Theme.color.black;
  }, [fetching]);

  const copyValue = useCallback((item: IDetailsElement) => {
    if (item.value && typeof item.value === 'string') {
      navigator.clipboard.writeText(item.value);
      setCopiedText(item.value);
    }
  }, []);

  const handleClick = useCallback(async (item: IDetailsElement) => {
    if (item.tags?.includes('copy')) {
      copyValue(item);
    }

    if (item.onClick) {
      item.onClick();
    }
  }, [copyValue]);

  return (
    <Card header={title}>
      {details.map((item) => (
        <div key={item.label}>
          <CardDetails.Label>
            {item.label}
          </CardDetails.Label>
          <CardDetails.ValueWrapper>
            {React.isValidElement(item.value) ? item.value : (
              <CardDetails.Value
                color   = {getDetailValueColor(item)}
                onClick = {() => handleClick(item)}
              >
                {fetching ? '...' : (item.value || t('noContent'))}
                {item.tags?.includes('copy') && (
                  <span>{copiedText === item.value ? <>Copied</> : <CopyOutlined />}</span>
                )}
              </CardDetails.Value>
            )}
          </CardDetails.ValueWrapper>
        </div>
      ))}
    </Card>
  );
};

CardDetails.Label = styled.div`
  color          : ${({ theme }) => theme.color.black};
  font-size      : 12px;
  font-weight    : 600;
  line-height    : 17px;
  margin-top     : 20px;
  text-transform : uppercase;
`;

CardDetails.Value = styled.p<{ color: string }>`

  color  : ${({ color }) => color};
  cursor : ${({ color, theme }) => (color === theme.color.blue ? 'pointer' : 'text')};
`;

CardDetails.ValueWrapper = styled.div`
  span {
    color       : ${({ theme }) => theme.color.black};
    margin-left : 5px;
    opacity     : 0;
    transition  : all 0.2s;
  }

  p {
    font-size   : 14px;
    line-height : 20px;
    margin-top  : 3px;

    &:hover { span { opacity: 1; } }
  }
`;
