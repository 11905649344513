import { Formik }                         from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation }                 from 'react-i18next';
import * as Yup                           from 'yup';

import { ErrorModal, LoginForm, LoginWrapper, ResetPasswordModal } from './components';
import { TApiCaller, TFunction }                                   from '../../models';
import { saveTokenData }                                           from '../../utils';

interface LoginValues {
  email          : string;
  password       : string;
  rememberActive : boolean;
}

interface LoginProps {
  apiHandler        : TApiCaller;
  clientId          : string;
  clientSecret      : string;
  onLogin           : () => void;
  resetPasswordLink : string;
}

const useValidationSchema = (t: TFunction) => Yup.object().shape({
  email : Yup
    .string()
    .email(t('itDoesNotLookLikeEmail'))
    .required(t('requiredField')),
  password : Yup
    .string()
    .min(8, `${t('minCharactersLength')} 8`)
    .required(t('requiredField')),
});

export const Login = ({ apiHandler, clientId, clientSecret, onLogin, resetPasswordLink }: LoginProps) => {
  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const [currentModal, setModal]        = useState<'error' | 'reset' | null>(null);
  const [isFetching, setFetchingStatus] = useState<boolean>(false);

  const initialValues = useMemo<LoginValues>(() => ({
    email          : '',
    password       : '',
    rememberActive : false,
  }), []);

  const loginUser = useCallback(async (values: LoginValues) => {
    setFetchingStatus(true);
    try {
      const { email, password, rememberActive } = values;
      const formData                            = new FormData();

      formData.append('username', email);
      formData.append('password', password);
      formData.append('client_id', clientId);
      formData.append('client_secret', clientSecret);
      formData.append('grant_type', 'password');
      formData.append('scope', 'user');

      // eslint-disable-next-line camelcase
      const { data: { token_type, access_token } } = await apiHandler(
        'connect/token',
        'post',
        { formData },
        { isFormData: true, isIdentity: true },
      );

      saveTokenData(token_type, access_token, rememberActive);
      onLogin();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setModal('error');
    }
    setFetchingStatus(false);
  }, [onLogin]);

  const onClickContactNewsie = useCallback(() => window.open('mailto:support@localnewsie.com'), []);

  return (
    <LoginWrapper>
      {currentModal === 'reset' && (
        <ResetPasswordModal
          apiHandler        = {apiHandler}
          closeModal        = {() => setModal(null)}
          resetPasswordLink = {resetPasswordLink}
        />
      )}
      {currentModal === 'error' && (
        <ErrorModal
          closeModal = {() => setModal(null)}
          comment    = {t('pleaseMakeSureThatYouEnteredTheCorrectCredentials')}
        />
      )}
      <Formik
        initialValues    = {initialValues}
        onSubmit         = {loginUser}
        validateOnChange = {false}
        validationSchema = {validationSchema}
      >
        <LoginForm
          isFetching            = {isFetching}
          onClickContactNewsie  = {onClickContactNewsie}
          onClickForgotPassword = {() => setModal('reset')}
        />
      </Formik>
    </LoginWrapper>
  );
};
