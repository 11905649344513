import { ISelectedArea }   from '@components/AreaSelection/AreaSelection';
import { TERRITORY_TYPES } from '@modules/territories/pages/AddTerritoryContainerL';

import { ERequestedReviewStatus, ETipType } from './enums';

export type TTimeout = ReturnType<typeof setTimeout>;

interface IImage {
  imageId      : number | null;
  title        : string | null;
  description  : string | null;
  thumbnailUri : string | null;
  previewUri   : string | null;
  imageUri     : string;
  mimeType     : 'youtube' | 'image' | 'mp4';
  sizeInBytes  : number | null;
  credit       : string | null;
  storyId?     : number | null;
}

interface IAdvertisingChannel {
  adId        : number;
  channelId   : number;
  channelName : string;
}

export interface IAdvertisingModel {
  adId         : number;
  allDay       : boolean;
  dateEnd      : string;
  dateStart    : string;
  image        : IImage;
  imageId      : number;
  name         : string;
  rAdTerritory : (ISelectedArea & { adId: number })[];
  rAdChannel   : IAdvertisingChannel[];
  timeZone     : string;
  websiteUri   : string;
}

export interface ILicenseModel {
  email       : string;
  firstName   : string;
  lastName    : string;
  userId      : string;
  territories : {
    territoryId   : number;
    territoryName : string;
  }[];
}

interface IPublisher {
  iconUri     : string;
  name        : string;
  publisherId : number;
  websiteUri  : string;
}

interface IFeedChannel {
  channelId     : number;
  channelName   : string;
  feedId        : number;
  territoryId   : number | null;
  territoryName : number | null;
}

export interface IFeedModel {
  feedId?        : number;
  name           : string;
  publisher?     : IPublisher;
  publisherId    : number; // TODO: if we have publisher, why we need publisherId?
  rFeedChannel   : IFeedChannel[];
  rFeedTerritory : (ISelectedArea & { feedId: number })[];
  rssUri         : string;
  status?        : boolean;
}

interface IStoryChannel {
  storyId     : number;
  channelId   : number;
  channelName : string;
}

export interface IStoryModel {
  author              : string;
  publisher?          : IPublisher;
  publisherId         : number;
  businessId          : number;
  businessName?       : string;
  businessLocation?   : any;
  businessLocationId? : number;
  created?            : string | any;
  description         : string;
  shortDescription    : string | null;
  endDateUtc          : string;
  feed?               : any;
  feedId?             : number;
  rStoryChannel       : IStoryChannel[];
  rStoryImage         : {
    storyId?  : number | null;
    imageId   : number | null;
    order     : number;
    isEnabled : boolean;
    image     : IImage;
  }[];
  rStoryTerritory     : (ISelectedArea & { storyId: number })[];
  startDateUtc        : string;
  status              : number;
  storyId?            : number;
  tags                : string[];
  title               : string;
}

interface ICoordinate {
  x : number;
  y : number;
}

export interface ILocation {
  address1         : string | null;
  address2         : string | null;
  city             : string | null;
  country          : string | null;
  countryRegionId  : string | null;
  district         : string | null;
  formatted        : string | null;
  geog             : {
    coordinate   : ICoordinate | null;
    coordinates? : ICoordinate[] | null;
  };
  googlePlaceId    : string | null;
  googlePlaceQuery : string | null;
  locationId?      : number | null;
  postCode         : string | null;
  postCodes?       : string[];
  state            : string | null;
  stateProvId      : string | null;
  timeZone         : string | null;
}

export interface IBusinessImage {
  businessId : number;
  imageId    : number;
  order      : number;
  isEnabled  : boolean;
  image      : IImage;
}

export interface ICategoryModel {
  categoryId? : number;
  name        : string;
  parentId?   : number | null;
  iconUri     : string;
}

export interface ICredentialBusinessCategory {
  credentialId : number;
  categoryId   : number;
  categoryName : string;
}

export interface IAffiliationModel {
  credentialId                : number;
  name                        : string;
  logoUri                     : string;
  websiteUri                  : string;
  rCredentialTerritory        : (ISelectedArea & { credentialId: number })[];
  rCredentialBusinessCategory : ICredentialBusinessCategory[];
}

export interface IBusinessModel {
  businessId?   : number;
  name          : string;
  userIsBlocked : boolean;
}

export interface IBusinessLocation {
  alertHeader                 : string | null;
  alertText                   : string | null;
  businessId                  : number;
  businessLocationId          : number | null;
  description                 : string;
  establishedDate             : string | null;
  googleBusinessName          : string | null;
  googleReviewsActive         : boolean;
  isAlertActive               : boolean;
  iconUri                     : string | null;
  location                    : ILocation | null;
  locationId?                 : number;
  logoUri                     : string | null;
  name                        : string;
  operationalHours            : string | null;
  phoneNumber                 : string | null;
  smeDescription              : string | null;
  smeImage                    : IImage | null;
  status                      : ERequestedReviewStatus;
  rBusinessImage              : {
    businessLocationId : number | null;
    imageId            : number | null;
    order              : number;
    isEnabled          : boolean;
    image              : IImage;
  }[];
  rBusinessBusinessCategory   : {
    businessLocationId : number | null;
    categoryId         : number;
    categoryName?      : string;
    categoryParentId?  : number;
  }[];
  rBusinessLocationCredential : {
    businessLocationId? : number;
    credentialId        : number;
    credentialLogoUri   : string;
    credentialName      : string;
  }[];
  rBusinessLocationChannel    : {
    businessLocationId? : number;
    channelId           : number;
    channelName?        : string;
  }[];
  rBusinessTerritory          : {
    businessLocationId : number | null;
    enabled?           : boolean;
    order?             : number;
    territoryId        : number;
    territoryName?     : string;
  }[];
  type                        : null;
  yelpBusinessId              : string | null;
  yelpBusinessName            : string | null;
  yelpReviewsActive           : boolean;
  websiteUri                  : string | null;
}

export interface IEventModel {
  eventId            : number;
  status             : ERequestedReviewStatus;
  title              : string;
  description        : string;
  websiteUri         : string;
  dateStart          : string;
  dateEnd            : string | null;
  timeZone           : string;
  allDay             : boolean;
  tags               : string[];
  eventRecurrenceId  : number | null;
  eventRecurrence    : { recurrenceRule: string; recurrenceDateStart : string } | null;
  businessLocationId : number | null;
  businessLocation?  : IBusinessLocation;
  locationId?        : number | null;
  location?          : ILocation | null;
  rEventImage        : {
    eventId   : number | null;
    imageId   : number | null;
    order     : number;
    isEnabled : boolean;
    image     : IImage;
  }[];
  rEventTerritory    : (ISelectedArea & { eventId: number })[];
}

export interface IRequestedFeedModel {
  channelId     : number;
  channelName   : string;
  feedRequestId : number;
  notes         : string | null;
  publisherName : string;
  publisherUri  : string;
  status        : ERequestedReviewStatus;
  territoryId   : number;
  territoryName : string;
  territoryType : TERRITORY_TYPES;
}

interface ISlideChannel {
  slideId     : number;
  channelId   : number;
  channelName : string;
}

export interface ISlideModel {
  slideId           : number;
  imageId           : number;
  image             : IImage;
  name              : string;
  text              : string;
  websiteUri        : string;
  dateStart         : string;
  dateEnd           : string;
  timeZone          : string;
  slideRecurrenceId : number | null;
  slideRecurrence   : { recurrenceRule: string; recurrenceDateStart : string } | null;
  allDay            : boolean;
  rSlideTerritory   : (ISelectedArea & { slideId: number })[];
  rSlideChannel     : ISlideChannel[];
}

export interface IChannelModel {
  channelCategoryId : number;
  channelId?        : number;
  iconUri           : string;
  locale            : string;
  headerUri         : string | null;
  channelType       : number;
  color             : string;
  name              : string;
  rChannelTerritory : (ISelectedArea & { channelId: number })[];
  thumbnailUri      : string | null;
}

export interface ITagModel {
  id     : number;
  name   : string;
  iconId : number;
  icon   : IImage;
}

export interface IChannelsCategory {
  channelCategoryId : number;
  color             : string;
  headerUri         : string | null;
  isCitySpecific?   : boolean;
  name              : string;
}

export interface ITerritoryModel {
  availableInMobileApp : boolean;
  channel              : IChannelModel | null;
  channelId            : number | null;
  locale               : string;
  locations            : ILocation[];
  name                 : string;
  parentId             : number | null;
  parentName           : string | null;
  territoryId          : number;
  type                 : TERRITORY_TYPES;
}

// TODO:: delete
export interface IAccountData {
  bio            : string | null;
  contactEmail   : string | null;
  displayName    : string | null;
  email          : string | null;
  facebookUri    : string | null;
  firstName      : string;
  instagramUri   : string | null;
  lastName       : string;
  phoneNumber    : string | null;
  photoImage     : IImage | null;
  profileImage   : IImage | null;
  territoryIds   : number[];
  twitterUri     : string | null;
}

export const LOCALES = [
  { label: 'EN-US', value: 'en_us' },
];

export interface ITipModel {
  id   : number;
  tip  : string;
  type : ETipType;
}

export interface IAutosuggestOption {
  value    : any;
  label    : string;
  iconUrl? : string;
}

type TFormsStrings = {
  headerText                : string | null;
  blockOneTitle             : string | null;
  blockOneDescription       : string | null;
  blockTwoTitle             : string | null;
  blockTwoDescription       : string | null;
  blockThreeTitle           : string | null;
  blockThreeDescription     : string | null;
  registrationFlowTitle     : string | null;
  registrationFlowListItems : string[];
}

export interface IForms {
  categoryId             : number | null;
  categoryName           : string;
  name                   : string | null;
  rSettingProfileChannel : {
    channelId   : number;
    channelName : string;
  }[];
  settingsId             : number;
  settingsModel          : {
    localization     : 'us' | 'usEs' | 'gb';
    primaryColor     : string;
    secondaryColor   : string;
    activeItemColor  : string;
    mainPage         : {
      headerImageUri : string;
      blockOneImages : { imageUrl: string }[];
      blockTwoImage  : {
        imageUrl : string;
        mimeType : 'image' | 'video';
      } | string;
    };
    registrationFlow : {
      images: { imageUrl: string }[];
    };
    strings          : {
      us   : { [P in keyof TFormsStrings]: NonNullable<TFormsStrings[P]> };
      usEs : TFormsStrings;
      gb   : TFormsStrings;
    };
  };
}

export interface IPublisherModel {
  created     : string;
  iconUri     : string;
  name        : string;
  publisherId : number;
  updated     : string;
  websiteUri  : string;
}

export interface IOptionType {
  label   : string;
  source? : any;
  value   : any;
}
