import { differenceInMinutes } from 'date-fns';
import { useEffect, useState } from 'react';

import { TDynamicLinkGenerator } from './dynamicLink';

type TUseEventShareLinkHook = (
  event : {
    dateStart : string;
    dateEnd   : string;
    eventId   : number;
    title     : string;
    imageUri  : string;
  } | null,
) => [string | null, boolean];

type TEventShareLinkInitializer = (dynamicLinkHandler : TDynamicLinkGenerator) => TUseEventShareLinkHook;

// eslint-disable-next-line arrow-body-style
export const initializeEventShareLinkHook: TEventShareLinkInitializer = (dynamicLinkHandler) => {
  return (event) => {
    const [shareLink, setShareLink]       = useState<string | null>(null);
    const [isFetching, setFetchingStatus] = useState<boolean>(false);

    const generateShareLink = async () => {
      if (!event) { return; }

      setFetchingStatus(true);
      const { shortLink } = await dynamicLinkHandler(
        `event/${event.eventId}`,
        undefined,
        {
          description : differenceInMinutes(new Date(event.dateStart), new Date(event.dateEnd)).toString(),
          image       : event.imageUri,
          title       : event.title,
        },
        true,
      );
      setShareLink(shortLink);
      setFetchingStatus(false);
    };

    useEffect(() => {
      if (event && !isFetching && !shareLink) {
        generateShareLink();
      }
    }, [event]);

    return [shareLink, isFetching];
  };
};
