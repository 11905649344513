import { Empty as EmptyAntd } from 'antd';

import { EmptyIcon } from '../../../public/svg';

export const Empty = () => (
  <EmptyAntd
    description = {null}
    image       = {<EmptyIcon height="100px" width="100px" />}
    imageStyle  = {{ height: 35, margin: '10px 0' }}
  />
);
