import { useCallback, useState } from 'react';
import { useTranslation }        from 'react-i18next';
import styled                    from 'styled-components';

import { Modal }         from './Modal';
import { Button }        from '../Button';
import { CardStatus }    from '../Card/CardStatus';
import { Input }         from '../Input/Input';
import { DeniedCircle }  from '../StatusCircles';
import { ECardStatuses } from '../../enums';

export interface IDenyModalProps {
  comment?     : string;
  onSubmit     : (value: string) => Promise<void>;
  title        : string;
  button       : {
    label?  : string;
    onClick : () => void;
  }
}

export const DenyModal = ({
  button,
  comment,
  onSubmit,
  title,
}: IDenyModalProps) => {
  const { t } = useTranslation();

  const [inputValue, setValue] = useState<string>('');
  const [status, setStatus]    = useState<ECardStatuses>(ECardStatuses.None);

  const onClickSubmit = useCallback(async (message: string) => {
    try {
      await onSubmit(message);
      setStatus(ECardStatuses.Success);
    } catch (error) {
      setStatus(ECardStatuses.Failure);
    }
  }, [inputValue, onSubmit]);

  return (
    <Modal
      bodyStyle = {() => 'padding: 50px;'}
      onCancel  = {button.onClick}
      width     = "718px"
    >
      <DenyModal.Content>
        <CardStatus
          status       = {status || ECardStatuses.None}
          wrapperStyle = {() => 'transform: translateY(-10px);'}
        />
        <DenyModal.Body justifyContent={comment ? 'flex-start' : 'center'}>
          <DeniedCircle
            margin = "0 0 14px"
            size   = "64px"
            icon   = {{
              height : '26px',
              width  : '26px',
            }}
          />
          <DenyModal.Title>{title}</DenyModal.Title>
          {comment && <DenyModal.Comment>{comment}</DenyModal.Comment>}
          <Input
            bordered
            autoSize         = {{ maxRows: 4, minRows: 4 }}
            changeFieldValue = {setValue}
            type             = "textarea"
            value            = {inputValue}
            wrapperStyle     = {() => 'margin: 23px 0 35px'}
          />
        </DenyModal.Body>
        <DenyModal.Buttons>
          <DenyModal.BackButton
            {...button}
            bordered = {false}
            label    = {button.label || t('closeThePopUp')}
          />
          <DenyModal.SubmitButton>
            <Button
              label   = {t('send')}
              onClick = {() => onClickSubmit(inputValue)}
            />
          </DenyModal.SubmitButton>
        </DenyModal.Buttons>
      </DenyModal.Content>
    </Modal>
  );
};

DenyModal.Body = styled.div<{ justifyContent: string }>`
  align-items     : center;
  display         : flex;
  flex            : 1;
  flex-direction  : column;
  justify-content : ${({ justifyContent }) => justifyContent};
  width           : 100%;
`;

DenyModal.BackButton = styled(Button)`
  border-radius   : 0;
  height          : auto;
  padding         : 0;
  text-decoration : underline;
`;

DenyModal.Buttons = styled.div`
  display         : flex;
  justify-content : center;
  position        : relative;
  width           : 100%;
`;

DenyModal.Comment = styled.p`
  color      : ${({ theme }) => theme.color.black};
  font-size  : 16px;
  margin-top : 12px;
  text-align : center;
`;

DenyModal.Content = styled.div`
  align-items    : center;
  display        : flex;
  flex-direction : column;
  font-family    : 'Poppins';
  min-height     : 370px;
  position       : relative;
`;

DenyModal.SubmitButton = styled.div`
  position : absolute;
  right    : 0;
`;

DenyModal.Title = styled.h3`
  color       : ${({ theme }) => theme.color.black};
  font-size   : 31px;
  line-height : 44px;
`;
