import { Field, Form, Formik } from 'formik';
import { useCallback }         from 'react';
import styled                  from 'styled-components';

import { Button, Input }          from '@common/components';
import { Modal }                  from '@src/App';
import { ERequestedReviewStatus } from '@models/enums';

export interface IReviewResponseFormValues {
  message: string;
}

export interface IRequestModal {
  itemName : string;
  response : ERequestedReviewStatus;
  onSubmit : (values: IReviewResponseFormValues) => Promise<void>;
}

const ReviewResponse = ({ itemName, response, onSubmit }: IRequestModal) => (
  <ReviewResponse.Wrapper>
    <ReviewResponse.Title response={response}>
      <h1>{response === ERequestedReviewStatus.RequestedChanges ? 'Request changes' : 'Deny'}</h1>
      <span>({itemName})</span>
    </ReviewResponse.Title>
    <Formik
      initialValues = {{ message: '' }}
      onSubmit      = {onSubmit}
    >
      <Form>
        <Field
          multiline
          component   = {Input}
          name        = "requestModalMessage"
          type        = "textarea"
          placeholder = "Write what should be changed"
        />
        <ReviewResponse.ButtonWrapper><Button label="Send" /></ReviewResponse.ButtonWrapper>
      </Form>
    </Formik>
  </ReviewResponse.Wrapper>
);

export const useReviewResponseModal = () => {
  const onClickSend = useCallback(async (
    values       : IReviewResponseFormValues,
    submitValues : IRequestModal['onSubmit'],
  ) => {
    await submitValues(values);
    Modal.close();
  }, []);

  const openReviewResponseModal = useCallback((
    itemName : IRequestModal['itemName'],
    response : IRequestModal['response'],
    onSubmit : IRequestModal['onSubmit'],
  ) => {
    Modal.open(ReviewResponse, {
      itemName,
      response,
      onSubmit : (values: IReviewResponseFormValues) => onClickSend(values, onSubmit),
    });
  }, []);

  return { openReviewResponseModal };
};

ReviewResponse.Title = styled.div<{ response: ERequestedReviewStatus }>`
  align-items : center;
  display     : flex;
  gap         : 5px;

  > h1 {
    font-size   : 22px;
    margin      : 0;
    white-space : nowrap;
    color       : ${({ response, theme }) => (response === ERequestedReviewStatus.RequestedChanges
    ? theme.textColor.orange
    : theme.textColor.red)};
  }

  span {
    color         : ${({ theme }) => theme.textColor.gray};
    display       : inline-block;
    font-size     : 20px;
    font-weight   : 500;
    max-width     : 350px;
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }
`;

ReviewResponse.Wrapper = styled.div`
  background-color : #fff;
  padding          : 20px;
  width            : 600px;

  form > div:last-child { margin: 20px auto 0; }
  textarea { font-size: 16px; }
`;

ReviewResponse.ButtonWrapper = styled.div`
  display        : flex;
  justify-content: end;
`;
