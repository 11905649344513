import React from 'react';

import { ERequestedReviewStatus } from '@models/enums';
import { theme as Theme }         from '@styles/theme';

export const getRequestStatusLabel = (status: ERequestedReviewStatus) => {
  if (status === ERequestedReviewStatus.Approved) {
    return <p style={{ color: 'green' }}>Approved</p>;
  } else if (status === ERequestedReviewStatus.Denied) {
    return <p style={{ color: Theme.textColor.red }}>Denied</p>;
  } else if (status === ERequestedReviewStatus.RequestedChanges) {
    return <p style={{ color: Theme.textColor.orange }}>Changes are requested</p>;
  }

  return <p style={{ color: Theme.textColor.gray }}>New</p>;
};
