import { Input as InputAnt }              from 'antd';
import { useCallback, useMemo, useState } from 'react';
import styled                             from 'styled-components';

import { Label } from '../Label';

export interface BaseInputProps {
  bordered?     : boolean;
  disabled?     : boolean;
  required?     : boolean;
  label?        : string;
  maxLength?    : number;
  onBlur?       : () => void;
  onChange      : (value: string) => void;
  onPressEnter? : () => void;
  placeholder?  : string;
  prefix?       : string;
  type?         : 'tel' | 'text' | 'email' | 'number' | 'password' | 'textarea';
  value         : number | string;
  autoSize?     : {
    maxRows : number;
    minRows : number;
  };
}

export const BaseInput = ({
  autoSize,
  disabled,
  label,
  maxLength,
  onBlur,
  onChange,
  onPressEnter,
  placeholder,
  prefix,
  value,
  bordered   = false,
  required   = true,
  type       = 'text',
}: BaseInputProps) => {
  const [isFieldFocused, setFocusStatus] = useState<boolean>(false);
  const [isFieldOnHover, setHoverStatus] = useState<boolean>(false);

  const onBlurInner = useCallback(() => {
    setFocusStatus(false);

    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const onChangeValue = useCallback((e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: nextValue } = e.currentTarget;

    onChange(nextValue);
  }, [onChange]);

  const fieldProps = useMemo(() => ({
    bordered,
    disabled,
    onPressEnter,
    placeholder,
    prefix,
    type,
    value,
    onBlur   : onBlurInner,
    onChange : onChangeValue,
    onFocus  : () => setFocusStatus(true),
  }), [bordered, disabled, onBlurInner, onChangeValue, onPressEnter, placeholder, type, value]);

  return (
    <BaseInput.Wrapper
      bordered       = {bordered}
      isFieldFocused = {isFieldFocused}
      isFieldOnHover = {isFieldOnHover}
    >
      {label && (
        <Label
          label    = {label}
          required = {required}
        />
      )}
      <BaseInput.Field
        bordered     = {bordered}
        isLabel      = {!!label}
        onMouseEnter = {() => setHoverStatus(true)}
        onMouseLeave = {() => setHoverStatus(false)}
      >
        {type === 'textarea' && (
          <BaseInput.TextArea
            {...fieldProps}
            autoSize  = {autoSize || { minRows: 1, maxRows: 6 }}
            maxLength = {maxLength}
          />
        )}
        {type === 'password' && (
          <BaseInput.Password {...fieldProps} />
        )}
        {!['textarea', 'password'].includes(type) && (
          <BaseInput.Component {...fieldProps} />
        )}
        {maxLength && (
          <BaseInput.Counter>{value ? (maxLength - value.toString().length) : maxLength}</BaseInput.Counter>
        )}
      </BaseInput.Field>
    </BaseInput.Wrapper>
  );
};

BaseInput.Counter = styled.p`
  bottom    : 10px;
  color     : #ccc;
  font-size : 10px;
  right     : 10px;
  position  : absolute;
`;

BaseInput.Field = styled.div<{ bordered: boolean; isLabel: boolean }>`
  /* stylelint-disable property-no-vendor-prefix */
  /* Chrome, Safari, Edge, Opera - hide arrows when type is number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin             : 0;
    -webkit-appearance : none;
  }
  /* Firefox */
  input[type=number] { -moz-appearance: textfield; }
  /* stylelint-enable property-no-vendor-prefix */

  input,
  textarea {
    color      : ${({ theme }) => theme.field.placeholderColor};
    margin-top : ${({ bordered, isLabel }) => ((!isLabel && '0') || (isLabel && bordered && '7px') || '14px')};
    transition : all 0.3s;

    &:focus { color: ${({ theme }) => theme.color.blue}; }

    ::placeholder { color: ${({ theme }) => theme.color.gray}; }
  }

  input,
  span { padding: ${({ bordered }) => (!bordered && '0')}; }

  textarea { padding: ${({ bordered }) => (bordered ? '11px' : '0')}; }

  > input,
  > span,
  > textarea {
    background-color : #fff;
    border-radius    : 0;
    font-size        : 14px;
    line-height      : 20px;
    transition       : all 0.2s;

    ::placeholder { color: ${({ theme }) => theme.color.gray}; }

    &:hover,
    &:focus { background-color: #fff; }

    ${({ bordered, theme }) => (bordered ? `
      border        : 1px solid ${theme.color.lightGray};
      border-radius : 4px;
      height        : 44px;
      transition    : all 0.2s;

      &:hover { border: 1px solid ${theme.color.blue} }
      &:focus {
        border     : 1px solid ${theme.color.blue};
        box-shadow : 0 0 3px 0 ${theme.color.blue};
      }
    ` : `
    `)}
  }

  .ant-input-prefix {
    color        : ${({ theme }) => theme.color.gray};
    margin-right : 0;
  }

  .ant-input-password,
  .ant-input-password:hover { background-color: transparent; }

  .ant-input-prefix,
  .ant-input-suffix { align-items: flex-end; }
`;

BaseInput.Component = styled(InputAnt)``;

BaseInput.Password = styled(InputAnt.Password)`
  .ant-input-suffix {
    font-size     : 15px;
    margin-bottom : 3px;

    svg {
      color      : ${({ theme }) => theme.field.placeholderColor};
      transition : all 0.2s;

      &:hover { color: ${({ theme }) => theme.color.blue}; }
    }
  }
`;

BaseInput.TextArea = styled(InputAnt.TextArea)`
  resize: none;
`;

BaseInput.Wrapper = styled.div<{
  bordered       : boolean;
  isFieldFocused : boolean;
  isFieldOnHover : boolean;
}>`
  display          : flex;
  flex-direction   : column;
  justify-content  : center;
  position         : relative;
  transition       : all 0.2s;
  width            : 100%;
  z-index          : 200;

  ${({ bordered, isFieldFocused, isFieldOnHover, theme }) => !bordered && `
    box-shadow : 0 1px 0 0 ${theme.color.lightGray};
    padding    : 0 0 3px 0;

    ${isFieldOnHover ? `box-shadow: 0 1px 0 0 ${theme.color.blue};` : ''}
    ${isFieldFocused ? `box-shadow : 0 3px 0 0 ${theme.color.blue};` : ''}
  `}
`;
