export const saveTokenData = (tokenType: string, accessToken: string, rememberMe: boolean): void => {
  localStorage.setItem('tokenType', tokenType);
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
};

export const getTokenData = () => {
  const tokenType   = localStorage.getItem('tokenType') || sessionStorage.getItem('tokenType');
  const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');

  return ({
    tokenType,
    accessToken,
  });
};
