import { useMemo, useCallback }      from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { connect }                   from 'react-redux';
import { Formik, FormikProps }       from 'formik';
import * as Yup                      from 'yup';

import {
  IAreaSelectionItem,
  ISelectedArea,
  mapAreasToBannedType,
  validateSelectedAreas,
} from '@components/AreaSelection/AreaSelection';
import { CircularLoader }                   from '@components/Loaders/CircularLoader';
import { useConfirmRecurringModalHandlers } from '@components/RecurrenceEditor/ConfirmRecurringModal';
import {
  validateStringForSpecialSymbolsOnly,
  validateTheCorrectTimeDirection,
  validateTheTimeDifference,
} from '@common/utils';

import { ISlideModel, IOptionType } from '@models/index';
import { IState }                   from '@store/rootReducer';
import { useFetchEntity }           from '@utils/fetchEntity';

import { FormSlide } from '../components/FormSlide';

import {
  addSlide  as addSlideAction,
  editSlide as editSlideAction,
  getMeta,
  ISliderActionsCreators,
} from '../sliderReducer';
import styled from 'styled-components';

interface ISlide {
  addSlide   : ISliderActionsCreators['addSlide'];
  editSlide  : ISliderActionsCreators['editSlide'];
  isFetching : boolean;
}

interface TSliderFormModel {
  name               : string,
  text               : string,
  websiteUri         : string,
  dateStart          : string | null,
  dateEnd            : string | null,
  timeZone           : string;
  withRegularRepeats : boolean;
  slideRecurrenceId  : number | null;
  allDay             : boolean;
  rSlideTerritory    : IAreaSelectionItem[];
  rSlideChannel      : IOptionType[];
  slideRecurrence    : { 
    recurrenceRule      : string | null;
    recurrenceDateStart : string | null;
  };
  image              : {
    imageUri : string;
    mimeType : string;
  } | null;
}

export type TSliderPostModel = Omit<TSliderFormModel, 'slideRecurrence' | 'image' | 'rSlideChannel' | 'rSlideTerritory' | 'withRegularRepeats'> & {
  slideId?        : number;
  imageId?        : number;
  slideRecurrence : {
    recurrenceRule      : string | null;
    recurrenceDateStart : string | null
  } | null;
  image           : {
    imageId      : number | null,
    title        : null,
    description  : null,
    thumbnailUri : null,
    previewUri   : null,
    imageUri?    : string,
    mimeType?    : string,
    sizeInBytes  : null,
    credit       : null,
  },
  rSlideTerritory : (ISelectedArea & { slideId: number; })[],
  rSlideChannel   : {
    slideId?  : number;
    channelId : number;
  }[],
}

const useValidationSchema = (t: TFunction<'translation'>) => Yup.object().shape({
  allDay : Yup.bool()
    .required(),
  dateEnd : Yup.string()
    .typeError('Select slide ends date')
    .test(...validateTheCorrectTimeDirection(t, 'dateStart'))
    .test(...validateTheTimeDifference(t, 'dateStart'))
    .required(),
  dateStart : Yup.string()
    .typeError('Select slide starts date')
    .required(),
  image : Yup.object()
    .shape({ imageUri : Yup.string().required() })
    .typeError('Upload slide image of video')
    .required(),
  name : Yup.string()
    .max(100, 'Max character count is 100')
    .test(...validateStringForSpecialSymbolsOnly((value) => value))
    .typeError('Enter slide name')
    .required('Name is a required field'),
  rSlideTerritory : validateSelectedAreas(),
  rSlideChannel : Yup.array()
    .min(1, 'Select at least 1 channel').of(
      Yup.object().shape({
        label : Yup.string().required(),
        value : Yup.number().required(),
      }),
    ),
  text : Yup.string()
    .max(200, 'Max character count is 200')
    .nullable(),
  timeZone : Yup.string()
    .required(),
  websiteUri : Yup.string()
    .url('It does not look like a valid url')
    .max(200, 'Max character count is 200')
    .nullable(),
  withRegularRepeats : Yup.bool()
    .required(),
});

const SlidesComponent = ({
  addSlide,
  editSlide,
  isFetching,
}: ISlide) => {
  const [slide, isSlideFetching] = useFetchEntity('feature/Slide') as [ISlideModel | null, boolean, () => Promise<void>];

  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const initialValues = useMemo<TSliderFormModel>(() => ({
    name               : slide?.name || '',
    text               : slide?.text || '',
    websiteUri         : slide?.websiteUri || '',
    dateStart          : slide?.dateStart || null,
    dateEnd            : slide?.dateEnd || null,
    timeZone           : slide?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    withRegularRepeats : !!slide?.slideRecurrenceId || false,
    slideRecurrenceId  : slide?.slideRecurrenceId || null,
    slideRecurrence    : slide?.slideRecurrence || {
      recurrenceRule      : '',
      recurrenceDateStart : '',
    },
    allDay             : slide?.allDay || false,
    image              : slide?.image && slide.image.imageUri
      ? {
        imageUri : slide.image.imageUri,
        mimeType : slide.image.mimeType,
      }
      : null,
    rSlideTerritory    : (slide?.rSlideTerritory || []) as unknown as IAreaSelectionItem[],
    rSlideChannel      : slide?.rSlideChannel
      ? slide.rSlideChannel.map((et) => ({ label: et.channelName, value: et.channelId }))
      : [],
  }), [slide]);
  
  const [openConfirmRecurringModal] = useConfirmRecurringModalHandlers('slide');

  const onSubmit = useCallback(async (values: TSliderFormModel) => {
    const requestData: TSliderPostModel = {
      allDay            : values.allDay,
      dateEnd           : values.dateEnd,
      dateStart         : values.dateStart,
      name              : values.name,
      text              : values.text,
      timeZone          : values.timeZone,
      websiteUri        : values.websiteUri,
      slideRecurrence   : values.slideRecurrence.recurrenceRule ? values.slideRecurrence : null,
      slideRecurrenceId : values.slideRecurrenceId || null,
      rSlideTerritory   : mapAreasToBannedType(values.rSlideTerritory, { slideId: slide?.slideId }) as ISlideModel['rSlideTerritory'],
      rSlideChannel     : values.rSlideChannel.map((et: any) => ({
        slideId   : slide?.slideId,
        channelId : et.value,
      })),
      image             : {
        imageId      : slide?.imageId || null,
        title        : null,
        description  : null,
        thumbnailUri : null,
        previewUri   : null,
        imageUri     : values.image?.imageUri,
        mimeType     : values.image?.mimeType,
        sizeInBytes  : null,
        credit       : null,
      },
      slideId : slide?.slideId,
      imageId : slide?.imageId,
    };

    if (slide) {
      let isAll = !!requestData.slideRecurrence;
      if (slide.slideRecurrenceId && isAll) {
        isAll = await openConfirmRecurringModal();
      }
      editSlide(requestData, isAll);
    } else {
      addSlide(requestData);
    }
  }, [slide]);

  const updateValuesIfNeeded = useCallback((() => {
    let { dateStart, withRegularRepeats } = initialValues;

    return (values: TSliderFormModel) => {
      if (values.dateStart && values.withRegularRepeats && (values.dateStart !== dateStart)) {
        values.slideRecurrence.recurrenceDateStart = values.dateStart;
      }
      if (!values.withRegularRepeats && (values.withRegularRepeats !== withRegularRepeats)) {
        values.slideRecurrence  = { recurrenceRule: '', recurrenceDateStart: '' };
        values.slideRecurrenceId = null;
      }
      if (values.withRegularRepeats && values.dateStart && (values.withRegularRepeats !== withRegularRepeats)) {
        values.slideRecurrence  = { recurrenceRule: '', recurrenceDateStart: values.dateStart };
      }

      dateStart          = values.dateStart;
      withRegularRepeats = values.withRegularRepeats;

      return values;
    };
  })(), [initialValues]);

  if (isSlideFetching) return (
    <CircularLoader />
  );

  return (
    <Container>
      <Formik
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<any>) => (
          <FormSlide
            {...props}
            values     = {updateValuesIfNeeded(props.values)}
            isFetching = {isFetching}
          />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 948px; }
`;

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addSlide  : addSlideAction,
  editSlide : editSlideAction,
};

export const Slides = connect(mapStateToProps, mapDispatchToProps)(SlidesComponent);
