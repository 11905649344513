import { useMemo, useCallback }        from 'react';
import { connect }                     from 'react-redux';
import { TFunction, useTranslation }   from 'react-i18next';
import { Formik, FormikProps }         from 'formik';
import * as Yup                        from 'yup';
import styled                          from 'styled-components';

import {
  IAreaSelectionItem,
  ISelectedArea,
  mapAreasToBannedType,
  validateSelectedAreas,
}                         from '@components/AreaSelection/AreaSelection';
import { CircularLoader } from '@components/Loaders/CircularLoader';
import {
  validateTheCorrectTimeDirection,
  validateTheTimeDifference,
} from '@common/utils';

import { IStoryModel, IOptionType } from '@models/index';
import { useFetchEntity }           from '@utils/fetchEntity';
import { IState }                   from '@store/rootReducer';

import {
  addStory  as addStoryAction,
  editStory as editStoryAction,
  getMeta,
  IStoriesActionsCreators,
}                    from '../storiesReducer';
import { FormStory } from '../components/FormStory';

interface IStories {
  addStory   : IStoriesActionsCreators['addStory'];
  editStory  : IStoriesActionsCreators['editStory'];
  isFetching : boolean;
}

interface TStoryFormModel {
  title              : string;
  description        : string;
  author             : string;
  status?            : number;
  startDateUtc?      : string;
  endDateUtc?        : string;
  tags               : string;
  businessLocationId : number | null,
  rStoryTerritory    : IAreaSelectionItem[];
  rStoryChannel      : IOptionType[];
  publisher: {
    value?: number;
    label?: string;
  } | null;
  rStoryImage        : {
    imageUri : string;
    mimeType : string;
  } | null;
  businessId         : {
    value?: number;
    label?: string;
  } | null;
  businessName       : {
    value?: number;
    label?: string;
  } | null;
}

type TStoryPostModel = Omit<TStoryFormModel, 'startDateUtc' | 'endDateUtc' | 'tags' | 'businessId' | 'rStoryTerritory' | 'rStoryChannel' | 'rStoryImage' | 'publisher' | 'businessName'> & {
  startDateUtc?    : string;
  shortDescription : string;
  publisherId?     : number;
  endDateUtc       : string | null;
  storyId?         : number;
  tags             : string[];
  businessId       : number | null;
  rStoryTerritory  : (ISelectedArea & { storyId: number; })[];
  rStoryChannel    : { channelId: any; storyId: number | undefined; }[];
  rStoryImage      : {
    storyId   : number | null;
    imageId   : number | null;
    order     : number;
    isEnabled : boolean;
    image     : {
      storyId      : number | null;
      imageId      : number | null;
      title        : null;
      description  : null;
      thumbnailUri : null;
      previewUri   : null;
      imageUri?    : string;
      mimeType     : string;
      sizeInBytes  : null;
      credit       : null;
    }
  }[];
};

const useValidationSchema = (t: TFunction<'translation'>) =>Yup.object().shape({
  title : Yup.string()
    .max(300, 'Max length is 300 symbols')
    .typeError('Enter story title')
    .required('Title is required field'),
  description : Yup.string()
    .typeError('Enter story description')
    .required('Description is required field'),
  author          : Yup.string()
    .typeError('Enter story author')
    .required('Author is required field'),
  publisher       : Yup.object()
    .shape({ label: Yup.string().required('Find and select story publisher') })
    .typeError('Find and select story publisher')
    .required(),
  status          : Yup.string()
    .required('Select story status'),
  startDateUtc    : Yup.string()
    .required('Select story starts date'),
  endDateUtc      : Yup.string()
    .test(...validateTheCorrectTimeDirection(t, 'dateStart'))
    .test(...validateTheTimeDifference(t, 'dateStart'))
    .nullable(),
  rStoryImage     : Yup.object()
    .typeError('Upload story preview image')
    .required(),
  tags            : Yup.array().ensure(),
  businessId      : Yup.object().nullable(),
  rStoryTerritory : validateSelectedAreas(0),
  rStoryChannel   : Yup.array().ensure(),
});

const getShortDescriptionFromHtml = (html: string): string => {
  const temp           = document.createElement('div');
  temp.innerHTML       = html;
  const plainText      = temp.textContent || temp.innerText || '';
  let shortDescription = plainText;

  while (shortDescription.startsWith('\n')) {
    shortDescription = shortDescription.replace(/^(\n)/, '');
  }

  if (shortDescription.length > 247) {
    shortDescription = shortDescription.slice(0, 247).concat('...');
  }

  return shortDescription;
};

const StoriesComponent = ({
  addStory,
  editStory,
  isFetching,
}: IStories) => {
  const [story, isStoryFetching] = useFetchEntity('feature/Story') as [IStoryModel | null, boolean, () => Promise<void>];
  const { t }                    = useTranslation();
  const validationSchema         = useValidationSchema(t);

  const initialValues = useMemo<TStoryFormModel>(() => ({
    title              : story?.title || '',
    description        : story?.description || '',
    author             : story?.author || '',
    publisher          : {
      value : story?.publisher?.publisherId,
      label : story?.publisher?.name,
    } || null,
    status             : story?.status,
    startDateUtc       : story?.startDateUtc,
    endDateUtc         : story?.endDateUtc,
    tags : story?.tags.length ? `#${story.tags.map((item) => item.replace(/ /g, '')).join(' #')}` : '',
    rStoryImage        : story?.rStoryImage
      ? { imageUri: story.rStoryImage[0].image.imageUri, mimeType: 'image' }
      : null,
    businessId         : { value: story?.businessId, label: story?.businessName } || null,
    businessName       : { value: story?.businessId, label: story?.businessName } || null,
    businessLocationId : story?.businessLocationId || null,
    rStoryTerritory    : (story?.rStoryTerritory || []) as unknown as IAreaSelectionItem[],
    rStoryChannel      : story?.rStoryTerritory
      ? story.rStoryChannel.map((sc) => ({ label: sc.channelName, value: sc.channelId }))
      : [],
  }), [story]);

  const onSubmit = useCallback((values: TStoryFormModel) => {
    const requestData: TStoryPostModel = {
      title              : values.title,
      description        : values.description,
      shortDescription   : getShortDescriptionFromHtml(values.description),
      author             : values.author,
      publisherId        : values.publisher?.value,
      status             : values.status,
      startDateUtc       : values.startDateUtc,
      endDateUtc         : values.endDateUtc || null,
      businessId         : values.businessName?.value || null,
      businessLocationId : values.businessLocationId || null,
      tags               : values.tags.length ? values.tags.replace(' ', '').split('#').filter((item: any) => item.length) : [],
      rStoryTerritory    : mapAreasToBannedType(values.rStoryTerritory, { storyId: story?.storyId }) as IStoryModel['rStoryTerritory'],
      rStoryChannel      : values.rStoryChannel.map((item: IOptionType) => ({
        channelId : item.value,
        storyId   : story ? story.storyId : undefined,
      })),
      rStoryImage        : [{
        storyId   : story?.storyId || null,
        imageId   : story?.rStoryImage[0].imageId || null,
        order     : 1,
        isEnabled : true,
        image     : {
          storyId      : story?.storyId || null,
          imageId      : story?.rStoryImage[0].imageId || null,
          title        : null,
          description  : null,
          thumbnailUri : null,
          previewUri   : null,
          imageUri     : story?.rStoryImage[0].image.imageUri || values?.rStoryImage?.imageUri,
          mimeType     : 'image',
          sizeInBytes  : null,
          credit       : null,
        },
      }],
    };
    
    if (story) {
      requestData.storyId = story.storyId;
      editStory(requestData);
    } else {
      addStory(requestData);
    }
  }, [story]);

  if (isStoryFetching) return (
    <CircularLoader />
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<any>) => (
          <FormStory {...props} isFetching={isFetching} />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 947px; }
`;

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addStory  : addStoryAction,
  editStory : editStoryAction,
};

export const Stories = connect(mapStateToProps, mapDispatchToProps)(StoriesComponent);
