import { FieldProps }  from 'formik';
import { useCallback } from 'react';
import styled          from 'styled-components';

import { FieldError } from '@common/components';

import {
  BaseColorSelect,
  IBaseColorSelectProps,
  IColorSelectValue,
} from './BaseColorSelect';

type TColorSelectProps = Omit<IBaseColorSelectProps, 'onChange' | 'value'>
& FieldProps<IColorSelectValue>

export const ColorSelect = ({ field, form, ...props }: TColorSelectProps) => {
  const onChange = useCallback((newValue: IColorSelectValue) => {
    form.setFieldValue(field.name, newValue);
  }, [field, form]);

  return (
    <ColorSelect.Wrapper>
      <BaseColorSelect
        {...props}
        onChange = {onChange}
        value    = {field.value}
      />
      <FieldError
        field = {field}
        form  = {form}
      />
    </ColorSelect.Wrapper>
  );
};

ColorSelect.Wrapper = styled.div`
  position: relative;
`;
