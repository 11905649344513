import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { Notifications } from '@components/Notifications/Notifications';
import { callApi }       from '@utils/apiCaller';

import {
  fetchRequestedFeeds,
  fetchRequestedFeedsSuccessfully,
  fetchRequestedFeedsFailure,
  deleteRequestedFeeds,
  deleteRequestedFeedsSuccessfully,
  deleteRequestedFeedsFailure,
  updateRequestedFeedStatus,
  updateRequestedFeedStatusSuccessfully,
  updateRequestedFeedStatusFailure,

  getMeta,

  IRequestedFeedsActions,
  IRequestedFeedsReducer,
} from './requestedFeedsReducer';

function* handleFetchRequestedFeeds() {
  try {
    const { order, orderBy, page, rowsPerPage }: IRequestedFeedsReducer['meta'] = yield select(getMeta);
    const { data: { items, totalCount } } = yield callApi(`feature/FeedRequest?page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOder=${order}`);

    yield put(fetchRequestedFeedsSuccessfully(items, totalCount));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put(fetchRequestedFeedsFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Requested feeds. Please try again', 'error');
  }
}

function* handleUpdateRequestedFeedStatus(action: IRequestedFeedsActions['updateRequestedFeedStatus']) {
  const { feedRequestId, Status, EmailText } = action.payload.attributes;

  try {
    yield callApi(`/feature/FeedRequest/status?feedRequestId=${feedRequestId}&Status=${Status}&EmailText=${EmailText}`, 'PATCH', action.payload.attributes);
    yield put(fetchRequestedFeeds());
    yield put(updateRequestedFeedStatusSuccessfully());
    yield put(replace('/admin/requested-feeds'));

  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put(updateRequestedFeedStatusFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while updating Requested feed. Please try again', 'error');
  }
}

function* handleDeleteRequestedFeeds(action: IRequestedFeedsActions['deleteRequestedFeeds']) {
  try {
    const { requestedFeedsIds } = action.payload.attributes;

    for (let i = 0; i < requestedFeedsIds.length; i++) {
      yield callApi(`feature/FeedRequest/${requestedFeedsIds[i]}`, 'DELETE');
    }

    yield put(fetchRequestedFeeds());
    yield put(deleteRequestedFeedsSuccessfully());
    yield call(Notifications.enqueueSnackbar, `Requested ${requestedFeedsIds.length > 1 ? 'feeds were' : 'feed was'} deleted successfully`, 'success');
  } catch (error) {
    const { requestedFeedsIds } = action.payload.attributes;

    // eslint-disable-next-line no-console
    console.error(error);
    yield put(deleteRequestedFeedsFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting ${requestedFeedsIds.length > 1 ? 'requested feeds' : 'the requested feed'}. Please try again`, 'error');
  }
}

function* requestedFeedsSagas() {
  yield all([
    takeLatest(fetchRequestedFeeds, handleFetchRequestedFeeds),
    takeLatest(deleteRequestedFeeds, handleDeleteRequestedFeeds),
    takeLatest(updateRequestedFeedStatus, handleUpdateRequestedFeedStatus),
  ]);
}

export { requestedFeedsSagas };
