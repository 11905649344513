import { MediaUploader as CommonMediaUploader, MediaUploaderProps } from '@common/components/MediaUploader/MediaUploader';

import { uploadFile } from '@utils/uploadFile';

export const MediaUploader = ({ ...props }: MediaUploaderProps) => (
  <CommonMediaUploader
    {...props}
    fileUploader = {uploadFile}
  />
);
