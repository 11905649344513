import { Checkbox as CheckboxAnt } from 'antd';
import { CheckboxChangeEvent }     from 'antd/lib/checkbox/Checkbox';
import { FieldProps }              from 'formik';
import { useCallback }             from 'react';
import styled                      from 'styled-components';

import { Label, LabelProps } from '../Label/Label';

interface CheckboxProps extends Partial<FieldProps> {
  disabled?     : boolean;
  label?        : string;
  labelStyle?   : LabelProps['componentStyle'];
  setValue?     : (value: boolean) => void;
  value?        : boolean;
  wrapperStyle? : {
    alignSelf? : 'flex-start' | 'flex-end' | 'center' | 'auto';
    margin?    : string;
  }
}

export const Checkbox = ({
  disabled,
  field,
  form,
  label,
  setValue,
  value,
  labelStyle   = { transform: 'none' },
  wrapperStyle = {
    alignSelf : 'auto',
    margin    : '24px 0',
  },
}: CheckboxProps) => {
  const onChange = useCallback((e: CheckboxChangeEvent) => {
    const newValue = e.target.checked;

    if (form && field) {
      form.setFieldValue(field.name, newValue);
    } else if (setValue) {
      setValue(newValue);
    }
  }, [field, form, setValue]);

  return (
    <Checkbox.Wrapper {...wrapperStyle}>
      <CheckboxAnt
        checked  = {field?.value || value}
        disabled = {disabled}
        onChange = {onChange}
      />
      {label && (
        <Label
          componentStyle = {labelStyle}
          label          = {label}
        />
      )}
    </Checkbox.Wrapper>
  );
};

Checkbox.Wrapper = styled.div<{ alignSelf?: string; margin?: string; }>`
  align-items : center;
  align-self  : ${({ alignSelf }) => alignSelf};
  display     : flex;
  gap         : 8px;
  height      : 18px;
  margin      : ${({ margin }) => margin};

  .ant-checkbox-wrapper {
    font-size   : 18px;
    line-height : normal;

    ::after { left: 25%; }
  }

  .ant-checkbox-inner {
    border-width : 2px;
    height       : 18px;
    width        : 18px;
  }
`;
