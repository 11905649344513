import { useEffect }               from 'react';
import { connect }                 from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { initializeAxiosStatusHandlers } from '@common/initializers/axiosStatusHandlers';

// auth
import { LogIn }                  from '@modules/auth/pages/Login';
import { getAuthStatus, logOut }  from '@modules/auth/authReducer';
// admin tabs
import { FeedsList }              from '@modules/feeds/pages/FeedsList';
import { Feeds }                  from '@modules/feeds/pages/FeedsContainer';
import { RequestedFeedsList }     from '@modules/feeds/pages/RequestedFeedsList';
import { StoriesList }            from '@modules/stories/pages/StoriesList';
import { Stories }                from '@modules/stories/pages/StoriesContainer';
import { BusinessesList }         from '@modules/businesses/pages/BusinessesList';
import { Businesses }             from '@modules/businesses/pages/AddBusinessesContainer';
import { ChannelsCategoriesList } from '@modules/channels/pages/ChannelsList';
import { ChannelsCategories }     from '@modules/channels/pages/AddCategoryContainer';
import { TerritoryList }          from '@modules/territories/pages/TerritoryList';
import { Territories }            from '@modules/territories/pages/AddTerritoryContainerL';
import { EventsList }             from '@modules/events/pages/EventsList';
import { Events }                 from '@modules/events/pages/EventsContainer';
import { CategoriesList }         from '@modules/categories/pages/CategoriesList';
import { Categories }             from '@modules/categories/pages/AddCategoryContainer';
import { AffiliationsList }       from '@modules/affiliations/pages/AffiliationsList';
import { Affiliations }           from '@modules/affiliations/pages/AffiliationContainer';
import { SlidesList }             from '@modules/slider/pages/SlidesList';
import { Slides }                 from '@modules/slider/pages/SlidesContainer';
import { FormsList }              from '@modules/forms/pages/FormList';
import { Forms }                  from '@modules/forms/pages/FormsContainer';
import { License }                from '@modules/licenses/pages/License';
import { LicensesList }           from '@modules/licenses/pages/LicensesList';
import { AdvertisingList }        from '@modules/advertisments/pages/AdvertisingList';
import { Advertising }            from '@modules/advertisments/pages/Advertising';
import { TipsList }               from '@modules/tips/pages/TipsList';
import { Tips }                   from '@modules/tips/pages/Tips';
import { TagsList }               from '@modules/tags/pages/TagsList';
import { Tags }                   from '@modules/tags/pages/TagView';

import { MenuItemContainer } from '@components/Menu/MenuItemContainer';

import { browserHistory, store } from './store';

export const notAuthorizedCallback = () => {
  localStorage.clear();
  store.createdStore.dispatch(logOut());
};

export const RoutesComponent = (props: any) => {
  useEffect(() => {
    initializeAxiosStatusHandlers({ onUnauthorized: notAuthorizedCallback });
  }, []);

  useEffect(() => {
    if (!props.isAuthorized) {
      browserHistory.replace('/login');
    }
  }, [props.isAuthorized]);

  return (
    <Switch>
      <Route path="/login" exact component={LogIn} />
      {props.isAuthorized && (
        <Switch>
          <Route
            path="/admin"
            render={() => (
              <MenuItemContainer>
                <Switch>
                  <Route path="/admin/stories" exact component={StoriesList} />
                  <Route path="/admin/stories/:id" exact component={Stories} />
                  <Route path="/admin/businesses" exact component={BusinessesList} />
                  <Route path="/admin/businesses/:id" exact component={Businesses} />
                  <Route path="/admin/channels" exact component={ChannelsCategoriesList} />
                  <Route path="/admin/channels/:id" exact component={ChannelsCategories} />
                  <Route path="/admin/areas" exact component={TerritoryList} />
                  <Route path="/admin/areas/:id" exact component={Territories} />
                  <Route path="/admin/events" exact component={EventsList} />
                  <Route path="/admin/events/:id" exact component={Events} />
                  <Route path="/admin/categories" exact component={CategoriesList} />
                  <Route path="/admin/categories/:id" exact component={Categories} />
                  <Route path="/admin/affiliations" exact component={AffiliationsList} />
                  <Route path="/admin/affiliations/:id" exact component={Affiliations} />
                  <Route path="/admin/carousel" exact component={SlidesList} />
                  <Route path="/admin/carousel/:id" exact component={Slides} />
                  <Route path="/admin/forms" exact component={FormsList} />
                  <Route path="/admin/forms/:id" exact component={Forms} />
                  <Route path="/admin/feeds" exact component={FeedsList} />
                  <Route path="/admin/feeds/:id" exact component={Feeds} />
                  <Route path="/admin/requested-feeds/" exact component={RequestedFeedsList} />
                  <Route path="/admin/clients" exact component={LicensesList} />
                  <Route path="/admin/clients/:id" exact component={License} />
                  <Route path="/admin/sponsors/" exact component={AdvertisingList} />
                  <Route path="/admin/sponsors/:id" exact component={Advertising} />
                  <Route path="/admin/tips" exact component={TipsList} />
                  <Route path="/admin/tips/:id" exact component={Tips} />
                  <Route path="/admin/tags" exact component={TagsList} />
                  <Route path="/admin/tags/:id" exact component={Tags} />
                  <Redirect to="/admin/feeds" />
                </Switch>
              </MenuItemContainer>
            )}
          />
          <Redirect to="/admin/channels" />
        </Switch>
      )}
    </Switch>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthorized: getAuthStatus(state),
});

export const Routes = connect(mapStateToProps, null)(RoutesComponent);

export const getCurrentRoute = (state: any) => state.router.location;
