import { all, takeLatest, put } from 'redux-saga/effects';
import { replace }              from 'connected-react-router';

import { getTokenData }         from '@common/utils/tokenHandlers';

import {
  saveLogInData,
  saveLogInDataSuccessfully,
  saveLogInDataFailure,
} from './authReducer';

function* handleLogInEvent() {
  const { accessToken, tokenType } = getTokenData();
  if (typeof accessToken === 'string' && typeof tokenType === 'string') {
    yield put(saveLogInDataSuccessfully(accessToken, tokenType));
    yield put(replace('/admin/channels'));
  } else {
    yield put(saveLogInDataFailure('Failed to save login data'));
  }
}

function* authSagas() {
  yield all([
    takeLatest(saveLogInData, handleLogInEvent),
  ]);
}

export { authSagas, handleLogInEvent };
