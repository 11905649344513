import { FieldProps }  from 'formik';
import { useCallback } from 'react';
import styled          from 'styled-components';

import { FieldError }                  from '../FieldError';
import { BaseSelectProps, BaseSelect } from '../Select/BaseSelect';
import { useUpdateComponentValue }     from '../../hooks';
import { TOption }                     from '../../models';
import { TDebounceFetcherHook }        from '../../initializers/debounceFetchHook';
import { setPopupContainer }           from '../../utils';

export interface IAutoSuggestProps extends Omit<BaseSelectProps, 'onChange' | 'options' | 'value'>, Partial<FieldProps> {
  endpoint       : string;
  errorMessage?  : string;
  onChange       : (newValue: TOption | null) => void;
  responseMapper : (data: unknown[]) => TOption[];
  value?         : TOption;
  wrapperStyle?  : { margin?: string };
}

interface ICommonAutoSuggest extends IAutoSuggestProps {
  debounceFetcher: TDebounceFetcherHook;
}

export const AutoSuggest = ({
  debounceFetcher,
  endpoint,
  errorMessage,
  field,
  form,
  onChange,
  responseMapper,
  value,
  wrapperStyle,
  ...componentProps
}: ICommonAutoSuggest) => {
  const [options, isFetching, onSearch] = debounceFetcher(endpoint, responseMapper);
  const { updateComponentValue }        = useUpdateComponentValue<TOption | null>(field, form, onChange);

  const clearField = useCallback(() => {
    updateComponentValue(null);
  }, [updateComponentValue]);

  const onSelect = useCallback((optionValue: number | string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateComponentValue(options.find((item: any) => item.value === optionValue) || null);
    onSearch('');
  }, [updateComponentValue, onSearch, options]);

  return (
    <AutoSuggest.Wrapper {...wrapperStyle}>
      <BaseSelect
        allowClear
        showSearch
        {...componentProps}
        getPopupContainer = {setPopupContainer}
        filterOption      = {false}
        loading           = {isFetching}
        onClear           = {clearField}
        onSearch          = {onSearch}
        onSelect          = {onSelect}
        options           = {options}
        value             = {field?.value?.label || value?.label || null}
      />
      <FieldError
        errorMessage = {errorMessage}
        field        = {field}
        form         = {form}
      />
    </AutoSuggest.Wrapper>
  );
};

AutoSuggest.Wrapper = styled.div<{ margin?: string; }>`
  margin   : ${({ margin, theme }) => margin || theme.field.gap};
  position : relative;

  .ant-select-single .ant-select-selector .ant-select-selection-search { left: 0; }
`;
