import { useCallback, useEffect, useMemo } from 'react';
import { connect }                         from 'react-redux';
import { Formik, FormikProps }             from 'formik';
import { RouteComponentProps }             from 'react-router-dom';
import * as Yup                            from 'yup';
import styled                              from 'styled-components';

import { CircularLoader }    from '@components/Loaders/CircularLoader';
import { IColorSelectValue } from '@components/ColorSelect/BaseColorSelect';

import { useFetchEntity }            from '@utils/fetchEntity';
import { splitColor, validateColor } from '@utils/colors';
import { IChannelsCategory }         from '@models/index';
import { IState }                    from '@store/rootReducer';

import {
  addCategory   as addCategoryAction,
  addChannel    as addChannelAction,
  editCategory  as editCategoryAction,
  editChannel   as editChannelAction,
  deleteChannel as deleteChannelAction,
  fetchChannels as fetchChannelsAction,
  getCategoryChannels,
  getMeta,
  IChannelsActionsCreators,
  IChannelsReducer,
}                       from '../channelsReducerL';
import { CategoryForm } from '../components/CategoryForm';

export type TChannelsCategoryFormModel = Omit<IChannelsCategory, 'color' | 'channelCategoryId' | 'headerUri'> & {
  channelCategoryId? : number;
  color              : IColorSelectValue;
  categoryHeaderUri  : { imageUri: string | null; mimeType: string; } | null;
}

type TChannelsCategoryPostModel = Omit<IChannelsCategory, 'isCitySpecific' | 'channelCategoryId'>;

interface IChannelsCategoriesContainer {
  addCategory        : IChannelsActionsCreators['addCategory'];
  addChannel         : IChannelsActionsCreators['addChannel'];
  channels           : IChannelsReducer['categoryChannels'];
  deleteChannel      : IChannelsActionsCreators['deleteChannel'];
  editCategory       : IChannelsActionsCreators['editCategory'];
  editChannel        : IChannelsActionsCreators['editChannel'];
  fetchChannels      : IChannelsActionsCreators['fetchChannels'];
  isChannelsFetching : boolean;
  isFetching         : boolean;
}

const validationSchema = Yup.object().shape({
  color : validateColor(),
  name  : Yup
    .string()
    .required('Enter channels category name'),
});

const ChannelsCategoriesContainer = ({
  addCategory,
  addChannel,
  channels,
  deleteChannel,
  editCategory,
  editChannel,
  fetchChannels,
  isChannelsFetching,
  isFetching,
}: IChannelsCategoriesContainer & RouteComponentProps) => {
  const [category, isCategoryFetching] = useFetchEntity<IChannelsCategory>('feature/channelCategory');

  const initialValues = useMemo<TChannelsCategoryFormModel>(() => ({
    channelCategoryId : category?.channelCategoryId,
    color             : splitColor(category?.color || ''),
    name              : category?.name || '',
    categoryHeaderUri : category ? {imageUri: category.headerUri, mimeType: 'image'} : null,
  }), [category]);

  const onSubmit = useCallback((values: TChannelsCategoryFormModel) => {
    const { color } = values.color;

    const requestData: TChannelsCategoryPostModel = {
      color,
      name      : values.name,
      headerUri : values.categoryHeaderUri ? values.categoryHeaderUri.imageUri : null,
    };

    if (category) {
      editCategory({ ...category, ...requestData });
    } else {
      addCategory(requestData);
    }
  }, [category]);

  useEffect(() => {
    if (category?.channelCategoryId) {
      fetchChannels(category.channelCategoryId);
    }
  }, [category]);

  if (isCategoryFetching) return (
    <Container>
      <CircularLoader />
    </Container>
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validateOnBlur   = {false}
        validationSchema = {validationSchema}
      >
        {({ ...props }: FormikProps<TChannelsCategoryFormModel>) => (
          <CategoryForm
            {...props}
            addChannel         = {addChannel}
            categoryChannels   = {channels}
            deleteChannel      = {deleteChannel}
            editChannel        = {editChannel}
            isChannelsFetching = {isChannelsFetching}
            isFetching         = {isFetching}
            values             = {props.values}
          />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 948px; }
`;

const mapStateToProps = (state: IState) => ({
  channels           : getCategoryChannels(state),
  isChannelsFetching : getMeta(state).isChannelsFetching,
  isFetching         : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addCategory   : addCategoryAction,
  addChannel    : addChannelAction,
  deleteChannel : deleteChannelAction,
  editCategory  : editCategoryAction,
  editChannel   : editChannelAction,
  fetchChannels : fetchChannelsAction,
};

export const ChannelsCategories = connect(mapStateToProps, mapDispatchToProps)(ChannelsCategoriesContainer);
