import { useCallback, useEffect, useRef }        from 'react';
import { Form, Field, FormikProps }              from 'formik';
import styled                                    from 'styled-components';

import {
  Card,
  Input,
  Button,
  Label, 
  FormButtons,
} from '@common/components';

import { CircularLoader }     from '@components/Loaders/CircularLoader';
import { FormList, IElement } from '@components/List/FormList';
import { MediaUploader }      from '@components/MediaUploader/MediaUploader';
import { Title }              from '@components/Title/Title';

import { Modal }          from '@src/App';
import { ICategoryModel } from '@src/models';
import { useFetchEntity } from '@utils/fetchEntity';

import { AddSubcategory }             from './AddSubcategory';
import { ICategoriesActionsCreators } from '../categoriesReducer';

interface IFormCategory {
  parentId?         : number;
  isFetching        : boolean;
  isEditMode        : boolean;
  subcategoriesData : IElement[];
  addSubcategory    : ICategoriesActionsCreators['addSubcategory'];
  editSubcategory   : ICategoriesActionsCreators['editSubcategory'];
  deleteSubcategory : ICategoriesActionsCreators['deleteSubcategory'];
  isSubFetching     : boolean;
  submitForm        : () => void;
}

interface ICategoryForm extends Omit<ICategoryModel, 'iconUri'> {
  imageUri : string | {
    imageUri : string;
    mimeType : string;
  };
}

export const FormCategory = ({
  parentId,
  isFetching,
  isEditMode,
  addSubcategory,
  editSubcategory,
  deleteSubcategory,
  isSubFetching,
  subcategoriesData,
  submitForm,
}: FormikProps<ICategoryForm> & IFormCategory) => {
  const modalDataRef = useRef<ICategoryForm | null>(null);

  const onClickAddSubcategory = useCallback(() => {
    Modal.open(AddSubcategory, {
      saveSubcategory: (values: ICategoryForm) => {
        modalDataRef.current = null;

        addSubcategory({ ...values, parentId });
      },
    });
  }, [parentId]);

  const onEditSubcategory = useCallback((subcategory: IElement) => {
    const subcategoryValues = { ...(subcategory as unknown as ICategoryForm), iconUri: {imageUri: subcategory.icon, mimeType: 'image' }};
    
    modalDataRef.current    = subcategoryValues;

    Modal.open(AddSubcategory, {
      deleteSubcategory : (values: ICategoryForm) => deleteSubcategory({ ...values, parentId }),
      saveSubcategory   : (values: ICategoryForm) => editSubcategory({ ...values, parentId }),
      subcategoryValues,
    });
  }, [parentId]);

  useEffect(() => {
    if (Modal.update) {
      Modal.update(AddSubcategory, {
        deleteSubcategory : modalDataRef.current ? (values: ICategoryForm) => deleteSubcategory({ ...values, parentId }) : undefined,
        isLoading         : isSubFetching,
        subcategoryValues : modalDataRef.current,
        saveSubcategory   : (values: ICategoryForm) => {
          if (modalDataRef.current) {
            editSubcategory({ ...values, parentId });
          } else {
            addSubcategory({ ...values, parentId });
          }
        },
      });
    }
  }, [isSubFetching, modalDataRef]);

  const [category] = useFetchEntity('feature/Category') as [ICategoryForm | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${category ? 'edit the' : 'add a'} category`} />
      <FormCategory.Wrapper noValidate>
        <Field
          label       = "name"
          name        = "name"
          placeholder = "Enter a title"
          component   = {Input}
        />
        <Field
          label           = "icon image"
          name            = "iconUri"
          container       = "categories"
          labelAlignment  = "top"
          component       = {MediaUploader}
          mimeTypeOptions = "image"
          imageStyle      = {{
            backgroundColor : '#E6E9F4',
            height          : '58px',
            width           : '58px',
            borderRadius    : '50%',
          }}
        />
        <FormButtons
          disabled      = {{main: isFetching || isSubFetching}}
          onClickCancel = {() => history.back()}
          onSubmit      = {submitForm}
        />

        <FormCategory.Column>
          {isEditMode && (
            <>
              <FormCategory.Separator />
              <FormCategory.LabelCategoryWrapper>
                <Label label="subcategories" />
                <FormCategory.ButtonWrapper>
                  <Button
                    label   = "Add Subcategory"
                    type    = "button"
                    onClick = {onClickAddSubcategory}
                  />
                </FormCategory.ButtonWrapper>
              </FormCategory.LabelCategoryWrapper>
              {isSubFetching ? (
                <CircularLoader padding="30px 0 0 0" />
              ) : (
                <FormList data={subcategoriesData} onEdit={onEditSubcategory} />
              )}
            </>
          )}
        </FormCategory.Column>
      </FormCategory.Wrapper>
    </Card>
  );
};

FormCategory.Separator = styled.div`
  border-bottom : 1px solid ${({ theme }) => theme.borderColor.gray};
  margin-top    : 12px;
  margin-bottom : 20px;

  @media (min-width: 1284px) {
    width       : 845px;
    margin-left : 15px;
  }
`;

FormCategory.LabelCategoryWrapper = styled.div`
  margin-top      : 16px;
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
`;

FormCategory.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

FormCategory.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;

FormCategory.ButtonWrapper = styled.div`
  @media (min-width: 1284px) {
    position : relative;
    left     : 490px;
  }
`;
