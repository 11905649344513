import { Popover }                              from 'antd';
import { FieldProps }                           from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled                                   from 'styled-components';

import { useFieldError }     from '../../hooks';
import { setPopupContainer } from '../../utils';

export interface FieldErrorProps extends Partial<FieldProps> {
  errorMessage? : string;
  fieldType?    : 'bordered';
  value?        : number | string;
}

export const FieldError = ({
  errorMessage,
  field,
  fieldType,
  form,
  value,
}: FieldErrorProps): JSX.Element => {
  const PADDING_GAP = 10;

  const messageRef = useRef<HTMLParagraphElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { fieldError, isErrorVisible } = useFieldError({ errorMessage, field, form, value });

  const [messageEllipsis, setMessageEllipsis] = useState(false);

  const stringFieldError = useMemo(() => {
    if (typeof fieldError === 'object' && fieldError !== null) {
      return Object.values(fieldError).join('. ');
    }

    return fieldError;
  }, [fieldError]);

  useEffect(() => {
    if (messageRef.current?.offsetWidth && wrapperRef.current?.offsetWidth) {
      setMessageEllipsis(messageRef.current.offsetWidth === wrapperRef.current.offsetWidth - PADDING_GAP);
    }
  }, [stringFieldError]);

  return (
    <Popover
      content           = {fieldError}
      getPopupContainer = {setPopupContainer}
      overlayStyle      = {{ maxWidth: 450 }}
      placement         = "bottom"
      visible           = {messageEllipsis ? undefined : false}
    >
      <FieldError.MessageWrapper
        visible       = {isErrorVisible}
        ref           = {(ref) => { wrapperRef.current = ref; }}
        visibleBottom = {fieldType === 'bordered' ? '-23px' : '-19px'}
      >
        <p ref={(ref) => { messageRef.current = ref; }}>
          {`*${stringFieldError}`}
        </p>
      </FieldError.MessageWrapper>
    </Popover>
  );
};

FieldError.MessageWrapper = styled.div<{ visible: boolean; visibleBottom: string }>`
  bottom       : ${({ visible, visibleBottom }) => (visible ? visibleBottom : '0')};
  color        : ${({ theme }) => theme.color.red};
  font-size    : 12px;
  height       : ${({ visible }) => (visible ? 'auto' : '0')};
  line-height  : 14px;
  opacity      : ${({ visible }) => (visible ? 1 : 0)};
  padding-left : 10px;
  position     : absolute;
  transition   : all 0.2s;
  width        : 100%;
  z-index      : 100;

  p {
    display       : inline-block;
    overflow      : hidden;
    max-width     : 100%;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }
`;
