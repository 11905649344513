import { useEffect, useState }   from 'react';
import styled                    from 'styled-components';

import { FileFilled } from '@ant-design/icons';

export interface ISuggestedValues {
  values: {
    id    : string;
    label : string;
    value : string;
  }[];
}

export const SuggestedValues = ({
  values,
}: ISuggestedValues) => {
  const [copiedText, setCopyText] = useState<string | null>(null);

  useEffect(() => {
    navigator.clipboard.writeText(copiedText || '');
  }, [copiedText]);

  return (
    <SuggestedValues.Wrapper>
      <SuggestedValues.Title>Suggested values</SuggestedValues.Title>
      {values.map((item) => (
        <SuggestedValues.Item key={item.value}>
          <h4>{item.label}:</h4>
          <SuggestedValues.Value>
            <p onClick={() => setCopyText(item.value)}>{item.value}</p>
            {copiedText === item.value ? <p>Copied</p> : <FileFilled />}
          </SuggestedValues.Value>
        </SuggestedValues.Item>
      ))}
    </SuggestedValues.Wrapper>
  );
};

SuggestedValues.Item = styled.div`
  align-items   : center;
  display       : flex;
  font-family   : RobotoCondensed-Regular;
  gap           : 10px;
  margin-bottom : 5px;
  padding-left  : 10px;
  user-select   : none;

  > h4 {
    font-family : RobotoCondensed-Regular;
    font-size   : 18px;
    font-weight : 600;
  }
`;

SuggestedValues.Title = styled.h3`
  cursor      : default;
  font-family : RobotoCondensed-Regular;
  font-size   : 20px;
  font-weight : 600;
  opacity     : 0.8;
`;

SuggestedValues.Value = styled.div`
  align-items : center;
  display     : flex;
  gap         : 5px;

  > p {
    font-size: 16px;

    &:first-child {
      cursor          : pointer;
      text-decoration : underline;
    }

    &:nth-child(2) { opacity: 0; }
  }

  svg {
    height     : 18px;
    opacity    : 0;
    transition : all 0.1s;
    width      : 18px;
  }

  &:hover {
    svg,
    p:nth-child(2) { opacity: 1; }
  }
`;

SuggestedValues.Wrapper = styled.div``;
