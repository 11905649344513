import { useMemo, useCallback, useState } from 'react';
import { connect }                        from 'react-redux';
import { Formik, FormikProps }            from 'formik';
import * as Yup                           from 'yup';
import styled                             from 'styled-components';

import { CircularLoader }       from '@components/Loaders/CircularLoader';
import { callApi }              from '@utils/apiCaller';
import { useFetchEntity }       from '@utils/fetchEntity';
import { findForbiddenSymbols } from '@utils/validators';

import { IForms }         from '@models/index';
import { IState }         from '@store/rootReducer';

import { FormForms } from '../components/FormFroms';

import {
  addForm     as addFormAction,
  deleteForms as deleteFormAction,
  editForm    as editFormAction,
  getMeta,
  IFormsActionsCreators,
} from '../formsReducer';

interface ISlide {
  addForm    : IFormsActionsCreators['addForm'];
  deleteForm : IFormsActionsCreators['deleteForms'];
  editForm   : IFormsActionsCreators['editForm'];
  isFetching : boolean;
}

const INIT_STRINGS = {
  headerTitle               : null,
  blockOneTitle             : null,
  blockOneDescription       : null,
  blockTwoTitle             : null,
  blockTwoDescription       : null,
  blockThreeTitle           : null,
  blockThreeDescription     : null,
  registrationFlowTitle     : null,
  registrationFlowListItems : [],
};

interface ILocalization {
  headerTitle?             : string | null;
  blockOneTitle            : string | null;
  blockOneDescription      : string | null;
  blockTwoTitle            : string | null;
  blockTwoDescription      : string | null;
  blockThreeTitle          : string | null;
  blockThreeDescription    : string | null;
  registrationFlowTitle    : string | null;
  registrationFlowListItems: string[] | null;
}

interface IFormModel {
  localization           : string;
  name                   : string | null;
  categoryId             : number | null;
  categoryName           : string | null;
  rSettingProfileChannel?: any;
  mainPage               : {
    blockOneImages : { uri: string; mimeType: string; }[];
    blockTwoImage  : { imageUri: string; mimeType: string; } | null;
    headerImageUri : { imageUri: string; mimeType: string; } | null;
  };
  registrationFlow : {
    images: { uri: string; mimeType: string; }[];
  };
  strings : {
    us  : ILocalization;
    usEs: ILocalization;
    gb  : ILocalization;
  },
}

interface IFormPostModel {
  settingsId?  : number;
  categoryId?  : number;
  name?        : string | null;
  settingsModel: {
    mainPage         : {
      blockOneImages : { imageUrl: string; mimeType: string; }[];
      blockTwoImage  : { imageUrl?: string; mimeType?: string; };
      headerImageUri?: string;
    };
    registrationFlow : {
      images: { imageUrl: string; mimeType: string; }[];
    };
    strings          : {
      us  : ILocalization;
      usEs: ILocalization;
      gb  : ILocalization;
    },
  },
  rSettingProfileChannel: { channelId: number; }
}

const FormsComponent = ({
  addForm,
  editForm,
  isFetching,
}: ISlide) => {

  const [form, isFormFetching]     = useFetchEntity('settings/organizations/ui/id') as [IForms | null, boolean, () => Promise<void>];
  const [newFormName, setFormName] = useState<string>('');

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name : Yup.string()
        .typeError('Enter form name')
        .test(...findForbiddenSymbols())
        .test(
          'is-uniq',
          'Name already exists and should be unique',
          async (value) => {
            if (form && (form.name === value || form.categoryName === value) || value === newFormName) {
              return true;
            }

            try {
              await callApi(`settings/organizations/ui/${value}`, 'get');

              return false;
            } catch (error) {
              setFormName(value as string);

              return true;
            }
          },
        ),
      categoryId             : Yup.number().nullable(),
      channels               : Yup.array().ensure(),
      rSettingProfileChannel : Yup.array().ensure(),
      mainPage               : Yup.object().shape({
        headerImageUri : Yup.object()
          .typeError('Upload header background image(main page)')
          .required(),
        blockOneImages : Yup.array()
          .min(4, 'Upload 4 images for first section(main page)')
          .of(Yup.object().shape({
            uri: Yup.string().required(),
          })),
        blockTwoImage : Yup.object()
          .typeError('Upload image for section no.2(main page)')
          .required(),
      }),
      strings : Yup.object().shape({
        us   : Yup.object().shape({
          headerTitle           : Yup.string()
            .typeError('Enter header title(main page)')
            .required(),
          blockOneTitle         : Yup.string()
            .typeError('Enter section no.1 title(main page)')
            .required(),
          blockOneDescription   : Yup.string()
            .typeError('Enter section no.1 description(main page)')
            .required(),
          blockTwoTitle         : Yup.string()
            .typeError('Enter section no.2 title(main page)')
            .required(),
          blockTwoDescription   : Yup.string()
            .typeError('Enter section no.2 description(main page)')
            .required(),
          blockThreeTitle       : Yup.string()
            .typeError('Enter section no.3 title(main page)')
            .required(),
          blockThreeDescription : Yup.string()
            .typeError('Enter section no.3 description(main page)')
            .required(),
          registrationFlowTitle : Yup.string().nullable()
            .typeError('Enter sidebar title(registration page)')
            .required('Enter sidebar title(registration page)'),
          registrationFlowListItems : Yup.array().of(Yup.string().required()),
        }),
        usEs : Yup.object().shape({
          headerTitle               : Yup.string().nullable(),
          blockOneTitle             : Yup.string().nullable(),
          blockOneDescription       : Yup.string().nullable(),
          blockTwoTitle             : Yup.string().nullable(),
          blockTwoDescription       : Yup.string().nullable(),
          blockThreeTitle           : Yup.string().nullable(),
          blockThreeDescription     : Yup.string().nullable(),
          registrationFlowTitle     : Yup.string().nullable(),
          registrationFlowListItems : Yup.array().of(Yup.string().required()),
        }),
        gb   : Yup.object().shape({
          headerTitle               : Yup.string().nullable(),
          blockOneTitle             : Yup.string().nullable(),
          blockOneDescription       : Yup.string().nullable(),
          blockTwoTitle             : Yup.string().nullable(),
          blockTwoDescription       : Yup.string().nullable(),
          blockThreeTitle           : Yup.string().nullable(),
          blockThreeDescription     : Yup.string().nullable(),
          registrationFlowTitle     : Yup.string().nullable(),
          registrationFlowListItems : Yup.array().of(Yup.string().required()),
        }),
      }),
      registrationFlow    : Yup.object().shape({
        images: Yup.array()
          .of(Yup.object().shape({
            uri: Yup.string().required(),
          })),
      }),
    });
  }, [form, newFormName]);

  const initialValues = useMemo<IFormModel>(() => {
    if (form) {   
      const { mainPage } = form.settingsModel;
      const prevBlockTwoImage = typeof mainPage.blockTwoImage === 'object'
        ? {
          imageUri : mainPage.blockTwoImage.imageUrl,
          mimeType : mainPage.blockTwoImage.mimeType,
        } : {
          imageUri : mainPage.blockTwoImage,
          mimeType : 'image',
        };

      const result = {
        localization           : 'us',
        name                   : form.name || form.categoryName,
        categoryId             : form.categoryId,
        categoryName           : form.categoryName,
        strings                : form.settingsModel.strings,
        rSettingProfileChannel : form.rSettingProfileChannel.map((item) => ({
          label: item.channelName,
          value: item.channelId,
        })),
        mainPage : {
          blockOneImages : mainPage.blockOneImages.map(el => ({
            mimeType : 'image',
            uri      : el.imageUrl,
          })),
          blockTwoImage  : prevBlockTwoImage,
          headerImageUri : { imageUri: mainPage.headerImageUri, mimeType: 'image' },
        },
        registrationFlow : {
          images : form.settingsModel.registrationFlow.images.map(el => ({
            mimeType : 'image',
            uri      : el.imageUrl,
          })),
        },
      };
      return result;
    }

    return ({
      localization           : 'us',
      name                   : null,
      categoryId             : null,
      categoryName           : null,
      rSettingProfileChannel : [],
      mainPage               : {
        headerImageUri : null,
        blockOneImages : [],
        blockTwoImage  : null,
      },
      strings                : {
        us   : { ...INIT_STRINGS },
        usEs : { ...INIT_STRINGS },
        gb   : { ...INIT_STRINGS },
      },
      registrationFlow    : {
        images: [],
      },
    });
  }, [form]);

  const onSubmit = useCallback(async (values: IFormModel) => {
    const { categoryId, name, rSettingProfileChannel } = values;
    const requestData: IFormPostModel = {
      settingsModel: {
        mainPage         : {
          blockOneImages : values.mainPage.blockOneImages.map((el: { mimeType: string; uri: string; }) => ({
            mimeType: el.mimeType,
            imageUrl: el.uri,
          })),
          blockTwoImage  : { imageUrl: values.mainPage.blockTwoImage?.imageUri, mimeType: values.mainPage.blockTwoImage?.mimeType },
          headerImageUri : values.mainPage.headerImageUri?.imageUri,
        },
        registrationFlow : {
          images : values.registrationFlow.images.map((item: { uri: string; }) => ({
            mimeType: 'image',
            imageUrl: item.uri,
          })),
        },
        strings: values.strings,
      },
      rSettingProfileChannel: rSettingProfileChannel.map((item: { label: string; value: number; }) => ({ channelId: item.value })),
    };

    if (categoryId) {
      requestData.categoryId = categoryId;
    } else {
      requestData.name = name;
    }
    
    if (form) {
      requestData.settingsId = form.settingsId;
      editForm(requestData);
      
    } else {
      addForm(requestData);
    }
  }, [form]);

  if (isFormFetching) return (
    <CircularLoader />
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<any>) => (
          <FormForms
            {...props}
            form       = {form}
            isFetching = {isFetching}
          />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 947px; }
`;

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addForm    : addFormAction,
  deleteForm : deleteFormAction,
  editForm   : editFormAction,
};

export const Forms = connect(mapStateToProps, mapDispatchToProps)(FormsComponent);
