export const parseServerError = (error: any) => {
  if (!error?.response?.data) {
    return null;
  }
  if (typeof error.response.data.errorMessage === 'string') {
    return error.response.data.errorMessage;
  }
  if (typeof error.response.data.error === 'string') {
    return error.response.data.error;
  }
  return null;
};
