import { Popover }                        from 'antd';
import { useCallback, useMemo, useState } from 'react';
import styled                             from 'styled-components';

import { setPopupContainer } from '../../utils';

interface TerritorySelectProps {
  currentTerritory  : string;
  onSelectTerritory : (territoryId: number) => void;
  territories       : {
    territoryName : string;
    territoryId   : number;
  }[];
}

export const TerritorySelect = ({ currentTerritory, onSelectTerritory, territories }: TerritorySelectProps) => {
  const [isPopoverActive, setPopoverStatus] = useState(false);

  const popoverDisabled = useMemo(() => territories.length < 2, [territories]);

  const changeCurrentTerritory = useCallback((territoryId: number) => {
    setPopoverStatus(false);
    onSelectTerritory(territoryId);
  }, [onSelectTerritory]);

  const popoverContent = useMemo(() => (
    <TerritorySelect.PopoverContent>
      {territories.map((item) => (
        <TerritorySelect.TerritoryItem
          key     = {item.territoryId}
          onClick = {() => changeCurrentTerritory(item.territoryId)}
        >
          <p>{item.territoryName}</p>
        </TerritorySelect.TerritoryItem>
      ))}
    </TerritorySelect.PopoverContent>
  ), [changeCurrentTerritory, territories]);

  if (!currentTerritory) { return null; }

  return (
    <TerritorySelect.Wrapper
      cursor      = {popoverDisabled ? 'default' : 'pointer'}
      rotateAngle = {isPopoverActive ? '-90deg' : '90deg'}
    >
      <Popover
        content           = {popoverContent}
        getPopupContainer = {setPopupContainer}
        onVisibleChange   = {popoverDisabled ? undefined : setPopoverStatus}
        overlayInnerStyle = {{ padding: '-12px' }}
        placement         = "bottomLeft"
        trigger           = "click"
        visible           = {popoverDisabled ? false : isPopoverActive}
      >
        <p>{currentTerritory} {!popoverDisabled && <span>&gt;</span>}</p>
      </Popover>
    </TerritorySelect.Wrapper>
  );
};

TerritorySelect.PopoverContent = styled.div`
  margin  : -12px -16px;
  padding : 10px 0;
`;

TerritorySelect.TerritoryItem = styled.div`
  color      : ${({ theme }) => theme.color.black};
  cursor     : pointer;
  padding    : 5px 20px;
  transition : all 0.2s;

  &:hover {
    background-color : #0058ff1a;
    color            : ${({ theme }) => theme.color.blue};
  }
`;

TerritorySelect.Wrapper = styled.div<{ cursor: string; rotateAngle: string; }>`
  p {
    font-size   : 14px;
    color       : ${({ theme }) => theme.color.black};
    cursor      : ${({ cursor }) => cursor};
    font-weight : 600;
    margin-left : 8px;
    min-width   : 100px;
    user-select : none;
  }

  span {
    display    : inline-block;
    font-size  : 18px;
    transform  : ${({ rotateAngle }) => `rotate(${rotateAngle})`};
    transition : all 0.3s;
  }
`;
