import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { ITableMeta } from '@components/Table/ClickableItem';

import { IFeedModel, IOptionType } from '@models/index';

import { IState } from '@store/rootReducer';

export const STATE_KEY = 'feeds';

export interface IFeedsReducer {
  feeds : IFeedModel[];
  meta  : Required<ITableMeta> & {
    channels   : IOptionType[];
    isFetching : boolean;
    name       : string;
    totalFeeds : number;
    publisher  : IOptionType | null;
    territory  : IOptionType | null;
  };
}

export enum FeedTypes {
  fetchFeeds              = 'fetchFeeds',
  fetchFeedsSuccessfully  = 'fetchFeedsSuccessfully',
  fetchFeedsFailure       = 'fetchFeedsFailure',
  addFeed                 = 'addFeed',
  addFeedSuccessfully     = 'addFeedSuccessfully',
  addFeedFailure          = 'addFeedFailure',
  editFeed                = 'editFeed',
  editFeedSuccessfully    = 'editFeedSuccessfully',
  editFeedFailure         = 'editFeedFailure',
  deleteFeeds             = 'deleteFeeds',
  deleteFeedsSuccessfully = 'deleteFeedsSuccessfully',
  deleteFeedsFailure      = 'deleteFeedsFailure',
}

export interface IFeedsActions {
  [FeedTypes.fetchFeeds]              : Action<{ meta: Partial<IFeedsReducer['meta']> }>;
  [FeedTypes.fetchFeedsSuccessfully]  : Action<{ feeds: IFeedModel[]; meta: { totalFeeds: number } }>;
  [FeedTypes.fetchFeedsFailure]       : Action<{}>;
  [FeedTypes.addFeed]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [FeedTypes.addFeedSuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [FeedTypes.addFeedFailure]          : Action<{ meta: { isFetching: false }}>;
  [FeedTypes.editFeed]                : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [FeedTypes.editFeedSuccessfully]    : Action<{ meta: { isFetching: false }}>;
  [FeedTypes.editFeedFailure]         : Action<{ meta: { isFetching: false }}>;
  [FeedTypes.deleteFeeds]             : Action<{ attributes: { feedIds: number[] }; meta: { isFetching: true }}>;
  [FeedTypes.deleteFeedsSuccessfully] : Action<{}>;
  [FeedTypes.deleteFeedsFailure]      : Action<{}>;
}

export interface IFeedsActionsCreators {
  [FeedTypes.fetchFeeds]              : (feedMeta?: Partial<IFeedsReducer['meta']>) => IFeedsActions[FeedTypes.fetchFeeds];
  [FeedTypes.fetchFeedsSuccessfully]  : (feeds: IFeedModel[], totalCount: number) => IFeedsActions[FeedTypes.fetchFeedsSuccessfully];
  [FeedTypes.fetchFeedsFailure]       : () => IFeedsActions[FeedTypes.fetchFeedsFailure];
  [FeedTypes.addFeed]                 : (values: any) => IFeedsActions[FeedTypes.addFeed];
  [FeedTypes.addFeedSuccessfully]     : () => IFeedsActions[FeedTypes.addFeedSuccessfully];
  [FeedTypes.addFeedFailure]          : () => IFeedsActions[FeedTypes.addFeedFailure];
  [FeedTypes.editFeed]                : (values: any) => IFeedsActions[FeedTypes.editFeed];
  [FeedTypes.editFeedSuccessfully]    : () => IFeedsActions[FeedTypes.editFeedSuccessfully];
  [FeedTypes.editFeedFailure]         : () => IFeedsActions[FeedTypes.editFeedFailure];
  [FeedTypes.deleteFeeds]             : (feedIds: number[]) => IFeedsActions[FeedTypes.deleteFeeds];
  [FeedTypes.deleteFeedsSuccessfully] : () => IFeedsActions[FeedTypes.deleteFeedsSuccessfully];
  [FeedTypes.deleteFeedsFailure]      : () => IFeedsActions[FeedTypes.deleteFeedsFailure];
}

type Actions = IFeedsActions[FeedTypes.fetchFeedsSuccessfully]
| IFeedsActions[FeedTypes.fetchFeedsFailure]
| IFeedsActions[FeedTypes.addFeed]
| IFeedsActions[FeedTypes.addFeedSuccessfully]
| IFeedsActions[FeedTypes.addFeedFailure]
| IFeedsActions[FeedTypes.editFeedSuccessfully]
| IFeedsActions[FeedTypes.editFeedFailure]
| IFeedsActions[FeedTypes.deleteFeeds]
| IFeedsActions[FeedTypes.deleteFeedsSuccessfully]
| IFeedsActions[FeedTypes.deleteFeedsFailure];

const initialState: IFeedsReducer = {
  feeds : [],
  meta  : {
    channels    : [],
    isFetching  : false,
    name        : '',
    totalFeeds  : 0,
    page        : 0,
    publisher   : null,
    rowsPerPage : 50,
    territory   : null,
    orderBy     : 'Name',
    order       : 'Asc',
  },
};

export const {
  fetchFeeds,
  fetchFeedsSuccessfully,
  fetchFeedsFailure,
  addFeed,
  addFeedSuccessfully,
  addFeedFailure,
  editFeed,
  editFeedSuccessfully,
  editFeedFailure,
  deleteFeeds,
  deleteFeedsSuccessfully,
  deleteFeedsFailure,
}: IFeedsActionsCreators = createActions(
  {
    FETCH_FEEDS              : (
      feedMeta = {},
    ) => ({ meta: { ...feedMeta, isFetching: true } }),
    FETCH_FEEDS_SUCCESSFULLY : (feeds, totalFeeds) => ({ feeds, meta: { totalFeeds, isFetching: false } }),
    FETCH_FEEDS_FAILURE      : () => ({ meta: { isFetching: false } }),

    ADD_FEED              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    ADD_FEED_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    ADD_FEED_FAILURE      : () => ({ meta: { isFetching: false }}),

    EDIT_FEED              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    EDIT_FEED_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    EDIT_FEED_FAILURE      : () => ({ meta: { isFetching: false }}),

    DELETE_FEEDS              : (feedIds) => ({ attributes: { feedIds }, meta: { isFetching: true }}),
    DELETE_FEEDS_SUCCESSFULLY : () => ({}),
    DELETE_FEEDS_FAILURE      : () => ({}),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const feedsReducer = handleActions<IFeedsReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchFeeds,
      fetchFeedsSuccessfully,
      addFeed,
      addFeedSuccessfully,
      addFeedFailure,
      editFeed,
      editFeedSuccessfully,
      editFeedFailure,
      deleteFeeds,
      deleteFeedsSuccessfully,
      deleteFeedsFailure,
    )]: (
      state  : IFeedsReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchFeeds.toString()): {
          const actionTyped = action as IFeedsActions['fetchFeeds'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, feeds: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchFeedsSuccessfully.toString()): {
          const actionTyped = action as IFeedsActions['fetchFeedsSuccessfully'];
        
          return ({ ...state, feeds: actionTyped.payload.feeds, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default feedsReducer;

interface IFeedsSelectors {
  getFeeds : (state: IState) => IFeedModel[];
  getMeta  : (state: IState) => IFeedsReducer['meta'];
}

export const getFeeds: IFeedsSelectors['getFeeds'] = (state: IState) => state[STATE_KEY].feeds;
export const getMeta: IFeedsSelectors['getMeta']   = (state: IState) => state[STATE_KEY].meta;
