import { FieldProps, getIn }                         from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled                                        from 'styled-components';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import { Tooltip }         from '@components/Tooltip/Tooltip';
import { Checkbox, Label } from '@common/components';
import { ILocation }       from '@src/models';
import { callApi }         from '@utils/apiCaller';

import { ITerritoryFormModel } from '../pages/AddTerritoryContainerL';

export interface IZipCode {
  code       : string;
  isSelected : boolean;
}

interface IZipCodeSelect extends FieldProps<ITerritoryFormModel['locations'][0]['postCodes']> {
  location: ILocation;
}

export const ZipCodeSelect = ({ field, form, location }: IZipCodeSelect) => {
  const [isFetching, setFetchingStatus] = useState<boolean>(false);

  const fieldError = useMemo(() => {
    if (getIn(form.touched, field.name) && getIn(form.errors, field.name)) {
      return getIn(form.errors, field.name);
    }

    if (location.countryRegionId === 'GB' && !location.postCode) {
      return 'You have to select suggestion from location lookup field with outward postal code for UK';
    }

    return null;
  }, [form, field, location]);

  const isAllCodesAreSelected = useMemo(() => (field.value || []).every((item) => item.isSelected), [field]);

  const fetchZipCodes = useCallback(async () => {
    if (location.countryRegionId === 'GB' && !location.postCode) {
      return;
    }

    setFetchingStatus(true);
    try {
      const prevZipCodes = (field.value || []).map((item) => item.code);
      const { data }     = await callApi<string[]>(`feature/BusinessLocation/zipcodes?country=${location.countryRegionId}&state=${location.state}&city=${location.city}&zipCode=${location.postCode}`);
      const result       = data.map((responseItem) => ({ code: responseItem, isSelected: location.locationId ? prevZipCodes.includes(responseItem) : true }));

      form.setFieldValue(field.name, result);
    } catch (error) {
      console.error(error);
    }
    setFetchingStatus(false);
  }, [field, form, location]);

  const onClickZipCode = useCallback((zipCode?: IZipCode) => {
    let newValue: IZipCode[];

    if (!zipCode) {
      newValue = (field.value || []).map((item) => ({ ...item, isSelected: !isAllCodesAreSelected }));
    } else {
      newValue = (field.value || []).map((item) => (item.code === zipCode.code
        ? { ...item, isSelected: !item.isSelected }
        : item
      ));
    }

    form.setFieldValue(field.name, newValue);
  }, [field, form, isAllCodesAreSelected]);

  useEffect(() => {
    fetchZipCodes();
  }, [location.googlePlaceId]);

  if (!location.googlePlaceId) return null;

  return (
    <ZipCodeSelect.Wrapper>
      <Label
        required
        label = "zip / postal codes"
      />
      <Tooltip title={fieldError}>
        {isFetching && (
          <ZipCodeSelect.Loader>
            <LoadingOutlined style={{ fontSize: 30 }} />
          </ZipCodeSelect.Loader>
        )}
        {!!fieldError && !(field.value || []).length && (
          <ZipCodeSelect.ErrorIcon>
            <ExclamationCircleOutlined />
          </ZipCodeSelect.ErrorIcon>
        )}
        {!isFetching && !!(field.value || []).length && (
          <ZipCodeSelect.Content>
            <ZipCodeSelect.Option isMainCheckbox isError={!!fieldError}>
              <Checkbox
                label    = "All"
                setValue = {() => onClickZipCode()}
                value    = {isAllCodesAreSelected}
              />
            </ZipCodeSelect.Option>
            {(field.value || []).map((item) => (
              <ZipCodeSelect.Option key={item.code} isError={!!fieldError}>
                <Checkbox
                  label    = {`${item.code}${item.code.charAt(item.code.length - 2) === ' ' ? '**' : ''}`}
                  setValue = {() => onClickZipCode(item)}
                  value    = {item.isSelected}
                />
              </ZipCodeSelect.Option>
            ))}
          </ZipCodeSelect.Content>
        )}
      </Tooltip>
    </ZipCodeSelect.Wrapper>
  );
};

ZipCodeSelect.Content = styled.div`
  display    : flex;
  flex-wrap  : wrap;
  margin-top : 10px;

  > div:not(:last-child) { margin-right: 5px; }
`;

ZipCodeSelect.ErrorIcon = styled.div`
  align-items     : flex-end;
  display         : flex;
  justify-content : center;
  min-height      : 41px;

  > svg {
    color     : rgb(255, 0, 0, 0.8);
    font-size : 40px;
  }
`;

ZipCodeSelect.Loader = styled.div`
  display         : flex;
  justify-content : center;
  margin          : 10px 0 20px;
`;

ZipCodeSelect.Option = styled.div<{ isError: boolean; isMainCheckbox?: boolean }>`
  align-items   : center;
  border        : ${({ isError }) => (`1px solid ${isError ? '#f44336' : 'black'}`)};
  border-radius : 3px;
  cursor        : pointer;
  display       : flex;
  margin-bottom : 5px;
  padding       : 0 5px;
  width         : 95px;
  height        : 30px;

  span { font-weight: ${({ isMainCheckbox }) => (isMainCheckbox ? '800' : '400')}; }
`;

ZipCodeSelect.Wrapper = styled.div`
  display   : flex;
  flex-direction: column;
  margin-top: 20px;
`;
