import { useMemo }        from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { ERequestedEntityStatus } from '../../enums';
import { theme as Theme }         from '../../styles';

export interface ICustomTableComponent {
  component : JSX.Element;
  tag       : 'component';
}

export interface IClickableItem {
  onClick : () => void;
  tag     : 'link';
  title   : string | number;
}

export interface IIconItem {
  icon    : JSX.Element;
  onClick : (() => void) | null;
  tag     : 'icon';
}

export interface IStatusItem {
  status : ERequestedEntityStatus;
  tag    : 'status';
}

export const ClickableItem = ({ onClick, title }: Omit<IClickableItem, 'tag'>) => (
  <ClickableItem.Component onClick={onClick}>
    {title}
  </ClickableItem.Component>
);

ClickableItem.Component = styled.p`
  color       : ${({ theme }) => theme.color.blue};
  cursor      : pointer;
  line-height : 20px;
`;

export const IconItem = ({ icon, onClick }: IIconItem) => (
  <IconItem.Wrapper
    active  = {!!onClick}
    onClick = {onClick || undefined}
  >
    {icon}
  </IconItem.Wrapper>
);

IconItem.Wrapper = styled.div<{ active: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.5)};

  span {
    cursor : ${({ active }) => (active ? 'pointer' : 'default')};
  }
`;

export const StatusItem = ({ status }: { status: ERequestedEntityStatus }) => {
  const { t } = useTranslation();

  const color = useMemo(() => {
    const { green, lightOrange, red } = Theme.color;
    const colors                        = [lightOrange, green, red, lightOrange];

    return colors[status];
  }, [status]);

  const label = useMemo(() => {
    const localizationKeys = ['pending', 'approved', 'denied', 'pending'];

    return t(localizationKeys[status]);
  }, [status]);

  return (
    <StatusItem.Component color={color}>
      {label}
    </StatusItem.Component>
  );
};

StatusItem.Component = styled.p<{ color: string }>`
  color: ${({ color }) => color};
`;
