import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';

import { Table, TableProps }              from '@common/components/Table/Table';
import { Card, CardHeader }               from '@common/components/Card';
import { BaseInput }                      from '@common/components';
import { ECardStatuses }                  from '@common/enums';
import { IChannelModel, ITerritoryModel } from '@common/models';
import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
  setFitFilterField,
} from '@common/components/FiltrationSorting';

import { routesConfig } from '@components/Breadcrumbs/routesConfig';
import { AutoSuggest }  from '@components/Autosuggest/Autosuggest';
import { DeleteModal }  from '@components/Modal/DeleteModal';

import { useFetchQueryRules }                       from '@hooks/useFetchQueryRules';
import { ESortOrder }                               from '@models/enums';
import { IFeedModel, IOptionType, IPublisherModel } from '@src/models';
import { Modal }                                    from '@src/App';
import { IState }                                   from '@store/rootReducer';
import {
  deleteFeeds as deleteFeedsAction,
  fetchFeeds  as fetchFeedsAction,
  getFeeds,
  getMeta,
  IFeedsActionsCreators,
  IFeedsReducer,
} from '../feedsReducer';

interface IFeeds {
  feeds       : IFeedModel[];
  feedMeta    : IFeedsReducer['meta'];
  fetchFeeds  : IFeedsActionsCreators['fetchFeeds'];
  deleteFeeds : IFeedsActionsCreators['deleteFeeds'];
  isFetching  : boolean;
  location    : { state?: { meta: {
    channel?   : IOptionType;
    territory? : IOptionType;
  }; }; };
}

const mapTerritories = (data: ITerritoryModel[]): IOptionType[] => {
  return data.map((item) => ({ label: item.name, value: item.territoryId, source: item }));
};

const mapPublishers = (data: IPublisherModel[]): IOptionType[] => {
  return data.map((item) => ({ label: item.name, value: item.publisherId }));
};

const mapChannels = (data: IChannelModel[]): IOptionType[] => {
  return data.map((item) => ({ label: item.name, value: item.channelId}));
};

const sortOptions = [
  { label: 'Feed name', key: 'Name', value: 'Name' },
  { label: 'Publisher', key: 'Publisher', value: 'Publisher' },
  { label: 'Status', key: 'Status', value: 'Status' },
];

const filterOptions: IFilterOption[] = [{
  component          : (props) => setFitFilterField(BaseInput, props),
  initialFilterValue : '',
  label              : 'Feed name',
  value              : 'name',
}, {
  component          : (props) => setFitFilterField(AutoSuggest, { ...props, endpoint: 'feature/Territory/search?name', responseMapper: mapTerritories }),
  initialFilterValue : '',
  label              : 'Areas',
  value              : 'territory',
}, {
  component          : (props) => setFitFilterField(AutoSuggest, { ...props, endpoint: 'feature/Channel/search?name', responseMapper: mapChannels }),
  initialFilterValue : '',
  multiple           : true,
  label              : 'Channel',
  value              : 'channels',
}, {
  component          : (props) => setFitFilterField(AutoSuggest, { ...props, endpoint: 'feature/Publisher/search?name', responseMapper: mapPublishers }),
  initialFilterValue : null,
  multiple           : true,
  label              : 'Publisher',
  value              : 'publisher',
}];

export const FeedList = ({
  feeds,
  feedMeta,
  fetchFeeds,
  deleteFeeds,
  isFetching,
  location,
}: IFeeds) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [popoverVisible, setPopoverStatus] = useState(false);
  
  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'feeds-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Asc, label: sortOptions[0].label } },
  );

  const applyRules = useCallback(() => {
    const nextFilters = Object.keys(rules.filtration).reduce((acc: any, curr) => {
      acc[curr] = rules.filtration[curr].value;
      return acc;
    }, {});
    if (rules.sorting) {
      fetchFeeds({ ...feedMeta, ...nextFilters, orderBy: rules.sorting.value, order: rules.sorting.order });
    } else {
      fetchFeeds({ ...feedMeta, ...nextFilters });
    }
  }, [feedMeta, rules]);

  const onClickFeed = useCallback((feed: IFeedModel) => {
    history.push(routesConfig.feedsEdit.endpoint.replace(':id', feed.feedId?.toString() || ''));
  }, []);

  const onDeleteFiltrationRule = useCallback((ruleKey: string) => {
    handlers.deleteFiltrationRule(ruleKey);
    fetchFeeds({...feedMeta, [ruleKey]: ''});
  }, [feedMeta, handlers]);

  const onFetchByPublisherId = useCallback((feed: IFeedModel) => {
    if (feed.publisher) {
      handlers.setFiltrationRule({ label: 'Publisher', value: feed.publisher.name, key: 'publisher' });
    }
  }, [feedMeta.publisher, handlers.setFiltrationRule]);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchFeeds({ page: page !== feedMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [feedMeta.page, feedMeta.rowsPerPage]);

  const deleteFeed = useCallback(async (feedId: (number | string)[]) => { 
    deleteFeeds(feedId as number[]);
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons           = {[{ action: () => history.push(routesConfig.feedsAdd.endpoint), label: t('createFeed') }]}
      title             = {t('feeds')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {onDeleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'feedName',  label: t('feed Name') },
    { id: 'publisher', label: t('publisher') },
    { id: 'status',    label: t('status') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (feeds.map((feed) => ({
    feedName  : {
      onClick : () => onClickFeed(feed),
      tag     : 'link',
      title   : feed.name || '',
    },
    publisher : {
      onClick : () => onFetchByPublisherId(feed),
      tag     : 'link',
      title   : feed.publisher?.name || '',
    },
    status      : {
      component : <p key={feed.feedId} style={{ color: feed.status ? 'green' : 'red' }}>{feed.status ? 'valid' : 'invalid'}</p>,
      tag       : 'component',
    },
    id : feed.feedId || '',
  }))), [feeds, onFetchByPublisherId]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex: 1 },
      { flex: 2 },
      { flex: 1 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : feedMeta.page + 1,
      total           : feedMeta?.totalFeeds,
      pageSize        : feedMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [feedMeta.page, feedMeta.totalFeeds, feedMeta.rowsPerPage]);

  useEffect(() => {
    setPopoverStatus(false);
    applyRules();
  }, [rules]);

  useEffect(() => {
    if (location.state?.meta?.channel) {
      fetchFeeds({ ...feedMeta, channels: [location.state.meta.channel] });
    } else if (location.state?.meta?.territory) {
      fetchFeeds({ ...feedMeta, territory: location.state.meta.territory });
    } else {
      fetchFeeds();
    }
  }, []);

  useEffect(() => {
    if (location.state?.meta.territory) {
      handlers.setFiltrationRule({ label: 'Areas', value: {value: location.state.meta.territory?.value, label: location.state.meta.territory?.label}, key: 'territory' });
    }
  }, []);

  return (
    <>
      <Card
        header = {header}
        status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
        style  = {{ width: '100%' }}
      >
        {!feedMeta.isFetching && (
          <Table
            content  = {tableContent}
            headers  = {tableHeaders}
            settings = {tableSettings}
            onDelete = {(ids: (string | number)[]) => {
              const feedForDeleting = feeds.find(obj => obj.feedId === ids[0]);
              Modal.open(DeleteModal, {
                onDeleteConfirm : () => {
                  deleteFeed(ids);
                  Modal.close();
                },
                confirmMessage    : (ids.length === 1) ? 'The feed will be removed and unlinked from all channels' : 'You cannot restore deleted data',
                entityNameMessage : `For removing enter the full feed name: "${feedForDeleting?.name}"`,
                name              : (ids.length === 1) ? `${feedForDeleting?.name}` : '',
              });
            }}
          />
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  feeds      : getFeeds(state),
  feedMeta   : getMeta(state),
  isFetching : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  deleteFeeds : deleteFeedsAction,
  fetchFeeds  : fetchFeedsAction,
};

export const FeedsList = connect(mapStateToProps, mapDispatchToProps)(FeedList);
