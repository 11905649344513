import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import { connect }                                   from 'react-redux';
import { format }                                    from 'date-fns';

import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
}                                       from '@common/components/FiltrationSorting';
import { getDateISOWithTimeZoneOffset } from '@common/components/DatePicker/DatePicker';
import { Table, TableProps }            from '@common/components/Table/Table';
import { Card, CardHeader }             from '@common/components/Card';
import { ECardStatuses, ESortOrder }    from '@common/enums';
import { routesConfig }                 from '@components/Breadcrumbs/routesConfig';
import { DeleteModal }                  from '@components/Modal/DeleteModal';

import { ISlideModel }        from '@src/models';
import { IState }             from '@store/rootReducer';
import { useFetchQueryRules } from '@hooks/useFetchQueryRules';
import { Modal }              from '@src/App';
import {
  fetchSlides  as fetchSlidesAction,
  deleteSlides as deleteSlidesAction,
  getMeta,
  getSlides,
  ISliderActionsCreators,
  ISliderReducer,
} from '../sliderReducer';

interface ISlides {
  slides       : ISlideModel[];
  slideMeta    : ISliderReducer['meta'];
  totalSlides  : number;
  fetchSlides  : ISliderActionsCreators['fetchSlides'];
  deleteSlides : ISliderActionsCreators['deleteSlides'];
  isFetching   : boolean;
}

const sortOptions = [
  { label: 'Starts', key: 'DateStart', value: 'DateStart' },
  { label: 'Ends', key: 'DateEnd', value: 'DateEnd' },
  { label: 'Name', key: 'Name', value: 'Name' },
];

const filterOptions: IFilterOption[] = [];

const SlideList = ({
  slides,
  slideMeta,
  totalSlides,
  fetchSlides,
  deleteSlides,
  isFetching,
}: ISlides) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [popoverVisible, setPopoverStatus] = useState(false);
  
  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'slides-list',
    { filtration: {}, sorting: { value: sortOptions[0].value, order: ESortOrder.Desc, label: sortOptions[0].label } },
  );

  const onApplyFilters = useCallback((newFilter: any) => {
    if (newFilter.sorting) {
      fetchSlides({ ...slideMeta, orderBy: newFilter.sorting.value, order: newFilter.sorting.order });
    } else {
      fetchSlides({ ...slideMeta });
    }
  }, [slideMeta]);

  const onClickSlide = useCallback((slide: ISlideModel) => {
    history.push(routesConfig.sliderEdit.endpoint.replace(':id', slide.slideId.toString()));
  }, []);

  const onSetPage = useCallback((page: number, pageSize?: number) => {
    fetchSlides({ page: page !== slideMeta.page + 1 ? page - 1 : 0, rowsPerPage: pageSize });
  }, [slideMeta.page, slideMeta.rowsPerPage]);

  const deleteSlide = useCallback(async (slideId: (number | string)[], withRecurring: boolean) => {
    deleteSlides(slideId as number[], withRecurring);
  }, []);
  
  const header = useMemo(() => (
    <CardHeader
      buttons           = {[{ action: () => history.push(routesConfig.sliderAdd.endpoint), label: t('createCarousel') }]}
      title             = {t('carousel')}
      popoverOptions    = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {handlers.deleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'name',   label: t('name') },
    { id: 'starts', label: t('starts') },
    { id: 'ends',   label: t('ends') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (slides.map((slide) => ({
    title : {
      onClick : () => onClickSlide(slide),
      tag     : 'link',
      title   : slide.name || '---',
    },
    starts : format(new Date(getDateISOWithTimeZoneOffset(new Date(slide.dateStart), slide.timeZone, true)), 'MMM dd, yyyy'),
    ends   : format(new Date(getDateISOWithTimeZoneOffset(new Date(slide.dateEnd), slide.timeZone, true)), 'MMM dd, yyyy'),
    id     : slide.slideId,
  }))), [slides]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    scrollable : true,
    content    : { gap: '5px' },
    columns    : [
      { flex : 2 },
      { flex : 1.5 },
      { flex : 1.5 },
    ],
    pagination : {
      setPage         : onSetPage,
      current         : slideMeta.page + 1,
      total           : totalSlides,
      pageSize        : slideMeta.rowsPerPage,
      pageSizeOptions : ['50', '100', '200', '500'],
      showTotal       : true,
    },
  }), [slideMeta.page, totalSlides, slideMeta.rowsPerPage]);

  useEffect(() => {
    fetchSlides();
  }, []);

  useEffect(() => {
    setPopoverStatus(false);
    onApplyFilters(rules);
  }, [rules]);

  return (
    <Card
      header = {header}
      status = {isFetching ? ECardStatuses.Pending : ECardStatuses.None}
      style  = {{width: '1200px'}}
    >
      {!slideMeta.isFetching && (
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
          onDelete = {(ids: (string | number)[]) => {
            const slideForDeleting = slides.find(obj => (obj.slideId === ids[0]));
            Modal.open(DeleteModal, {
              onDeleteConfirm : (withRecurring: boolean) => {
                deleteSlide(ids, withRecurring);
                Modal.close();
              },
              confirmMessage        : (ids.length === 1) ? 'The slide will be removed' : 'You cannot restore deleted data',
              entityNameMessage     : `For removing enter the slide ID "${ids}"`,
              entityCheckboxMessage : slideForDeleting?.slideRecurrenceId ? 'Remove all recurring slides' : undefined,
              name                  : (ids.length === 1) ? `${ids}` : '',
            });
          }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IState) => ({
  slides      : getSlides(state),
  slideMeta   : getMeta(state),
  totalSlides : getMeta(state).totalSlides,
  isFetching  : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  fetchSlides  : fetchSlidesAction,
  deleteSlides : deleteSlidesAction,
};

export const SlidesList = connect(mapStateToProps, mapDispatchToProps)(SlideList);
