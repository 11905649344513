import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { Button } from '../Button';

interface IFilterSortingSelectorProps {
  onChange: (form: 'sorting' | 'filtration') => void;
}

export const FilterSortingSelector = ({ onChange }: IFilterSortingSelectorProps) => {
  const { t } = useTranslation();

  return (
    <FilterSortingSelector.Wrapper>
      <Button
        bordered = {false}
        label    = {t('addSortingRule')}
        onClick  = {() => onChange('sorting')}
      />
      <div />
      <Button
        bordered = {false}
        label    = {t('addFiltrationRule')}
        onClick  = {() => onChange('filtration')}
      />
    </FilterSortingSelector.Wrapper>
  );
};

FilterSortingSelector.Wrapper = styled.div`
  display: flex;

  button {
    white-space : nowrap;
    width       : max-content;
  }

  > div {
    background-color : ${({ theme }) => theme.color.lightGray};
    width            : 1px;
  }
`;
