import { FieldProps }     from 'formik';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import { IMimeTypeSelector, MimeTypeSelector } from './MimeTypeSelector';
import { ImageResizer, TReactCropPropsProps }  from '../ImageResizer';
import { FieldError }                          from '../FieldError';
import { theme as Theme }                      from '../../styles';
import { TFileUploader }                       from '../../models';

/**
 * TODO: add value and onChange for components without Formik
 */
export type MediaUploaderProps = FieldProps & {
  acceptedFormats?   : string;
  aspectRatio?       : number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickableTrigger?  : React.DetailedReactHTMLElement<any, HTMLElement>;
  disabled?          : boolean;
  fileSizeLimitInMB? : number;
  label?             : string;
  mimeTypeOptions?   : IMimeTypeSelector['mimeTypeOptions'];
  required?          : boolean;
  deleteIconPosition : 'center' | 'topRight';
  resizerProps?      : TReactCropPropsProps;
  wrapperStyle?      : { margin?: string; }
  imageStyle?        : {
    backgroundColor? : string;
    border?          : string;
    borderRadius?    : string;
    height?          : string;
    margin?          : string;
    width?           : string;
  };
}

interface CommonMediaUploader extends MediaUploaderProps {
  fileUploader: TFileUploader;
}

const INITIAL_IMAGE_STYLE: MediaUploaderProps['imageStyle'] = {
  backgroundColor : '#e6e9f4',
  border          : `1px solid ${Theme.color.lightGray}`,
  borderRadius    : '2px',
  height          : 'auto',
  margin          : '18px 0 0',
  width           : '100%',
};

export const MediaUploader = ({
  acceptedFormats,
  clickableTrigger,
  field,
  fileUploader,
  form,
  imageStyle,
  label,
  mimeTypeOptions,
  resizerProps,
  wrapperStyle,
  aspectRatio        = 16 / 9,
  deleteIconPosition = 'topRight',
  disabled           = false,
  fileSizeLimitInMB  = 10,
  required           = true,
}: CommonMediaUploader) => {
  const { t } = useTranslation();

  const [imageWidth, setImageWidth]         = useState<number>(0);
  const [isResizerActive, setResizerStatus] = useState<boolean>(false);
  const [isUploading, setUploadingStatus]   = useState(false);

  const imageForResizer = useRef<File | null>(null);

  const imageHeight = useMemo(() => {
    if (imageStyle?.height) {
      return imageStyle.height;
    } else if (imageWidth && aspectRatio) {
      return `${imageWidth / aspectRatio}px`;
    }

    return 'auto';
  }, [imageWidth, imageStyle]);

  const uploadUserFile = useCallback(async (userFile: File, mimeType: 'image' | 'video') => {
    setUploadingStatus(true);
    try {
      const mediaData = await fileUploader(userFile, { container: 'media' });

      if (mediaData) {
        form.setFieldValue(field.name, { imageUri: mediaData.uri, mimeType });
      } else {
        form.setFieldTouched(field.name, true, false);
        form.setFieldError(field.name, t('invalidMediaExtensionWasUsed'));
      }
    } catch (error) {
      form.setFieldTouched(field.name, true, false);
      form.setFieldError(field.name, t('uploadFailed'));
    }
    setUploadingStatus(false);
  }, [field, form]);

  const onClickDelete = useCallback(() => {
    form.setFieldValue(field.name, null);
  }, [field, form]);

  const onCloseResizer = useCallback((image?: File) => {
    if (image) {
      uploadUserFile(image, 'image');
    }

    setResizerStatus(false);
  }, [uploadUserFile]);

  const onSelectFile = useCallback((e: ChangeEvent<HTMLInputElement>, mimeType: 'image' | 'video') => {
    if (!e.target.files || e.target.files.length === 0) return;

    const ONE_MEGABYTE_IN_BYTES     = 1048576;
    const VIDEO_SIZE_LIMIT_IN_BYTES = fileSizeLimitInMB * ONE_MEGABYTE_IN_BYTES;
    const selectedFile: File        = e.target.files[0];

    if (mimeType === 'video' && selectedFile.size > VIDEO_SIZE_LIMIT_IN_BYTES) {
      form.setFieldTouched(field.name, true, false);
      form.setFieldError(field.name, `${t('fileSizeIsTooBig')} ${t('maxFileSizeIs')} ${fileSizeLimitInMB}MB.`);
      return;
    }

    if (mimeType === 'image') {
      imageForResizer.current = selectedFile;

      setResizerStatus(true);
    } else {
      uploadUserFile(selectedFile, mimeType);
    }
  }, [aspectRatio, form, fileSizeLimitInMB, uploadUserFile]);

  const onSubmitYouTubeVideo = useCallback((youtubeVideoId: string) => {
    const youtubeEmbedUrl = `https://www.youtube.com/embed/${youtubeVideoId}`;

    form.setFieldValue(field.name, { imageUri: youtubeEmbedUrl, label: null, mimeType: 'youtube' });
  }, [field, form]);

  useEffect(() => {
    setImageWidth(document.getElementById(field.name)?.offsetWidth || 0);

    window.onresize = () => setImageWidth(document.getElementById(field.name)?.offsetWidth || 0);

    return () => {
      window.onresize = () => null;
    };
  }, []);

  return (
    <MediaUploader.Wrapper {...wrapperStyle}>
      {isResizerActive && imageForResizer.current && (
        <ImageResizer
          aspectRatio = {aspectRatio || undefined}
          image       = {imageForResizer.current}
          onClose     = {onCloseResizer}
          onSubmit    = {onCloseResizer}
          {...resizerProps}
        />
      )}
      {label && (
        <MediaUploader.Label>
          <p>{label} {required && <>&#42;</>}</p>
        </MediaUploader.Label>
      )}
      <MediaUploader.ImageWrapper
        id       = {field.name}
        height   = {imageHeight}
        overflow = {!field.value?.imageUri ? 'initial' : 'hidden'}
        {...INITIAL_IMAGE_STYLE}
        {...imageStyle}
      >
        {isUploading && <LoadingOutlined />}
        {!isUploading && (
          <>
            {!field.value?.imageUri && (
              <MimeTypeSelector
                clickableTrigger     = {clickableTrigger}
                disabled             = {disabled}
                acceptedFormats      = {acceptedFormats}
                mimeTypeOptions      = {mimeTypeOptions}
                onSelectFile         = {onSelectFile}
                onSubmitYouTubeVideo = {onSubmitYouTubeVideo}
                fieldName            = {field.name}
              />
            )}
            {field.value && field.value.imageUri && (
              <>
                {field.value.mimeType === 'image' && (
                  <img src={field.value.imageUri} alt="" />
                )}
                {field.value.mimeType === 'video' && (
                  <MediaUploader.VideoWrapper controls key={field.value.imageUri}>
                    <source src={`${field.value.imageUri}`} type="video/mp4" />
                  </MediaUploader.VideoWrapper>
                )}
                {field.value.mimeType === 'youtube' && (
                  <iframe width="100%" height="100%" src={field.value.imageUri} title="Youtube iframe" />
                )}
                <MediaUploader.DeleteWrapper deleteIconPosition={deleteIconPosition}>
                  <DeleteOutlined onClick={onClickDelete} />
                </MediaUploader.DeleteWrapper>
              </>
            )}
          </>
        )}
      </MediaUploader.ImageWrapper>
      <FieldError
        field = {field}
        form  = {form}
      />
    </MediaUploader.Wrapper>
  );
};

MediaUploader.DeleteWrapper = styled.div<{ deleteIconPosition: MediaUploaderProps['deleteIconPosition'] }>`
  align-items     : center;
  cursor          : pointer;
  display         : flex;
  justify-content : center;
  position        : absolute;
  transition      : all 0.2s;

  ${({ deleteIconPosition }) => (deleteIconPosition === 'center' ? `
    left      : 0;
    font-size : 23px;
    height    : 100%;
    top       : 0;
    width     : 100%;
  ` : `
    border-radius : 5px;
    font-size     : 15px;
    height        : 25px;
    right         : 5px;
    top           : 5px;
    width         : 25px;
  `)}
`;

MediaUploader.ImageWrapper = styled.div<{
  backgroundColor? : string;
  border?          : string;
  borderRadius?    : string;
  height?          : string;
  margin?          : string;
  overflow         : string;
  width?           : string;
}>`
  align-items      : center;
  background-color : ${({ backgroundColor }) => backgroundColor};
  border           : ${({ border }) => border};
  border-radius    : ${({ borderRadius }) => borderRadius};
  display          : flex;
  font-size        : 30px;
  height           : ${({ height }) => height};
  justify-content  : center;
  margin           : ${({ margin }) => margin};
  overflow         : ${({ overflow }) => overflow};
  position         : relative;
  width            : ${({ width }) => width};
  z-index          : 200;

  > img {
    object-fit : contain;
    width      : 100%;
    height     : 100%;
  }

  > div:nth-child(2) {
    background-color : rgba(255, 255, 255, 0);
    opacity          : 0.1;
  }

  &:hover {
    > div:nth-child(2) {
      background-color : rgba(255, 255, 255, 0.3);
      opacity          : 1;
    }
  }
`;

MediaUploader.Label = styled.div`
  align-items     : center;
  color           : ${({ theme }) => theme.color.black};
  display         : flex;
  font-size       : 12px;
  justify-content : space-between;
  line-height     : 18px;
  text-transform  : uppercase;

  p { font-weight: 600; }

  svg {
    cursor    : pointer;
    font-size : 18px;
  }
`;

MediaUploader.VideoWrapper = styled.video`
  height    : 100%;
  max-width : 100%;
  width     : auto;
`;

MediaUploader.Wrapper = styled.div<{ margin?: string; }>`
  margin   : ${({ margin }) => (margin || '20px 0 0')};
  position : relative;
  width    : 100%;
`;
