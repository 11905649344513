import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }       from '@utils/apiCaller';
import { Notifications } from '@components/Notifications/Notifications';

import {
  fetchStories,
  fetchStoriesSuccessfully,
  fetchStoriesFailure,
  addStory,
  addStorySuccessfully,
  addStoryFailure,
  editStory,
  editStorySuccessfully,
  editStoryFailure,
  deleteStories,
  deleteStoriesSuccessfully,
  deleteStoriesFailure,

  getMeta,

  IStoriesActions,
} from './storiesReducer';

function* handleFetchStories() {
  try {
    const { author, page, rowsPerPage, orderBy, order } = yield select(getMeta);
    const { data: { items, totalCount } }               = yield callApi(
      `feature/story?author=${author}&page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}`);

    yield put(fetchStoriesSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchStoriesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Stories. Please try again', 'error');
  }
}

function* handleAddStory(action: IStoriesActions['addStory']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/story', 'post', values);
    yield put(replace('/admin/stories'));
    yield put(addStorySuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Story was added successfully', 'success');
  } catch (e) {
    yield put(addStoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Story. Please try again', 'error');
  }
}

function* handleEditStory(action: IStoriesActions['editStory']) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/story', 'put', values);
    yield put(editStorySuccessfully());
    yield put(replace('/admin/stories'));
    yield call(Notifications.enqueueSnackbar, 'Story was edited successfully', 'success');
  } catch (e) {
    yield put(editStoryFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Story. Please try again', 'error');
  }
}

function* handleDeleteStories(action: IStoriesActions['deleteStories']) {
  try {
    const { storyIds } = action.payload.attributes;

    for (let i = 0; i < storyIds.length; i++) {
      yield callApi('feature/story', 'delete', storyIds[i]);
    }

    yield put(fetchStories());
    yield put(deleteStoriesSuccessfully());

    yield call(Notifications.enqueueSnackbar, `${storyIds.length > 1 ? 'Stories were' : 'Story was'} deleted successfully`, 'success');
  } catch (e) {
    const { storyIds } = action.payload.attributes;

    yield put(deleteStoriesFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${storyIds.length > 1 ? 'Stories' : 'a Story'}. Please try again`, 'error');
  }
}

function* storiesSagas() {
  yield all([
    takeLatest(fetchStories, handleFetchStories),
    takeLatest(addStory, handleAddStory),
    takeLatest(editStory, handleEditStory),
    takeLatest(deleteStories, handleDeleteStories),
  ]);
}

export { storiesSagas };
