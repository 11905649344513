import { notification } from 'antd';
import { ArgsProps }    from 'antd/lib/notification';

/** First add Notification component to app root file for correct message output */
export const showNotification = ({ ...props }: ArgsProps) => {
  const getContainer = () => document.getElementById('notification-root') || document.body;

  return notification.open({
    getContainer,
    placement : 'bottomLeft',
    ...props,
  });
};
