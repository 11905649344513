import { useMemo, useCallback }        from 'react';
import { connect }                     from 'react-redux';
import { Formik, FormikProps }         from 'formik';
import * as Yup                        from 'yup';
import styled                          from 'styled-components';

import {
  IAreaSelectionItem,
  mapAreasToBannedType,
  validateSelectedAreas,
}                         from '@components/AreaSelection/AreaSelection';
import { CircularLoader } from '@components/Loaders/CircularLoader';

import { useFetchEntity }                 from '@utils/fetchEntity';
import { IAffiliationModel, IOptionType } from '@models/index';
import { IMediaElement }                  from '@modules/businesses/pages/AddBranchContainer';
import { IState }                         from '@store/rootReducer';

import { FormAffiliation }   from '../components/FormAffiliation';

import {
  addAffiliation  as addAffiliationAction,
  editAffiliation as editAffiliationAction,
  getMeta,
  IAffiliationActionsCreators,
} from '../affiliationsReducer';

interface IAffiliations {
  addAffiliation  : IAffiliationActionsCreators['addAffiliation'];
  editAffiliation : IAffiliationActionsCreators['editAffiliation'];
  isFetching      : boolean;
}

export interface IAffiliationFormValues {
  affiliationName                        : string;
  affiliationLogoUri                     : IMediaElement | null;
  affiliationWebsiteUri                  : string;
  affiliationRCredentialTerritory        : IAreaSelectionItem[];
  affiliationRCredentialBusinessCategory : IOptionType[];
}

export const validationSchema = Yup.object().shape({
  affiliationRCredentialTerritory : validateSelectedAreas(),
  affiliationName                 : Yup.string()
    .required('Enter affiliation name'),
  affiliationLogoUri : Yup.object()
    .shape({ imageUri: Yup.string().required() })
    .typeError('Select affiliation logo'),
  affiliationWebsiteUri : Yup.string()
    .required('Enter affiliation website link'),
  affiliationRCredentialBusinessCategory : Yup.array()
    .min(1, 'Please select at least one category')
    .ensure(),
});

const AffiliationsComponent = ({
  addAffiliation,
  editAffiliation,
  isFetching,
}: IAffiliations) => {
  const [affiliation, isAffiliationFetching] = useFetchEntity('feature/Credential') as [IAffiliationModel | null, boolean, () => Promise<void>];
  
  const initialValues = useMemo<IAffiliationFormValues>(() => ({
    affiliationName                        : affiliation?.name || '',
    affiliationLogoUri                     : affiliation ? { imageUri: affiliation.logoUri, mimeType: 'image' } : null,
    affiliationWebsiteUri                  : affiliation?.websiteUri || '',
    affiliationRCredentialTerritory        : (affiliation?.rCredentialTerritory || []) as unknown as IAreaSelectionItem[],
    affiliationRCredentialBusinessCategory : (affiliation?.rCredentialBusinessCategory || []).map((item) => ({ label: item.categoryName, value: item.categoryId })),
  }), [affiliation]);

  const onSubmit = useCallback((values: typeof initialValues) => {
    const requestData = {
      name                        : values.affiliationName,
      logoUri                     : values.affiliationLogoUri?.imageUri,
      websiteUri                  : values.affiliationWebsiteUri,
      rCredentialTerritory        : mapAreasToBannedType(values.affiliationRCredentialTerritory, { credentialId : affiliation?.credentialId }) as IAffiliationModel['rCredentialTerritory'],
      rCredentialBusinessCategory : values.affiliationRCredentialBusinessCategory.map((item) => ({
        credentialId : affiliation?.credentialId,
        categoryId   : item.value,
      })),
    };

    if (affiliation) {
      editAffiliation({ ...requestData, credentialId: affiliation.credentialId });
    } else {
      addAffiliation(requestData);
    }
  }, [affiliation]);

  if (isAffiliationFetching) return (
    <CircularLoader />
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<IAffiliationFormValues>) => (
          <FormAffiliation isFetching={isFetching} submitForm={props.submitForm} />
        )}
      </Formik>
    </Container>
    
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 948px; }
`;

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addAffiliation  : addAffiliationAction,
  editAffiliation : editAffiliationAction,
};

export const Affiliations = connect(mapStateToProps, mapDispatchToProps)(AffiliationsComponent);
