import { Formik, FormikProps }                              from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled                                               from 'styled-components';
import * as Yup                                             from 'yup';

import { CircularLoader }    from '@components/Loaders/CircularLoader';
import { IColorSelectValue } from '@components/ColorSelect/BaseColorSelect';
import {
  IAreaSelectionItem,
  mapAreasToBannedType,
  validateSelectedAreas,
} from '@components/AreaSelection/AreaSelection';

import { callApi }                                       from '@utils/apiCaller';
import { splitColor, validateColor }                     from '@utils/colors';
import { IChannelModel, IChannelsCategory, IOptionType } from '@models/index';

import {
  CHANNELS,
  ChannelForm,
}                           from '../components/ChannelForm';
import { IChannelsReducer } from '../channelsReducerL';

export type TChannelValues = Omit<IChannelModel, 'rChannelTerritory' | 'color'> & { rChannelTerritory: IAreaSelectionItem[], color: IColorSelectValue };

const Container = styled.div<{ ref: any }>`
  background-color : #fff;
  display          : flex;
  flex-direction   : column;
  justify-content  : center;
  min-height       : 500px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 900px; }
`;

const useValidationSchema = (channelType: CHANNELS) => {
  const validationSchema = useMemo(() => Yup.object().shape({
    color       : validateColor(),
    channelType : Yup.number()
      .typeError('Select channel reach type')
      .required(),
    headerUri : Yup.object()
      .nullable(),
    iconUri : Yup.object()
      .shape({ imageUri: Yup.string().required('Upload channel icon') })
      .typeError('Upload channel icon')
      .required(),
    location : Yup.object().shape({
      address1      : Yup.string().nullable(),
      googlePlaceId : Yup.string().nullable(),
    }),
    name : Yup.string()
      .typeError('Enter channel name')
      .required('Name is required'),
    thumbnailUri : Yup.object()
      .nullable(),
    rChannelTerritory : validateSelectedAreas(channelType === CHANNELS.GLOBAL ? 0 : 1),
  }), [channelType]);

  return validationSchema;
};

interface IAddChannelContainer {
  channelData        : Partial<IChannelModel> | null;
  isChannelsFetching : boolean;
  onSubmit           : (values: any) => void;
  parent             : IChannelsReducer['categories'][0];
}

export const AddChannelContainer = React.forwardRef(({
  isChannelsFetching,
  onSubmit,
  parent,
  channelData,
}: IAddChannelContainer, ref) => {
  const [categories, setCategories]     = useState<IOptionType[]>([]);
  const [isFetching, setFetchingStatus] = useState<boolean>(false);
  const [channelType, setChannelType]   = useState<CHANNELS>(channelData?.channelType || CHANNELS.GLOBAL);

  const validationSchema = useValidationSchema(channelType);

  const initialValues = useMemo<TChannelValues>(() => ({
    channelId         : channelData?.channelId,
    channelType       : (parent.isCitySpecific && CHANNELS.TERRITORY_SPECIFIC) || channelData?.channelType || CHANNELS.GLOBAL,
    color             : splitColor((parent.color as unknown as any).color),
    channelCategoryId : parent.channelCategoryId,
    iconUri           : ({imageUri: channelData?.iconUri, mimeType: 'image'}) as unknown as string,
    headerUri         : ({imageUri: channelData?.headerUri, mimeType: 'image'}) as unknown as string || null,
    locale            : channelData?.locale || 'en_us',
    name              : channelData?.name || '',
    thumbnailUri      : ({imageUri: channelData?.thumbnailUri, mimeType: 'image'}) as unknown as string || null,
    rChannelTerritory : (channelData?.rChannelTerritory || []) as unknown as IAreaSelectionItem[],
  }), [channelData, parent]);

  const fetchChannelsCategories = useCallback(async () => {
    setFetchingStatus(true);
    try {
      const { data }: { data: { items: IChannelsCategory[] } } = await callApi('feature/ChannelCategory?itemsPerPage=999');

      const categoriesOptions: IOptionType[] = data.items.reduce((acc: IOptionType[], curr) => {
        if (curr.isCitySpecific && !parent.isCitySpecific) return acc;

        return [...acc, { label: curr.name, value: curr.channelCategoryId }];
      }, []);

      setCategories(categoriesOptions);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error, 'Error | fetchChannelsCategories');
    }
    setFetchingStatus(false);
  }, [parent]);

  const onSubmitValues = useCallback((values: TChannelValues) => {
    const { color } = values.color;

    const requestData: IChannelModel = {
      ...values,
      color,
      iconUri      : (values.iconUri as unknown as any).imageUri,
      headerUri    : (values.headerUri as unknown as any)?.imageUri,
      thumbnailUri : (values.thumbnailUri as unknown as any)?.imageUri,
      rChannelTerritory: values.channelType !== CHANNELS.GLOBAL
        ? mapAreasToBannedType(values.rChannelTerritory, { channelId: values.channelId }) as IChannelModel['rChannelTerritory']
        : [],
    };

    onSubmit(requestData);
  }, [channelData]);

  useEffect(() => {
    fetchChannelsCategories();
  }, []);

  if (isFetching) return (
    <CircularLoader padding="0" />
  );

  return (
    <Container ref={ref} tabIndex={1}>
      {isFetching ? (
        <CircularLoader padding="0" />
      ) : (
        <>
          <Formik
            enableReinitialize
            initialValues    = {initialValues}
            validateOnBlur   = {false}
            validationSchema = {validationSchema}
            onSubmit         = {onSubmitValues}
          >
            {({ ...props }: FormikProps<TChannelValues>) => (
              <ChannelForm
                {...props}
                categories         = {categories}
                isChannelsFetching = {isChannelsFetching}
                parent             = {parent}
                setChannelType     = {setChannelType}
              />
            )}
          </Formik>
        </>
      )}
    </Container>
  );
});
