import styled from 'styled-components';

export const ContentDeleteWrapper = styled.div`
  align-items      : center;
  background-color : #fff;
  cursor           : pointer;
  display          : flex;
  height           : 100%;
  justify-content  : center;
  left             : 0;
  opacity          : 0;
  position         : absolute;
  top              : 0;
  transition       : all 0.2s;
  width            : 100%;
  z-index          : 100;

  &:hover { opacity: 0.8; }

  span {
    color     : black;
    font-size : 20px;
  }
`;

export const ContentRow = styled.div<{ spanColor?: string; }>`
  color       : #7e84a3;
  font-size   : 12px;
  line-height : 15px;
  position    : relative;

  p {
    cursor         : pointer;
    text-transform : lowercase;
  }

  span {
    color       : ${({ spanColor, theme }) => (spanColor || theme.color.red)};
    font-weight : 600;
  }
`;

export const ContentSeparator = styled.div`
  background-color : #e6e9f4;
  height           : 1px;
  margin           : 16px 0;
  width            : 100%;
`;

export const ContentWrapper = styled.div`
  padding : 2px 0 6px;
  width   : 160px;
`;
