import { all, fork } from 'redux-saga/effects';

import { authSagas }           from '@modules/auth/authSagas';
import { feedsSagas }          from '@modules/feeds/feedsSagas';
import { storiesSagas }        from '@modules/stories/storiesSagas';
import { businessesSagas }     from '@modules/businesses/businessesSagas';
import { channelsSagas}        from '@modules/channels/channelsSagasL';
import { territoriesSagas }    from '@modules/territories/territoriesSagasL';
import { eventsSagas }         from '@modules/events/eventsSagas';
import { categoriesSagas }     from '@modules/categories/categoriesSagas';
import { affiliationsSagas }   from '@modules/affiliations/affiliationsSagas';
import { sliderSagas }         from '@modules/slider/sliderSagas';
import { formsSagas }          from '@modules/forms/formsSagas';
import { requestedFeedsSagas } from '@modules/feeds/requestedFeedsSagas';
import { licensesSagas }       from '@modules/licenses/LicensesSagas';
import { advertisingSagas }    from '@modules/advertisments/advertisingSagas';
import { tipsSagas }           from '@modules/tips/tipsSagas';
import { tagsSagas }           from '@modules/tags/tagsSagas';

function* rootSaga(): IterableIterator<any> {

  yield all([
    fork(authSagas),
    fork(feedsSagas),
    fork(storiesSagas),
    fork(businessesSagas),
    fork(channelsSagas),
    fork(territoriesSagas),
    fork(eventsSagas),
    fork(categoriesSagas),
    fork(affiliationsSagas),
    fork(sliderSagas),
    fork(formsSagas),
    fork(requestedFeedsSagas),
    fork(licensesSagas),
    fork(advertisingSagas),
    fork(tipsSagas),
    fork(tagsSagas),
  ]);
}

export { rootSaga };
