import { Field, Form }    from 'formik';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { Card, FormButtons, Input } from '@common/components';
import { MediaUploader }            from '@components/MediaUploader/MediaUploader';
import { Title }                    from '@components/Title/Title';

import { ITagModel } from '@src/models';

interface ITagsFormProps {
  isFetching : boolean;
  tag        : ITagModel | null;
}

export const TagForm = ({ isFetching, tag }: ITagsFormProps) => {
  const { t } = useTranslation();
  
  return (
    <Card>
      <Title name={`${tag ? 'edit the' : 'add a'} tag`} />
      <TagForm.Wrapper noValidate>
        <TagForm.Column>
          <Field
            media
            multi           = {false}
            label           = "icon"
            name            = "icon"
            container       = "slides"
            component       = {MediaUploader}
            mimeTypeOptions = "image"
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '165px',
            }}
          />
        </TagForm.Column>

        <TagForm.Column rightSide>
          <Field
            label       = "name"
            name        = "name"
            placeholder = {t('enterName')}
            component   = {Input}
          />
          <FormButtons
            disabled      = {{ main: isFetching }}
            onClickCancel = {() => history.back()}
          />
        </TagForm.Column>
      </TagForm.Wrapper>
    </Card>
  );
};

TagForm.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

TagForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
