import { FieldProps }                     from 'formik';
import { FocusEventHandler, useCallback } from 'react';
import styled                             from 'styled-components';
import PlacesAutocompleteLib, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
  PropTypes as SearchOptionsProps,
} from 'react-places-autocomplete';

import { FieldError }                  from '../FieldError';
import { BaseSelectProps, BaseSelect } from '../Select/BaseSelect';
import { ILocation, TOption }          from '../../models';

/* eslint-disable camelcase */
interface IAddressParts {
  administrative_area_level_1? : string;
  country?                     : string;
  formatted                    : string;
  googlePlaceId                : string;
  locality?                    : string;
  neighborhood?                : string;
  postal_code?                 : string;
  postal_town?                 : string;
  latLng                       : {
    lat : number;
    lng : number;
  };
  shortNames : {
    administrative_area_level_1 : string;
    country                     : string;
  };
}
/* eslint-enable camelcase */

interface IPlacesAutocomplete extends FieldProps, BaseSelectProps {
  locationPath? : string;
  searchOptions : SearchOptionsProps['searchOptions'];
  wrapperStyle? : { margin?: string };
}

export const INITIAL_LOCATION: ILocation = {
  formatted        : null,
  address1         : '',
  address2         : null,
  country          : '',
  state            : '',
  city             : '',
  district         : null,
  postCode         : '',
  googlePlaceId    : null,
  stateProvId      : null,
  countryRegionId  : null,
  googlePlaceQuery : null,
  timeZone         : null,
  geog             : { coordinate: null },
};

const getAddressParts = async (result: google.maps.GeocoderResult) => {
  const latLng = await getLatLng(result);

  let addressParts: IAddressParts = {
    latLng,
    formatted     : result.formatted_address,
    googlePlaceId : result.place_id,
    shortNames    : {
      administrative_area_level_1 : '',
      country                     : '',
    },
  };

  result.address_components.forEach((element) => {
    if (element.long_name) {
      addressParts = {
        ...addressParts,
        [element.types[0]] : element.long_name,
      };
    }

    if (element.short_name) {
      addressParts = {
        ...addressParts,
        shortNames : {
          ...addressParts.shortNames,
          [element.types[0]] : element.short_name,
        },
      };
    }
  });

  addressParts.latLng        = latLng;
  addressParts.googlePlaceId = result.place_id;
  addressParts.formatted     = result.formatted_address;

  return addressParts;
};

const getStateProvId = (countryCode: string, stateCode: string) => {
  if (stateCode && stateCode.length === 2 && stateCode === stateCode.toUpperCase()) {
    return `${countryCode}-${stateCode}`;
  }

  return null;
};

const mapToLocationData = (addressParts: IAddressParts) => {
  const location: ILocation = {
    formatted        : addressParts.formatted,
    address1         : addressParts.formatted,
    address2         : null,
    country          : addressParts.country || '',
    state            : addressParts.administrative_area_level_1 || '',
    city             : addressParts.locality || addressParts.postal_town || '',
    district         : addressParts.neighborhood || null,
    postCode         : addressParts.postal_code || '',
    googlePlaceId    : addressParts.googlePlaceId,
    countryRegionId  : addressParts.shortNames.country,
    googlePlaceQuery : null,
    timeZone         : null,
    geog             : {
      coordinate : {
        x : addressParts.latLng.lng,
        y : addressParts.latLng.lat,
      },
    },
    stateProvId : getStateProvId(
      addressParts.shortNames.country,
      addressParts.shortNames.administrative_area_level_1,
    ),
  };

  return location;
};

export const PlacesAutocomplete = ({
  field,
  form,
  searchOptions,
  wrapperStyle,
  locationPath = 'location',
  ...componentProps
}: IPlacesAutocomplete) => {
  const clearField = useCallback(() => {
    form.setFieldValue(field.name, '');
  }, [field, form]);

  const mapSuggestions = useCallback((suggestions: readonly Suggestion[]): TOption[] => suggestions.map((item) => ({
    label : item.description,
    value : item.description,
  })), []);

  const onBlur = useCallback((e: React.FocusEvent<HTMLElement>, blurHandler: FocusEventHandler<Element>) => {
    if (blurHandler) {
      blurHandler(e);
    }
  }, []);

  const onChange = useCallback((value: string) => {
    form.setFieldValue(field.name, value);

    if (form.values.hasOwnProperty('location')) {
      form.setFieldValue('location.googlePlaceId', null);
    }
  }, [form, field]);

  const onGetPopupContainer = useCallback((node) => (node ? node.parentNode : document.body), []);

  const onSelect = useCallback((address: string) => {
    form.setFieldValue(field.name, address, false);

    geocodeByAddress(address)
      .then((results: google.maps.GeocoderResult[]) => getAddressParts(results[0]))
      .then((addressParts: IAddressParts) => mapToLocationData(addressParts))
      .then((location: ILocation) => form.setFieldValue(locationPath, location))
      .catch((error: unknown) => {
        // eslint-disable-next-line no-console
        console.error(error, 'Error PlacesAutocomplete onSelect');
      });
  }, [field, form]);

  return (
    <PlacesAutocompleteLib
      onChange      = {onChange}
      searchOptions = {searchOptions}
      value         = {field.value}
    >
      {({ getInputProps, suggestions, loading }) => (
        <PlacesAutocomplete.Wrapper {...wrapperStyle}>
          <BaseSelect
            allowClear
            showSearch
            {...componentProps}
            getPopupContainer = {onGetPopupContainer}
            filterOption      = {false}
            loading           = {loading}
            onClear           = {clearField}
            onBlur            = {(e) => onBlur(e, getInputProps().onBlur)}
            onSelect          = {(value: string | number) => onSelect(value.toString())}
            onSearch          = {(value: string) => getInputProps().onChange({  target: { value } })}
            options           = {field.value ? mapSuggestions(suggestions) : []}
            value             = {field.value || undefined}
          />
          <FieldError
            field = {field}
            form  = {form}
          />
        </PlacesAutocomplete.Wrapper>
      )}
    </PlacesAutocompleteLib>
  );
};

PlacesAutocomplete.Wrapper = styled.div<{ margin?: string; }>`
  margin   : ${({ margin, theme }) => margin || theme.field.gap};
  position : relative;

  .ant-select-single .ant-select-selector .ant-select-selection-search { left: 0; }
`;
