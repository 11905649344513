import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState }      from '@store/rootReducer';
import { IStoryModel } from '@models/index';
import { ITableMeta }  from '@components/Table/ClickableItem';

export const STATE_KEY = 'stories';

export interface IStoriesReducer {
  stories : IStoryModel[];
  meta    : {
    author       : string;
    isFetching   : boolean;
    totalStories : number;
    page         : number;
    rowsPerPage  : number;
    orderBy      : string;
    order        : 'Asc' | 'Desc';
  };
}

export enum StoryTypes {
  fetchStories              = 'fetchStories',
  fetchStoriesSuccessfully  = 'fetchStoriesSuccessfully',
  fetchStoriesFailure       = 'fetchStoriesFailure',
  addStory                  = 'addStory',
  addStorySuccessfully      = 'addStorySuccessfully',
  addStoryFailure           = 'addStoryFailure',
  editStory                 = 'editStory',
  editStorySuccessfully     = 'editStorySuccessfully',
  editStoryFailure          = 'editStoryFailure',
  deleteStories             = 'deleteStories',
  deleteStoriesSuccessfully = 'deleteStoriesSuccessfully',
  deleteStoriesFailure      = 'deleteStoriesFailure',
}

export interface IStoriesActions {
  [StoryTypes.fetchStories]              : Action<{ meta: ITableMeta }>;
  [StoryTypes.fetchStoriesSuccessfully]  : Action<{ stories: IStoryModel[]; meta: { totalStories: number } }>;
  [StoryTypes.fetchStoriesFailure]       : Action<{}>;
  [StoryTypes.addStory]                  : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [StoryTypes.addStorySuccessfully]      : Action<{ meta: { isFetching: false }}>;
  [StoryTypes.addStoryFailure]           : Action<{ meta: { isFetching: false }}>;
  [StoryTypes.editStory]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [StoryTypes.editStorySuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [StoryTypes.editStoryFailure]          : Action<{ meta: { isFetching: false }}>;
  [StoryTypes.deleteStories]             : Action<{ attributes: { storyIds: number[] }; meta: { isFetching: true }}>;
  [StoryTypes.deleteStoriesSuccessfully] : Action<{}>;
  [StoryTypes.deleteStoriesFailure]      : Action<{}>;
}

export interface IStoriesActionsCreators {
  [StoryTypes.fetchStories]              : (storyMeta?: ITableMeta) => IStoriesActions[StoryTypes.fetchStories];
  [StoryTypes.fetchStoriesSuccessfully]  : (stories: IStoryModel[], totalCount: number) => IStoriesActions[StoryTypes.fetchStoriesSuccessfully];
  [StoryTypes.fetchStoriesFailure]       : () => IStoriesActions[StoryTypes.fetchStoriesFailure];
  [StoryTypes.addStory]                  : (values: any) => IStoriesActions[StoryTypes.addStory];
  [StoryTypes.addStorySuccessfully]      : () => IStoriesActions[StoryTypes.addStorySuccessfully];
  [StoryTypes.addStoryFailure]           : () => IStoriesActions[StoryTypes.addStoryFailure];
  [StoryTypes.editStory]                 : (values: any) => IStoriesActions[StoryTypes.editStory];
  [StoryTypes.editStorySuccessfully]     : () => IStoriesActions[StoryTypes.editStorySuccessfully];
  [StoryTypes.editStoryFailure]          : () => IStoriesActions[StoryTypes.editStoryFailure];
  [StoryTypes.deleteStories]             : (storyIds: number[]) => IStoriesActions[StoryTypes.deleteStories];
  [StoryTypes.deleteStoriesSuccessfully] : () => IStoriesActions[StoryTypes.deleteStoriesSuccessfully];
  [StoryTypes.deleteStoriesFailure]      : () => IStoriesActions[StoryTypes.deleteStoriesFailure];
}

type Actions = IStoriesActions[StoryTypes.fetchStoriesSuccessfully]
| IStoriesActions[StoryTypes.fetchStoriesFailure]
| IStoriesActions[StoryTypes.addStory]
| IStoriesActions[StoryTypes.addStorySuccessfully]
| IStoriesActions[StoryTypes.addStoryFailure]
| IStoriesActions[StoryTypes.editStorySuccessfully]
| IStoriesActions[StoryTypes.editStoryFailure]
| IStoriesActions[StoryTypes.deleteStories]
| IStoriesActions[StoryTypes.deleteStoriesSuccessfully]
| IStoriesActions[StoryTypes.deleteStoriesFailure];

const initialState: IStoriesReducer = {
  stories : [],
  meta    : {
    author       : '',
    isFetching   : false,
    totalStories : 0,
    page         : 0,
    rowsPerPage  : 50,
    orderBy      : 'Created',
    order        : 'Desc',
  },
};

export const {
  fetchStories,
  fetchStoriesSuccessfully,
  fetchStoriesFailure,
  addStory,
  addStorySuccessfully,
  addStoryFailure,
  editStory,
  editStorySuccessfully,
  editStoryFailure,
  deleteStories,
  deleteStoriesSuccessfully,
  deleteStoriesFailure,
}: IStoriesActionsCreators = createActions(
  {
    FETCH_STORIES              : (
      storyMeta = {},
    ) => ({ meta: { ...storyMeta, isFetching: true } }),
    FETCH_STORIES_SUCCESSFULLY : (stories, totalStories) => ({ stories, meta: { totalStories, isFetching: false } }),
    FETCH_STORIES_FAILURE      : () => ({}),

    ADD_STORY              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    ADD_STORY_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    ADD_STORY_FAILURE      : () => ({ meta: { isFetching: false }}),

    EDIT_STORY              : (values) => ({ attributes: { ...values }, meta: { isFetching: true }}),
    EDIT_STORY_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    EDIT_STORY_FAILURE      : () => ({ meta: { isFetching: false }}),

    DELETE_STORIES              : (storyIds) => ({ attributes: { storyIds }, meta: { isFetching: true }}),
    DELETE_STORIES_SUCCESSFULLY : () => ({}),
    DELETE_STORIES_FAILURE      : () => ({}),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const storiesReducer = handleActions<IStoriesReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchStories,
      fetchStoriesSuccessfully,
      addStory,
      addStorySuccessfully,
      addStoryFailure,
      editStory,
      editStorySuccessfully,
      editStoryFailure,
      deleteStories,
      deleteStoriesSuccessfully,
      deleteStoriesFailure,
    )]: (
      state  : IStoriesReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchStories.toString()): {
          const actionTyped = action as IStoriesActions['fetchStories'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, stories: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchStoriesSuccessfully.toString()): {
          const actionTyped = action as IStoriesActions['fetchStoriesSuccessfully'];
         
          return ({ ...state, stories: actionTyped.payload.stories, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default storiesReducer;

interface IFeedsSelectors {
  getStories : (state: IState) => IStoryModel[];
  getMeta    : (state: IState) => IStoriesReducer['meta'];
}

export const getStories: IFeedsSelectors['getStories'] = (state: IState) => state[STATE_KEY].stories;
export const getMeta: IFeedsSelectors['getMeta']       = (state: IState) => state[STATE_KEY].meta;
