import styled from 'styled-components';

interface ButtonProps {
  bordered?       : boolean;
  disabled?       : boolean;
  label           : string;
  onClick?        : () => void;
  type?           : 'button' | 'submit';
  componentStyle? : {
    background? : string;
    color?      : string;
    fontSize?   : string;
    height?     : string;
    margin?     : string;
    padding?    : string;
  }
}

const INITIAL_COMPONENT_STYLE = {
  background : '#fff',
  color      : '#5a607f',
  fontSize   : '14px',
  height     : '36px',
  margin     : '0',
  padding    : '0 16px',
};

export const Button = ({
  componentStyle,
  label,
  onClick,
  bordered = true,
  disabled = false,
  type     = 'submit',
}: ButtonProps) => (
  <Button.Component
    bordered = {bordered}
    onClick  = {disabled ? undefined : onClick}
    disabled = {disabled}
    type     = {type}
    {...INITIAL_COMPONENT_STYLE}
    {...componentStyle}
  >
    {label || 'Save'}
  </Button.Component>
);

Button.Component = styled.button<{
  background? : string;
  bordered?   : boolean;
  color?      : string;
  disabled?   : boolean;
  fontSize?   : string;
  height?     : string;
  margin?     : string;
  padding?    : string;
}>`
  background-color : ${({ background }) => background};
  border           : ${({ bordered, theme }) => (bordered ? `1px solid ${theme.color.lightGray}` : 'none')};
  border-radius    : 4px;
  color            : ${({ color }) => color};
  cursor           : ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size        : ${({ fontSize }) => fontSize};
  height           : ${({ height }) => height};
  line-height      : 21px;
  margin           : ${({ margin }) => margin};
  opacity          : ${({ disabled }) => (disabled ? 0.7 : 1)};
  outline          : none;
  padding          : ${({ padding }) => padding};
  transition       : color 0.15s, border 0.25s, box-shadow 0.25s;

  ${({ background, bordered, color, disabled, theme }) => !disabled && `
    &:hover {
      border     : ${bordered ? `1px solid ${theme.color.blue}` : 'none'};
      box-shadow : ${(!bordered && background) ? `0 0 2px 0 ${background}` : 'none'};
      color      : ${background !== '#fff' ? color : theme.color.blue};
    }
  `}
`;
