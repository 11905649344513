import { FieldProps }  from 'formik';
import { useCallback } from 'react';
import styled          from 'styled-components';

import { BaseInput, BaseInputProps } from './BaseInput';
import { FieldError }                from '../FieldError';
import { theme as Theme }            from '../../styles';

interface InputProps extends Omit<BaseInputProps, 'onChange'>, Partial<FieldProps> {
  changeFieldValue? : (newValue: string) => void;
  errorMessage?     : string;
  wrapperStyle?     : (theme: typeof Theme) => string;
}

export const Input = ({
  changeFieldValue,
  errorMessage,
  field,
  form,
  value,
  wrapperStyle,
  ...componentProps
}: InputProps) => {
  const onChange = useCallback((nextValue: string) => {
    if (form && field) {
      form.setFieldValue(field.name, nextValue);
    } else if (changeFieldValue) {
      changeFieldValue(nextValue);
    }
  }, [field, form, changeFieldValue]);

  return (
    <Input.Wrapper wrapperStyle={wrapperStyle}>
      <BaseInput
        {...componentProps}
        onChange = {onChange}
        value    = {field?.value || value}
      />
      <FieldError
        errorMessage = {errorMessage}
        field        = {field}
        fieldType    = {componentProps.bordered ? 'bordered' : undefined}
        form         = {form}
        value        = {value}
      />
    </Input.Wrapper>
  );
};

Input.Wrapper = styled.div<{ wrapperStyle: InputProps['wrapperStyle'] }>`
  margin   : ${({ theme }) => theme.field.gap};
  position : relative;
  width    : 100%;

  ${({ wrapperStyle, theme }) => wrapperStyle && wrapperStyle(theme)}
`;
