import React, { useCallback, useState, useMemo } from 'react';
import { Formik, FormikProps }                   from 'formik';
import styled                                    from 'styled-components';
import { useTranslation }                        from 'react-i18next';
import * as Yup                                  from 'yup';

import { TFunction } from '@common/models';
import {
  validatePhoneNumber,
  validateStringForSpecialSymbolsOnly,
}from '@common/utils';

import { IBusinessLocation, IOptionType } from '@models/index';
import { ERequestedReviewStatus }         from '@models/enums';
import { INITIAL_LOCATION }               from '@modules/territories/pages/AddTerritoryContainerL';

import { BranchForm } from '../components/BranchForm';

const Container = styled.div<{ ref: any }>`
  background-color : #fff;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 900px; }
`;

export interface IImageUploaderItem {
  label    : string | null;
  imageId? : number | null;
  imageUrl : string;
  mimeType : 'youtube' | 'image' | 'mp4';
  order    : number;
}

const useValidationSchema = (isWithoutAddress: boolean, t: TFunction) => {
  const validationSchema = useMemo(() => Yup.object().shape({
    alertHeader : Yup.string()
      .nullable()
      .when('isAlertActive', {
        is   : true,
        then : Yup.string()
          .max(100, 'Max character count is 100')
          .test(...validateStringForSpecialSymbolsOnly((value) => value))
          .typeError('Enter alert title')
          .required('Enter alert title'),
      }),
    alertText : Yup.string()
      .nullable()
      .when('isAlertActive', {
        is   : true,
        then : Yup.string()
          .max(2000, 'Max character count is 2000')
          .test(...validateStringForSpecialSymbolsOnly((value) => value))
          .typeError('Enter alert description')
          .required('Enter alert description'),
      }),
    description : Yup.string()
      .max(600, 'Max character count is 600')
      .test(...validateStringForSpecialSymbolsOnly((value) => value))
      .typeError('Enter branch description')
      .required('Description is required field'),
    establishedDate : Yup.string()
      .nullable(),
    googleBusinessName : Yup.string()
      .nullable()
      .when(['location.googlePlaceId'], {
        is   : (googlePlaceId: number) => googlePlaceId === null,
        then : Yup.string().nullable()
          .max(200, 'Max character count is 200')
          .test(
            'is-google-place-valid',
            'Start typing and choose the option that suits you',
            (value?: string | null) => !value,
          ),
      }),
    googleReviewsActive : Yup.boolean()
      .required(),
    iconUri : Yup.object()
      .typeError('Upload branch logo')
      .required(),
    isAlertActive : Yup.boolean()
      .required(),
    location: isWithoutAddress
      ? Yup.object()
      : Yup.object().shape({
        address1 : Yup.string()
          .test(...validateStringForSpecialSymbolsOnly((value) => value))
          .typeError('Find and select branch address')
          .required(),
        googlePlaceId : Yup.string()
          .required(),
        city : Yup.string()
          .test(...validateStringForSpecialSymbolsOnly((value) => value))
          .typeError('Enter branch city')
          .required(''),
        state : Yup.string()
          .test(...validateStringForSpecialSymbolsOnly((value) => value))
          .typeError('Enter branch state')
          .required(''),
        postCode : Yup.string()
          .test(...validateStringForSpecialSymbolsOnly((value) => value))
          .typeError('Enter branch zip or post code')
          .required('Enter branch zip or post code'),
        country : Yup.string()
          .test(...validateStringForSpecialSymbolsOnly((value) => value))
          .typeError('Enter branch country')
          .required(''),
      }),
    logoUri : Yup.object()
      .nullable(),
    name : Yup.string()
      .max(200, 'Max character count is 200')
      .min(3, 'Name: min character count is 3')
      .test(...validateStringForSpecialSymbolsOnly((value) => value))
      .typeError('Enter branch name')
      .required('Name is required field'),
    operationalHours : Yup.string()
      .test(...validateStringForSpecialSymbolsOnly((value) => value))
      .max(1000, 'Max character count is 1000')
      .nullable(),
    phoneNumber  : validatePhoneNumber(t, false)
      .nullable(),
    rBusinessBusinessCategory : Yup.array()
      .min(1, 'Select at least one subcategory')
      .ensure()
      .required(),
    rBusinessLocationCredential : Yup.array()
      .ensure(),
    rBusinessImage : Yup.array()
      .min(1, 'Select at least one slide')
      .max(6, 'There are only 6 slides you can add')
      .test(
        'is-caption-valid',
        'Special symbols only are forbidden',
        (slider: any) => !slider.find((s: any) => /^[^a-zA-Z0-9]+$/.test(s.label || '')),
      ),
    rBusinessTerritory : Yup.array()
      .min(1, 'Select at least one area')
      .ensure()
      .required(),
    smeDescription : Yup.string()
      .nullable(),
    smeImage : Yup.object()
      .nullable(),
    websiteUri : Yup.string()
      .url('Please enter a valid website URL')
      .max(200, 'Max character count is 200')
      .nullable(),
    yelpBusinessName : Yup.object()
      .nullable()
      .when('yelpReviewsActive', {
        is   : true,
        then : Yup.object().test(
          'is-valid-yelp-business',
          'You should find yelp business into yelp system',
          (v) => !!v).typeError('You should find yelp business into yelp system')
          .shape({ label: Yup.string().required('You should find yelp business into yelp system') }),
      }),
    yelpReviewsActive : Yup.boolean()
      .required(),
  }), [isWithoutAddress]);

  return validationSchema;
};

type TBranchPostValues = Omit<IBusinessLocation, 'rBusinessImage' | 'smeImage'> & {
  smeImage: {
    imageId? : number;
    imageUri : string;
    mimeType : 'image';
  } | null;
  rBusinessImage: {
    image: {
      imageId  : number | null;
      imageUri : string;
      mimeType : string;
    }
    businessLocationId: number | null;
    imageId   : number | null;
    order     : number;
    isEnabled : boolean;
  }[];
};

interface IAddBranchContainer {
  initialValues : Partial<IBusinessLocation> & { businessId: number };
  isLoading     : boolean;
  onAdd         : (values: TBranchPostValues) => void;
  onDelete      : any;
}

export interface IMediaElement {
  mimeType : string;
  imageUri : string;
  imageId? : number | null;
}

export type TBranchFormValues = Omit<IBusinessLocation, 'rBusinessLocationChannel' | 'rBusinessTerritory' | 'rBusinessBusinessCategory' | 'rBusinessImage' | 'iconUri' | 'logoUri' | 'smeImage' | 'yelpBusinessName' | 'yelpBusinessId' >
& {
  isWithoutAddress          : boolean;
  rBusinessBusinessCategory : IOptionType[];
  rBusinessImage            : {
    uri                : string;
    mimeType           : string;
    id                 : number | null;
    businessLocationId : number | null;
    order              : number | null;
    isEnabled          : boolean;
  }[];
  rBusinessLocationChannel  : IOptionType[];
  rBusinessTerritory        : IOptionType[];
  iconUri                   : IMediaElement | null;
  logoUri                   : IMediaElement | null;
  smeImage                  : IMediaElement | null;
  yelpBusinessName          : {
    value?: string | null;
    label?: string | null;
  } | null;
  yelpBusinessId            : {
    value?: string | null;
    label?: string | null;
  } | null;
}

export const AddBranchContainer = React.forwardRef(({
  onAdd,
  onDelete,
  isLoading,
  initialValues,
}: IAddBranchContainer, ref) => {
  const [confirmDelete, setConfirmDeleteStatus] = useState<boolean>(false);
  const [isWithoutAddress, setAddressStatus]    = useState<boolean>(false);
  const { t }                                   = useTranslation();

  const validationSchema = useValidationSchema(isWithoutAddress, t);

  const initialValuesInner = useMemo<TBranchFormValues>(() => ({
    alertHeader                 : initialValues.alertHeader || null,
    alertText                   : initialValues.alertText || null,
    businessId                  : initialValues.businessId,
    businessLocationId          : initialValues.businessLocationId || null,
    description                 : initialValues.description || '',
    establishedDate             : initialValues.establishedDate || null,
    googleBusinessName          : initialValues.googleBusinessName || null,
    googleReviewsActive         : !!initialValues.googleReviewsActive,
    isAlertActive               : !!initialValues.isAlertActive,
    isWithoutAddress            : initialValues.businessLocationId
      ? !initialValues?.location
      : false,
    iconUri                     : initialValues.iconUri ? {imageUri: initialValues.iconUri, mimeType: 'image'} : null,
    location                    : initialValues.location || INITIAL_LOCATION,
    locationId                  : initialValues.locationId,
    logoUri                     : initialValues.logoUri ? {imageUri: initialValues.logoUri, mimeType: 'image'} : null,
    name                        : initialValues.name || '',
    operationalHours            : initialValues.operationalHours || null,
    phoneNumber                 : initialValues.phoneNumber || null,
    status                      : typeof initialValues.status === 'number' ? initialValues.status : ERequestedReviewStatus.Approved,
    rBusinessBusinessCategory   : initialValues.rBusinessBusinessCategory?.map((item) => ({
      label : item.categoryName as string,
      value : item.categoryId,
    })) || [],
    rBusinessImage              : initialValues.rBusinessImage?.map((businessImage) => ({
      uri                : businessImage.image.imageUri,
      mimeType           : businessImage.image.mimeType,
      id                 : businessImage.imageId,
      businessLocationId : businessImage.businessLocationId,
      order              : businessImage.order,
      isEnabled          : true,
    })) || [],
    rBusinessLocationChannel    : initialValues.rBusinessLocationChannel?.map((item) => ({
      label : item.channelName as string,
      value : item.channelId,
    })) || [],
    rBusinessLocationCredential : initialValues.rBusinessLocationCredential || [],
    rBusinessTerritory          : initialValues.rBusinessTerritory?.map((item) => ({
      label : item.territoryName as string,
      value : item.territoryId,
    })) || [],
    smeDescription              : initialValues.smeDescription || null,
    smeImage                    : initialValues.smeImage ? { ...initialValues.smeImage } : null,
    type                        : null,
    yelpReviewsActive           : !!initialValues.yelpBusinessId,
    yelpBusinessId              : { value: initialValues.yelpBusinessId, label: initialValues.yelpBusinessName} || null,
    yelpBusinessName            : { value: initialValues.yelpBusinessId, label: initialValues.yelpBusinessName} || null,
    websiteUri                  : initialValues.websiteUri || null,
  }), [initialValues]);

  const onDeleteInternal = useCallback(() => {
    if (confirmDelete) {
      onDelete(initialValues);
    } else {
      setConfirmDeleteStatus(true);
    }
  }, [initialValues, confirmDelete, setConfirmDeleteStatus]);

  const onSubmit = useCallback((values: TBranchFormValues) => {
    const { googleBusinessName, location, rBusinessBusinessCategory, rBusinessImage, rBusinessLocationChannel, rBusinessTerritory, smeImage, ...restValues } = values;

    onAdd({
      googleBusinessName        : isWithoutAddress ? null : googleBusinessName,
      location                  : isWithoutAddress ? null : location,
      rBusinessBusinessCategory : rBusinessBusinessCategory.map((item) => ({
        businessLocationId : initialValuesInner.businessLocationId,
        categoryId         : item.value,
      })),
      rBusinessImage            : rBusinessImage.map((item, index) => ({
        order              : index + 1,
        isEnabled          : true,
        imageId            : item.id,
        businessLocationId : initialValuesInner.businessLocationId,
        image              : {
          mimeType : item.mimeType,
          imageUri : item.uri,
          imageId  : item.id,
        },
      })),
      rBusinessLocationChannel  : rBusinessLocationChannel.map((item) => ({ channelId: item.value })),
      rBusinessTerritory        : rBusinessTerritory.map((item) => ({
        businessLocationId : initialValuesInner.businessLocationId,
        territoryId        : item.value,
      })),
      ...restValues,
      iconUri  : restValues.iconUri ? restValues.iconUri.imageUri : '',
      logoUri  : restValues.logoUri ? restValues.logoUri.imageUri : '',
      yelpBusinessId: values.yelpBusinessId?.value || '',
      yelpBusinessName: values.yelpBusinessId?.label || '',
      smeImage : smeImage ? {
        imageUri : smeImage.imageUri,
        mimeType : 'image',
        imageId  : smeImage.imageId || undefined,
      } : null,
    });
  }, [initialValuesInner, isWithoutAddress]);

  const updateValuesIfNeeded = useCallback((() => {
    let prevIsAlertActive = initialValues.isAlertActive;
    let prevYelpPreviews  = initialValues.yelpReviewsActive;

    return (values: TBranchFormValues) => {
      if (!values.isAlertActive && values.isAlertActive !== prevIsAlertActive) {
        values.alertHeader = null;
        values.alertText   = null;
      }

      if (isWithoutAddress !== values.isWithoutAddress) {
        setAddressStatus(values.isWithoutAddress);
      }

      if (values.yelpReviewsActive === false && prevYelpPreviews === true) {
        values.yelpBusinessId   = null;
        values.yelpBusinessName = null;
      }

      prevIsAlertActive = values.isAlertActive;
      prevYelpPreviews  = values.yelpReviewsActive;

      return values;
    };
  })(), [isWithoutAddress, setAddressStatus]);

  return (
    <Container ref={ref} tabIndex={1}>
      <Formik
        initialValues    = {initialValuesInner}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<TBranchFormValues>) => (
          <BranchForm
            {...props}
            businessLocationId = {initialValuesInner.businessLocationId}
            confirmDelete      = {confirmDelete}
            isLoading          = {isLoading}
            onDeleteInternal   = {onDeleteInternal}
            values             = {updateValuesIfNeeded(props.values)}
          />
        )}
      </Formik>
    </Container>
  );
});
