import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }       from '@utils/apiCaller';
import { Notifications } from '@components/Notifications/Notifications';

import {
  fetchForms,
  fetchFormsSuccessfully,
  fetchFormsFailure,

  addForm,
  addFormSuccessfully,
  addFormFailure,

  editForm,
  editFormSuccessfully,
  editFormFailure,

  deleteForms,
  deleteFormsSuccessfully,
  deleteFormsFailure,

  FormTypes,
  getMeta,

  IFormsActions,
  IFormsReducer,
} from './formsReducer';

function* handleFetchForms() {
  try {
    const { page,  rowsPerPage }: IFormsReducer['meta'] = yield select(getMeta);
    const { data: { items } } = yield callApi(`settings/organizations/ui?page=${page}&itemsPerPage=${rowsPerPage}`);

    yield put(fetchFormsSuccessfully(items));
  } catch (e) {
    yield put(fetchFormsFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Forms. Please try again', 'error');
  }
}

function* handleAddForm(action: IFormsActions[FormTypes.addForm]) {
  try {
    const values = action.payload.attributes;

    yield callApi('settings/organizations/ui', 'post', values);
    yield put(addFormSuccessfully());
    yield put(replace('/admin/forms'));
    yield put(fetchForms());
    yield call(Notifications.enqueueSnackbar, 'Form was added successfully', 'success');
  } catch (e) {
    yield put(addFormFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Form. Please try again', 'error');
  }
}

function* handleEditForm(action: IFormsActions[FormTypes.editForm]) {
  try {
    const { values } = action.payload.attributes;

    yield callApi(`settings/organizations/ui/${values.settingsId}`, 'put', values);
    yield put(editFormSuccessfully());
    yield put(replace('/admin/forms'));
    yield call(Notifications.enqueueSnackbar, 'Form was edited successfully', 'success');
  } catch (e) {
    yield put(editFormFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Form. Please try again', 'error');
  }
}

function* handleDeleteForms(action: IFormsActions[FormTypes.deleteForms]) {
  try {
    const { settingsIds, replaceEndpoint } = action.payload.attributes;

    for (let i = 0; i < settingsIds.length; i++) {
      yield callApi(`settings/organizations/ui/${settingsIds[i]}`, 'delete');
    }

    yield put(deleteFormsSuccessfully());
    yield put(fetchForms());
    yield call(Notifications.enqueueSnackbar, `${settingsIds.length > 1 ? 'Forms were' : 'Form was'} deleted successfully`, 'success');

    if (replaceEndpoint) {
      yield put(replace(replaceEndpoint));
    }
  } catch (e) {
    const { settingsIds } = action.payload.attributes;

    yield put(deleteFormsFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${settingsIds.length > 1 ? 'Forms' : 'a Form'}. Please try again`, 'error');
  }
}

function* formsSagas() {
  yield all([
    takeLatest(fetchForms, handleFetchForms),
    takeLatest(addForm, handleAddForm),
    takeLatest(editForm, handleEditForm),
    takeLatest(deleteForms, handleDeleteForms),
  ]);
}

export { formsSagas };
