import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState } from '@store/rootReducer';
import { IForms } from '@models/index';

import { ITableMeta } from '@components/Table/ClickableItem';

export const STATE_KEY = 'forms';

export interface IFormsReducer {
  forms        : IForms[];
  meta         : {
    isFetching  : boolean;
    totalForms  : number;
    page        : number;
    rowsPerPage : number;
  };
}

export enum FormTypes {
  fetchForms              = 'fetchForms',
  fetchFormsSuccessfully  = 'fetchFormsSuccessfully',
  fetchFormsFailure       = 'fetchFormsFailure',
  addForm                 = 'addForm',
  addFormSuccessfully     = 'addFormSuccessfully',
  addFormFailure          = 'addFormFailure',
  editForm                = 'editForm',
  editFormSuccessfully    = 'editFormSuccessfully',
  editFormFailure         = 'editFormFailure',
  deleteForms             = 'deleteForms',
  deleteFormsSuccessfully = 'deleteFormsSuccessfully',
  deleteFormsFailure      = 'deleteFormsFailure',
}

export interface IFormsActions {
  [FormTypes.fetchForms]              : Action<{ meta: ITableMeta }>;
  [FormTypes.fetchFormsSuccessfully]  : Action<{ forms: IForms[]; meta: { isFetching: false } }>;
  [FormTypes.fetchFormsFailure]       : Action<{}>;
  [FormTypes.addForm]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [FormTypes.addFormSuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [FormTypes.addFormFailure]          : Action<{ meta: { isFetching: false }}>;
  [FormTypes.editForm]                : Action<{ attributes: { values: { [f: string]: any } }; meta: { isFetching: true }}>;
  [FormTypes.editFormSuccessfully]    : Action<{ meta: { isFetching: false }}>;
  [FormTypes.editFormFailure]         : Action<{ meta: { isFetching: false }}>;
  [FormTypes.deleteForms]             : Action<{ attributes: { settingsIds: number[]; replaceEndpoint?: string }; meta: { isFetching: true } }>;
  [FormTypes.deleteFormsSuccessfully] : Action<{}>;
  [FormTypes.deleteFormsFailure]      : Action<{}>;
}

export interface IFormsActionsCreators {
  [FormTypes.fetchForms]              : (formsMeta?: ITableMeta) => IFormsActions[FormTypes.fetchForms];
  [FormTypes.fetchFormsSuccessfully]  : (forms: IForms[]) => IFormsActions[FormTypes.fetchFormsSuccessfully];
  [FormTypes.fetchFormsFailure]       : () => IFormsActions[FormTypes.fetchFormsFailure];
  [FormTypes.addForm]                 : (values: any) => IFormsActions[FormTypes.addForm];
  [FormTypes.addFormSuccessfully]     : () => IFormsActions[FormTypes.addFormSuccessfully];
  [FormTypes.addFormFailure]          : () => IFormsActions[FormTypes.addFormFailure];
  [FormTypes.editForm]                : (values: any) => IFormsActions[FormTypes.editForm];
  [FormTypes.editFormSuccessfully]    : () => IFormsActions[FormTypes.editFormSuccessfully];
  [FormTypes.editFormFailure]         : () => IFormsActions[FormTypes.editFormFailure];
  [FormTypes.deleteForms]             : (settingsIds: number[], replaceEndpoint?: string) => IFormsActions[FormTypes.deleteForms];
  [FormTypes.deleteFormsSuccessfully] : () => IFormsActions[FormTypes.deleteFormsSuccessfully];
  [FormTypes.deleteFormsFailure]      : () => IFormsActions[FormTypes.deleteFormsFailure];
}

type Actions = IFormsActions[FormTypes.fetchFormsSuccessfully]
| IFormsActions[FormTypes.fetchFormsFailure]
| IFormsActions[FormTypes.addForm]
| IFormsActions[FormTypes.addFormSuccessfully]
| IFormsActions[FormTypes.addFormFailure]
| IFormsActions[FormTypes.editFormSuccessfully]
| IFormsActions[FormTypes.editFormFailure]
| IFormsActions[FormTypes.deleteForms]
| IFormsActions[FormTypes.deleteFormsSuccessfully]
| IFormsActions[FormTypes.deleteFormsFailure];

const initialState: IFormsReducer = {
  forms : [],
  meta   : {
    isFetching  : false,
    totalForms  : 0,
    page        : 0,
    rowsPerPage : 50,
  },
};

export const {
  fetchForms,
  fetchFormsSuccessfully,
  fetchFormsFailure,
  addForm,
  addFormSuccessfully,
  addFormFailure,
  editForm,
  editFormSuccessfully,
  editFormFailure,
  deleteForms,
  deleteFormsSuccessfully,
  deleteFormsFailure,
}: IFormsActionsCreators = createActions(
  {
    FETCH_FORMS              : (
      formsMeta = {},
    ) => ({ meta: { ...formsMeta, isFetching: true } }),
    FETCH_FORMS_SUCCESSFULLY : (forms) => ({ forms, meta: { totalForms: forms.length, isFetching: false } }),
    FETCH_FORMS_FAILURE      : () => ({ meta: { isFetching: false } }),

    ADD_FORM              : (values) => ({ attributes: values, meta: { isFetching: true } }),
    ADD_FORM_SUCCESSFULLY : () => ({ meta: { isFetching: false } }),
    ADD_FORM_FAILURE      : () => ({ meta: { isFetching: false } }),

    EDIT_FORM              : (values) => ({ attributes: { values }, meta: { isFetching: true } }),
    EDIT_FORM_SUCCESSFULLY : () => ({ meta: { isFetching: false } }),
    EDIT_FORM_FAILURE      : () => ({ meta: { isFetching: false } }),

    DELETE_FORMS              : (settingsIds, replaceEndpoint) => ({ attributes: { settingsIds, replaceEndpoint }, meta: { isFetching: true } }),
    DELETE_FORMS_SUCCESSFULLY : () => ({}),
    DELETE_FORMS_FAILURE      : () => ({}),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const formReducer = handleActions<IFormsReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchForms,
      fetchFormsSuccessfully,
      addForm,
      addFormSuccessfully,
      addFormFailure,
      editForm,
      editFormSuccessfully,
      editFormFailure,
      deleteForms,
      deleteFormsSuccessfully,
      deleteFormsFailure,
    )]: (
      state  : IFormsReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default formReducer;

interface IFormsSelectors {
  getForms : (state: IState) => IForms[];
  getMeta  : (state: IState) => IFormsReducer['meta'];
}

export const getForms: IFormsSelectors['getForms']= (state: IState) => state[STATE_KEY].forms;
export const getMeta: IFormsSelectors['getMeta']  = (state: IState) => state[STATE_KEY].meta;
