import { ISvgIconOptions } from './index';

export default ({ height = '22px', width = '22px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 515 430">
    <path
      d    = "M458.67,426.67H53.33C23.94,426.67,0,402.75,0,373.33V224c0-1.49,0.32-3.01,0.94-4.37L85.61,31.47 C94.23,12.35,113.32,0,134.25,0h243.5c20.93,0,40.02,12.35,48.64,31.47l84.67,188.16c0.62,1.37,0.94,2.88,0.94,4.37v149.33 C512,402.75,488.06,426.67,458.67,426.67L458.67,426.67z M21.33,226.28v147.05c0,17.64,14.36,32,32,32h405.33 c17.64,0,32-14.36,32-32V226.28L406.93,40.19c-5.16-11.43-16.62-18.86-29.18-18.86h-243.5c-12.57,0-24.02,7.42-29.18,18.88 L21.33,226.28z"
      fill = "#d5d7e3"
    />
    <path
      d    = "M358.55,320h-205.1c-13.27,0-25.3-8.34-29.95-20.76l-21.63-57.66c-1.54-4.14-5.55-6.91-9.98-6.91H16 c-5.89,0-10.67-4.78-10.67-10.67s4.78-10.67,10.67-10.67h75.88c13.27,0,25.3,8.34,29.97,20.76l21.61,57.66 c1.56,4.14,5.57,6.91,9.98,6.91h205.08c4.44,0,8.45-2.77,9.98-6.91l21.61-57.66c4.69-12.42,16.73-20.76,29.99-20.76h81.22 c5.89,0,10.67,4.78,10.67,10.67s-4.78,10.67-10.67,10.67h-81.22c-4.44,0-8.45,2.77-9.98,6.91l-21.61,57.66 C383.85,311.66,371.8,320,358.55,320z"
      fill = "#d5d7e3"
    />
  </svg>
);
