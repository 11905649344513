import { PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';

import { getImageTypeFromImageSrc } from '../../utils';

export const toBlob = (canvas: HTMLCanvasElement, type: string): Promise<Blob | null> => new Promise((resolve) => {
  canvas.toBlob(resolve, type, 1);
});

// source: https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/canvasPreview.ts
export const getCroppedImg = async (image: HTMLImageElement, crop: PixelCrop): Promise<Blob | null> => {
  const canvas = document.createElement('canvas');
  const ctx    = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const pixelRatio = window.devicePixelRatio;

  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
  canvas.width  = Math.floor(crop.width * scaleX * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.translate(-centerX, -centerY);

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();

  const blobFile = await toBlob(canvas, getImageTypeFromImageSrc(image.src) as string);

  return blobFile;
};

export const centerImageAspectCrop = (width: number, height: number, aspect: number) => centerCrop(
  makeAspectCrop({ unit: '%', width: 90 }, aspect, width, height),
  width,
  height,
);
