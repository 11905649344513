import React, { useEffect }         from 'react';
import { useSnackbar, VariantType } from 'notistack';

import { IErrorNotificationData, ErrorResponseParser } from './NotificationParsers';

export const Notifications: any = () => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(
    (): void => {
      Notifications.enqueueSnackbar = (data: string | IErrorNotificationData, variant: VariantType): void => {
        enqueueSnackbar(variant === 'error' ? <ErrorResponseParser data={data} /> : data, { variant });
      };
    },
    [],
  );

  return null;
};
