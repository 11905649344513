import { FieldProps }  from 'formik';
import { useCallback } from 'react';

export const useUpdateComponentValue = <T = unknown>(
  field?    : FieldProps['field'],
  form?     : FieldProps['form'],
  onChange? : (newValue: T) => void,
): {
  updateComponentValue: (newValue: T) => void,
} => {
  const updateComponentValue = useCallback((newValue: T) => {
    if (field && form) {
      form.setFieldValue(field.name, newValue);
    } else if (onChange) {
      onChange(newValue);
    } else {
      // eslint-disable-next-line no-console
      console.error('No handler for changing component value.');
    }
  }, [field, form, onChange]);

  return { updateComponentValue };
};
