import { theme as commonTheme } from '@common/styles/theme';

export const theme = {
  ...commonTheme,
  textColor       : {
    gray      : '#7e7e7e',
    lightGrey : '#cbd0d3',
    blue      : '#2999F1',
    orange    : '#ff8800',
    red       : '#f44336',
  },
  backgroundColor : {
    mainOrange    : '#F0552A',
    primaryOrange : '#ff9800',
    gray          : '#bdbdbd',
  },
  borderColor: {
    gray : '#7e7e7e',
    red  : '#f44336',
  },
  colorSchemes    : {
    orange: '#F0552A',
  },
};
