import { ReactElement } from 'react';
import 'antd/dist/antd.css';
// styles for RecurrenceEditorComponent from @syncfusion/ej2-react-schedule
import '../public/app.css';
import './utils/i18n';

import ReactDOM  from 'react-dom';

import { App } from './App';

const renderRoot = (app: ReactElement): void => {
  ReactDOM.render(app, document.getElementById('root'));
};

renderRoot(<App />);
