import React      from 'react';
import styled     from 'styled-components';

type Action = {
  name    : string;
  onClick : () => void;
}

interface ISuccess {
  primaryText : string;
  actions     : Action[];
}

const Container = styled.div<{ ref: any }>`
  width            : 620px;
  background-color : #fff;
`;

const Title = styled.h1`
  color      : ${({ theme }) => theme.textColor.blue};
  text-align : center;
`;

const Primarytext = styled.p`
  text-align  : center;
  font-weight : bold;
  font-size   : 16px;
`;

const SecondaryText = styled.p`
  text-align : center;
  font-size  : 16px;
`;

const ActionButtonWrapper = styled.div`
  display         : flex;
  justify-content : space-around;
  align-items     : center;
`;

const ActionButton = styled.div`
  color       : ${({ theme }) => theme.textColor.blue};
  width       : 140px;
  text-align  : center;
  font-size   : 16px;
  cursor      : pointer;
  padding     : 12px;
  line-height : 22px;
`;

export const Success = React.forwardRef(({
  primaryText,
  actions,
}: ISuccess, ref) => {

  return (
    <Container ref={ref} tabIndex={1}>
      <Title>Success!</Title>
      <Primarytext>{primaryText}</Primarytext>
      <SecondaryText>What would you like to do from here?</SecondaryText>
      <ActionButtonWrapper>
        {actions.map((a: Action) => (
          <ActionButton key= {a.name} onClick={a.onClick}>{a.name}</ActionButton>
        ))}
      </ActionButtonWrapper>
    </Container>
  );
});
