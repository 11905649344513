import { Formik }               from 'formik';
import { useMemo, useCallback } from 'react';
import { connect }              from 'react-redux';
import styled                   from 'styled-components';
import * as Yup                 from 'yup';

import { CircularLoader } from '@components/Loaders/CircularLoader';

import { validateStringForSpecialSymbolsOnly }    from '@common/utils';
import { ILicenseModel, IOptionType }             from '@src/models';
import { IState }                                 from '@store/rootReducer';
import { useFetchEntity }                         from '@utils/fetchEntity';
import { validatePassword, validatePasswordCopy } from '@utils/validators';

import {
  addLicense    as addLicenseAction,
  updateLicense as updateLicenseAction,
  getMeta,
  ILicensesActionsCreators,
  ILicensesReducer,
}                      from '../licensesReducer';
import { LicenseForm } from '../components/LicenseForm';

interface ILicense {
  addLicense    : ILicensesActionsCreators['addLicense'];
  license       : ILicenseModel;
  licenseMeta   : ILicensesReducer['meta'];
  updateLicense : ILicensesActionsCreators['updateLicense'];
}

export type TLicenseFormModel = {
  email        : string;
  firstName    : string;
  lastName     : string;
  password     : string;
  passwordCopy : string;
  territories  : IOptionType[];
}

export type TLicensePostModel = Omit<TLicenseFormModel, 'passwordCopy' | 'territories'> & {
  territoryIds: number[];
}

export type TLicensePutModel = Omit<ILicenseModel, 'territories'> & {
  territoryIds: number[];
}

const useValidationSchema = (editMode: boolean) => Yup.object().shape({
  password     : !editMode ? validatePassword() : Yup.string(),
  passwordCopy : !editMode ? validatePasswordCopy() : Yup.string(),
  email        : Yup.string()
    .email('It doesn\'t look like email')
    .required('This field cannot be empty'),
  firstName : Yup.string()
    .max(50, 'Max character count is 50')
    .min(2, 'Min character count is 2')
    .test(...validateStringForSpecialSymbolsOnly((value) => value))
    .required('This field cannot be empty'),
  lastName : Yup.string()
    .max(50, 'Max character count is 50')
    .min(2, 'Min character count is 2')
    .test(...validateStringForSpecialSymbolsOnly((value) => value))
    .required('This field cannot be empty'),
  territories : Yup.array()
    .ensure()
    .min(1, 'Assign at least 1 area'),
});

const LicenseC = ({
  addLicense,
  licenseMeta,
  updateLicense,
}: ILicense) => {'';
  const [license, isLicenseFetching] = useFetchEntity<ILicenseModel>('LicenseAccount');

  const validationSchema = useValidationSchema(!!license);

  const initialValues = useMemo<TLicenseFormModel>(() => ({
    email        : '',
    firstName    : '',
    lastName     : '',
    password     : '',
    passwordCopy : '',
    ...license,
    territories : (license?.territories || []).map((item) => ({
      label: item.territoryName,
      value: item.territoryId,
    })),
  }), [license]);

  const onSubmit = useCallback(async (values: TLicenseFormModel) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { passwordCopy, territories, ...requestValues } = values;

    const requestData: TLicensePostModel = {
      ...requestValues,
      territoryIds: territories.map((item) => item.value),
    };

    if (license) {
      updateLicense({ ...requestData, userId: license.userId });
    } else {
      addLicense(requestData);
    }
  }, [license]);

  if (isLicenseFetching) return (
    <CircularLoader />
  );

  return (
    <Container>
      <Formik
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validationSchema = {validationSchema}
      >
        <LicenseForm
          isEditMode = {!!license}
          isFetching = {licenseMeta.isFetching}
        />
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 947px; }
`;

const mapStateToProps = (state: IState) => ({
  licenseMeta : getMeta(state),
});

const mapDispatchToProps = {
  addLicense    : addLicenseAction,
  updateLicense : updateLicenseAction,
};

export const License = connect(mapStateToProps, mapDispatchToProps)(LicenseC);
