import { useCallback, useState } from 'react';
import { useTranslation }        from 'react-i18next';

import { Modal }         from './Modal';
import { FormButtons }   from '../Button';
import { Input }         from '../Input/Input';
import { Label }         from '../Label/Label';
import { ECardStatuses } from '../../enums';

export interface IRequestModalProps {
  comment  : string;
  title    : string;
  onCancel : () => void;
  onSubmit : (value: string) => Promise<void>;
}

export const RequestModal = ({ comment, onCancel, onSubmit, title }: IRequestModalProps) => {
  const { t } = useTranslation();

  const [inputValue, setValue] = useState<string>('');
  const [status, setStatus]    = useState<ECardStatuses>(ECardStatuses.None);

  const onClickSubmit = useCallback(async (message: string) => {
    try {
      await onSubmit(message);
      setStatus(ECardStatuses.Success);
    } catch (error) {
      setStatus(ECardStatuses.Failure);
    }
  }, [inputValue, onSubmit]);

  return (
    <Modal
      onCancel = {onCancel}
      status   = {status}
      title    = {title}
      width    = "718px"
    >
      <Label
        label          = {comment}
        required       = {false}
        componentStyle = {{
          fontWeight : 500,
          transform  : 'none',
        }}
      />
      <Input
        bordered
        autoSize         = {{ maxRows: 6, minRows: 6 }}
        changeFieldValue = {setValue}
        type             = "textarea"
        value            = {inputValue}
        wrapperStyle     = {() => 'margin: 7px 0 32px'}
      />
      <FormButtons
        mainButtonLabel = {t('send')}
        onSubmit        = {() => onClickSubmit(inputValue)}
      />
    </Modal>
  );
};
