type TPromise = Promise<{ content: string; fileName: string }>;

const fileToBase64 = (file: File): TPromise => {
  const promise: TPromise = new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        content  : reader.result as string,
        fileName : file.name,
      });
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

  return promise;
};

export { fileToBase64 };
