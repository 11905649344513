import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState }                           from '@store/rootReducer';
import { IChannelModel, IChannelsCategory } from '@models/index';
import { ITableMeta }                       from '@components/Table/ClickableItem';

import { TChannelValues } from './pages/AddChannelContainer';

export const STATE_KEY = 'channels';

export interface IChannelsReducer {
  categories       : IChannelsCategory[];
  categoryChannels : IChannelModel[];
  meta             : {
    isChannelsFetching : boolean;
    isFetching         : boolean;
    totalCategories    : number;
    page               : number;
    rowsPerPage        : number;
    orderBy            : 'Name';
    order              : 'Asc' | 'Desc';
  };
}

export enum ChannelTypes {
  fetchCategories             = 'fetchCategories',
  fetchCategoriesSuccessfully = 'fetchCategoriesSuccessfully',
  fetchCategoriesFailure      = 'fetchCategoriesFailure',

  addCategory             = 'addCategory',
  addCategorySuccessfully = 'addCategorySuccessfully',
  addCategoryFailure      = 'addCategoryFailure',

  editCategory             = 'editCategory',
  editCategorySuccessfully = 'editCategorySuccessfully',
  editCategoryFailure      = 'editCategoryFailure',

  deleteCategories             = 'deleteCategories',
  deleteCategoriesSuccessfully = 'deleteCategoriesSuccessfully',
  deleteCategoriesFailure      = 'deleteCategoriesFailure',

  fetchChannels             = 'fetchChannels',
  fetchChannelsSuccessfully = 'fetchChannelsSuccessfully',
  fetchChannelsFailure      = 'fetchChannelsFailure',

  addChannel             = 'addChannel',
  addChannelSuccessfully = 'addChannelSuccessfully',
  addChannelFailure      = 'addChannelFailure',

  editChannel             = 'editChannel',
  editChannelSuccessfully = 'editChannelSuccessfully',
  editChannelFailure      = 'editChannelFailure',

  deleteChannel             = 'deleteChannel',
  deleteChannelSuccessfully = 'deleteChannelSuccessfully',
  deleteChannelFailure      = 'deleteChannelFailure',
}

export interface IChannelsActions {
  [ChannelTypes.fetchCategories]             : Action<{ meta: ITableMeta }>;
  [ChannelTypes.fetchCategoriesSuccessfully] : Action<{ categories: IChannelsReducer['categories']; meta: { totalCategories: number } }>;
  [ChannelTypes.fetchCategoriesFailure]      : Action<{}>;

  [ChannelTypes.addCategory]             : Action<{ attributes: { values: Omit<IChannelsCategory, 'isCitySpecific' | 'channelCategoryId'> }; meta: { isFetching: true }}>;
  [ChannelTypes.addCategorySuccessfully] : Action<{ meta: { isFetching: false }}>;
  [ChannelTypes.addCategoryFailure]      : Action<{ meta: { isFetching: false }}>;

  [ChannelTypes.editCategory]             : Action<{ attributes: { values: IChannelsCategory }; meta: { isFetching: true }}>;
  [ChannelTypes.editCategorySuccessfully] : Action<{ category: IChannelsReducer['categories'][0]; meta: { isFetching: false }}>;
  [ChannelTypes.editCategoryFailure]      : Action<{ meta: { isFetching: false }}>;

  [ChannelTypes.deleteCategories]             : Action<{ attributes: { categoryIds: number[] }; meta: { isFetching: true }}>;
  [ChannelTypes.deleteCategoriesSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ChannelTypes.deleteCategoriesFailure]      : Action<{ meta: { isFetching: false } }>;

  [ChannelTypes.fetchChannels]             : Action<{ attributes: { channelCategoryId: number } }>;
  [ChannelTypes.fetchChannelsSuccessfully] : Action<{ categoryChannels: IChannelModel[] }>;
  [ChannelTypes.fetchChannelsFailure]      : Action<{}>;

  [ChannelTypes.addChannel]             : Action<{ attributes: { values: TChannelValues }; meta: { isChannelsFetching: true }}>;
  [ChannelTypes.addChannelSuccessfully] : Action<{ meta: { isChannelsFetching: false }}>;
  [ChannelTypes.addChannelFailure]      : Action<{ meta: { isChannelsFetching: false }}>;

  [ChannelTypes.editChannel]             : Action<{ attributes: { values: TChannelValues; initialCategoryId: number }; meta: { isChannelsFetching: true }}>;
  [ChannelTypes.editChannelSuccessfully] : Action<{ meta: { isChannelsFetching: false } }>;
  [ChannelTypes.editChannelFailure]      : Action<{ meta: { isChannelsFetching: false }}>;

  [ChannelTypes.deleteChannel]             : Action<{ attributes: { channelCategoryId: number; channelId: number }; meta: { isChannelsFetching: true } }>;
  [ChannelTypes.deleteChannelSuccessfully] : Action<{ meta: { isChannelsFetching: false } }>;
  [ChannelTypes.deleteChannelFailure]      : Action<{ meta: { isChannelsFetching: false } }>;
}

export interface IChannelsActionsCreators {
  [ChannelTypes.fetchCategories]             : (meta?: ITableMeta) => IChannelsActions[ChannelTypes.fetchCategories];
  [ChannelTypes.fetchCategoriesSuccessfully] : (categories: IChannelsReducer['categories'][], totalCategories: number) => IChannelsActions[ChannelTypes.fetchCategoriesSuccessfully];
  [ChannelTypes.fetchCategoriesFailure]      : () => IChannelsActions[ChannelTypes.fetchChannelsFailure];

  [ChannelTypes.addCategory]             : (values: Omit<IChannelsCategory, 'isCitySpecific' | 'channelCategoryId'>) => IChannelsActions[ChannelTypes.addCategory];
  [ChannelTypes.addCategorySuccessfully] : () => IChannelsActions[ChannelTypes.addCategorySuccessfully];
  [ChannelTypes.addCategoryFailure]      : () => IChannelsActions[ChannelTypes.addCategoryFailure];

  [ChannelTypes.editCategory]             : (values: IChannelsCategory) => IChannelsActions[ChannelTypes.editCategory];
  [ChannelTypes.editCategorySuccessfully] : (category: IChannelsReducer['categories'][0]) => IChannelsActions[ChannelTypes.editCategorySuccessfully];
  [ChannelTypes.editCategoryFailure]      : () => IChannelsActions[ChannelTypes.editCategoryFailure];

  [ChannelTypes.deleteCategories]             : (categoryIds: number[]) => IChannelsActions[ChannelTypes.deleteCategories];
  [ChannelTypes.deleteCategoriesSuccessfully] : () => IChannelsActions[ChannelTypes.deleteCategoriesSuccessfully];
  [ChannelTypes.deleteCategoriesFailure]      : () => IChannelsActions[ChannelTypes.deleteCategoriesFailure];

  [ChannelTypes.fetchChannels]             : (channelCategoryId: number) => IChannelsActions[ChannelTypes.fetchChannels];
  [ChannelTypes.fetchChannelsSuccessfully] : (categoryChannels: IChannelModel[]) => IChannelsActions[ChannelTypes.fetchChannelsSuccessfully];
  [ChannelTypes.fetchChannelsFailure]      : () => IChannelsActions[ChannelTypes.fetchChannelsFailure];

  [ChannelTypes.addChannel]             : (values: TChannelValues) => IChannelsActions[ChannelTypes.addChannel];
  [ChannelTypes.addChannelSuccessfully] : () => IChannelsActions[ChannelTypes.addChannelSuccessfully];
  [ChannelTypes.addChannelFailure]      : () => IChannelsActions[ChannelTypes.addChannelFailure];

  [ChannelTypes.editChannel]             : (values: TChannelValues, initialCategoryId: number) => IChannelsActions[ChannelTypes.editChannel];
  [ChannelTypes.editChannelSuccessfully] : () => IChannelsActions[ChannelTypes.editChannelSuccessfully];
  [ChannelTypes.editChannelFailure]      : () => IChannelsActions[ChannelTypes.editChannelFailure];

  [ChannelTypes.deleteChannel]             : (channelId: number, channelCategoryId: number) => IChannelsActions[ChannelTypes.deleteChannel];
  [ChannelTypes.deleteChannelSuccessfully] : () => IChannelsActions[ChannelTypes.deleteChannelSuccessfully];
  [ChannelTypes.deleteChannelFailure]      : () => IChannelsActions[ChannelTypes.deleteChannelFailure];
}

type Actions = IChannelsActions[ChannelTypes.fetchCategories]
| IChannelsActions[ChannelTypes.fetchCategoriesSuccessfully]
| IChannelsActions[ChannelTypes.fetchCategoriesFailure]
| IChannelsActions[ChannelTypes.addCategory]
| IChannelsActions[ChannelTypes.addCategorySuccessfully]
| IChannelsActions[ChannelTypes.addCategoryFailure]
| IChannelsActions[ChannelTypes.editCategory]
| IChannelsActions[ChannelTypes.editCategorySuccessfully]
| IChannelsActions[ChannelTypes.editCategoryFailure]
| IChannelsActions[ChannelTypes.deleteCategories]
| IChannelsActions[ChannelTypes.deleteCategoriesSuccessfully]
| IChannelsActions[ChannelTypes.deleteCategoriesFailure]
| IChannelsActions[ChannelTypes.fetchChannels]
| IChannelsActions[ChannelTypes.fetchChannelsSuccessfully]
| IChannelsActions[ChannelTypes.fetchChannelsFailure]
| IChannelsActions[ChannelTypes.addChannel]
| IChannelsActions[ChannelTypes.addChannelSuccessfully]
| IChannelsActions[ChannelTypes.addChannelFailure]
| IChannelsActions[ChannelTypes.editChannel]
| IChannelsActions[ChannelTypes.editChannelSuccessfully]
| IChannelsActions[ChannelTypes.editChannelFailure]
| IChannelsActions[ChannelTypes.deleteChannel]
| IChannelsActions[ChannelTypes.deleteChannelSuccessfully]
| IChannelsActions[ChannelTypes.deleteChannelFailure]

const initialState: IChannelsReducer = {
  categories       : [],
  categoryChannels : [],
  meta     : {
    isChannelsFetching : false,
    isFetching         : false,
    totalCategories    : 0,
    page               : 0,
    rowsPerPage        : 50,
    orderBy            : 'Name',
    order              : 'Asc',
  },
};

export const {
  fetchCategories,
  fetchCategoriesSuccessfully,
  fetchCategoriesFailure,

  addCategory,
  addCategorySuccessfully,
  addCategoryFailure,

  editCategory,
  editCategorySuccessfully,
  editCategoryFailure,

  deleteCategories,
  deleteCategoriesSuccessfully,
  deleteCategoriesFailure,

  fetchChannels,
  fetchChannelsSuccessfully,
  fetchChannelsFailure,

  addChannel,
  addChannelSuccessfully,
  addChannelFailure,

  editChannel,
  editChannelSuccessfully,
  editChannelFailure,

  deleteChannel,
  deleteChannelSuccessfully,
  deleteChannelFailure,
}: IChannelsActionsCreators = createActions(
  {
    FETCH_CATEGORIES              : (meta = {}) => ({ meta: { ...meta, isFetching: true } }),
    FETCH_CATEGORIES_SUCCESSFULLY : (categories: IChannelsCategory[], totalCategories: number) => ({ categories, meta: { totalCategories, isFetching: false } }),
    FETCH_CATEGORIES_FAILURE      : () => ({ meta: { isFetching: false } }),

    ADD_CATEGORY              : (values: Omit<IChannelsCategory, 'channels' | 'channelCategoryId'>) => ({ attributes: { values }, meta: { isFetching: true } }),
    ADD_CATEGORY_SUCCESSFULLY : () => ({ meta: { isFetching: false } }),
    ADD_CATEGORY_FAILURE      : () => ({ meta: { isFetching: false } }),

    EDIT_CATEGORY              : (values: IChannelsCategory) => ({ attributes: { values }, meta: { isFetching: true } }),
    EDIT_CATEGORY_SUCCESSFULLY : (category) => ({ category, meta: { isFetching: false } }),
    EDIT_CATEGORY_FAILURE      : () => ({ meta: { isFetching: false } }),

    DELETE_CATEGORIES              : (categoryIds: number[]) => ({ attributes: { categoryIds }, meta: { isFetching: true } }),
    DELETE_CATEGORIES_SUCCESSFULLY : () => ({ meta: { isFetching: false } }),
    DELETE_CATEGORIES_FAILURE      : () => ({ meta: { isFetching: false } }),

    FETCH_CHANNELS              : (channelCategoryId: number) => ({ channels: [], attributes: { channelCategoryId }, meta: { isChannelsFetching: true } }),
    FETCH_CHANNELS_SUCCESSFULLY : (categoryChannels: IChannelModel[]) => ({ categoryChannels, meta: { isChannelsFetching: false } }),
    FETCH_CHANNELS_FAILURE      : () => ({ meta: { isChannelsFetching: false } }),

    ADD_CHANNEL              : (values: TChannelValues) => ({ attributes: { values }, meta: { isChannelsFetching: true } }),
    ADD_CHANNEL_SUCCESSFULLY : () => ({ meta: { isChannelsFetching: false } }),
    ADD_CHANNEL_FAILURE      : () => ({ meta: { isChannelsFetching: false } }),

    EDIT_CHANNEL              : (values: TChannelValues, initialCategoryId: number) => ({ attributes: { values, initialCategoryId }, meta: { isChannelsFetching: true } }),
    EDIT_CHANNEL_SUCCESSFULLY : () => ({ meta: { isChannelsFetching: false } }),
    EDIT_CHANNEL_FAILURE      : () => ({ meta: { isChannelsFetching: false } }),

    DELETE_CHANNEL              : (channelId: number, channelCategoryId: number) => ({ attributes: { channelId, channelCategoryId }, meta: { isChannelsFetching: true } }),
    DELETE_CHANNEL_SUCCESSFULLY : () => ({ meta: { isChannelsFetching: false } }),
    DELETE_CHANNEL_FAILURE      : () => ({ meta: { isChannelsFetching: false } }),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const channelsReducer = handleActions<IChannelsReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchCategories,
      fetchCategoriesSuccessfully,
      fetchCategoriesFailure,

      addCategory,
      addCategorySuccessfully,
      addCategoryFailure,

      editCategory,
      editCategorySuccessfully,
      editCategoryFailure,

      deleteCategories,
      deleteCategoriesSuccessfully,
      deleteCategoriesFailure,

      fetchChannels,
      fetchChannelsSuccessfully,
      fetchChannelsFailure,

      addChannel,
      addChannelSuccessfully,
      addChannelFailure,

      editChannel,
      editChannelSuccessfully,
      editChannelFailure,

      deleteChannel,
      deleteChannelSuccessfully,
      deleteChannelFailure,
    )]: (
      state  : IChannelsReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchCategories.toString()): {
          const actionTyped = action as IChannelsActions['fetchCategories'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, categories: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchCategoriesSuccessfully.toString()): {
          const actionTyped = action as IChannelsActions['fetchCategoriesSuccessfully'];
          
          return ({ ...state, categories: actionTyped.payload.categories, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta                      = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default channelsReducer;

interface IChannelsSelectors {
  getCategories       : (state: IState) => IChannelsReducer['categories'];
  getCategoryChannels : (state: IState) => IChannelsReducer['categoryChannels'];
  getMeta             : (state: IState) => IChannelsReducer['meta'];
}

export const {
  getCategories,
  getCategoryChannels,
  getMeta,
}: IChannelsSelectors = {
  getCategories       : (state: IState) => state[STATE_KEY].categories,
  getCategoryChannels : (state: IState) => state[STATE_KEY].categoryChannels,
  getMeta             : (state: IState) => state[STATE_KEY].meta,
};
