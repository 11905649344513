export const setEmptyStringsToNull = <T, >(values: { [key: string]: unknown | null }): T => {
  const valuesKeys = Object.keys(values);

  const result = valuesKeys.reduce((prev, curr) => {
    if (values[curr] || typeof values[curr] !== 'string') {
      return { ...prev, [curr]: values[curr] };
    }

    return { ...prev, [curr]: null };
  }, {});

  return result as T;
};
