import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }       from '@utils/apiCaller';
import { Notifications } from '@components/Notifications/Notifications';

import {
  fetchAffiliations,
  fetchAffiliationsSuccessfully,
  fetchAffiliationsFailure,
  addAffiliation,
  addAffiliationSuccessfully,
  addAffiliationFailure,
  editAffiliation,
  editAffiliationSuccessfully,
  editAffiliationFailure,
  deleteAffiliations,
  deleteAffiliationsSuccessfully,
  deleteAffiliationsFailure,

  getMeta,

  AffiliationTypes,
  IAffiliationActions,
} from './affiliationsReducer';

function* handleFetchAffiliations() {
  try {
    const { page, rowsPerPage, orderBy, order } = yield select(getMeta);
    const { data: { items, totalCount } }       = yield callApi(`feature/credential?page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}`);

    yield put(fetchAffiliationsSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchAffiliationsFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Affiliations. Please try again', 'error');
  }
}

function* handleAddAffiliation(action: IAffiliationActions[AffiliationTypes.addAffiliation]) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/credential', 'post', values);
    yield put(addAffiliationSuccessfully());
    yield put(replace('/admin/affiliations'));
    yield put(fetchAffiliations());
    yield call(Notifications.enqueueSnackbar, 'Affiliation was added successfully', 'success');
  } catch (e) {
    yield put(addAffiliationFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Affiliation. Please try again', 'error');
  }
}

function* handleEditAffiliation(action: IAffiliationActions[AffiliationTypes.editAffiliation]) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/credential', 'put', values);
    yield put(editAffiliationSuccessfully());
    yield call(Notifications.enqueueSnackbar, 'Affiliation was edited successfully', 'success');
  } catch (e) {
    yield put(editAffiliationFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Affiliation. Please try again', 'error');
  }
}

function* handleDeleteAffiliations(action: IAffiliationActions[AffiliationTypes.deleteAffiliations]) {
  try {
    const { credentialIds } = action.payload.attributes;

    for (let i = 0; i < credentialIds.length; i++) {
      yield callApi('feature/credential', 'delete', credentialIds[i]);
    }

    yield put(deleteAffiliationsSuccessfully());
    yield put(fetchAffiliations());
    yield call(Notifications.enqueueSnackbar, `${credentialIds.length > 1 ? 'Affiliations were' : 'Affiliation was'} deleted successfully`, 'success');
  } catch (e) {
    const { credentialIds } = action.payload.attributes;

    yield put(deleteAffiliationsFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${credentialIds.length > 1 ? 'Affiliations' : 'a Affiliation'}. Please try again`, 'error');
  }
}

function* affiliationsSagas() {
  yield all([
    takeLatest(fetchAffiliations, handleFetchAffiliations),
    takeLatest(addAffiliation, handleAddAffiliation),
    takeLatest(editAffiliation, handleEditAffiliation),
    takeLatest(deleteAffiliations, handleDeleteAffiliations),
  ]);
}

export { affiliationsSagas };
