import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { replace }                            from 'connected-react-router';

import { callApi }       from '@utils/apiCaller';
import { Notifications } from '@components/Notifications/Notifications';

import {
  fetchSlides,
  fetchSlidesSuccessfully,
  fetchSlidesFailure,
  addSlide,
  addSlideSuccessfully,
  addSlideFailure,
  editSlide,
  editSlideSuccessfully,
  editSlideFailure,
  deleteSlides,
  deleteSlidesSuccessfully,
  deleteSlidesFailure,

  getMeta,

  SliderTypes,
  ISliderActions,
} from './sliderReducer';

function* handleFetchSlides() {
  try {
    const { page, rowsPerPage, orderBy, order } = yield select(getMeta);
    const { data: { items, totalCount } }       = yield callApi(`feature/slide?page=${page}&itemsPerPage=${rowsPerPage}&sortingKey=${orderBy}&sortingOrder=${order}`);

    yield put(fetchSlidesSuccessfully(items, totalCount));
  } catch (e) {
    yield put(fetchSlidesFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while fetching Carousel. Please try again', 'error');
  }
}

function* handleAddSlide(action: ISliderActions[SliderTypes.addSlide]) {
  try {
    const values = action.payload.attributes;

    yield callApi('feature/slide', 'post', values);
    yield put(addSlideSuccessfully());
    yield put(replace('/admin/carousel'));
    yield put(fetchSlides());
    yield call(Notifications.enqueueSnackbar, 'Slide was added successfully', 'success');
  } catch (e) {
    yield put(addSlideFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while adding a Slide. Please try again', 'error');
  }
}

function* handleEditSlide(action: ISliderActions[SliderTypes.editSlide]) {
  try {
    const { values, withRecurring } = action.payload.attributes;

    yield callApi(`feature/slide?withRecurring=${!!withRecurring}`, 'put', values);
    yield put(editSlideSuccessfully());
    yield put(replace('/admin/carousel'));
    yield call(Notifications.enqueueSnackbar, 'Slide was edited successfully', 'success');
  } catch (e) {
    yield put(editSlideFailure());
    yield call(Notifications.enqueueSnackbar, 'Ooops... Something went wrong while editing a Slide. Please try again', 'error');
  }
}

function* handleDeleteSlides(action: ISliderActions[SliderTypes.deleteSlides]) {
  try {
    const { slideIds, withRecurring } = action.payload.attributes;

    for (let i = 0; i < slideIds.length; i++) {
      yield callApi(`feature/slide/${slideIds[i]}?withRecurring=${!!withRecurring}`, 'delete');
    }
    yield put(deleteSlidesSuccessfully());
    yield put(fetchSlides());
    yield call(Notifications.enqueueSnackbar, `${slideIds.length > 1 ? 'Slides were' : 'Slide was'} deleted successfully`, 'success');

  } catch (e) {
    const { slideIds } = action.payload.attributes;

    yield put(deleteSlidesFailure());
    yield call(Notifications.enqueueSnackbar, `Ooops... Something went wrong while deleting a ${slideIds.length > 1 ? 'Slides' : 'a Slide'}. Please try again`, 'error');
  }
}

function* sliderSagas() {
  yield all([
    takeLatest(fetchSlides, handleFetchSlides),
    takeLatest(addSlide, handleAddSlide),
    takeLatest(editSlide, handleEditSlide),
    takeLatest(deleteSlides, handleDeleteSlides),
  ]);
}

export { sliderSagas };
