import styled from 'styled-components';

interface IClickableItem {
  isClickable? : boolean;
  label        : string;
  onClick      : () => void;
}

export interface ITableMeta {
  order?       : 'Asc' | 'Desc';
  orderBy?     : string;
  page?        : number;
  rowsPerPage? : number;
}

export const ClickableItem = ({ isClickable = true, label, onClick }: IClickableItem) => {
  return (
    <ClickableItem.Name isClickable={isClickable} onClick={isClickable ? onClick : undefined}>
      {label}
    </ClickableItem.Name>
  );
};

ClickableItem.Name = styled.p<{ isClickable: boolean }>`
  color  : ${({ isClickable }) => (isClickable ? 'blue' : 'black')};
  cursor : ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;
