import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { ITableMeta }          from '@components/Table/ClickableItem';
import { IRequestedFeedModel } from '@models/index';
import { IState }              from '@store/rootReducer';

interface IRequestedFeedPatchModel {
  feedRequestId : number;
  Status        : number;
  EmailText     : string;
}

export const STATE_KEY = 'requestedFeeds';

export interface IRequestedFeedsReducer {
  requestedFeeds : IRequestedFeedModel[];
  meta           : Required<ITableMeta> & {
    isFetching          : boolean;
    totalRequestedFeeds : number;
  };
}

export enum RequestedFeedTypes {
  fetchRequestedFeeds             = 'fetchRequestedFeeds',
  fetchRequestedFeedsSuccessfully = 'fetchRequestedFeedsSuccessfully',
  fetchRequestedFeedsFailure      = 'fetchRequestedFeedsFailure',

  deleteRequestedFeeds             = 'deleteRequestedFeeds',
  deleteRequestedFeedsSuccessfully = 'deleteRequestedFeedsSuccessfully',
  deleteRequestedFeedsFailure      = 'deleteRequestedFeedsFailure',

  updateRequestedFeedStatus             = 'updateRequestedFeedStatus',
  updateRequestedFeedStatusSuccessfully = 'updateRequestedFeedStatusSuccessfully',
  updateRequestedFeedStatusFailure      = 'updateRequestedFeedStatusFailure',
}

export interface IRequestedFeedsActions {
  [RequestedFeedTypes.fetchRequestedFeeds]             : Action<{ meta: Partial<IRequestedFeedsReducer['meta']> & { isFetching: true } }>;
  [RequestedFeedTypes.fetchRequestedFeedsSuccessfully] : Action<{ requestedFeeds: IRequestedFeedModel[]; meta: { isFetching: false; totalRequestedFeeds: number } }>;
  [RequestedFeedTypes.fetchRequestedFeedsFailure]      : Action<{ meta: { isFetching: false } }>;

  [RequestedFeedTypes.deleteRequestedFeeds]             : Action<{ attributes: { requestedFeedsIds: number[] }; meta: { isFetching: true } }>;
  [RequestedFeedTypes.deleteRequestedFeedsSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [RequestedFeedTypes.deleteRequestedFeedsFailure]      : Action<{ meta: { isFetching: false } }>;

  [RequestedFeedTypes.updateRequestedFeedStatus]             : Action<{ attributes: IRequestedFeedPatchModel; meta: { isFetching: true } }>;
  [RequestedFeedTypes.updateRequestedFeedStatusSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [RequestedFeedTypes.updateRequestedFeedStatusFailure]      : Action<{ meta: { isFetching: false } }>;
}

export interface IRequestedFeedsActionsCreators {
  [RequestedFeedTypes.fetchRequestedFeeds]             : (meta?: Partial<IRequestedFeedsReducer['meta']>) => IRequestedFeedsActions[RequestedFeedTypes.fetchRequestedFeeds];
  [RequestedFeedTypes.fetchRequestedFeedsSuccessfully] : (result: IRequestedFeedModel[], total: number) => IRequestedFeedsActions[RequestedFeedTypes.fetchRequestedFeedsSuccessfully];
  [RequestedFeedTypes.fetchRequestedFeedsFailure]      : () => IRequestedFeedsActions[RequestedFeedTypes.fetchRequestedFeedsFailure];

  [RequestedFeedTypes.deleteRequestedFeeds]             : (ids: number[]) => IRequestedFeedsActions[RequestedFeedTypes.deleteRequestedFeeds];
  [RequestedFeedTypes.deleteRequestedFeedsSuccessfully] : () => IRequestedFeedsActions[RequestedFeedTypes.deleteRequestedFeedsSuccessfully];
  [RequestedFeedTypes.deleteRequestedFeedsFailure]      : () => IRequestedFeedsActions[RequestedFeedTypes.deleteRequestedFeedsFailure];

  [RequestedFeedTypes.updateRequestedFeedStatus]             : (values: IRequestedFeedPatchModel) => IRequestedFeedsActions[RequestedFeedTypes.updateRequestedFeedStatus];
  [RequestedFeedTypes.updateRequestedFeedStatusSuccessfully] : () => IRequestedFeedsActions[RequestedFeedTypes.updateRequestedFeedStatusSuccessfully];
  [RequestedFeedTypes.updateRequestedFeedStatusFailure]      : () => IRequestedFeedsActions[RequestedFeedTypes.updateRequestedFeedStatusFailure];
}

type Actions = IRequestedFeedsActions[RequestedFeedTypes.fetchRequestedFeeds]
| IRequestedFeedsActions[RequestedFeedTypes.fetchRequestedFeedsSuccessfully]
| IRequestedFeedsActions[RequestedFeedTypes.fetchRequestedFeedsFailure]
| IRequestedFeedsActions[RequestedFeedTypes.deleteRequestedFeeds]
| IRequestedFeedsActions[RequestedFeedTypes.deleteRequestedFeedsSuccessfully]
| IRequestedFeedsActions[RequestedFeedTypes.deleteRequestedFeedsFailure]
| IRequestedFeedsActions[RequestedFeedTypes.updateRequestedFeedStatus]
| IRequestedFeedsActions[RequestedFeedTypes.updateRequestedFeedStatusSuccessfully]
| IRequestedFeedsActions[RequestedFeedTypes.updateRequestedFeedStatusFailure];

const initialState: IRequestedFeedsReducer = {
  requestedFeeds : [],
  meta           : {
    isFetching          : false,
    order               : 'Asc',
    orderBy             : 'PublisherName',
    page                : 0,
    rowsPerPage         : 50,
    totalRequestedFeeds : 0,
  },
};

export const {
  fetchRequestedFeeds,
  fetchRequestedFeedsSuccessfully,
  fetchRequestedFeedsFailure,

  deleteRequestedFeeds,
  deleteRequestedFeedsSuccessfully,
  deleteRequestedFeedsFailure,

  updateRequestedFeedStatus,
  updateRequestedFeedStatusSuccessfully,
  updateRequestedFeedStatusFailure,
}: IRequestedFeedsActionsCreators = createActions({
  fetchRequestedFeeds             : (meta = {}) => ({ meta: { ...meta, isFetching: true } }),
  fetchRequestedFeedsSuccessfully : (requestedFeeds, totalRequestedFeeds) => ({ requestedFeeds, meta: { totalRequestedFeeds, isFetching: false } }),
  fetchRequestedFeedsFailure      : () => ({ meta: { isFetching: false } }),

  deleteRequestedFeeds             : (requestedFeedsIds) => ({ attributes: { requestedFeedsIds }, meta: { isFetching: true } }),
  deleteRequestedFeedsSuccessfully : () => ({ meta: { isFetching: false } }),
  deleteRequestedFeedsFailure      : () => ({ meta: { isFetching: false } }),

  updateRequestedFeedStatus             : (params: IRequestedFeedPatchModel) => ({ attributes: { ...params }, meta: { isFetching: true } }),
  updateRequestedFeedStatusSuccessfully : () => ({ meta: { isFetching: false } }),
  updateRequestedFeedStatusFailure      : () => ({ meta: { isFetching: false } }),
}, {
  prefix: STATE_KEY,
}) as any;

const requestedFeedsReducer = handleActions<IRequestedFeedsReducer>({
  // @ts-ignore
  [combineActions(
    fetchRequestedFeeds,
    fetchRequestedFeedsSuccessfully,
    fetchRequestedFeedsFailure,
    deleteRequestedFeeds,
    deleteRequestedFeedsSuccessfully,
    deleteRequestedFeedsFailure,
    updateRequestedFeedStatus,
    updateRequestedFeedStatusSuccessfully,
    updateRequestedFeedStatusFailure,
  )]: (
    state  : IRequestedFeedsReducer,
    action : Actions,
  ) => {
    switch (action.type) {
      case (fetchRequestedFeeds.toString()): {
        const actionTyped = action as IRequestedFeedsActions['fetchRequestedFeeds'];

        if (actionTyped.payload.meta.page === 0) {
          return ({
            ...state,
            requestedFeeds : [],
            meta           : { ...state.meta, ...actionTyped.payload.meta },
          });
        }

        return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
      }
      case (fetchRequestedFeedsSuccessfully.toString()): {
        const actionTyped = action as IRequestedFeedsActions['fetchRequestedFeedsSuccessfully'];

        return ({
          ...state,
          requestedFeeds : actionTyped.payload.requestedFeeds,
          meta           : { ...state.meta, ...actionTyped.payload.meta },
        });
      }
      default: {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const { attributes, meta, ...data } = action.payload as any;
        const nextMeta = { ...state.meta, ...meta };

        return ({ ...state, ...data, meta: nextMeta });
      }
    }
  },
}, initialState);

interface IRequestedFeedsSelectors {
  getRequestedFeeds : (state: IState) => IRequestedFeedModel[];
  getMeta           : (state: IState) => IRequestedFeedsReducer['meta'];
}

export const {
  getRequestedFeeds,
  getMeta,
}: IRequestedFeedsSelectors = {
  getRequestedFeeds : (state: IState) => state[STATE_KEY].requestedFeeds,
  getMeta           : (state: IState) => state[STATE_KEY].meta,
};

export default requestedFeedsReducer;
