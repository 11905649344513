import styled from 'styled-components';

import { Button }         from './Button';
import { theme as Theme } from '../../styles';

export interface FooterButtonsProps {
  margin?       : string;
  position?     : FOOTER_BUTTONS_POSITION;
  wrapperStyle? : (theme: typeof Theme) => string;
  buttons       : {
    color?  : string;
    label   : string;
    onClick : (...args: unknown[]) => void;
  }[],
}

export enum FOOTER_BUTTONS_POSITION {
  HORIZONTAL_FULL_WIDTH,
  HORIZONTAL_SEPARATED,
  VERTICAL,
}

export const FooterButtons = ({
  buttons,
  wrapperStyle,
  margin   = '0',
  position = FOOTER_BUTTONS_POSITION.HORIZONTAL_SEPARATED,
}: FooterButtonsProps) => (!buttons.length ? null : (
  <FooterButtons.Wrapper
    margin       = {margin}
    position     = {position}
    wrapperStyle = {wrapperStyle}
  >
    {buttons.map((item) => (
      <Button
        bordered       = {false}
        key            = {item.label}
        label          = {item.label}
        onClick        = {item.onClick}
        componentStyle = {{
          color    : item.color || Theme.color.blue,
          fontSize : '12px',
          height   : '19px',
          padding  : '0 11px',
        }}
      />
    ))}
  </FooterButtons.Wrapper>
));

FooterButtons.Wrapper = styled.div<{ margin: string; position: FOOTER_BUTTONS_POSITION; wrapperStyle: FooterButtonsProps['wrapperStyle'] }>`
  display        : flex;
  padding        : 0 16px;
  align-items    : flex-start;
  flex-direction : column;

  ${({ position }) => position !== FOOTER_BUTTONS_POSITION.VERTICAL && `
    align-items    : center;
    flex-direction : row;
  `}
  justify-content : ${({ position }) => (position === FOOTER_BUTTONS_POSITION.HORIZONTAL_FULL_WIDTH ? 'space-between' : 'flex-start')};
  margin          : ${({ margin }) => margin};

  button {
    &:first-child { padding-left: 0; }
  }

  ${({ position }) => position === FOOTER_BUTTONS_POSITION.HORIZONTAL_SEPARATED && `
    button:not(:first-child) { border-left: 1px solid #e3e3e3; }
  `}

  ${({ wrapperStyle, theme }) => wrapperStyle && wrapperStyle(theme)}
`;
