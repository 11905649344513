import { initializeDebounceFetchHook } from '@common/initializers/debounceFetchHook';
import { callApi }                     from '@utils/apiCaller';
import {
  MultipleAutoSuggest as CommonMultipleAutoSuggest,
  IMultipleAutoSuggest,
} from '@common/components/AutoSuggest';

const useDebounceFetch = initializeDebounceFetchHook(callApi);

export const MultipleAutoSuggest = ({ ...props }: IMultipleAutoSuggest) => (
  <CommonMultipleAutoSuggest {...props} debounceFetcher={useDebounceFetch} />
);
