import styled from 'styled-components';

import { UnseenIcon } from '../../../public/svg';

interface IUnseenCircle {
  margin? : string;
}

export const UnseenCircle = ({ margin }: IUnseenCircle) => (
  <UnseenCircle.Wrapper margin={margin}>
    <UnseenIcon />
  </UnseenCircle.Wrapper>
);

UnseenCircle.Wrapper = styled.div<{ margin?: string; size?: string }>`
  align-items      : center;
  background-color : #f5f6fa;
  border-radius    : 50%;
  display          : flex;
  justify-content  : center;
  height           : 32px;
  margin           : ${({ margin }) => margin || '0'};
  width            : 32px;
`;
