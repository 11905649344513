import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { ITagModel } from '@src/models';
import { IState }    from '@store/rootReducer';

import { ITagFormModel } from './pages/TagView';

export const STATE_KEY = 'tags';

export interface ITagsReducer {
  tags : ITagModel[];
  meta : {
    isFetching : boolean;
  };
}

export enum ETagsTypes {
  addTag             = 'addTag',
  addTagSuccessfully = 'addTagSuccessfully',
  addTagFailure      = 'addTagFailure',

  deleteTags             = 'deleteTags',
  deleteTagsSuccessfully = 'deleteTagsSuccessfully',
  deleteTagsFailure      = 'deleteTagsFailure',

  fetchTags             = 'fetchTags',
  fetchTagsSuccessfully = 'fetchTagsSuccessfully',
  fetchTagsFailure      = 'fetchTagsFailure',

  updateTag             = 'updateTag',
  updateTagSuccessfully = 'updateTagSuccessfully',
  updateTagFailure      = 'updateTagFailure',
}

export interface ITagsActions {
  [ETagsTypes.addTag]             : Action<{ attributes: ITagFormModel; meta: { isFetching: true } }>;
  [ETagsTypes.addTagSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ETagsTypes.addTagFailure]      : Action<{ meta: { isFetching: false } }>;

  [ETagsTypes.deleteTags]             : Action<{ attributes: { tagsIds: number[] }; meta: { isFetching: true } }>;
  [ETagsTypes.deleteTagsSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ETagsTypes.deleteTagsFailure]      : Action<{ meta: { isFetching: false } }>;

  [ETagsTypes.fetchTags]             : Action<{ meta: Partial<ITagsReducer['meta']> & { isFetching: true } }>;
  [ETagsTypes.fetchTagsSuccessfully] : Action<{ tags: ITagsReducer['tags']; meta: { isFetching: false } }>;
  [ETagsTypes.fetchTagsFailure]      : Action<{ meta: { isFetching: false } }>;

  [ETagsTypes.updateTag]             : Action<{ attributes: ITagFormModel; meta: { isFetching: true } }>;
  [ETagsTypes.updateTagSuccessfully] : Action<{ meta: { isFetching: false } }>;
  [ETagsTypes.updateTagFailure]      : Action<{ meta: { isFetching: false } }>;
}

export interface ITagsActionsCreators {
  [ETagsTypes.addTag]             : (values: ITagFormModel) => ITagsActions[ETagsTypes.addTag];
  [ETagsTypes.addTagSuccessfully] : () => ITagsActions[ETagsTypes.addTagSuccessfully];
  [ETagsTypes.addTagFailure]      : () => ITagsActions[ETagsTypes.addTagFailure];

  [ETagsTypes.deleteTags]             : (tagsIds: number[]) => ITagsActions[ETagsTypes.deleteTags];
  [ETagsTypes.deleteTagsSuccessfully] : () => ITagsActions[ETagsTypes.deleteTagsSuccessfully];
  [ETagsTypes.deleteTagsFailure]      : () => ITagsActions[ETagsTypes.deleteTagsFailure];

  [ETagsTypes.fetchTags]             : (meta?: Partial<ITagsReducer['meta']>) => ITagsActions[ETagsTypes.fetchTags];
  [ETagsTypes.fetchTagsSuccessfully] : (result: ITagsReducer['tags']) => ITagsActions[ETagsTypes.fetchTagsSuccessfully];
  [ETagsTypes.fetchTagsFailure]      : () => ITagsActions[ETagsTypes.fetchTagsFailure];

  [ETagsTypes.updateTag]             : (values: ITagFormModel) => ITagsActions[ETagsTypes.updateTag];
  [ETagsTypes.updateTagSuccessfully] : () => ITagsActions[ETagsTypes.updateTagSuccessfully];
  [ETagsTypes.updateTagFailure]      : () => ITagsActions[ETagsTypes.updateTagFailure];
}

type Actions = ITagsActions[ETagsTypes.fetchTags]
| ITagsActions[ETagsTypes.fetchTagsSuccessfully]
| ITagsActions[ETagsTypes.fetchTagsFailure]
| ITagsActions[ETagsTypes.addTag]
| ITagsActions[ETagsTypes.addTagSuccessfully]
| ITagsActions[ETagsTypes.addTagFailure]
| ITagsActions[ETagsTypes.deleteTags]
| ITagsActions[ETagsTypes.deleteTagsSuccessfully]
| ITagsActions[ETagsTypes.deleteTagsFailure]
| ITagsActions[ETagsTypes.updateTag]
| ITagsActions[ETagsTypes.updateTagSuccessfully]
| ITagsActions[ETagsTypes.updateTagFailure];

const initialState: ITagsReducer = {
  tags : [],
  meta : {
    isFetching : false,
  },
};

export const {
  addTag,
  addTagFailure,
  addTagSuccessfully,

  deleteTags,
  deleteTagsFailure,
  deleteTagsSuccessfully,

  fetchTags,
  fetchTagsSuccessfully,
  fetchTagsFailure,

  updateTag,
  updateTagSuccessfully,
  updateTagFailure,
}: ITagsActionsCreators = createActions({
  addTag             : (params: ITagFormModel, tagsMeta = {}) => ({ attributes: { ...params }, meta: { ...tagsMeta, isFetching: true } }),
  addTagSuccessfully : () => ({ meta: { isFetching: false } }),
  addTagFailure      : () => ({ meta: { isFetching: false } }),

  deleteTags             : (tagsIds: number[]) => ({ attributes: { tagsIds }, meta: { isFetching: true } }),
  deleteTagsFailure      : () => ({ meta: { isFetching: false } }),
  deleteTagsSuccessfully : () => ({ meta: { isFetching: false } }),

  fetchTags             : (meta = {}) => ({ meta: { ...meta, isFetching: true } }),
  fetchTagsSuccessfully : (tags: ITagsReducer['tags']) => ({ tags, meta: { isFetching: false } }),
  fetchTagsFailure      : () => ({ meta: { isFetching: false } }),

  updateTag             : (params: ITagFormModel) => ({ attributes: { ...params }, meta: { isFetching: true } }),
  updateTagSuccessfully : () => ({ meta: { isFetching: false } }),
  updateTagFailure      : () => ({ meta: { isFetching: false } }),
}, {
  prefix: STATE_KEY,
}) as any;

const tagsReducer = handleActions<ITagsReducer>({
  // @ts-ignore
  [combineActions(
    addTag,
    addTagFailure,
    addTagSuccessfully,

    deleteTags,
    deleteTagsFailure,
    deleteTagsSuccessfully,

    fetchTags,
    fetchTagsSuccessfully,
    fetchTagsFailure,

    updateTag,
    updateTagSuccessfully,
    updateTagFailure,
  )]: (
    state  : ITagsReducer,
    action : Actions,
  ) => {
    switch (action.type) {
      case (fetchTags.toString()): {
        const actionTyped = action as ITagsActions['fetchTags'];

        return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
      }
      case (fetchTagsSuccessfully.toString()): {
        const actionTyped  = action as ITagsActions['fetchTagsSuccessfully'];
  
        return ({
          ...state,
          tags : actionTyped.payload.tags,
          meta : { ...state.meta, ...actionTyped.payload.meta },
        });
      }
      default: {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const { attributes, meta, ...data } = action.payload as any;
        const nextMeta = { ...state.meta, ...meta };

        return ({ ...state, ...data, meta: nextMeta });
      }
    }
  },
}, initialState);

interface ITagsSelectors {
  getTags : (state: IState) => ITagsReducer['tags'];
  getMeta : (state: IState) => ITagsReducer['meta'];
}

export const {
  getTags,
  getMeta,
}: ITagsSelectors = {
  getTags : (state: IState) => state[STATE_KEY].tags,
  getMeta : (state: IState) => state[STATE_KEY].meta,
};

export default tagsReducer;
