import styled from 'styled-components';

import { Empty } from '../Empty';

interface INotFoundWindowProps {
  message  : string;
  onClick? : () => void;
}

export const NotFoundWindow = ({ message, onClick }: INotFoundWindowProps) => (
  <NotFoundWindow.Wrapper>
    <Empty />
    <NotFoundWindow.Message onClick={onClick}>
      {message}
    </NotFoundWindow.Message>
  </NotFoundWindow.Wrapper>
);

NotFoundWindow.Message = styled.p`
  color      : ${({ theme }) => theme.color.darkGray};
  cursor     : pointer;
  text-align : center;
  transition : all 0.2s;

  &:hover { color: ${({ theme }) => theme.color.blue}; }
`;

NotFoundWindow.Wrapper = styled.div`
  cursor: default;

  > div { padding: 3px 0 10px; }
`;
