import { Form, Field, FormikProps }       from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import styled                             from 'styled-components';

import {
  Card,
  Button,
  Input,
  Label,
  FormButtons,
} from '@common/components';

import { ColorSelect }                      from '@components/ColorSelect/ColorSelect';
import { MediaUploader }                    from '@components/MediaUploader/MediaUploader';
import { IElement }                         from '@components/List/FormList';
import { Title }                            from '@components/Title/Title';

import { Modal }                            from '@src/App';
import { IChannelModel, IChannelsCategory } from '@src/models';
import { useFetchEntity }                   from '@utils/fetchEntity';

import { AddChannelContainer, TChannelValues } from '../pages/AddChannelContainer';
import { TChannelsCategoryFormModel }          from '../pages/AddCategoryContainer';
import { ChannelsList }                        from './ChannelsList';

interface ICategoryForm extends FormikProps<TChannelsCategoryFormModel> {
  addChannel         : (values: TChannelValues) => void;
  categoryChannels   : IChannelModel[];
  deleteChannel      : (channelId: number, channelCategoryId: number) => void;
  editChannel        : (values: TChannelValues, initialCategoryId: number) => void;
  isChannelsFetching : boolean;
  isFetching         : boolean;
}

export const CategoryForm = ({
  addChannel,
  categoryChannels,
  deleteChannel,
  editChannel,
  isChannelsFetching,
  isFetching,
  values,
  submitForm,
}: ICategoryForm) => {
  const modalDataRef = useRef<IChannelModel | null>(null);

  const onClickAddChannel = useCallback(() => {
    Modal.open(AddChannelContainer, {
      isChannelsFetching,
      onSubmit : (values: TChannelValues) => addChannel(values),
      parent   : values,
    });
  }, [isChannelsFetching, values]);

  const onClickEditChannel = useCallback((element: IElement) => {
    modalDataRef.current = element.source as IChannelModel;

    Modal.open(AddChannelContainer, {
      isChannelsFetching,
      channelData : element.source as IChannelModel,
      onSubmit    : (channel: TChannelValues) => editChannel(channel, values.channelCategoryId as number),
      parent      : values,
    });
  }, [isChannelsFetching, values]);

  useEffect(() => {
    if (Modal.update) {
      Modal.update(AddChannelContainer, {
        isChannelsFetching,
        channelData : modalDataRef.current,
        onSubmit    : modalDataRef.current?.channelId ? editChannel : addChannel,
        parent      : values,
      });
    }
  }, [isChannelsFetching, modalDataRef, values]);

  const [category] = useFetchEntity('feature/channelCategory') as [IChannelsCategory | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${category ? 'edit the' : 'add a'} channels category`} />
      <CategoryForm.Wrapper>
        <CategoryForm.Column>
          <Field
            label       = "name"
            name        = "name"
            placeholder = "Enter a name"
            component   = {Input}
          />
          <Field
            component = {ColorSelect}
            label     = "Color"
            name      = "color"
          />
        </CategoryForm.Column>

        <CategoryForm.Column rightSide>
          <Field
            isWithResizer
            withRemove
            required        = {false}
            aspectRatio     = {378 / 112}
            label           = "header image (Optional)"
            name            = "categoryHeaderUri"
            mimeTypeOptions = "image"
            container       = "channels"
            component       = {MediaUploader}
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '100px',
            }}
          />
          <FormButtons
            disabled      = {{main: isFetching}}
            onClickCancel = {() => history.back()}
            onSubmit      = {submitForm}
          />
        </CategoryForm.Column>
      </CategoryForm.Wrapper>

      {values.channelCategoryId && (
        <>
          <CategoryForm.Separator />
          <CategoryForm.LabelCategoryWrapper>
            <Label label="Category channels" />
            <Button
              disabled = {isChannelsFetching}
              label    = "Add channel"
              type     = "button"
              onClick  = {onClickAddChannel}
            />
          </CategoryForm.LabelCategoryWrapper>
          <ChannelsList
            channelCategoryId  = {values.channelCategoryId as number}
            categoryChannels   = {values.isCitySpecific ? null : categoryChannels}
            isChannelsUpdating = {isChannelsFetching}
            onDelete           = {deleteChannel}
            onEdit             = {onClickEditChannel}
          />
        </>
      )}
    </Card>
  );
};

CategoryForm.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

CategoryForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;

CategoryForm.Separator = styled.div`
  border-bottom : 1px solid ${({ theme }) => theme.borderColor.gray};
  margin-top    : 12px;
  margin-bottom : 20px;
`;

CategoryForm.LabelCategoryWrapper = styled.div`
  margin-top      : 16px;
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
`;
