import { Formik, FormikProps }       from 'formik';
import { useMemo, useCallback }      from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { connect }                   from 'react-redux';
import * as Yup                      from 'yup';
import styled                        from 'styled-components';

import { 
  validateStringForSpecialSymbolsOnly, 
  validateTheCorrectTimeDirection, 
  validateTheTimeDifference }          from '@common/utils';
import { CircularLoader }              from '@components/Loaders/CircularLoader';
import {
  validateSelectedAreas,
  IAreaSelectionItem,
  mapAreasToBannedType,
} from '@components/AreaSelection/AreaSelection';

import { useFetchEntity }                 from '@utils/fetchEntity';
import { IAdvertisingModel, IOptionType } from '@src/models';
import { IState }                         from '@store/rootReducer';

import { AdvertisingForm } from '../components/AdvertisingForm';
import {
  addAdvertising    as addAdvertisingAction,
  updateAdvertising as updateAdvertisingAction,
  getMeta,
  IAdvertisingActionsCreators,
} from '../advertisingReducer';

export type TAdvertisingFormModel = Omit<IAdvertisingModel, 'adId' | 'image' | 'imageId' | 'rAdChannel' | 'rAdTerritory'> & {
  adId?       : number;
  imageId?    : number;
  rAdChannel  : IOptionType[];
  rAdTerritory: IAreaSelectionItem[];
  image       : {
    imageUri : string;
    mimeType : string;
  } | null;
}

export type TAdvertisingPostModel = Omit<TAdvertisingFormModel, 'image' | 'rAdChannel' | 'rAdTerritory'> & {
  rAdTerritory: ReturnType<typeof mapAreasToBannedType>;
  rAdChannel  : {
    adId?     : number;
    channelId : number;
  }[];
  image : {
    adId?    : number;
    imageId? : number;
    imageUri : string;
    mimeType : string;
  };
};

interface IAdvertising {
  addAdvertising    : IAdvertisingActionsCreators['addAdvertising'];
  updateAdvertising : IAdvertisingActionsCreators['updateAdvertising'];
  isFetching        : boolean;
}

const useValidationSchema = (t: TFunction<'translation'>) => Yup.object().shape({
  rAdTerritory : validateSelectedAreas(),
  dateStart : Yup.string()
    .typeError('Select sponsor starts date')
    .required('Select sponsor starts date'),
  dateEnd : Yup.string()
    .typeError('Select sponsor ends date')
    .test(...validateTheCorrectTimeDirection(t, 'dateStart'))
    .test(...validateTheTimeDifference(t, 'dateStart'))
    .required('Select sponsor ends date'),
  image : Yup.object()
    .shape({ imageUri : Yup.string().required() })
    .typeError('Upload sponsor image of video')
    .required(),
  name : Yup.string()
    .max(100, 'Max character count is 100')
    .test(...validateStringForSpecialSymbolsOnly((value) => value))
    .typeError('Enter sponsor name')
    .required('Name is a required field'),
  rAdChannel : Yup.array().min(1, 'Select at least 1 channel').of(
    Yup.object().shape({ value : Yup.number().required() }),
  ),
  websiteUri : Yup.string()
    .url('It does not look like a valid url')
    .max(200, 'Max character count is 200')
    .nullable(),
});

const AdvertisingC = ({
  addAdvertising,
  updateAdvertising,
  isFetching,
}: IAdvertising) => {
  const [advertising, isAdvertisingFetching] = useFetchEntity('feature/ad') as [IAdvertisingModel | null, boolean, () => Promise<void>];
  const { t }                                = useTranslation();
  const validationSchema                     = useValidationSchema(t);

  const initialValues = useMemo<TAdvertisingFormModel>(() => ({
    name       : '',
    websiteUri : '',
    dateStart  : '',
    dateEnd    : '',
    timeZone   : Intl.DateTimeFormat().resolvedOptions().timeZone,
    allDay     : false,
    ...advertising,
    rAdTerritory : (advertising?.rAdTerritory || []) as unknown as IAreaSelectionItem[],
    rAdChannel   : (advertising?.rAdChannel || []).map((channel) => ({
      label : channel.channelName,
      value : channel.channelId,
    })),
    image : advertising?.image ? {
      imageUri : advertising.image.imageUri,
      mimeType : advertising.image.mimeType,
    } : null,
  }), [advertising]);

  const onSubmit = useCallback(async (values: TAdvertisingFormModel) => {
    const { adId, image, imageId, rAdChannel, rAdTerritory, ...restValues } = values;

    if (!image) {
      return;
    }

    const requestData: TAdvertisingPostModel = {
      ...restValues,
      adId,
      rAdChannel   : rAdChannel.map((channel) => ({ adId, channelId: channel.value })),
      rAdTerritory : mapAreasToBannedType(rAdTerritory, { adId }),
      image        : {
        adId,
        imageId,
        imageUri : image.imageUri,
        mimeType : image.mimeType,
      },
      
    };

    if (adId) {
      updateAdvertising(requestData);
    } else {
      addAdvertising(requestData);
    }
  }, []);

  if (isAdvertisingFetching) return (
    <CircularLoader />
  );

  return (
    <Container>
      <Formik
        initialValues    = {initialValues}
        validationSchema = {validationSchema}
        validateOnBlur   = {false}
        onSubmit         = {onSubmit}
      >
        {({ ...props }: FormikProps<TAdvertisingFormModel>) => (
          <AdvertisingForm
            {...props}
            isFetching = {isFetching}
          />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 948px; }
`;

const mapStateToProps = (state: IState) => ({
  isFetching: getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addAdvertising    : addAdvertisingAction,
  updateAdvertising : updateAdvertisingAction,
};

export const Advertising = connect(mapStateToProps, mapDispatchToProps)(AdvertisingC);
