import { Select as SelectAntd }   from 'antd';
import { Key }                    from 'antd/lib/table/interface';
import { FieldProps }             from 'formik';
import { useCallback, useEffect } from 'react';
import styled                     from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

import { TOption } from '../../models';

const { Option } = SelectAntd;

interface IBorderlessSelect {
  label         : string;
  onEmptyField? : (options: TOption[]) => void;
  options       : TOption[];
  wrapperStyle? : { margin?: string };
}

export const BorderlessSelect = ({
  field,
  form,
  label,
  onEmptyField,
  options,
  wrapperStyle,
}: IBorderlessSelect & FieldProps) => {
  const onChange = useCallback((value: Key) => {
    form.setFieldValue(field.name, value);
  }, []);

  useEffect(() => {
    if (!field.value && !!options.length) {
      if (onEmptyField) {
        onEmptyField(options);
      } else {
        form.setFieldValue(field.name, options[0].value);
      }
    }
  }, [form, onEmptyField, options]);

  return (
    <BorderlessSelect.Wrapper {...wrapperStyle}>
      <BorderlessSelect.Label>{label}</BorderlessSelect.Label>
      {!options.length ? (
        <BorderlessSelect.LoaderWrapper>
          <LoadingOutlined />
        </BorderlessSelect.LoaderWrapper>
      ) : (
        <SelectAntd
          bordered      = {false}
          dropdownStyle = {{ minWidth: '250px' }}
          onChange      = {onChange}
          value         = {field.value}
        >
          {options.map((option) => (
            <Option
              key   = {option.value}
              value = {option.value}
            >
              {option.label}
            </Option>
          ))}
        </SelectAntd>
      )}
    </BorderlessSelect.Wrapper>
  );
};

BorderlessSelect.LoaderWrapper = styled.div`
  align-items : center;
  color       : ${({ theme }) => theme.color.darkGray};
  display     : flex;
  margin-left : 10px;

  > span { line-height: 0 !important; }
`;

BorderlessSelect.Label = styled.p`
  color       : ${({ theme }) => theme.color.darkGray};
  font-size   : 14px;
  line-height : 20px;
`;

BorderlessSelect.Wrapper = styled.div<{ margin?: string; }>`
  margin  : ${({ margin }) => margin || '24px 0 0'};
  display : flex;
  height  : 20px;

  .ant-select {
    color     : ${({ theme }) => theme.color.black};
    height    : 20px;
    max-width : 400px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder { line-height: 20px; }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border  : none;
    height  : 20px;
    padding : 0 7px;

    .ant-select-selection-search-input { height: 20px; }
  }

  input,
  span {
    line-height : 20px;
    font-size   : 14px;
  }
`;
