import { Form, Field, FormikProps } from 'formik';
import { useCallback, useMemo }     from 'react';
import { useTranslation }           from 'react-i18next';
import { Suggestion }               from 'react-places-autocomplete';
import styled                       from 'styled-components';

import { DeleteOutlined } from '@ant-design/icons';

import {
  Checkbox,
  Input,
  Select,
  Card,
  FormButtons,
  PlacesAutocomplete,
} from '@common/components';
import { GoogleMapPoints } from '@components/PlacesAutocomplete/GoogleMapPoints';
import { AutoSuggest }     from '@components/Autosuggest/Autosuggest';
import { Title }           from '@components/Title/Title';

import { INITIAL_LOCATION, TERRITORY_TYPES }   from '@modules/territories/pages/AddTerritoryContainerL';
import { ILocation, ITerritoryModel, LOCALES } from '@src/models';
import { useFetchEntity }                      from '@utils/fetchEntity';

import { ITerritoryFieldsSettings } from '../pages/AddTerritoryContainerL';
import { ZipCodeSelect }            from './ZipCodeSelect';

interface IFormTerritory extends FormikProps<Omit<ITerritoryModel, 'territoryId'>> {
  fieldsSettings : ITerritoryFieldsSettings;
  isFetching     : boolean;
}

export const getChildType = (type: TERRITORY_TYPES) => {
  if (type === TERRITORY_TYPES.COUNTRY) {
    return TERRITORY_TYPES.STATE;
  } else if (type === TERRITORY_TYPES.STATE) {
    return TERRITORY_TYPES.CITY;
  }
};

const getParentType = (type: TERRITORY_TYPES) => {
  if (![TERRITORY_TYPES.COUNTRY, TERRITORY_TYPES.STATE].includes(type)) {
    return TERRITORY_TYPES.STATE;
  } else if (type === TERRITORY_TYPES.STATE) {
    return TERRITORY_TYPES.COUNTRY;
  }

  return '';
};

export const TYPES_TO_SHOW_ALL_FORM = [
  TERRITORY_TYPES.CITY,
  TERRITORY_TYPES.CITY_COLLECTION,
  TERRITORY_TYPES.DIVIDED_CITY,
];

const TYPES_WITH_SEVERAL_LOCATIONS = [
  TERRITORY_TYPES.CITY_COLLECTION,
  TERRITORY_TYPES.DIVIDED_CITY,
];

export const FormTerritory = ({ fieldsSettings, isFetching, setFieldValue, values, submitForm }: IFormTerritory) => {
  const { t } = useTranslation();

  const isCityDeletionAvailable    = useMemo(() => (values.locations as ILocation[])?.length > 1, [values]);
  const isNewCityCreationAvailable = useMemo(() => !values.locations?.find((item) => !item.googlePlaceId), [values]);

  const addNewCity = useCallback(() => {
    setFieldValue('locations', [...(values.locations as ILocation[]), INITIAL_LOCATION]);
  }, [values]);

  const deleteSelectedCity = useCallback((cityIndex: number) => {
    setFieldValue('locations', values.locations?.filter((_item, index) => index !== cityIndex));
  }, [values]);

  const filterGooglePlaceSuggestions = useCallback((suggestions: readonly Suggestion[]) => {
    if (values.type !== TERRITORY_TYPES.DIVIDED_CITY) return suggestions;

    if (suggestions.length
      && !!suggestions[0].description.split(' ').find((item) => item === 'UK')
      && (suggestions[0].types.includes('postal_code') || suggestions[0].types.includes('postal_code_prefix'))) {
      return suggestions.filter((item) => item.types.includes('postal_code_prefix'));
    }

    return suggestions.filter((item) => !(item.types.includes('street_address') || item.types.includes('country') || item.types.includes('administrative_area_level_1')));
  }, [values]);

  const mapSearchResponseParent = useCallback((data: any[]) => {
    return data.map((d: any) => ({
      label : `${d.name}${d.parentName ? `, ${d.parentName}` : ''}`,
      value : d.territoryId,
    }));
  }, []);

  const mapSearchResponseChannel = useCallback((data: any[]) => {
    return data.map((d: any) => ({
      label : d.name,
      value : d.channelId,
    }));
  }, []);

  const [territory] = useFetchEntity('feature/Territory') as [ITerritoryModel | null, boolean, () => Promise<void>];

  return (
    <Card>
      <Title name={`${territory ? 'edit the' : 'add an'} area`} />
      <FormTerritory.Wrapper noValidate>
        <FormTerritory.Column>
          {TYPES_TO_SHOW_ALL_FORM.indexOf(values.type as TERRITORY_TYPES) >= 0 && (
            <>
              <Field
                label     = {t('availableInApp')}
                name      = "availableInMobileApp"
                component = {Checkbox}
              />
              <div style={{ marginBottom: '50px' }}> </div>
            </>
          )}
          <Field
            disabled         = {fieldsSettings?.parentName.isDisabled || values.type === TERRITORY_TYPES.COUNTRY}
            required         = {values.type !== TERRITORY_TYPES.COUNTRY}
            label            = "parent"
            name             = "parent"
            fieldName        = "parentId"
            placeholder      = "None"
            component        = {AutoSuggest}
            endpoint         = {`feature/territory/search?type=${getParentType(values.type)}&name`}
            responseMapper   = {mapSearchResponseParent}
          />
          <Field
            label       = "name"
            name        = "name"
            placeholder = "Name this Area"
            component   = {Input}
          />
          {TYPES_TO_SHOW_ALL_FORM.includes(values.type) && (
            <>
              <GoogleMapPoints points={values.locations} />
              <Field
                required    = {false}
                label       = "locale"
                name        = "locale"
                placeholder = "EN-US"
                options     = {LOCALES}
                component   = {Select}
              />
              <Field
                required        = {false}
                label           = "primary channel (optional)"
                name            = "channel"
                placeholder     = "Type a Channel Name"
                endpoint        = "feature/channel/search?citySpecificOnly=true&name"
                fieldName       = "channelId"
                responseMapper  = {mapSearchResponseChannel}
                component       = {AutoSuggest}
              />
            </>
          )}
        </FormTerritory.Column>

        <FormTerritory.Column rightSide>
          <Field
            disabled    = {fieldsSettings?.type.isDisabled}
            label       = "type"
            name        = "type"
            placeholder = "Choose an Area Type"
            options     = {fieldsSettings.type.options}
            component   = {Select}
          />
          {TYPES_TO_SHOW_ALL_FORM.includes(values.type) && (values.locations as ILocation[])
            .map((location, cityIndex) => (TYPES_WITH_SEVERAL_LOCATIONS.includes(values.type) ? true : cityIndex === 0) && (
              <FormTerritory.LocationFields key={location.googlePlaceId || cityIndex}>
                {TYPES_WITH_SEVERAL_LOCATIONS.includes(values.type) && cityIndex === 0}
                <Field
                  autoComplete      = "nope"
                  label             = "location lookup"
                  filterSuggestions = {filterGooglePlaceSuggestions}
                  types             = {[values.type === TERRITORY_TYPES.DIVIDED_CITY ? 'geocode' : '(cities)']}
                  locationPath      = {`locations[${cityIndex}]`}
                  name              = {`locations[${cityIndex}].address1`}
                  placeholder       = "Type a City or Town Location"
                  component         = {PlacesAutocomplete}
                />
                <Field
                  disabled    = {values.type === TERRITORY_TYPES.DIVIDED_CITY}
                  label       = "city"
                  name        = {`locations[${cityIndex}].city`}
                  placeholder = "City"
                  component   = {Input}
                />
                <Field
                  disabled    = {values.type === TERRITORY_TYPES.DIVIDED_CITY}
                  label       = "state"
                  name        = {`locations[${cityIndex}].state`}
                  placeholder = "State or Province"
                  component   = {Input}
                />
                <Field
                  disabled    = {values.type === TERRITORY_TYPES.DIVIDED_CITY}
                  label       = "country"
                  name        = {`locations[${cityIndex}].country`}
                  placeholder = "Country"
                  component   = {Input}
                />
                {values.type === TERRITORY_TYPES.DIVIDED_CITY && (
                  <Field
                    component = {ZipCodeSelect}
                    location  = {location}
                    name      = {`locations[${cityIndex}].postCodes`}
                  />
                )}
                {TYPES_WITH_SEVERAL_LOCATIONS.includes(values.type) && (
                  <>
                    <FormTerritory.ActionSection
                      flexPosition      = {(cityIndex === values.locations.length - 1) ? 'space-between' : 'flex-end'}
                      isAddAvailable    = {isNewCityCreationAvailable}
                      isDeleteAvailable = {isCityDeletionAvailable}
                    >
                      {cityIndex === values.locations.length - 1 && (
                        <p onClick={isNewCityCreationAvailable ? addNewCity : () => {/**/}}>+ Add Another Location Lookup</p>
                      )}
                      <DeleteOutlined onClick={isCityDeletionAvailable ? () => deleteSelectedCity(cityIndex) : undefined} />
                    </FormTerritory.ActionSection>
                    <hr />
                  </>
                )}
              </FormTerritory.LocationFields>
            ))}
        </FormTerritory.Column>
      </FormTerritory.Wrapper>

      <FormButtons
        disabled      = {{main: isFetching}}
        onClickCancel = {() => history.back()}
        onSubmit      = {submitForm}
      />
    </Card>
  );
};

FormTerritory.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : start;
  `}
`;

FormTerritory.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;

FormTerritory.AddAnotherCityButton = styled.div<{ isActive: boolean }>`
  padding: 10px 0 0 210px;

  > p {
    color     : ${({ isActive }) => (isActive ? 'blue' : 'silver')};
    cursor    : ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
    font-size : 18px;
  }
`;

FormTerritory.ActionSection = styled.div<{ flexPosition: string; isAddAvailable: boolean; isDeleteAvailable: boolean }>`
  align-items     : center;
  display         : flex;
  justify-content : ${({ flexPosition }) => flexPosition};
  padding         : 10px 10px 0 210px;
  width           : 100%;

  > svg {
    color     : ${({ isDeleteAvailable }) => (isDeleteAvailable ? 'black' : 'silver')};
    cursor    : ${({ isDeleteAvailable }) => (isDeleteAvailable ? 'pointer' : 'not-allowed')};
    font-size : 30px;
  }

  > p {
    color     : ${({ isAddAvailable }) => (isAddAvailable ? 'blue' : 'silver')};
    cursor    : ${({ isAddAvailable }) => (isAddAvailable ? 'pointer' : 'not-allowed')};
    font-size : 18px;
  }
`;

FormTerritory.LocationFields = styled.div`
  > hr { opacity: 0.3; }

  > hr:not(:first-child) { margin-top: 10px; }
`;

FormTerritory.ButtonWrapper = styled.div`
  display         : flex;
  justify-content : end;
`;
