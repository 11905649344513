import { Formik, FormikProps }                    from 'formik';
import { useMemo, useCallback, useEffect }        from 'react';
import { connect }                                from 'react-redux';
import * as Yup                                   from 'yup';
import styled                                     from 'styled-components';

import { CircularLoader } from '@components/Loaders/CircularLoader';

import { IBusinessModel, IBusinessLocation } from '@models/index';
import { useFetchEntity }                    from '@utils/fetchEntity';
import { IState }                            from '@store/rootReducer';

import {
  addBusiness   as addBusinessAction,
  editBusiness  as editBusinessAction,
  fetchBranches as fetchBranchesAction,
  addBranch     as addBranchAction,
  editBranch    as editBranchAction,
  deleteBranch  as deleteBranchAction,
  getBranches,
  getMeta,
  IBusinessesActionsCreators,
}                       from '../businessesReducer';
import { FormBusiness } from '../components/FormBusiness';

interface IBusinessesComponent {
  branches         : IBusinessLocation[];
  addBusiness      : IBusinessesActionsCreators['addBusiness'];
  editBusiness     : IBusinessesActionsCreators['editBusiness'];
  fetchBranches    : IBusinessesActionsCreators['fetchBranches'];
  addBranch        : IBusinessesActionsCreators['addBranch'];
  editBranch       : IBusinessesActionsCreators['editBranch'];
  deleteBranch     : IBusinessesActionsCreators['deleteBranch'];
  isBranchFetching : boolean;
  isFetching       : boolean;
}

const validationSchema = Yup.object().shape({
  name : Yup.string()
    .typeError('Enter business name')
    .required(),
});

const BusinessesComponent = ({
  branches,
  addBusiness,
  editBusiness,
  fetchBranches,
  addBranch,
  editBranch,
  deleteBranch,
  isBranchFetching,
  isFetching,
}: IBusinessesComponent) => {
  const [business, isBusinessFetching] = useFetchEntity('feature/Business') as [IBusinessModel | null, boolean, () => Promise<void>];

  const initialValues = useMemo<IBusinessModel>(() => ({
    businessId    : business?.businessId || undefined,
    name          : business?.name || '',
    userIsBlocked : !!business?.userIsBlocked,
  }), [business]);

  const onSubmit = useCallback((values: IBusinessModel) => {
    if (business) {
      editBusiness(values);
    } else {
      addBusiness(values);
    }
  }, [business]);

  const branchesData = useMemo(() => {
    return branches.map((branch) => ({
      key     : branch.businessLocationId as number,
      primary : branch.name,
      source  : branch,
    }));
  }, [branches]);

  useEffect(() => {
    if (business?.businessId) {
      fetchBranches(business.businessId);
    }
  }, [business]);

  if (isBusinessFetching) return (
    <CircularLoader />
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validateOnBlur   = {false}
        validationSchema = {validationSchema}
      >
        {({ ...props }: FormikProps<IBusinessModel>) => (
          <FormBusiness
            {...props}
            addBranch        = {addBranch}
            branchesData     = {branchesData}
            deleteBranch     = {deleteBranch}
            editBranch       = {editBranch}
            isBranchFetching = {isBranchFetching}
            isEditMode       = {!!business}
            isFetching       = {isFetching}
          />
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 947px; }
`;

const mapStateToProps = (state: IState) => ({
  branches         : getBranches(state),
  isBranchFetching : getMeta(state).isBranchFetching,
  isFetching       : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  addBusiness   : addBusinessAction,
  editBusiness  : editBusinessAction,
  fetchBranches : fetchBranchesAction,
  addBranch     : addBranchAction,
  editBranch    : editBranchAction,
  deleteBranch  : deleteBranchAction,
};

export const Businesses = connect(mapStateToProps, mapDispatchToProps)(BusinessesComponent);
