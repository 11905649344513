import { forwardRef, useCallback, useMemo, useState } from 'react';
import styled                                         from 'styled-components';
import { Form, Field, Formik }                        from 'formik';
import * as Yup                                       from 'yup';

import { Input, Button }  from '@common/components';
import { MediaUploader }  from '@components/MediaUploader/MediaUploader';
import { Title }          from '@components/Title/Title';
import { ICategoryModel } from '@src/models';

import { validateCategoryName } from '../pages/AddCategoryContainer';

interface ISubcategory {
  subIconUri : any;
  subName    : string;
}

interface IAddSubcategory {
  deleteSubcategory : (values: ICategoryModel) => void;
  isLoading         : boolean;
  saveSubcategory   : (values: ICategoryModel) => void;
  subcategoryValues : ICategoryModel | null;
}

export const AddSubcategory = forwardRef(({
  deleteSubcategory,
  isLoading,
  saveSubcategory,
  subcategoryValues,
}: IAddSubcategory, ref) => {
  const [isDeletionConfirm, setDeletionConfirmStatus] = useState(false);

  const initialValues = useMemo<ISubcategory>(() => ({
    subIconUri : subcategoryValues?.iconUri || null,
    subName    : subcategoryValues?.name || '',
  }), [subcategoryValues]);

  const validationSchema = useMemo(() => Yup.object().shape({
    subIconUri : Yup.object()
      .shape({ imageUri: Yup.string().required('Upload category icon') })
      .typeError('Upload category icon').required('Upload category icon'),
    subName    : validateCategoryName(false, subcategoryValues?.name),
  }), [subcategoryValues]);

  const onSubmitValues = useCallback((values: ISubcategory) => {
    saveSubcategory({
      ...subcategoryValues,
      iconUri : values.subIconUri.imageUri,
      name    : values.subName,
    });
  }, [saveSubcategory, subcategoryValues]);

  const onClickDelete = useCallback(() => {
    if (isDeletionConfirm) {
      deleteSubcategory(subcategoryValues as ICategoryModel);
    } else {
      setDeletionConfirmStatus(true);
    }
  }, [subcategoryValues, isDeletionConfirm]);

  return (
    <Container ref={ref} tabIndex={1}>
      <Formik
        initialValues    = {initialValues}
        onSubmit         = {onSubmitValues}
        validationSchema = {validationSchema}
      >
        <Form>
          <Title name={`${subcategoryValues?.categoryId ? 'Edit' : 'Add'} Subcategory`} />
          <Field
            label     = "name"
            name      = "subName"
            component = {Input}
          />
          <Field
            label           = "icon image"
            name            = "subIconUri"
            labelAlignment  = "top"
            container       = "subcategory"
            component       = {MediaUploader}
            mimeTypeOptions = "image"
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '58px',
              width           : '58px',
              borderRadius    : '50%',
            }}
          />
          <ButtonWrapper>
            {subcategoryValues?.categoryId && (
              <Button
                type           = "button"
                onClick        = {onClickDelete}
                label          = {isDeletionConfirm ? 'Confirm' : 'Delete'}
                disabled       = {isLoading}
                componentStyle = {{margin: '0 20px 0 0'}}
              />
            )}
            <Button
              label    = {subcategoryValues?.categoryId ? 'Edit' : 'Add'}
              disabled = {isLoading}
            />
          </ButtonWrapper>
        </Form>
      </Formik>
    </Container>
  );
});

const ButtonWrapper = styled.div`
  display         : flex;
  justify-content : end;
`;

const Container = styled.div<{ ref: any }>`
  background-color : #fff;
  width            : 400px;

  @media (min-width: 1284px) { width: 600px; }
`;
