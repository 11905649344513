import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState }            from '@store/rootReducer';
import { IAffiliationModel } from '@models/index';
import { ITableMeta }        from '@components/Table/ClickableItem';

export const STATE_KEY = 'affiliations';

export interface IAffiliationsReducer {
  affiliations : IAffiliationModel[];
  meta         : {
    isFetching        : boolean;
    totalAffiliations : number;
    page              : number;
    rowsPerPage       : number;
    orderBy           : string;
    order             : 'Asc' | 'Desc';
  };
}

export enum AffiliationTypes {
  fetchAffiliations              = 'fetchAffiliations',
  fetchAffiliationsSuccessfully  = 'fetchAffiliationsSuccessfully',
  fetchAffiliationsFailure       = 'fetchAffiliationsFailure',
  addAffiliation                 = 'addAffiliation',
  addAffiliationSuccessfully     = 'addAffiliationSuccessfully',
  addAffiliationFailure          = 'addAffiliationFailure',
  editAffiliation                = 'editAffiliation',
  editAffiliationSuccessfully    = 'editAffiliationSuccessfully',
  editAffiliationFailure         = 'editAffiliationFailure',
  deleteAffiliations             = 'deleteAffiliations',
  deleteAffiliationsSuccessfully = 'deleteAffiliationsSuccessfully',
  deleteAffiliationsFailure      = 'deleteAffiliationsFailure',
}

export interface IAffiliationActions {
  [AffiliationTypes.fetchAffiliations]              : Action<{ meta: ITableMeta }>;
  [AffiliationTypes.fetchAffiliationsSuccessfully]  : Action<{ affiliations: IAffiliationModel[]; meta: { totalAffiliations: number } }>;
  [AffiliationTypes.fetchAffiliationsFailure]       : Action<{}>;
  [AffiliationTypes.addAffiliation]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [AffiliationTypes.addAffiliationSuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [AffiliationTypes.addAffiliationFailure]          : Action<{ meta: { isFetching: false }}>;
  [AffiliationTypes.editAffiliation]                : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [AffiliationTypes.editAffiliationSuccessfully]    : Action<{ meta: { isFetching: false }}>;
  [AffiliationTypes.editAffiliationFailure]         : Action<{ meta: { isFetching: false }}>;
  [AffiliationTypes.deleteAffiliations]             : Action<{ attributes: { credentialIds: number[]; meta: { isFetching: true } }}>;
  [AffiliationTypes.deleteAffiliationsSuccessfully] : Action<{}>;
  [AffiliationTypes.deleteAffiliationsFailure]      : Action<{}>;
}

export interface IAffiliationActionsCreators {
  [AffiliationTypes.fetchAffiliations]              : (affiliationMeta?: ITableMeta) => IAffiliationActions[AffiliationTypes.fetchAffiliations];
  [AffiliationTypes.fetchAffiliationsSuccessfully]  : (affiliations: IAffiliationModel[], totalCount: number) => IAffiliationActions[AffiliationTypes.fetchAffiliationsSuccessfully];
  [AffiliationTypes.fetchAffiliationsFailure]       : () => IAffiliationActions[AffiliationTypes.fetchAffiliationsFailure];
  [AffiliationTypes.addAffiliation]                 : (values: any) => IAffiliationActions[AffiliationTypes.addAffiliation];
  [AffiliationTypes.addAffiliationSuccessfully]     : () => IAffiliationActions[AffiliationTypes.addAffiliationSuccessfully];
  [AffiliationTypes.addAffiliationFailure]          : () => IAffiliationActions[AffiliationTypes.addAffiliationFailure];
  [AffiliationTypes.editAffiliation]                : (values: any) => IAffiliationActions[AffiliationTypes.editAffiliation];
  [AffiliationTypes.editAffiliationSuccessfully]    : () => IAffiliationActions[AffiliationTypes.editAffiliationSuccessfully];
  [AffiliationTypes.editAffiliationFailure]         : () => IAffiliationActions[AffiliationTypes.editAffiliationFailure];
  [AffiliationTypes.deleteAffiliations]             : (credentialIds: number[]) => IAffiliationActions[AffiliationTypes.deleteAffiliations];
  [AffiliationTypes.deleteAffiliationsSuccessfully] : () => IAffiliationActions[AffiliationTypes.deleteAffiliationsSuccessfully];
  [AffiliationTypes.deleteAffiliationsFailure]      : () => IAffiliationActions[AffiliationTypes.deleteAffiliationsFailure];
}

type Actions = IAffiliationActions[AffiliationTypes.fetchAffiliationsSuccessfully]
| IAffiliationActions[AffiliationTypes.fetchAffiliationsFailure]
| IAffiliationActions[AffiliationTypes.addAffiliation]
| IAffiliationActions[AffiliationTypes.addAffiliationSuccessfully]
| IAffiliationActions[AffiliationTypes.addAffiliationFailure]
| IAffiliationActions[AffiliationTypes.editAffiliationSuccessfully]
| IAffiliationActions[AffiliationTypes.editAffiliationFailure]
| IAffiliationActions[AffiliationTypes.deleteAffiliations]
| IAffiliationActions[AffiliationTypes.deleteAffiliationsSuccessfully]
| IAffiliationActions[AffiliationTypes.deleteAffiliationsFailure];

const initialState: IAffiliationsReducer = {
  affiliations : [],
  meta         : {
    isFetching        : false,
    totalAffiliations : 0,
    page              : 0,
    rowsPerPage       : 50,
    orderBy           : 'Name',
    order             : 'Asc',
  },
};

export const {
  fetchAffiliations,
  fetchAffiliationsSuccessfully,
  fetchAffiliationsFailure,
  addAffiliation,
  addAffiliationSuccessfully,
  addAffiliationFailure,
  editAffiliation,
  editAffiliationSuccessfully,
  editAffiliationFailure,
  deleteAffiliations,
  deleteAffiliationsSuccessfully,
  deleteAffiliationsFailure,
}: IAffiliationActionsCreators = createActions(
  {
    FETCH_AFFILIATIONS              : (
      affiliationMeta = {},
    ) => ({ meta: { ...affiliationMeta, isFetching: true } }),
    FETCH_AFFILIATIONS_SUCCESSFULLY : (affiliations, totalAffiliations) => ({ affiliations, meta: { totalAffiliations, isFetching: false } }),
    FETCH_AFFILIATIONS_FAILURE      : () => ({ meta: { isFetching: false } }),

    ADD_AFFILIATION              : (values) => ({ attributes: values, meta: { isFetching: true }}),
    ADD_AFFILIATION_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    ADD_AFFILIATION_FAILURE      : () => ({ meta: { isFetching: false }}),

    EDIT_AFFILIATION              : (values) => ({ attributes: values, meta: { isFetching: true }}),
    EDIT_AFFILIATION_SUCCESSFULLY : () => ({ meta: { isFetching: false }}),
    EDIT_AFFILIATION_FAILURE      : () => ({ meta: { isFetching: false }}),

    DELETE_AFFILIATIONS              : (credentialIds) => ({ attributes: { credentialIds }, meta: { isFetching: true } }),
    DELETE_AFFILIATIONS_SUCCESSFULLY : () => ({}),
    DELETE_AFFILIATIONS_FAILURE      : () => ({}),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const affiliationsReducer = handleActions<IAffiliationsReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchAffiliations,
      fetchAffiliationsSuccessfully,
      addAffiliation,
      addAffiliationSuccessfully,
      addAffiliationFailure,
      editAffiliation,
      editAffiliationSuccessfully,
      editAffiliationFailure,
      deleteAffiliations,
      deleteAffiliationsSuccessfully,
      deleteAffiliationsFailure,
    )]: (
      state  : IAffiliationsReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchAffiliations.toString()): {
          const actionTyped = action as IAffiliationActions['fetchAffiliations'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, affiliations: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return  ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchAffiliationsSuccessfully.toString()): {
          const actionTyped = action as IAffiliationActions['fetchAffiliationsSuccessfully'];

          return ({ ...state, affiliations: actionTyped.payload.affiliations, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default affiliationsReducer;

interface IAffiliationsSelectors {
  getAffiliations : (state: IState) => IAffiliationModel[];
  getMeta         : (state: IState) => IAffiliationsReducer['meta'];
}

export const getAffiliations: IAffiliationsSelectors['getAffiliations'] = (state: IState) => state[STATE_KEY].affiliations;
export const getMeta: IAffiliationsSelectors['getMeta']                 = (state: IState) => state[STATE_KEY].meta;
