import { useCallback }     from 'react';
import { connect }         from 'react-redux';

import { Login as CommonLogin }   from '@common/modules/Login';
import { callApi }                from '@utils/apiCaller';

import {
  saveLogInData,
  getErrorMessage,
  getMeta,
  IAuthActionsCreators,
}                                 from '../authReducer';

const { CLIENT_ID }     = process.env;
const { CLIENT_SECRET } = process.env;

interface ILoginComponent {
  saveLogInData: IAuthActionsCreators['saveLogInData'];
}

export const Login = ({ saveLogInData }: ILoginComponent) => {
  const onLogin = useCallback(() => {
    saveLogInData();
  }, []);

  return (
    <CommonLogin
      apiHandler        = {callApi}
      clientId          = {CLIENT_ID || ''}
      clientSecret      = {CLIENT_SECRET || ''}
      onLogin           = {onLogin}
      resetPasswordLink = ''
    />
  );
};

const mapStateToProps = (state: any) => ({
  errorMessage : getErrorMessage(state),
  isFetching   : getMeta(state).isFetching,
});

const mapDispatchToProps = {
  saveLogInData,
};

export const LogIn = connect(mapStateToProps, mapDispatchToProps)(Login);
