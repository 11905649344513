import 'react-image-crop/dist/ReactCrop.css';

import { useEffect, useState, useCallback, useRef }   from 'react';
import ReactCrop, { Crop, PixelCrop, ReactCropProps } from 'react-image-crop';
import styled                                         from 'styled-components';

import { getCroppedImg, centerImageAspectCrop } from './utils';

export type TReactCropPropsProps = Omit<ReactCropProps, | 'crop' | 'onChange' | 'onComplete'>;

export interface IBaseImageResizerProps extends TReactCropPropsProps {
  file          : File;
  getResizeFile : (file: () => Promise<File>) => void;
}

export const BaseImageResizer = ({ aspect, file, getResizeFile, ...reactCropProps }: IBaseImageResizerProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [crop, setCrop]                   = useState<Crop>();
  const [imageSrc, setImageSrc]           = useState('');

  const convertFileToFileReader = () => {
    setCrop(undefined);

    const reader = new FileReader();

    reader.addEventListener('load', () => setImageSrc(reader.result?.toString() || ''));
    reader.readAsDataURL(file);
  };

  const resizeFile = async () => {
    if (imageRef.current && completedCrop) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, completedCrop);

      if (croppedImageUrl) {
        const croppedFile = new File([croppedImageUrl], file.name, { type: file.type });

        return croppedFile;
      }
    }

    return file;
  };

  const onImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { height, width } = e.currentTarget;

      setCrop(centerImageAspectCrop(width, height, aspect));
    }
  }, [aspect]);

  useEffect(() => {
    convertFileToFileReader();
  }, []);

  useEffect(() => {
    getResizeFile(resizeFile);
  }, [resizeFile]);

  return (
    <BaseImageResizer.Wrapper>
      <ReactCrop
        {...reactCropProps}
        aspect     = {aspect}
        crop       = {crop}
        onChange   = {(_, percentCrop) => setCrop(percentCrop)}
        onComplete = {setCompletedCrop}
      >
        <img
          alt    = "resizer-canvas"
          onLoad = {onImageLoad}
          ref    = {imageRef}
          src    = {imageSrc}
        />
      </ReactCrop>
    </BaseImageResizer.Wrapper>
  );
};

BaseImageResizer.Wrapper = styled.div`
  background-color : #e6e9f4;
  display          : flex;
  margin-bottom    : 12px;
  max-width        : 740px;

  img { max-height: 650px; }
`;
