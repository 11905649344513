import { Avatar } from 'antd';
import styled     from 'styled-components';

import { UserOutlined } from '@ant-design/icons';

import { Username, UsernameProps } from './Username';
import { NewsieLogo }              from '../../../public/svg';

interface HeaderProps extends UsernameProps {
  accountImageUri?  : string;
  maxWidth?         : string;
  NotificationBell? : React.ReactNode;
  onClickLogo?      : () => void;
  TerritorySelect?  : React.ReactNode;
  withProfileImage? : boolean;
}

export const Header = ({
  accountImageUri,
  maxWidth,
  NotificationBell,
  onClickLogo,
  TerritorySelect,
  withProfileImage = true,
  ...usernameProps
}: HeaderProps) => (
  <Header.Wrapper>
    <Header.Container maxWidth={maxWidth}>
      <Header.LeftSide>
        <Header.LogoWrapper onClick={onClickLogo}>
          <NewsieLogo />
        </Header.LogoWrapper>
        {TerritorySelect}
      </Header.LeftSide>
      <Header.RightSide>
        {NotificationBell}
        {withProfileImage && (
          <Header.Avatar
            icon = {<UserOutlined />}
            size = {36}
            src  = {accountImageUri}
          />
        )}
        <Header.Username {...usernameProps} />
      </Header.RightSide>
    </Header.Container>
  </Header.Wrapper>
);

Header.Avatar = styled(Avatar)`
  margin-right: 12px;
`;

Header.Container = styled.div<{ maxWidth?: string; }>`
  align-items     : center;
  height          : 100%;
  display         : flex;
  justify-content : space-between;
  margin          : 0 auto;
  padding         : ${({ maxWidth }) => (maxWidth ? '0' : '0 28px')};
  width           : 100%;

  ${({ maxWidth }) => `max-width: ${maxWidth};`}

  > div { display: flex; }
`;

Header.LogoWrapper = styled.div<{ onClick?: () => void; }>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

Header.Username = styled(Username)`
  margin-right: 29px;
`;

Header.LeftSide = styled.div`
  > div > p { margin-top: 10.5px; }

  > img { width: 106px; }
`;

Header.RightSide = styled.div`
  align-items: center;
`;

Header.Wrapper = styled.div`
  box-shadow : 0 1px 4px #15223214;
  height     : 68px;
  width      : 100%;
  z-index    : 300;
`;
