type TGetImageTypeFromImageSrcUtil = (
  imageSrc      : string,
  defaultValue? : string | null,
) => string | null;

const getImageTypeFromImageSrc: TGetImageTypeFromImageSrcUtil = (
  imageSrc,
  defaultValue = 'image/jpeg',
) => {
  const regExp = /^data:(\w+\/\w+)/;
  const result = imageSrc.match(regExp);

  return Array.isArray(result) ? result[1] : defaultValue;
};

export { getImageTypeFromImageSrc };
