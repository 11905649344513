import {
  createActions,
  handleActions,
  combineActions,
  Action,
} from 'redux-actions';

import { IState }      from '@store/rootReducer';
import { ISlideModel } from '@models/index';
import { ITableMeta }  from '@components/Table/ClickableItem';

export const STATE_KEY = 'slider';

export interface ISliderReducer {
  slides : ISlideModel[];
  meta   : {
    isFetching  : boolean;
    totalSlides : number;
    page        : number;
    rowsPerPage : number;
    orderBy     : string;
    order       : 'Asc' | 'Desc';
  };
}

export enum SliderTypes {
  fetchSlides              = 'fetchSlides',
  fetchSlidesSuccessfully  = 'fetchSlidesSuccessfully',
  fetchSlidesFailure       = 'fetchSlidesFailure',
  addSlide                 = 'addSlide',
  addSlideSuccessfully     = 'addSlideSuccessfully',
  addSlideFailure          = 'addSlideFailure',
  editSlide                = 'editSlide',
  editSlideSuccessfully    = 'editSlideSuccessfully',
  editSlideFailure         = 'editSlideFailure',
  deleteSlides             = 'deleteSlides',
  deleteSlidesSuccessfully = 'deleteSlidesSuccessfully',
  deleteSlidesFailure      = 'deleteSlidesFailure',
}

export interface ISliderActions {
  [SliderTypes.fetchSlides]              : Action<{ meta: ITableMeta }>;
  [SliderTypes.fetchSlidesSuccessfully]  : Action<{ slides: ISlideModel[]; meta: { totalSlides: number } }>;
  [SliderTypes.fetchSlidesFailure]       : Action<{}>;
  [SliderTypes.addSlide]                 : Action<{ attributes: { [values: string]: any }; meta: { isFetching: true }}>;
  [SliderTypes.addSlideSuccessfully]     : Action<{ meta: { isFetching: false }}>;
  [SliderTypes.addSlideFailure]          : Action<{ meta: { isFetching: false }}>;
  [SliderTypes.editSlide]                : Action<{ attributes: { values: { [f: string]: any }; withRecurring: boolean }; meta: { isFetching: true }}>;
  [SliderTypes.editSlideSuccessfully]    : Action<{ meta: { isFetching: false }}>;
  [SliderTypes.editSlideFailure]         : Action<{ meta: { isFetching: false }}>;
  [SliderTypes.deleteSlides]             : Action<{ attributes: { slideIds: number[]; withRecurring?: boolean }; meta: { isFetching: true } }>;
  [SliderTypes.deleteSlidesSuccessfully] : Action<{}>;
  [SliderTypes.deleteSlidesFailure]      : Action<{}>;
}

export interface ISliderActionsCreators {
  [SliderTypes.fetchSlides]              : (slideMeta?: ITableMeta) => ISliderActions[SliderTypes.fetchSlides];
  [SliderTypes.fetchSlidesSuccessfully]  : (slides: ISlideModel[], totalCount: number) => ISliderActions[SliderTypes.fetchSlidesSuccessfully];
  [SliderTypes.fetchSlidesFailure]       : () => ISliderActions[SliderTypes.fetchSlidesFailure];
  [SliderTypes.addSlide]                 : (values: any) => ISliderActions[SliderTypes.addSlide];
  [SliderTypes.addSlideSuccessfully]     : () => ISliderActions[SliderTypes.addSlideSuccessfully];
  [SliderTypes.addSlideFailure]          : () => ISliderActions[SliderTypes.addSlideFailure];
  [SliderTypes.editSlide]                : (values: any, withRecurring: boolean) => ISliderActions[SliderTypes.editSlide];
  [SliderTypes.editSlideSuccessfully]    : () => ISliderActions[SliderTypes.editSlideSuccessfully];
  [SliderTypes.editSlideFailure]         : () => ISliderActions[SliderTypes.editSlideFailure];
  [SliderTypes.deleteSlides]             : (slideIds: number[], withRecurring?: boolean) => ISliderActions[SliderTypes.deleteSlides];
  [SliderTypes.deleteSlidesSuccessfully] : () => ISliderActions[SliderTypes.deleteSlidesSuccessfully];
  [SliderTypes.deleteSlidesFailure]      : () => ISliderActions[SliderTypes.deleteSlidesFailure];
}

type Actions = ISliderActions[SliderTypes.fetchSlidesSuccessfully]
| ISliderActions[SliderTypes.fetchSlidesFailure]
| ISliderActions[SliderTypes.addSlide]
| ISliderActions[SliderTypes.addSlideSuccessfully]
| ISliderActions[SliderTypes.addSlideFailure]
| ISliderActions[SliderTypes.editSlideSuccessfully]
| ISliderActions[SliderTypes.editSlideFailure]
| ISliderActions[SliderTypes.deleteSlides]
| ISliderActions[SliderTypes.deleteSlidesSuccessfully]
| ISliderActions[SliderTypes.deleteSlidesFailure];

const initialState: ISliderReducer = {
  slides : [],
  meta   : {
    isFetching  : false,
    totalSlides : 0,
    page        : 0,
    rowsPerPage : 50,
    orderBy     : 'DateStart',
    order       : 'Asc',
  },
};

export const {
  fetchSlides,
  fetchSlidesSuccessfully,
  fetchSlidesFailure,
  addSlide,
  addSlideSuccessfully,
  addSlideFailure,
  editSlide,
  editSlideSuccessfully,
  editSlideFailure,
  deleteSlides,
  deleteSlidesSuccessfully,
  deleteSlidesFailure,
}: ISliderActionsCreators = createActions(
  {
    FETCH_SLIDES              : (
      slideMeta = {},
    ) => ({ meta: { ...slideMeta, isFetching: true } }),
    FETCH_SLIDES_SUCCESSFULLY : (slides, totalSlides) => ({ slides, meta: { totalSlides, isFetching: false } }),
    FETCH_SLIDES_FAILURE      : () => ({ meta: { isFetching: false } }),

    ADD_SLIDE              : (values) => ({ attributes: values, meta: { isFetching: true } }),
    ADD_SLIDE_SUCCESSFULLY : () => ({ meta: { isFetching: false } }),
    ADD_SLIDE_FAILURE      : () => ({ meta: { isFetching: false } }),

    EDIT_SLIDE              : (values, withRecurring) => ({ attributes: { values, withRecurring }, meta: { isFetching: true } }),
    EDIT_SLIDE_SUCCESSFULLY : () => ({ meta: { isFetching: false } }),
    EDIT_SLIDE_FAILURE      : () => ({ meta: { isFetching: false } }),

    DELETE_SLIDES              : (slideIds, withRecurring) => ({ attributes: { slideIds, withRecurring }, meta: { isFetching: true } }),
    DELETE_SLIDES_SUCCESSFULLY : () => ({}),
    DELETE_SLIDES_FAILURE      : () => ({}),
  },
  {
    prefix: STATE_KEY,
  },
) as any;

const sliderReducer = handleActions<ISliderReducer>(
  {
    // @ts-ignore
    [combineActions(
      fetchSlides,
      fetchSlidesSuccessfully,
      addSlide,
      addSlideSuccessfully,
      addSlideFailure,
      editSlide,
      editSlideSuccessfully,
      editSlideFailure,
      deleteSlides,
      deleteSlidesSuccessfully,
      deleteSlidesFailure,
    )]: (
      state  : ISliderReducer,
      action : Actions,
    ) => {
      switch (action.type) {
        case (fetchSlides.toString()): {
          const actionTyped = action as ISliderActions['fetchSlides'];
          if (actionTyped.payload.meta.page === 0) {
            return ({ ...state, slides: [], meta: { ...state.meta, ...actionTyped.payload.meta } });
          }

          return ({ ...state, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        case (fetchSlidesSuccessfully.toString()): {
          const actionTyped = action as ISliderActions['fetchSlidesSuccessfully'];
       
          return ({ ...state, slides: actionTyped.payload.slides, meta: { ...state.meta, ...actionTyped.payload.meta } });
        }
        default:
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { attributes, meta, ...data } = action.payload as any;
          const nextMeta = { ...state.meta, ...meta };

          return ({ ...state, ...data, meta: nextMeta });
      }
    },
  },
  initialState,
);

export default sliderReducer;

interface ISliderSelectors {
  getSlides : (state: IState) => ISlideModel[];
  getMeta   : (state: IState) => ISliderReducer['meta'];
}

export const getSlides: ISliderSelectors['getSlides'] = (state: IState) => state[STATE_KEY].slides;
export const getMeta: ISliderSelectors['getMeta']     = (state: IState) => state[STATE_KEY].meta;
